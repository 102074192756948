import React, { useEffect,useState } from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    
    useEffect(() => {
        if (user.id) {
            history.replace("/")
        }
    }, [user.id])

    const handleSubmit = async (e) => {
        e.preventDefault();
    
    }
    return (
        <div className="register comptecree" >
        
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                        
                        <Form className="mb-5"  >


                        <div>
                              <img src='/images/valide.png' style={{width:'150px', marginBottom:'30px'}} />
                        </div>


                            <h3 className="mb-4" >Votre compte  a bien été crée</h3>
                       
                             <br/><br/>
                         
                             
                         
                             <Link to="/login?espace=pro" className='ps-btn ps-btn--fullwidth ps-btn-dark btn btn-primary' style={{background:'#000', color:'#FFF', borderColor:'#000'}}>Connexion</Link>
                        </Form>
                        
                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;