import React,{useEffect,useState,useRef} from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { loginUser } from "../../actions/user";
import { LOGOUT_USER } from "../../constants/actions";
import {IS_SUBMITED} from "../../constants/actions"
import { registerUser } from "../../actions/user";
import { useParams } from "react-router";
import PhoneInput from 'react-phone-input-2'
import { apiClient } from "../../actions/api"


const Page = () => {
    const dispatch = useDispatch();
    const { id } = useParams()

    const { user } = useSelector(state => state.user)
    const { issubmited } = useSelector(state => state.global)
    const {timezone} = useSelector(state => state.mixedmode)

    const history = useHistory()
    const [active,setActive]=useState(2);
    const [active2,setActive2]=useState(1);
    const [active3,setActive3]=useState(1);
    const [iflivreur,setIflivreur]=useState(false);
    const [phone, setPhone] = useState('')
    const [ifloading, setIfloading] = useState(0)
     const [adolecent, setAdolecent] = useState(false);

    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [filename1, setFilename1] = useState('');
    const [filename2, setFilename2] = useState('');



    const queryParams = new URLSearchParams(window.location.search);
    const back = queryParams.get('back');
    const tab = queryParams.get('tab');
    const livreur = queryParams.get('livreur');
    const espace = queryParams.get('espace');

       const handleClickfile = (t) => {

      switch(t){
             case 1:
               fileInput.current.click()
             break;
              case 2:
               fileInput2.current.click()
             break;

      }

  }

    const handleRemovefile = (t) => {

      switch(t){
             case 1:
                setFile1('')
             break;
              case 2:
                 setFile2('')
             break;



      }
      return false;

  }

function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'jpeg':
    case 'bmp':
    case 'png':
    case 'pdf':
    case 'doc':
    case 'docx':
      //etc
      return true;
  }
  return false;
}


function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}


    const handleFileChange = (event,t) => {

      const formData =  new FormData();
      formData.append('file',event.target.files[0]);



      if(t==1  ){
      setFilename1(event.target.files[0].name)
      }
      else{
         setFilename2(event.target.files[0].name)
      }

       if(t==1 || t==2 ){

            if(!isImage(event.target.files[0].name)){
               alert('Veuillez sélectionner un fichier valide (png,jpg,jpeg,gif,pdf,doc..)')
               return false;
            }

            formData.append('typefile','image');

       }


      setIfloading(1)

      apiClient().post(`/uploadfiles`,formData)
              .then((res) => {

         if(res.data.status=='successs'){
          setIfloading(0)

        switch(t){
             case 1:
                setFile1(res.data.message)
             break;
              case 2:
                setFile2(res.data.message)
             break;




      }

                }
                else{
                     setIfloading(0)
                alert('Erreur !')
                }

        })

  }


    useEffect(() => {

        if(issubmited){
            dispatch({ type: IS_SUBMITED, payload: false })
            if(espace=='patient'){
               history.push("/compte-cree");
            }

        }

        if(back || tab){
          setActive(2)
        }

       if(livreur){
          setActive2(3)
          setIflivreur(true)
        }




        if (user?.user_id) {
        if(user?.type=='A' || user?.google_id=='A'){
            if(back){
               history.replace("/"+back)
            }
            else{

                   if(user?.initpwd === 1){
                      history.replace("/profile")

                   }
                   else{
                     history.replace("/client/dashboard")
                   }


            }
        }
        else if(user.type=='V' || user.google_id=='V'){

                 if(user?.initpwd === 1){
                      history.replace("/praticien/profile")

                   }
                   else{
                      history.replace("/praticien/dashboard/")
                   }




        }

        }


    }, [user,issubmited])

    const handleTabs=(a)=>{

             setActive(a)

    }

    const handleTabs2=(a)=>{

             setActive2(a)
             if(a==2){
               history.push("/devenir-vendeur/")
             }

    }
      const handleTabs3=(a)=>{

             setActive3(a)

    }

    const handleSubmit = (e) => {


        e.preventDefault();

        let type='';
        if(espace=='patient'){

             type='A';


        }
        else if(espace=='pro'){
              type='V';

        }


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

         if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }






        dispatch(loginUser(formDataObj,type));
    }

  const handleSubmitAchteur = (e) => {


        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        // formDataObj.timezone = timezone
        formDataObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }
        let type='';

        if(espace=='patient'){

             type='A';

            if(formDataObj.adolesant!=1){
            setAdolecent(true)

            formDataObj.adolescent=1
             if(!file1){
                alert('Veuillez télécharger une autorisation scannée et signée par l’un des responsables légaux')
                  return false;

            }
            else{
                 formDataObj.file1=file1
            }


             if(!file2){
                  alert('Veuillez télécharger la copie de la pièce d’identité du responsable légal ayant signé l’autorisation')
                  return false;

            }
            else{
              formDataObj.file2=file2
            }



        }


        }
        else if(espace=='pro'){
              type='V';

        }






        dispatch(registerUser(formDataObj,type));

    }


     const handleSubmitTherapeut = (e) => {


        e.preventDefault();


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

          if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }

        dispatch(loginUser(formDataObj));
    }

   const gotTocreate = (e) => {
         history.replace("/register")
    }

     const handleChangecheckbox = event => {
    if (event.target.checked) {
       setAdolecent(false)
    } else {
     setAdolecent(true)
    }

  };




    return (
        <div className="login" >
           <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Login</li></ul></div></div></div>
          </section>
           <section className="section2" style={{backgroundColor:'#FFF',paddingTop: '25px'}}>
            <Container>

            <br/>

            <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Bienvenue sur Feel Better™ </h2>
                                  {
                              espace=='patient' ?
                               <div class="text-center"><a href="javascript:void(0)" class="btnlink marauto" style={{    color: '#FFF',
    marginTop: '21px'}} >Espace pour les patients</a></div>

                              :
                              <div class="text-center"><a href="javascript:void(0)" class="btnlink marauto" style={{    color: '#FFF',
    marginTop: '21px'}} >Espace pour les professionnels</a></div>


                            }


                           </div>
                        </div>
            </div>




                <Row>
                    <Col lg={{ span: 6 }} className="text-center mx-auto my-5" >





                      <div className="mb-5 divform forshadolog">

                        <div className='section8890'>
                         <a className={active == 2 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(2)} >Connexion</a>
                         <a className={active == 1 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(1)}  >Inscription</a>

                      </div>

                         {
                         active ==1 ?

                         <div>







                          {
                             espace == 'patient' ?

                      <Form className="mb-5" onSubmit={handleSubmitAchteur}  >

                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="first_name"
                                    type="text" placeholder="Votre Prénom *" required  />
                            </Form.Group>
                             <Form.Group className="mb-3" >

                                <Form.Control
                                    name="last_name"
                                    type="text" placeholder="Votre Nom *" required />
                            </Form.Group>

                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="country"
                                    type="text" placeholder="Pays de résidence *" required />
                            </Form.Group>

                               <Form.Group className="mb-3" >

                                <Form.Control
                                    name="city"
                                    type="text" placeholder="Ville ou commune *" required />
                            </Form.Group>




                           <Form.Group className="mb-3" >

                              <PhoneInput
                                          country={'fr'}
                                          value={phone}
                                          name={"phone"}
                                          enableLongNumbers={true}
                                           inputProps={{
                                              name: 'phone',
                                              required: true,
                                              placeholder:'Votre numéro de téléphone *'

                                            }}
                                        />


                            </Form.Group>


                             <Form.Group className="mb-3" >

                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email *" required />
                            </Form.Group>
                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe *" required />
                            </Form.Group>


                                      {
                                adolecent &&

                                <div>

                            <p className='alert alert-warning'>Pour démarrer votre suivi psychologique chez Feel Better™, il est obligatoire de fournir une autorisation parentale.<br/> Veuillez télécharger l’autorisation scannée et signée par l’un des responsables légaux et la copie de la pièce d’identité du responsable légal ayant signé l’autorisation.</p>

                           <Form.Group className="mb-3" >

                            <div onClick={() => handleClickfile(1)} className='btn ps-btn-dark btndwnload'>

                               {
                                    !file1 ?
                                      <label>Télécharger l'autorisation parentale <i className='fa fa-download'></i></label>

                                    :
                                    <label>{filename1}  <i className='fa fa-check'></i></label>

                                }

                                <form style={{padding:'0px'}}>
                                   <input
                                   style={{display:'none'}}
                                    type="file"
                                    accept='image/*'

                                    onChange={(e) => handleFileChange(e,1)}
                                    ref={fileInput}
                                />
                                </form>
                                <span style={{display:'none'}}><input type='text' name='file1' value={file1} /></span>

                           </div>


                            </Form.Group>

                           <Form.Group className="mb-3" >

                                <div onClick={() => handleClickfile(2)} className='btn ps-btn-dark btndwnload'>
                                {
                                    !file2 ?
                                       <label>Télécharger la copie de la pièce d’identité  <i className='fa fa-download'></i></label>
                                    :
                                    <label>{filename2}  <i className='fa fa-check'></i></label>

                                }




                                <form style={{padding:'0px'}}>
                                   <input
                                   style={{display:'none'}}
                                    type="file"
                                    accept='image/*'

                                    onChange={(e) => handleFileChange(e,2)}
                                    ref={fileInput2}
                                />
                                </form>
                                <span style={{display:'none'}}><input type='text' name='file2' value={file2} /></span>
                                </div>



                            </Form.Group>




                         </div>


                            }


                            <Form.Group className="mb-3" >

                            <div className='js889'>
                             <input type='checkbox' name='adolesant' value='1' onChange={handleChangecheckbox} />
                             <span style={{paddingLeft: '8px'}}>Je certifie avoir plus de 18 ans </span>

                            </div>
                            </Form.Group>

                             <Form.Group className="mb-3" >

                            <div className='js889'>
                             <input type='checkbox' required />
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance des <strong>CGV</strong> et <strong>CGU</strong> *</span>

                            </div>
                            </Form.Group>


                             <Form.Group className="mb-3" >

                            <div className='js889'>
                             <input type='checkbox' required />
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance de la  <strong>politique de confidentialité</strong> *</span>

                            </div>
                            </Form.Group>


                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                S'inscrire
                            </Button>

                               <Form.Group className="mb-3" >
                               <br/>


                            </Form.Group>

                        </Form>

                             : espace == 'pro' ?

                        <Form className="mb-5" onSubmit={handleSubmitAchteur}  >

                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="first_name"
                                    type="text" placeholder="Votre Prénom *" required />
                            </Form.Group>
                              <Form.Group className="mb-3" >

                             <Form.Control
                                    name="last_name"
                                    type="text" placeholder="Votre Nom *" required />
                            </Form.Group>

                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="country"
                                    type="text" placeholder="Pays de résidence *" required />
                            </Form.Group>

                               <Form.Group className="mb-3" >

                                <Form.Control
                                    name="city"
                                    type="text" placeholder="Ville ou commune *" required />
                            </Form.Group>

                           <Form.Group className="mb-3" >

                              <PhoneInput
                                          country={'fr'}
                                          value={phone}
                                          name={"phone"}
                                          enableLongNumbers={true}
                                           inputProps={{
                                              name: 'phone',
                                              required: true

                                            }}
                                        />


                            </Form.Group>



                             <Form.Group className="mb-3" >

                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email *"  required/>
                            </Form.Group>
                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe *" required />
                            </Form.Group>
   <Form.Group className="mb-3" >

                            <div className='js889'>
                             <input type='checkbox' required />
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance des <strong>CGV</strong> et <strong>CGU</strong> *</span>

                            </div>
                            </Form.Group>

                           <Form.Group className="mb-3" >

                            <div className='js889'>
                             <input type='checkbox' required />
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance de la  <strong>politique de confidentialité</strong> *</span>

                            </div>
                            </Form.Group>


                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                S'inscrire
                            </Button>

                        </Form>

                             :

                             <div></div>

                          }


                         </div>



                        :

                        <div>





                       <Form className="mb-5" onSubmit={handleSubmit}  >

                            <Form.Group className="mb-3" >
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="mail@website.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe *</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Min. 8 character" />
                            </Form.Group>
                            <Form.Group className="d-flex">
                                <Form.Check type="checkbox" label="Se souvenir de moi" />
                                <Link className="forgetlink" to="/password">Mot de passe oublié ?</Link>
                            </Form.Group>
                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                Connexion
                            </Button>


                        </Form>

                        </div>


                      }


                      </div>








                    </Col>
                </Row>
            </Container>
            </section>

              <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>
        </div>
    );
}

export default Page;