import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { PsyItem } from "../../components";
import InputInterface from "../../components/input-interface";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage,getTherapeutes,getTarifs} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import { apiClient } from "../../actions/api"
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { SET_FORMULE_UPDATE} from "../../constants/actions";

import { fetchAbonnement } from "../../actions/api_interface"


import 'moment/locale/fr';

const Page = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search);
  const isupdatetherapeut = queryParams.get('p');
  const [currentPage, setCurrentPage] = useState(1)
  const [show,setShow]=useState(false);
  const [filtres,setFiltres]=useState([]);
  const [counter, setCounter] = useState('')
  const [besoin, setBesoin] = useState('')
  const [date, setDate] = useState('')
  const [heure, setHeure] = useState('')

  const { data,pageinfo,pageCount,therapeutes,tarifs } = useSelector(state => state.mixedmode);
  const { user,formule,formuleupdate } = useSelector(state => state.user);


  const checkGoAsUpdate = () => {
    if (isupdatetherapeut) {
      return
    }

    fetchAbonnement(user, (data) => {
      if (data.abonnement?.formule) {
        let params = {'tarif':data.abonnement?.tarif, 'formule':data.abonnement?.formule}
        dispatch({ type: SET_FORMULE_UPDATE, payload: params })
        history.push('/list-psychologue/?p=true');
      }
    })
  }


  useEffect(()=>{
    checkGoAsUpdate()


    let params={}
    if(formule?.tarif?.id){
       params.id_tarif=formule?.tarif?.id;
       params.filtre=1
       setBesoin(formule?.tarif?.id)

    }


    if (isupdatetherapeut === 'true'){

          params.id_tarif=formuleupdate?.tarif?.id;
          params.filtre=1
          setBesoin(formuleupdate?.tarif?.id)
    }


    dispatch(getTherapeutes(params))
    dispatch(getTarifs(''))
  },[])

   const handleClick = (slug) => {
      history.push(slug);
  }

  const handleClose = () => setShow(false);

  const handleShow = (item) => {


    setShow(true);

  }


  const setNewDate = (date) => {
      let daterdv=date
      let datediff=moment().diff(daterdv, 'days')

      if (datediff > 0) {
        alert("Veuillez choisir une date égale ou supérieure à aujourd'hui !" )
        return false;
      }
      setDate(date);

  }


  const setFormFiltre = async () => {
    let params ={filtre:'1'}

    if(date){
      params.date=date
    }
    if(heure){
      params.heure=heure
    }
    if(besoin){
     params.id_tarif=besoin
    }

    dispatch(getTherapeutes(params))
  }





    return (
        <div className="home listtherapeutes" >


          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Liste des praticiens</li></ul></div></div></div>
          </section>
         <section className='sectionfiltre'>
           <Container>
                   <Row className="row">
                      <Col md={6}>
                         <div className=''>
                               <h1>Votre besoin :
                               <span>
                               {
                                isupdatetherapeut === 'true' ?

                                <span className='selectdepth'><select name='tarif' value={besoin} className='selectth' onChange={(event)=>setBesoin(event.target.value)} disabled>
                                 <option value={''}>{'Tous'}</option>
                                  {tarifs.map((item,i) => (
                                   <option value={item.id}>{item.titre}</option>
                                  ))}
                               </select></span>

                                :

                               <span className='selectdepth'><select name='tarif' value={besoin} className='selectth' onChange={(event)=>setBesoin(event.target.value)}>
                                 <option value={''}>{'Tous'}</option>
                                  {tarifs.map((item,i) => (
                                   <option value={item.id}>{item.titre}</option>
                                  ))}
                               </select> </span>


                               }

                            </span>

                               </h1>

                         </div>
                      </Col>

                      <Col md={6}>

                         <div className=''>
                           <Form className='formflex'>
                              <InputInterface type='date' onChange={(event)=>setNewDate(event.target.value)} text={date ==='' ? 'Sélectionner la date' : moment(date).format("DD/MM/YYYY")} />
                              <InputInterface type='time' onChange={(event)=>setHeure(event.target.value)}   text={heure ==='' ? "Sélectionner l'heure" : heure} />
                              <div className='item'>
                                   <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="button" onClick={setFormFiltre}>
                                  Filtrer <i class="fa fa-filter" aria-hidden="true"></i>
                                  </Button>
                              </div>




                           </Form>


                         </div>




                      </Col>
                   </Row>
            </Container>


         </section>

          <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3"> Sélectionnez votre praticien<span> </span> <div className='separate'></div></h2>

                      {therapeutes.map((item,i) => (
                          <PsyItem item={item}  isupdate={isupdatetherapeut} />
                      ))}
                      {
                        therapeutes.length <=0 &&
                         <div>Aucun praticien n'est trouvé ! </div>
                      }


                </Container>
          </section>



      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;