import React from "react";
import './styles.scss';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";


const Component = ({ favori,item,onClick, }) => {
    const history = useHistory();
    const {user} = useSelector(state => state.user)
 
    const handleClick = () => {

        history.push(`/boutique/produit/${item.product_slug}/${item.product_id}`);
    }

    return (
        <div className="ps-product" onClick={handleClick}>

        {
            /*

                        <div className="ps-product__thumbnail" style={{ backgroundImage: `url(${item.image_url})` }} ><a href="javascript:void(0)"></a>
                             {
                                favori== 1 &&
                                <div className='favoris'><i className='fa fa-heart' ></i></div>
                             }
                              
                               
                            </div>
                            <div className="ps-product__container">
                                <div className="ps-product__content"><a className="ps-product__title" href="#">{item.product_name}</a>
                                    <div className="ps-product__rating">
                                        <div className="br-widget br-readonly"><a href="#" data-rating-value="1" data-rating-text="1" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="2" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="3" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="4" className="br-selected br-current"></a><a href="#" data-rating-value="2" data-rating-text="5"></a></div>
                                    </div>
                                    <p className="ps-product__price sale">{parseFloat(item.sale_price).toFixed(2)} CFA </p>
                                </div>
                                <div className="ps-product__content hover"><a className="ps-product__title" href="#">{item.product_name}</a>
                                    <p className="ps-product__price sale">{parseFloat(item.sale_price).toFixed(2)} CFA</p>
                                </div>


                            </div>
                               <div>
                                 <a href="javascript:void(0)" className='add-to-cart'>Ajouter au panier</a>

                                </div>



            */
        }

                    <div className="ps-product__thumbnail" style={{ backgroundImage: `url(${item.image_url})` }} ><a href="javascript:void(0)"></a>
                             {
                                favori== 1 &&
                                <div className='favoris'><i className='fa fa-heart' ></i></div>
                             }
                              
                               
                            </div>
                            <div className="ps-product__container">
                                <div className="ps-product__content"><a className="ps-product__title" href="#">{item.product_name}</a>
                                    <div className="ps-product__rating">
                                        <div className="br-widget br-readonly"><a href="#" data-rating-value="1" data-rating-text="1" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="2" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="3" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="4" className="br-selected br-current"></a><a href="#" data-rating-value="2" data-rating-text="5"></a></div>
                                    </div>
                                    <p className="ps-product__price sale">{parseFloat(item.sale_price).toFixed(2)} € </p>
                                </div>
                                <div className="ps-product__content hover"><a className="ps-product__title" href="#">{item.product_name}</a>
                                    <p className="ps-product__price sale">{parseFloat(item.sale_price).toFixed(2)} €</p>
                                </div>


                            </div>
                               <div>
                                 <a href="javascript:void(0)" className='add-to-cart'>Ajouter au panier</a>

                                </div>
                
     </div>
    );
}

export default Component;