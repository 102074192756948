import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
 import { apiClient } from "../../actions/api"
 import { SET_TEST} from "../../constants/actions";
 import { setTest} from "../../actions/user";

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);
    const idtest = queryParams.get('id');

    const { user } = useSelector(state => state.user)

     const [show,setShow]=useState(false);
     const [start,setStart]=useState(false);
     const [finish,setfinish]=useState(false);
     const [indexquestion,setindexquestion]=useState(0);
     const [question,setquestion]=useState({});
   
     const { data,pageinfo } = useSelector(state => state.mixedmode);
     const [tests,setTests]=useState([]);
     const [test,setTest]=useState({});
     const [questions,setQuestions]=useState([]);
     const [reponses,setReponses]=useState([]);
 

   useEffect(()=>{
     
          apiClient().get(`/tests`,{} )
           .then((res) => {
          
              if(res.data){
                
                    setTests(res.data)

                    // if test



                    if(idtest){
                    for(let i=0; i < res.data.length; i++){
                       if(idtest==res.data[i].id){
                          handleShow(res.data[i])
                       }
                    }
                     
                    }


              }
              else{
              
               
            

              }
              
           })


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

   const handleClose = () => setShow(false);
 
   const handleShow = (item) => {

      apiClient().get(`/tests/`+item?.id,{} )
           .then((res) => {
          
              if(res.data){
                    
                    setQuestions(res.data)
                    setquestion(res.data[indexquestion])
                    setTest(item)
                    setShow(true);
              }
      
              
           })
     

    }
 
 const showresult = () => {
       


    
    let params={
              token:user.api_token,
              api_token:user.api_token,
              test_id:test?.id,
              reponses:reponses
          }


          apiClient().post(`/get-majorite`,params )
           .then((res) => {
          
              if(res.data.success==true){
                  let params1={article:res.data.article,majorite:res.data.majorite,test:res.data.test}
                   dispatch({ type: SET_TEST, payload: params1 })
                   history.push('/votre-resultat')
                   
              }
              else{
              
               
            

              }
              
           })
   
 }

   const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        if(!formDataObj?.reponse){
           alert("Merci de choisir une réponse !");
        }else{

                // add to object

                    let newreponses =reponses
                    newreponses.push(formDataObj)
                    setReponses(newreponses)


                //end


              
                 // go to next index
                 let  newindexq=indexquestion + 1
                if(questions.length <= newindexq){
                  setfinish(true)
                  setStart(false)
                  showresult()






                }
                else{
                 
                  setindexquestion(newindexq)
                  setquestion(questions[newindexq])

                }
                
           

        }

         var inputs = document.querySelectorAll('.ismycheck');
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].checked = false;
  }
    
    }



 const returnQuestions = () => {
      
      return (

     <form onSubmit={handleSubmit}>
      <input type='hidden' value={question?.id} name='question' />
        <div className='flexrowtarifs flexrowtarif2 row'>
         <div className=' col-md-12'>
            <div className="text-center flowpriceheader" style={{textTransform:'uppercase'}}><h2>{test?.titre}</h2></div>
          </div>

             <div className='item col-md-12'>
               <div className='pricebox'>
 
            
                   <label>
                   <strong>Question {indexquestion + 1} :</strong>
                   
                  </label>
                  <br/>
                  <div style={{    fontWeight: 'bold',marginBottom: '20px'}}> {question?.question}</div>
                 
                 
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                     {
                        question?.reponses?.map((item,key)=>(

                           <li className='itemfl988938' key={key}><strong>{key+1}</strong><label> <input required id={'o998DDD'+key+item?.id} type="radio" value={item?.id+'__'+item?.correct} className='forme-control ismycheck' name={"reponse"}/> <span>{item?.reponse}</span></label></li>


                        ))
                     }

                       
                      

                  </ul>

                
                  
                  <br/>

                   <div className="text-center"  >
                         

                                   <button type="submit" style={{    border: '0px'}}  className='btnlink marauto' >Valider </button>

                    </div>

                  </div>
               </div>
             </div>


             
         </div>
         </form>

        )
       
     

}


 const returnTest = () => {
      
      return (


        <div className='flexrowtarifs row'>
          <div className=' col-md-12'>
            <div className="text-center flowpriceheader" style={{textTransform:'uppercase'}}><h2>{test?.titre}</h2></div>
          </div>

             <div className='item col-md-12'>

                  <div style={{paddingLeft:20, paddingRight:20}} dangerouslySetInnerHTML={{ __html:test?.description}}></div>
                   <br/>
                  <div className="text-center"  >
                         

                                   <a href='javascript:void(0)' onClick={()=>setStart(true)} className='btnlink marauto' >Commencez le test </a>

                    </div>
             
             </div>


             
         </div>

        )
       
     

}
const returnFinish = () => {
      
      return (


        <div className='flexrowtarifs row'>
          <div className=' col-md-12'>
            <div className="text-center flowpriceheader" style={{textTransform:'uppercase'}}><h2>{test?.titre}</h2></div>
          </div>

             <div className='item col-md-12'>

               
                  <div className="text-center"  >

                                  <div className="text-center"> <img src='/images/157446935584697.gif' style={{width:'90px'}} /></div>
                                  <br/>
                        
                                   <a href='javascript:void(0)' className='alert alert-primary marauto' >Veuillez attendre.. </a>

                    </div>
             
             </div>


             
         </div>

        )
       
     

}
    return (
        <div className="home tarifs tests" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Tests</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3"> Nos tests <br/><span> </span> </h2>
                     
      
                </Container>
            </section>

      

             <section className="section2 section " >
                <Container>
                    
                  

                   <Row className="ps-block--site-features ps-block--site-features-2">

                     {
                        tests?.map((item,key)=>(

                           <Col md={4}>

                        <div className="ps-block__item pocket-shadow noshadowbox" >
                          <div className="ps-block__left text-center"><img src={'https://feel-better-company.com/gestion/public/tests/'+item?.image}/></div>
                          <div className="ps-block__right">
                          <h4 className="text-center">{item?.titre}</h4>
                          
                          </div>
                            <div className="text-center" >
                            <br/>

                                   <a href='javascript:void(0)' onClick={()=>handleShow(item)}  className='btnlink marauto' >Faire le test <i className='fa fa-long-arrow-right'></i></a>

                             </div>
                          </div>
                         
                      </Col>


                        )
                      )
                    }

                     

 

                   </Row>
 


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 


      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>

        {
          start ?
            returnQuestions()
            :
            finish ? 
             returnFinish()
            :
            returnTest()


        }






        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;