import React from "react";
import './styles.scss';


const Component = () => {
  const options = {
    timeZoneName: 'long',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  const formatter = new Intl.DateTimeFormat('fr-FR', options);
  const now = new Date()
  const timeZoneName = formatter.formatToParts(now).find(part => part.type === 'timeZoneName').value

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  return (
    <div className="show_timezone">
      <span>{timezone}</span> {timeZoneName}
    </div>
  )
}

export default Component
