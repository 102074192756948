import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { ProductItem } from "../../../components";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Accordion from 'react-bootstrap/Accordion'
import { getBest,getCategoryList, getColors, getTails,getsousCategoryList,getListProduct } from "../../../actions/mixedmode";
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { ProductItemPromo } from "../../../components";
import ReactPaginate from 'react-paginate';


function valuetext(value) {
  return `${value}°C`;
}
const Page = () => {
    const { slug } = useParams()
    const dispatch = useDispatch();
    const history = useHistory()
    const {categorieslist,souscategorieslist, colors, tails,dataamazon ,pageCount} = useSelector(state => state.mixedmode)
    const [showcategorie,setShowcategorie]=useState(false);
    const [categid,setCategid]=useState('');
    const [souscategid,setSousCategid]=useState('');
    const [colorid,setColorid]=useState([]);
    const [tailid,setTailid]=useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [rubrique, setRubrique] = useState(1)
    const [listprodutc,setListProduct]=useState([]);
    const [filtres,setFiltres]=useState([]);
    const [search, setSearch] = useState('')
    const [order, setOrder] = useState('')
    const [counter, setCounter] = useState('')
    const [ifprice, setIfprice] = useState('')
    const [minprice, setMinprice] = useState(7000)
    const [maxprice, setMaxprice] = useState(100000)

     const [value, setValue] = useState([7, 180]);
     const handleChange = (event, newValue) => {
       setValue(newValue);
       setMinprice(parseFloat(newValue[0]) * 1000)
       setMaxprice(parseFloat(newValue[1]) * 1000)
       setIfprice(1)
       setCounter(1)

     };



    useEffect(()=>{

        let params={slug:''}
        dispatch(getCategoryList('1'))
        dispatch(getListProduct(currentPage,params))
        //dispatch(getsousCategoryList(rubrique))
        //dispatch(getColors())
        //dispatch(getTails())


        if(Number.isInteger(parseInt(slug))){

            setCategid(slug)
            setCounter(1)
        }


        window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };
    },[])

    const isSticky = (e) => {
      const header = document.querySelector(".filtrerightmobile");
      const scrollTop = window.scrollY;

      if (header && header.classList) {
        scrollTop >= 250
          ? header.classList.add("is-sticky")
          : header.classList.remove("is-sticky");
      }
    };

     const handleClick = (slug) => {
        history.push(`/boutiques/${slug}#`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();


    }
     const handlerMenu = () => {

          setShowcategorie(!showcategorie)

    }

    const handleGoTo = (slug) => {
        history.push(slug);

    }

    const setOrderfiltre = (val) => {
        setOrder(val)
        setCounter(1)

    }

   const initfiltre = () => {
     setCategid('')
     setSousCategid('')
     setColorid([])
     setTailid([])

  }

  const searchproduct = (e) => {
          e.preventDefault();

          if(search){
             let params ={slug:rubrique,search:search,order:order}
             dispatch(getListProduct(currentPage,params))

          }
          else{

             let params ={slug:rubrique,order:order}
             dispatch(getListProduct(currentPage,params))


          }

          initfiltre()

    }


   useEffect(() => {

   if(counter){
      setFormFiltre()
   }
   console.log('Do something after counter has changed', counter);
}, [counter]);



    const HandleFiltre =  (slug,type,e) => {



e.preventDefault();
          switch(type){
             case 'categorie':
                setCategid(slug)
                setCounter(1)
                dispatch(getsousCategoryList(rubrique,slug))

             break;

             case 'souscategorie':

                 setSousCategid(slug)
                 setCounter(1)
             break;

             case 'color':

                 let newcolors=[...colorid];
                 var index = newcolors.indexOf(slug)

                 if(index > -1){

                    newcolors.splice(index,1);
                      setColorid(newcolors)
                      console.log(newcolors)
                      setCounter(1)
                 }
                 else{
                     newcolors.push(slug)
                   setColorid(newcolors)
                   setCounter(1)
                 }


             break;

             case 'tail':

                 let newtail=[...tailid];
                 var index = newtail.indexOf(slug)
                 if(index > -1){

                    newtail.splice(index,1);
                    setTailid(newtail)
                    setCounter(1)
                 }
                 else{
                     newtail.push(slug)
                     setTailid(newtail)
                     setCounter(1)
                 }

             break;

             case 'price':

             break;


          }




    }

    const  setFormFiltre = async () => {
      let params ={filtre:'1',slug:rubrique,order:order}


      if(ifprice==1){
          params.price=value
      }

      if(categid){

        params.categorie=categid

      }
      if(souscategid){

         params.souscateg=souscategid
      }
      if(colorid){
           params.colors=colorid

      }
      if(tailid){
           params.tails=tailid

      }

     dispatch(getListProduct(currentPage,params))
     setCounter('')

    }


      const   handlePageClick= (data) => {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        setCurrentPage(page)
         setCounter(1)
         window.scrollTo({top: 0});



    }


    return (
        <div className="categories listing listtherapeutes" >

         <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Produits</li></ul></div></div></div>
         </section>

             <section className='sectionfiltre'>
           <Container>
                   <Row className="row">


                      <Col md={12}>

                         <div className='categ777flex'>
                             <div className='categ777'>

                               <div className='orderBy posabscateg'>
                               <div className="select shop__header--select">
                                        <select style={{    width: '100%'}} onChange={e => HandleFiltre(e.target.value,'categorie',e)}  className="product__view--select">
                                            <option value=''>Filtrer par catégorie</option>
                                            <option value=''>Toutes les catégories</option>
                                                {categorieslist?.map((item) => (
                                                   <option value={item.category_id}>{item.category_name}</option>


                                                  ))}

                                        </select>
                                    </div>
                                </div>



                         </div>


                         <div className='orderBy'>
                               <div className="select shop__header--select">
                                        <select onChange={e => setOrderfiltre(e.target.value)}  className="product__view--select">
                                            <option   value="">Trier par</option>
                                            <option   value="1">Trier par nouveauté</option>
                                            <option value="2">Trier par prix</option>

                                        </select>
                                    </div>
                     </div>



                         </div>

                      </Col>
                   </Row>
            </Container>


         </section>





         <section className='section2' style={{background:'#FFF'}}>
            <div className="container">





              <div className='row'>


                <div className='col-md-12 products'>




                  <div className='list_items'>

                   <Row className="g-5" >



   {dataamazon?.map((item) => (
                        <Col md={3} className='col-6' >
                        {
                          item.is_off==1 ?
                           <ProductItemPromo item={item} />
                          :
                          <ProductItem item={item} />

                        }

                        </Col>
                      ))}









                   </Row>

                     <Row style={{marginTop: '100px'}}>
                    <ReactPaginate
                    pageCount={pageCount}
                    initialPage={currentPage - 1}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    itemClass="page-item"
                    linkClass="page-link"
                    containerClassName="pagination uk-flex-center"
                    activeClassName="active"
                    disabledClassName="disabled"
                    onPageChange={handlePageClick}
                    disableInitialCallback={true}
                />
    </Row>




                  </div>




                </div>

              </div>

            </div>

         </section>






        </div>
    );
}

export default Page;
