import DefaultParameters from './defaultParameters';
import { CRITICAL, MEDIUM, LOW } from '../congestionLevels';
import createLogger from '../../../../helpers/log';

const logging = createLogger('pafdebug:TimeBasedCongestionLevel');

const createTimeBasedCongestionLevel = (customParams = {}) => {
  const params = {
    ...DefaultParameters,
    ...customParams,
  };
  let dangerZone = false;
  let dangerZoneStart;
  return {
    getLevel({ audioPacketLoss }) {
      const logResult = congestionLevel => logging.info(`${congestionLevel}`, { audioPacketLoss, params });

      if (audioPacketLoss < params.packetLossCriticalThreshold) {
        dangerZone = false;
        return LOW;
      }

      const currentTime = Date.now();
      if (!dangerZone) {
        dangerZoneStart = currentTime;
        dangerZone = true;
      }

      if (currentTime > dangerZoneStart + params.criticalCongestionPeriod) {
        logResult('CRITICAL');
        return CRITICAL;
      } else if (currentTime > dangerZoneStart + (params.criticalCongestionPeriod / 2)) {
        logResult('MEDIUM');
        return MEDIUM;
      }

      return LOW;
    },
  };
};

export default createTimeBasedCongestionLevel;
