import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";

import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState('')
    const [date, setDate] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
 
    useEffect(() => {
        dispatch(getCommandeById(user.user_id,user.api_token,'client',{}));
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);



   
  
    function gotoCmd(id,item) {
      // dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/commande/detail/${id}`);
     }

   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)

             break;

             case 'rubrique':

                 setRubrique(slug)
                 setCounter(1)
             break;
            case 'order':

                 setOrder(slug)
                 setCounter(1)
             break;

          }

        
            
 
    }



    const  setFormFiltre = async () => {
      let params ={filtre:'1'}


      if(rubrique){
          params.rubrique=rubrique
      }

      if(order){
        
        params.order=order

      }
      if(date){
         
         params.date=date
      }
  
  
     dispatch(getCommandeById(user.user_id,user.api_token,'client',params));
     setCounter('')

    }






    
    return (
        <div className="mescommandes register bg778635 " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <Menu slug='commandes' />
               
            </Col>
              
            <Col  className="text-center mx-auto my-5 menudashright "  >

            <h3 className="mb-4" > Mes commandes </h3>
            <br/> 

            <div className='row'>
                 <div>

                 </div>
            </div>

 

            <div className='filtreorder'>
                <div className='item'>

                    <div className='colitem'>
                      <div >
                        Date
                      </div>

                     <div  className='iiin'>
                         
                         <input type="date" className='form-control' onChange={(event)=>HandleFiltre(event.target.value,'date',event)} />
                        
                     </div>

                    
                   


                    </div>


                </div>
                 <div className='item'>
                     <select className='orderbyorder'  onChange={e => HandleFiltre(e.target.value,'order',e)}  ><option value=''>Trier par</option><option value='created_at'>Date</option><option value='total_price'>Prix total</option></select>
                </div>


            </div>

                    <form className="mb-5 mb5mob" >
                           
                            
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                                <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Référence</th>
                                <th key="2">Date</th>
                                <th key="3" >Prix total</th>
                                <th key="4">Adresse</th>
                                <th key="5" >Statut</th>
                                <th key="6" >Actions</th>

                            </tr>
                          </thead>
                        <tbody>

                          {commandeslist?.map((item) => (
                            
                            <tr>
                              <td>{item?.track_code}</td>
                             
                              <td>
                                 {moment(new Date(item.created_at)).format("DD/MM/YYYY")}
                              </td>
                              <td>{(parseFloat(item.total_price)).toFixed(2) } €</td>
                               <td>{item?.address?.city ? item?.address?.city : item?.address?.street }</td>
                                 <td>
                                 {item.order_status==0 && <span className='badge alert-warning'>En attente</span>}
                                 {item.order_status==1 &&  <span className='badge alert-success'>Traité</span>}
                                 {item.order_status==6 &&  <span className='badge alert-primary'>Incomplet</span>}
                                 {item.order_status==5 &&  <span className='badge alert-success'>Validée</span>}
                                 {item.order_status==4 &&  <span className='badge alert-secondary'>En cours de livraison</span>}
                                 {item.order_status==3 &&  <span className='badge alert-info'>Livrée</span>}
                                 {item.order_status==2 &&  <span className='badge alert-danger'>Annulé</span>}
                                  {item.order_status==7 &&  <span className='badge alert-danger2'>Refusée</span>}
                              </td>
                              <td>
                              <div className="list-contr">

                                  <a onClick={() => {gotoCmd(item.order_id,item)}} href="javascript:void(0)" className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Voir <i class="icon-eye"></i></a>
                               

                               
                               </div>
                              </td>
                            </tr>
                        
                              
                              ))}
  

                      
                        
                          
                          
                          

                          </tbody>
                        </Table>
                          </div>
                           
                      

                    <div className='tavblemob'>

                           {commandeslist?.map((item) => (
                              <div className='items'>
                              <a  onClick={() => {gotoCmd(item.order_id,item)}} href="javascript:void(0)">

                                <div className='rowitmtop'>
                                   <span> {moment(new Date(item.created_at)).format("DD MMM YYYY")}</span>
                                   <span>Réf : {item?.track_code}</span>
                                </div>

                                <div className='process-item'>
 
                                    <div className='rowsitm'>
                                      <div> <span>{item?.details_order?.length} article{item?.details_order?.length >1 && 's'}</span></div>
                                       <div><b>{(parseFloat(item.total_price) ).toFixed(2) }  €</b></div>
                                       <div>

                                {item.order_status==0 && <span className='badge alert-warning'>En attente</span>}
                                 {item.order_status==1 &&  <span className='badge alert-success'>Traité</span>}
                                 {item.order_status==6 &&  <span className='badge alert-danger'>Incomplet</span>}
                                 {item.order_status==5 &&  <span className='badge alert-success'>Validée</span>}
                                 {item.order_status==4 &&  <span className='badge alert-success'>En cours</span>}
                                 {item.order_status==3 &&  <span className='badge alert-success'>Livrée</span>}
                                 {item.order_status==2 &&  <span className='badge alert-danger'>Annulé</span>}
                                 {item.order_status==7 &&  <span className='badge alert-danger'>Refusée</span>}

                                       </div>
                                    </div>

                                 </div>
                                </a>
                              </div>

                            ))}
                           

                            
                             
                        </div> 
 
                           

                          </Row>

                    </form>
                        
                         






   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;