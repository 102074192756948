import React,{ useState,useEffect,useRef }  from "react";
import { useDispatch, useSelector } from "react-redux";
import './styles.scss';
import { useHistory } from "react-router";
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { apiClient } from "../../actions/api"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { setMessages } from "../../actions/mixedmode";
import { defaultimg } from "../../assets/images";
import moment from 'moment'

const Component = ({item }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.user)
    const { contacts} = useSelector(state => state.mixedmode)
    const [activeitem, setActiveItem] = useState(0);
    const [conversation, setConversation] = useState([]);
    const [activeUser, setActiveUser] = useState({});
    const [message, setMessage] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const praticien = queryParams.get('p');
    const [activecontact, setactivecontact] = useState({});
    const [isnewcontact, setisnewcontact] = useState([]);
    const scrolto = useRef(null)

 const getpraticienoruser = () => {


    const data = {

                user_id: user.user_id,
                api_token:user.api_token,
                token:user.api_token,
                to:praticien,
                action:'getuser',


           }

          apiClient().post(`/setMessages`,data)
          .then((res) => {
            if(res.data?.success){
              setConversation(res.data?.data)
              setactivecontact(res.data?.user)
              setActiveUser(res.data?.user)
              let newarr=[]
              newarr.push(res.data?.user)
              setisnewcontact(newarr)
            }
          })


}

     const handleClick = (item) => {

          setActiveItem(item.user_id)
          const data = {

                user_id: user.user_id,
                api_token:user.api_token,
                token:user.api_token,
                to:item.user_id,
                action:'get',
                referer:'none'

           }

          apiClient().post(`/setMessages`,data)
            .then((res) => {

                    if(res.data?.success){
                        setConversation(res.data?.data)
                        setActiveUser(item)
                        //scrolto.current?.scrollIntoView({behavior: 'smooth'});
                    }
            })


    }
    const handleSubmit = (e) => {
           if(!message){
             return false;
           }


           const data = {
                message:message,
                user_id: user.user_id,
                api_token:user.api_token,
                token:user.api_token,
                to:activeUser.user_id,
                action:'save',
                referer:'none',
                forsave:1

           }


          apiClient().post(`/setMessages`,data)
            .then((res) => {

                    if(res.data?.success){

                       let params={
                          user_id: user.user_id,
                          api_token:user.api_token,
                          token:user.api_token,
                          action:'select'
                  }

                        dispatch(setMessages(params));
                         handleClick(activeUser)
                        setisnewcontact([])

                        setMessage('')
                    }
            })


    }

  useEffect(() => {

        let params={
                user_id: user.user_id,
                api_token:user.api_token,
                token:user.api_token,
                action:'select'
        }

          dispatch(setMessages(params));


      if(praticien){
          getpraticienoruser()
      }


    }, [praticien])

  useEffect(() => {
    const interval = setInterval(() => {
      if(activeUser?.user_id){


            const data = {

                user_id: user.user_id,
                api_token:user.api_token,
                token:user.api_token,
                to:activeUser.user_id,
                action:'get',
                referer:'none'

           }

          apiClient().post(`/setMessages`,data)
            .then((res) => {

                    if(res.data?.success){

                        setConversation(res.data?.data)


                    }
            })

      }

    }, 1000);
    return () => clearInterval(interval);
  })


    return (

               <div className='messages'>
               <div className="dashboard-list-box fl-wrap">
                                    <div className="dashboard-header color-bg fl-wrap">
                                        <h3>Messages</h3>
                                    </div>
                                    <div className="chat-wrapper fl-wrap">
                                        <div className="row">

                                            <div className="col-sm-8" style={{paddingRight: "19px"}}>
                                                <div className="chat-box fl-wrap scroolmessage"   >


                                                 {
                                                    (activeitem == 0 && !praticien ) &&
                                                      <div><h6 className='notfound'>Cliquez sur un profil pour voir les messages.</h6></div>
                                                 }


                                  {conversation.map((item,index) => {
                                       let newindex=index+1;
                                       return (

                                            item.from?.user_id==user.user_id ?
                                            <div  key={newindex} className="chat-message chat-message_guest fl-wrap">
                                                        <div className="dashboard-message-avatar">
                                                            <img src={user.picture ? "https://feel-better-company.com/gestion/public/uploads/"+user.picture : defaultimg} alt=""/>
                                                            <span className="chat-message-user-name cmun_sm" style={{display:'none'}}>{user.name}</span>
                                                        </div>
                                                        <span className="massage-date"> {moment(new Date(item.date_m)).format("DD/MM/YYYY")} <span>{moment(new Date(item.date_m)).format("HH:mm")}</span> </span>
                                                        <p>{item.message}  </p>
                                             </div>

                                             :

                                              <div key={newindex} className="chat-message chat-message_user fl-wrap">
                                                        <div className="dashboard-message-avatar">

                                                            <img src={activeUser.picture ? "https://feel-better-company.com/gestion/public/uploads/"+activeUser.picture : defaultimg} alt=""/>
                                                            <span className="chat-message-user-name cmun_sm">{activeUser.name}</span>
                                                        </div>
                                                        <span className="massage-date"> {moment(new Date(item.date_m)).format("DD/MM/YYYY")} <span>{moment(new Date(item.date_m)).format("HH:mm")}</span> </span>
                                                        <p>{item.message} </p>
                                               </div>


                                       );



                                })}
                                  <div ref={scrolto} ></div>




                                                </div>
                                                {
                                                  (activeitem > 0 || praticien ) &&

                                                    <div className="chat-widget_input fl-wrap">
                                                    <form   style={{padding:'0px'}}>

                                                        <textarea value={message} onChange={e => setMessage(e.target.value)}  placeholder='Votre Message' required>{message}</textarea>
                                                        <button onClick={handleSubmit} type="button"><i className="fa fa-send"></i></button>

                                                    </form>

                                                </div>
                                                }

                                            </div>


                                            <div className="col-sm-4">
                                                <div className="chat-contacts fl-wrap">


 {isnewcontact.map((item,index) => {
                                       let newindex=index+1;
                                       return (


                                                    <a onClick={()=>handleClick(item)}  key={newindex} className={newindex==1 ? "chat-contacts-item chat-contacts-item_active" : "chat-contacts-item"} href="javascript:void(0)">
                                                        <div className="dashboard-message-avatar">

                                                            <img src={item.picture ? "https://feel-better-company.com/gestion/public/uploads/"+item.picture : defaultimg} alt=""/>

                                                        </div>
                                                        <div className="chat-contacts-item-text">
                                                            <h4 style={{marginTop:"10px"}}>{item.name}</h4>
                                                            {
                                                              item.google_id=='V' ?
                                                               <span className='tag alert-primary'>Praticien</span>
                                                               :
                                                               <span className='tag alert-primary'>Patient</span>

                                                            }



                                                        </div>
                                                    </a>

                                        );



                                     })}


                                    {contacts.map((item,index) => {
                                      let newindex=index+1;
                                      if (!item) {
                                        // TODO - display "Deleted account" ?
                                        return null
                                      } else {
                                         return (
                                              <a onClick={()=>handleClick(item)}  key={newindex} className={activeitem == item.user_id ? "chat-contacts-item chat-contacts-item_active" : "chat-contacts-item"} href="javascript:void(0)">
                                                  <div className="dashboard-message-avatar">

                                                      <img src={item.picture ? "https://feel-better-company.com/gestion/public/uploads/"+item.picture : defaultimg} alt=""/>
                                                     {
                                                      item.nbmessage>0 &&
                                                       <div className="message-counter">{item.nbmessage}</div>
                                                     }

                                                  </div>
                                                  <div className="chat-contacts-item-text">
                                                      <h4 style={{marginTop:"10px"}}>{item.name}</h4>
                                                      {
                                                        item.google_id=='V' ?
                                                         <span className='tag alert-primary'>Praticien</span>
                                                         :
                                                         <span className='tag alert-primary'>Patient</span>

                                                      }



                                                  </div>
                                              </a>
                                          );
                                      }
                                     })}





                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
       </div>

    );
}

export default Component;