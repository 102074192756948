import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiBell,BiLock } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion' 
import Switch from '@material-ui/core/Switch';
import { apiClient } from "../../../actions/api"
import { LOGOUT_USER } from "../../../constants/actions";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);
    const label = { inputProps: { 'aria-label': '' } };
      const [issucces, setissucces] = useState(false);
     const [isloading, setisloading] = useState(false);
      const [parametres, setparametres] = useState({});
   const [securite, setSecurite] = useState(false);
   const [notification, setNotification] = useState(false);
  
    useEffect(() => {
          getParametres()
    }, []);

 useEffect(() => {
 
    
}, []);
 
    

    function getParametres() {
        let params={
           
              token:user.api_token,
              api_token:user.api_token,

          }


          apiClient().post(`/get/parametres`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                 if(res.data.parametres?.notification){

                  setNotification(true)
                }

                 if(res.data.parametres?.securite){

                  setSecurite(true)
                }
              
                
              }
            
              
           })
     }



  const handleChange = (event) => {
 
       setNotification(event.target.checked);
 
       
  }
  const handleChangeSecurite = (event) => {
  
       setSecurite(event.target.checked);
   
       
  }


  const handleSave = () => {
       
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
              notification:notification,
              securite:securite,
          }
 

   
       apiClient().post(`/save/parametres`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                if(res.data.parametres?.notification){

                  setNotification(true)
                }

                 if(res.data.parametres?.securite){

                  setSecurite(true)
                }
                 setissucces(true)
              
                
              }
            
              
           })
     
  }


  const handleRemove= () => {
       
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
           
          }
 

   
       apiClient().post(`/remove/profil`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                 dispatch({type:LOGOUT_USER});
              
                
              }
            
              
           })
     
  }


    return (
        <div className="mescommandes register parametres bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='parametres' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

     
                            <h3 className="mb-4" > Paramètres</h3>


                               {
    issucces &&

  <div className='alert alert-success'>
   Vos paramètres de sécurité ont été enregistrés avec succès.
 </div>
}



                             <div className='contentsection'>



                              <form   >

                                <div className='rowtitel'>
                                  
                                  <BiBell size={30} />
                                  <h3 className='htri1'>Notifications </h3>

                               </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                           
                                            <div className="col-sm-12">
                                             <div className='rowflex '>

                                                 <div className='item labelswt'>
                                                    <label > <span className='inlineblk'> Par email </span> <Switch {...label} checked={notification} size="small" color="secondary" onChange={handleChange}   /></label>
                                                 </div>
                                        

                                             </div>
                                              
      
                                            </div>
                                         </div>




                                      
                                    </div>
                                </div>



                                   </form>




                              <form   >

                                <div className='rowtitel'>
                                  
                                  <BiLock size={30}/>
                                  <h3 className='htri1'>Sécurité </h3>

                               </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                           <div className="col-sm-1" style={{display:'none'}}></div>
                                            <div className="col-sm-12">
                                             <div className='rowflex '>

                                                 <div className='item labelswt'>
                                                    <label > <span className='inlineblk'> Activer double authentification </span> <Switch {...label} checked={securite} size="small" color="secondary"   onChange={handleChangeSecurite}   /></label>
                                                 </div>
                                        

                                             </div>
                                              
      
                                            </div>
                                         </div>

                                    </div>
                                </div>



                                   </form>
                                


 
                              <form   className='formdeleteaccount' >

                                <div className='rowtitel'>
                                  
                                  <BiLock size={30}/>
                                  <h3 className='htri1'>Compte </h3>

                               </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                           
                                            <div className="col-sm-12">
                                             <div className='rowflex '>

                                                 <button className='btn btn-danger' type='button' onClick={handleRemove} >Supprimer mon compte</button>
                                        

                                             </div>
                                              
      
                                            </div>
                                         </div>

                                    </div>
                                </div>



                                   </form>

<br/>

<br/>
<button type="button" className="ps-btn ps-btn--fullwidth ps-btn-dark btn btn-primary" onClick={handleSave} >Enregistrer</button>
                             </div>



                         

                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;