import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiBell,BiLock } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion'
import Switch from '@material-ui/core/Switch';
import { apiClient } from "../../../actions/api"
import { SET_FORMULE_UPDATE} from "../../../constants/actions";


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [referent, setReferent] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);
    const label = { inputProps: { 'aria-label': '' } };
    const [abonnement, setAbonnement] = useState({});
    const [reservations, setreservations] = useState([]);
    const [reservationsEffectuees, setreservationsEffectuees] = useState([]);





    useEffect(() => {

        getAbonnement();

    }, []);

   useEffect(() => {


  }, []);



  function getAbonnement() {
    let params = {
      token:user.api_token,
      api_token:user.api_token,
    }


    apiClient().post(`/get/abonnement`, params)
    .then((res) => {

      if (res.data.success=='ok') {
          const abonnement = res.data.abonnement;
          const reservations = res.data.reservations;
          setAbonnement(abonnement);
          setreservations(reservations);
          const reservationsEffectuees = reservations.filter(
              resa => resa.abonnement_id === abonnement.id && resa.status === 2
          );
          setreservationsEffectuees(reservationsEffectuees);
          if(res.data.referent){
            setReferent(res.data.referent)
          }
      }
    })
  }


  const updatepraticien = () => {
    let params = {'tarif':abonnement?.tarif, 'formule':abonnement?.formule}
    dispatch({ type: SET_FORMULE_UPDATE, payload: params })
    history.push('/list-psychologue/?p=true');
  }



  console.log(abonnement)



    return (
        <div className="mescommandes register abonnement  bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='abonnement' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright"  >


                       <h3 className="mb-4" > Abonnement</h3>





       <div className='contentsection'>

      {
        abonnement?.formule &&
            <div className='flexrowtarifs row' style={{    textAlign: 'left'}}>
          <div className=' col-md-12'>
            <div className="text-center flowpriceheader"><img src={abonnement?.tarif?.image}/><h2>{abonnement?.tarif?.titre}</h2></div>
          </div>

             <div className='item col-md-6'>
               <div className='pricebox'>
                  <h3>{abonnement?.formule?.titre}</h3>
                  <h4>{parseFloat(abonnement?.formule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                   <div className="text-center alert alert-warning">
                       <strong>{reservationsEffectuees.length}/{abonnement?.formule?.seance}</strong> séance(s) effectuée(s)
                   </div>
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                       <li><i className='fa fa-check-circle icochecked'></i> {abonnement?.formule?.temps} min</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {abonnement?.formule?.seance} séances</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {abonnement?.formule?.pack}</li>

                  </ul>

                  <label>
                     Description
                  </label>
                  <p>{abonnement?.formule?.contenu}</p>


                  </div>
               </div>
             </div>

          </div>

      }


{
  !referent &&

  <div>
   <div style={{width:'100%',borderTop:'1px solid #ccc', paddingTop:'10px', marginTop:'30px'}} ></div>

       {
        parseInt(abonnement?.formule?.seance) - parseInt(reservationsEffectuees.length) > 0 &&

          <div className="flexitems alert alert-primary" >

            <a href="javascript:void(0)" onClick={()=>updatepraticien()} class="btnlink marauto btnlinkjaun">Je veux choisir un autre praticien </a>
        </div>

       }

</div>

}



<br/>

{
   abonnement?.therapeute &&

   <Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Praticien référent</Accordion.Header>
    <Accordion.Body>

    {
       referent!=='' &&

        <Row className="mb-3"  >



          <div className='itemimg col-md-3' style={{backgroundImage:referent?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+referent?.picture})` : "url('/images/default-profile.png')"}}></div>
          <h3>{referent?.name}</h3>
          <br/>

          <div>

             <div class="flexitems"><a href="javascript:void(0)" class="btnlink marauto" onClick={()=>history.push('/messages?p='+referent?.user_id)} >Contacter mon praticien   </a><a href="javascript:void(0)" onClick={()=>history.push('/profil-therapeute/'+referent?.name+'/'+referent?.user_id)} class="btnlink marauto btnlinkjaun">Voir profil du praticien   </a></div>


          </div>


          </Row>
    }




















    </Accordion.Body>
</Accordion.Item>

</Accordion>
}

<br/>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Historique des consultations</Accordion.Header>
    <Accordion.Body>


        <Row className="mb-3"  >

              <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Besoin</th>
                                <th key="1">Formule</th>
                                <th key="2">Date</th>
                                <th key="3" >Prix</th>
                                <th key="4">Praticien</th>
                                <th key="5">Statut</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>

                        {
                          reservations.map((item,i)=>(


                          <tr>
                              <td>{item.tarif?.titre}</td>
                              <td>{item.formule?.titre}</td>
                              <td>{moment(item?.date_rdv).format("DD/MM/YYYY")} {item?.heure_rdv_patient}</td>
                              <td>{parseFloat(item?.formule?.price).toFixed(2)}€</td>
                              <td><a href='#'>{item?.therapeute?.name}</a></td>
                              <td>

                                  {item.status==2 && <div className="badge alert-success"  > Effectuée</div>}
                                  {item.status==3 && <div className="badge alert-danger"  > Annulée</div>}
                                  {item.status==4 && <div className="badge alert-danger"  > Déplacée</div>}

                              </td>

                              <td>
                                <div className="list-contr">
                              {
                                item.facture &&
                                <a  href={item.facture} download='' target='__blanck' className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Facture <i class="fa fa-file-pdf-o"></i></a>
                              }



                               </div>
                              </td>

                          </tr>


                          ))
                        }






                        </tbody>



                      </Table>

              </div>

          </Row>



    </Accordion.Body>
</Accordion.Item>

</Accordion>



<br/>









 </div>





                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>

        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;