import React, {useState, useCallback, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router";
import classNames from 'classnames';
import AccCore from 'opentok-accelerator-core';

import { apiClient }      from "../../actions/api"
import { setFinishedCall} from "../../actions/mixedmode";

import './styles.scss';
import { useParams } from "react-router";
import {API_VONAGE_KEY} from "../../components/config/keys";
import DeviceSelection from "../../components/vonage/DeviceSelection/DeviceSelection";
import {CloseButton, Modal, Spinner, Button} from "react-bootstrap";
import moment from 'moment';
import {getRealTherapieTime} from '../../../src/actions/api_interface';

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers}),
    screenPublisherClass: classNames('video-container', { hidden: !active }),
    cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
        { 'active-gt2': activeCameraSubscribersGt2  },
        { 'active-odd': activeCameraSubscribersOdd  },
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !active }),
  };
};

const Page = () => {
  const { id, refresh } = useParams()
  const { user } = useSelector(state => state.user)
  const history  = useHistory()
  const dispatch = useDispatch()

  const [patients,   setPatients]   = useState(null)
  const [sessionId, setSessionId] = useState(null);
  const [token, setToken] = useState(null);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [selectedMicrophoneDevice, setSelectedMicrophoneDevice] = useState('');

  const [startDate, setStartDate] = useState(null)
  const [seanceTime, setSeanceTime] = useState(0)

  const [connected, setConnected] = useState(false);
  const [active, setActive] = useState(false);
  const [publishers, setPublishers] = useState(null);
  const [subscribers, setSubscribers] = useState(null);
  const [meta, setMeta] = useState(null);
  const [localAudioEnabled, setLocalAudioEnabled] = useState(true);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [otCore, setOtCore] = useState(null);
  const [showCloseModal, setShowCloseModal] = useState(false);

  const [currentCamera, setCurrentCamera] = useState(null);
  const [callStarted, setCallStarted] = useState(false);


  const setHandleChangeMicrophoneDevice = (value) => {
    setSelectedMicrophoneDevice(value);
  }
  const setHandleChangeVideoDevice = (value) => {
    setSelectedVideoDevice(value);
  }

  useEffect(() => {
    if (currentCamera) {
      currentCamera.setVideoSource(selectedVideoDevice);
      currentCamera.setAudioSource(selectedMicrophoneDevice);
    }
  }, [currentCamera, selectedVideoDevice, selectedMicrophoneDevice])

  useEffect(() => {
    if(otCore && active){
      const state = otCore.state();
      const camera = state.publishers.camera;
      const index = Object.keys(camera)[0];
      setCurrentCamera(camera[index]);
    }
  }, [otCore, active])

  const connectingMask = () =>
      <div className="App-mask">
        <Spinner />
        <div className="message with-spinner">Connecting</div>
      </div>;

  const startCallMask = start =>
      <div className="App-mask">
        {
          !callStarted ?
              <button className="message button clickable" onClick={start}>Rejoindre la consultation</button>
              :
              <>
                <Spinner />
                <div className="message with-spinner">Connecting</div>
              </>

        }

      </div>;

  useEffect(() => {
    if(!otCore && sessionId && token) {
      const otCoreOptions = {
        credentials: {
          apiKey: API_VONAGE_KEY,
          sessionId: sessionId,
          token: token,
          enableDtx: false,
          echoCancellation: true,
          //j'ai modifié ces params pour voir si ça améliorait le son sur mobile, à tester, pas déployé pour le moment
          audioBitrate: 18000,
          disableAudioProcessing: false,
          showControls: false,
          //au 23/08/23, les param si dessous sont déployés sur le serveurs
          // enableStereo: true,
          //audioBitrate: 28000,
          autoGainControl: false,
          //disableAudioProcessing: true,
        },
        // A container can either be a query selector or an HTML Element
        streamContainers(pubSub, type, data, streamId) {
          return {
            publisher: {
              camera: '#cameraPublisherContainer',
            },
            subscriber: {
              camera: '#cameraSubscriberContainer',
            },
          }[pubSub][type];
        },
        controlsContainer: '#controls',
        communication: {
          autoSubscribe: true,
          subscribeOnly: false,
          connectionLimit: null,
          callProperties: null, // Using default
        },
      }
      setOtCore(new AccCore(otCoreOptions));
    }
  }, [sessionId, token, otCore]);

  useEffect(() => {
    if(otCore){
      otCore.connect().then(() => setConnected(true));
      const events = [
        'subscribeToCamera',
        'unsubscribeFromCamera',
      ];

      const session = otCore.getSession();
      session.on('streamCreated', function (event) {
         session.subscribe(event.stream, 'cameraPublisherContainer', {
          insertMode: 'append',
          width: '100%',
          height: '100%'
        }, handleError);
      });

      events.forEach((event) => {
            otCore.on(event, ({publishers, subscribers, meta}) => {
              setPublishers(publishers);
              setSubscribers(subscribers);
              setMeta(meta);
            })
          }
      );

      return () => {
        otCore.disconnect();
      };
    }
  }, [otCore])

  const startCall = () => {
    setCallStarted(true);
    otCore.startCall()
        .then(({ publishers, subscribers, meta }) => {
          setPublishers(publishers);
          setSubscribers(subscribers);
          setMeta(meta);
          setActive(true);
        })
        .catch((error) => console.log(error));
  };

  const endCall = () => {
    otCore.endCall()
    let paramsDispatch={
      token:     user.api_token,
      api_token: user.api_token,
      id:        id
    }
    dispatch(setFinishedCall(paramsDispatch))
    if (user.google_id === "V") {
      therapistEnd()
    } else {
      patientEnd()
    }
    setActive(false);
  };

  const toggleLocalAudio = () => {
    otCore.toggleLocalAudio(!localAudioEnabled);
    setLocalAudioEnabled(!localAudioEnabled);
  };

  const toggleLocalVideo = () => {
    otCore.toggleLocalVideo(!localVideoEnabled);
    setLocalVideoEnabled(!localVideoEnabled);
  };

  const {
    localAudioClass,
    localVideoClass,
    localCallClass,
    controlClass,
    cameraPublisherClass,
    cameraSubscriberClass,
  } = containerClasses({ active, meta, localAudioEnabled, localVideoEnabled });

  const tokenFromSessionIdAndUser = useCallback(() => {
   if(sessionId && user){
     let params = {
       token: user.api_token,
       api_token: user.api_token,
       sessionId: sessionId
     }

     apiClient().post(`/tokenforsession`, params)
         .then((res) => {
           if (res.data.success === 'ok') {
             if (res.data.token) {
               setToken(res.data.token)
             }
           }
         })
   }
  }, [sessionId]);

  function handleError(error) {
    if (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    if (refresh) {
      window.location = window.location.origin + "/room-call/" + id
    }
    else if (user.google_id === "V") {
      let params = {
        token: user.api_token,
        api_token: user.api_token,
      }

      apiClient().post(`/getconsultation/therapeut`, params)
      .then((res) => {
        if (res.data.success === 'ok' && res.data.reservation?.heure_rdv) {
          setPatients(res.data.patients);
          let date = moment.utc(res.data.reservation.date_rdv + "T" + res.data.reservation.heure_rdv + "Z")
          setStartDate(date)
          setSeanceTime(getRealTherapieTime(res.data))
        }
      })
    }

  }, [])

  useEffect(() => {
    let params = {
      token: user.api_token,
      api_token: user.api_token,
      id:id
    }
    apiClient().post(`/roomtoken`, params)
    .then((res) => {
      if (res.data.success === 'ok') {
        setSessionId(res.data?.room)
      } else {
        history.replace("/")
      }
    })
  }, [id])

  useEffect(() => {
    tokenFromSessionIdAndUser()
  }, [sessionId])

  const therapistEnd = () => {
    let params2 = {
      token:user.api_token,
      api_token:user.api_token,
      patients:patients,
      action:'valide'
    }

    apiClient().post(`/set/reservation`, params2)
      .then((res) => { history.push('/fin-consultation/therapeut/' + id) })
      .catch(e => { history.push('/fin-consultation/therapeut/' + id) })
  }

  const patientEnd = () => {
    history.push('/fin-consultation/patient/' + id)
  }

  const startLeavingCall = useCallback(() => {
    if (!otCore) {
      return;
    }
    otCore.disconnect()
  }, [otCore]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!otCore) {
        return
      }

      let rest = moment.utc(startDate).add(seanceTime, "minutes").diff(moment.utc(), 'minutes')
      if (rest < 0) {
        startLeavingCall()
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [otCore, startDate, seanceTime]);

  const handleClose = () => setShowCloseModal(false);

  const renderCall = () => {
    return (
    <>
      <div className="App">
        <div className="App-main">
          <div className="App-video-container">
            {!connected && connectingMask()}
            {connected && !active && startCallMask(startCall)}
            <div id="cameraPublisherContainer" className={cameraPublisherClass} />
            <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
          </div>
          <div id="controls" className={controlClass}>
            <div className={localAudioClass} onClick={toggleLocalAudio} />
            <div className={localVideoClass} onClick={toggleLocalVideo} />
            <div className={localCallClass} onClick={()=>setShowCloseModal(true)} />
            <DeviceSelection
              setHandleChangeMicrophoneDevice={setHandleChangeMicrophoneDevice}
              setHandleChangeVideoDevice={setHandleChangeVideoDevice}
              joinCall={startCall}
            />
          </div>

        </div>
      </div>
      <Modal show={showCloseModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
            Êtes-vous sûr de vouloir terminer la séance ?
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="btn-modal">
            <Button variant="link" onClick={endCall} className="btnlink marauto">
              Oui
            </Button>
            <Button variant="link" onClick={()=>setShowCloseModal(false)} className="btnlink marauto">
              Non
            </Button>
          </div>
         </Modal.Body>
      </Modal>
    </>
    )
  }

  return renderCall()
}

export default Page
