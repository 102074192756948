const IntervalRunner = require('../ot/interval_runner');

export default ({ asynchronous = false } = {}) => {
  if (asynchronous) {
    return (
      predicate,
      { frequency = 10, timeout = 2000 } = {}
    ) => new Promise((resolve, reject) => {
      let timeoutTimerId;

      const intervalRunner = new IntervalRunner(async () => {
        async function tryPredicate() {
          try {
            return await predicate();
          } catch (e) {
            return false;
          }
        }

        const resolvedPredicate = await tryPredicate();

        if (resolvedPredicate) {
          clearTimeout(timeoutTimerId);
          intervalRunner.stop();
          resolve();
        }
      }, frequency);

      timeoutTimerId = setTimeout(() => {
        intervalRunner.stop();
        reject(new Error('TIMEOUT'));
      }, timeout);

      intervalRunner.start();
    });
  }

  return (predicate, { frequency = 60, timeout = 2000 } = {}) => new Promise((resolve, reject) => {
    let timeoutTimerId;

    const intervalRunner = new IntervalRunner(() => {
      function tryPredicate() {
        try {
          return predicate();
        } catch (e) {
          return false;
        }
      }

      if (tryPredicate()) {
        clearTimeout(timeoutTimerId);
        intervalRunner.stop();
        resolve();
      }
    }, frequency);

    timeoutTimerId = setTimeout(() => {
      intervalRunner.stop();
      reject(new Error('TIMEOUT'));
    }, timeout);

    intervalRunner.start();
  });
};
