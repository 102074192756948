import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
 
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiBell,BiLock } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import { Messages } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion' 
import Switch from '@material-ui/core/Switch';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
  
   



    return (
        <div className="mescommandes register messages bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='messages' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

                             <div className='contentsection'>

                                   <Messages />
                             </div>


                    </Col>
                </Row>
 

            </Container>
        </div>
    );
}

export default Page;