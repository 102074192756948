import React, { useEffect, useRef, useCallback, useState } from 'react';
import {
  useLocalParticipant,
  useVideoTrack,
  useDevices,
  useDaily,
  useDailyEvent,
} from '@daily-co/daily-react';
import UserMediaError from '../UserMediaError/UserMediaError';
import './HairCheck.css';
import { useDispatch, useSelector } from "react-redux"

export default function HairCheck({ joinCall, cancelCall }) {
  const localParticipant = useLocalParticipant();
  const videoTrack = useVideoTrack(localParticipant?.session_id);
  const { microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  const callObject = useDaily();
  const videoElement = useRef();
  const { user } = useSelector(state => state.user);
  const [loged, setLoged]   = useState(false);
  const [binded, setBinded] = useState(false);

  const [getUserMediaError, setGetUserMediaError] = useState(false);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const onChange = (e) => {
    callObject.setUserName(e.target.value);
  };

  const join = (e) => {
    e.preventDefault();
    joinCall();
  };


  useEffect(() => { // TODO - NOTA // hotfix (copy pasted on tile) -> remove later
    const video = document.getElementById('hair_check_self')
    if (video && !binded) {
      video.addEventListener('webkitbeginfullscreen', function() {
        if (!loged) {
          alert("Pour iOS : cliquer sur fermer pour sortir du test caméra")
          setLoged(true)
        }
      })
      setBinded(true)
    }
  })

  useEffect(() => {
    callObject.setUserName(user?.name);

    if (!videoTrack.persistentTrack) return;
    if (videoElement?.current) {
      videoElement.current.srcObject =
        videoTrack.persistentTrack && new MediaStream([videoTrack?.persistentTrack]);
    }
  }, [videoTrack.persistentTrack]);

  const updateMicrophone = (e) => {
    setMicrophone(e.target.value);
  };

  const updateSpeakers = (e) => {
    setSpeaker(e.target.value);
  };

  const updateCamera = (e) => {
    setCamera(e.target.value);
  };


  let selectedCamera  = cameras.find(camera => camera.selected)
  let selectedMicro   = microphones.find(speaker => speaker.selected)
  let selectedSpeaker = speakers.find(speaker => speaker.selected)

  return getUserMediaError ? (
    <UserMediaError />
  ) : (
    <form className="hair-check" onSubmit={join}>
      {/* Video preview */}
      {videoTrack?.persistentTrack && <video id="hair_check_self" muted playsInline autoPlay ref={videoElement} />}


      {/* Microphone select */}
      <div>
        <label htmlFor="micOptions">Microphone:</label>
        <select name="micOptions" id="micSelect" onChange={updateMicrophone} value={selectedMicro?.device?.deviceId}>
          {microphones?.map((mic) => (
            <option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
              {mic.device.label}
            </option>
          ))}
        </select>
      </div>

      {/* Speakers select */}
      <div>
        <label htmlFor="speakersOptions">Haut-parleurs:</label>
        <select name="speakersOptions" id="speakersSelect" onChange={updateSpeakers} value={selectedSpeaker?.device?.deviceId}>
          {speakers?.map((speaker) => (
            <option key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
              {speaker.device.label || "Par défaut"}
            </option>
          ))}
        </select>
      </div>

      {/* Camera select */}
      <div>
        <label htmlFor="cameraOptions">Camera:</label>
        <select name="cameraOptions" id="cameraSelect" onChange={updateCamera} value={selectedCamera?.device?.deviceId}>
          {cameras?.map((camera) => (
            <option key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
              {camera.device.label || "Par défaut"}
            </option>
          ))}
        </select>
      </div>

      <button onClick={join} type="submit">
         Rejoindre la consultation
      </button>
      <button onClick={cancelCall} className="cancel-call" type="button" style={{display:'none'}}>
        Back to start
      </button>
    </form>
  );
}
