import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { useHistory } from "react-router";
import { addCommentairetherapeut} from "../../actions/mixedmode";
import { apiClient } from "../../actions/api"
import 'moment/locale/fr';
 import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from "react-router";
import { addreclamation} from "../../actions/user";

import Agenda from '../../components/agenda/'
import { agendaClickToParams } from "../../actions/api_interface"



const Page = () => {
    const dispatch = useDispatch();
    const { slug,id } = useParams()
    const history = useHistory()
    const [show,setShow]=useState(false);
    const [value, setValue] = useState(0)
    const { user,formule } = useSelector(state => state.user);
    const [therapeute, settherapeut] = useState({})
    const [reservation, setreservation] = useState({})
    const [json, setJson] = useState([]);
    const [isgroupe, setisgroupe] = useState(false);
    const [nbreservation, setnbreservation] = useState(0);
    const [statusabonnement, setstatusabonnement] = useState('');
    const [therapreponse, settherapreponse] = useState(false);
    const [loading, setloading] = useState(false);
    const [isreclamation, setreclamation] = useState('');

    const [isrdvsave, setisrdvsave] = useState(false);



    const [avgnote,setavgnote]=useState(0);
     const [avg,setavg]=useState(0);
    const [note,setNote] = useState(4);
    const [notepour,setNotepour] = useState(0);
    const [notepour3,setNotepour3] = useState(0);
    const [notepour2,setNotepour2] = useState(0);
    const [notepour4,setNotepour4] = useState(0);
    const [notepour5,setNotepour5] = useState(0);

    // TODO - DRY$
  var settings
  if (window.innerWidth > 600) {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7
    }
  } else {
    settings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 500,
      infinite: true,
      dots: false
    }
  }




    useEffect(() => {

        if (!user.user_id ) {
            history.replace("/")
        }

        if(slug=='patient'){
           checkifconsultation()
        }
        else{
            detailreservation()

        }




    }, [user]);


    const addnewreclamation = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.type=2;
        dispatch(addreclamation(formDataObj));
    }


function checkifconsultation() {
        let params={

              token:user.api_token,
              api_token:user.api_token,
              id:id
          }


          apiClient().post(`/detail/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                settherapeut(res.data.reservations?.therapeute)



        let sum=0;
           for (var i = 0; i < res.data?.avg?.avg?.length; i++) {
                sum=sum+parseInt(res.data?.avg?.avg[i].note)
           }
            if(sum>0){
              setavgnote(sum/res.data?.avg?.avg.length)
            }

        if(res.data?.avg?.avg?.length){
         setavg(res.data?.avg)
         setNotepour(res.data?.avg?.avg?.filter(item => item.note==1).length);
         setNotepour3(res.data?.avg?.avg?.filter(item => item.note==3).length);
         setNotepour4(res.data?.avg?.avg?.filter(item => item.note==4).length);
         setNotepour5(res.data?.avg?.avg?.filter(item => item.note==5).length);
         setNotepour2(res.data?.avg?.avg?.filter(item => item.note==2).length);
        }


              }


           })
    }


function detailreservation() {
        let params={

              token:user.api_token,
              api_token:user.api_token,
              id:id
          }


          apiClient().post(`/detail/reservation/therapeut`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                setreservation(res.data.reservation)
                setisgroupe(res.data.isgroupe)
                setnbreservation(res.data.nbreservation)
                setstatusabonnement(res.data.mabonement)


              }


           })

           let isgroupeee=0;
           let newtimezone=localStorage.getItem('tiemzone') ? localStorage.getItem('tiemzone'): 'non';
           let params22={timezone:newtimezone}


           apiClient().post(`/getDates/`+user?.user_id+'/0',params22)
           .then((res) => {

             setJson(res.data.data)



           })



    }

   const handleChange = (event, newValue) => {
        setValue(newValue);
      };


     const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.note=note;
        formDataObj.type='user';
        formDataObj.user_id=user?.api_token? user?.user_id:0;
        formDataObj.therapeut_id=therapeute?.user_id;
        if(formDataObj.name=="" || formDataObj.email=="" || formDataObj.comment==""){
           alert("Merci de remplir tous les champs.");
        }else{
            dispatch(addCommentairetherapeut(formDataObj));
            history.replace('/')


        }

    }

    const handleSubmitreclama = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.type=2;
        dispatch(addreclamation(formDataObj));
        if(formDataObj.objet=='Problème technique'){
              setreclamation(2)
              document.getElementById('descth8').value=''
              document.getElementById('descth8').text=''
        }
        else{
              setreclamation(1)
               document.getElementById('desnote8').value=''
              document.getElementById('desnote8').text=''
        }

    }


      const setreponserefere = (e,t) => {
        e.preventDefault();


        let params={

              token:user.api_token,
              api_token:user.api_token,
              type:t,
              abonnementid:reservation?.abonnement_id
          }


          apiClient().post(`/set/reservation/refere`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                settherapreponse(true)


              }


           })






    }


    // TODO - dry
     const handleGoTo = (day, hourState) => {
       if (hourState.state === 1) {
          let params = agendaClickToParams(day, hourState, user?.user_id)

          setloading(true)
          params.patient_id   = reservation?.patient?.user_id
          params.tarif_id     = reservation?.tarif?.id
          params.formule_id   = reservation?.formule?.id
          params.date_rdv     = params.date
          params.heure_rdv    = params.heure
          params.total_price  = parseFloat(reservation?.formule?.price).toFixed(2)
          params.token        = user.api_token
          params.api_token    = user.api_token
          params.therapeut_id = params.therapeut


          apiClient().post(`/save/reservationtherapeut`,params )
           .then((res) => {

              if (res.data.success == 'ok') {
                   setloading(false)
                   setisrdvsave(true)
                   //history.replace('/praticien/dashboard/')
              }
              else{
                setloading(false)
              }

           })
         }

    }


  const rendertabproblem = () => {

return (


    <TabPanel value={value} index={2} className='tabpnel'>

           <section className="section2 " >


                <div className='row '>
                  <div className='col-md-12 '>



                   <Form className="mb-5" onSubmit={handleSubmitreclama}  >

                            <h3 className="mb-4" >Signaler un problème technique</h3>
                             <br/> <br/>

                          <input type='hidden' value='Problème technique' name='section' />


                          <Row className="mb-3"  >

                             <div className="col-md-12">

                             <p className="para alert alert-primary  center">Veuillez utiliser le formulaire ci-dessous pour référer du problème technique</p>
                              <br/>

                              {
                                isreclamation==2 &&
                                <p className="para alert alert-success  center">Votre demande est envoyée !</p>
                              }

                              <div className="profile-edit-container fl-wrap block_box"><div className="custom-form"><div className="row"><div className="col-sm-12"><label></label><input name="objet" type="hidden" className="form-control" value="Problème technique" required="required"/></div></div><br/><div className="row"><div className="col-sm-12"><label>Description </label><textarea name="description" rows="3" className="form-control" id='descth8'  required="required"></textarea></div></div><div className="row"><div className="col-sm-12">
                               <br/>
                                 <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                ENVOYER
                            </Button>
                              </div></div></div></div>

                             </div>

                          </Row>
                          <br/>
<br/>
                          <Row className="mb-3"  >



                          </Row>

                    </Form>







                  </div>
                </div>

            </section>
      </TabPanel>
  )
  }

  const rendertabnote = () => {


  return (

      <TabPanel value={value} index={1} className='tabpnel'>

                    <section className="section2 " >


                <div className='row '>
                  <div className='col-md-12 '>



                   <Form className="mb-5" onSubmit={handleSubmitreclama}  >

                            <h3 className="mb-4" >Ajouter une note inquiétante</h3>
                             <br/> <br/>

                          <input type='hidden' value='Note inquiétante' name='section' />


                          <Row className="mb-3"  >

                             <div className="col-md-12">
                             <p className="para alert alert-primary  center">Veuillez utiliser le formulaire ci-dessous pour informer de la note inquiétante</p>
                              <br/>
                                 {
                                isreclamation==1 &&
                                <p className="para alert alert-success  center">Votre demande est envoyée !</p>
                              }


                              <div className="profile-edit-container fl-wrap block_box"><div className="custom-form"><div className="row"><div className="col-sm-12"><label></label><input name="objet" type="hidden" className="form-control" required="required" value="Note inquiétante"/></div></div><br/><div className="row"><div className="col-sm-12"><label>Description </label><textarea name="description" rows="3" className="form-control" id='desnote8' required="required"></textarea></div></div><div className="row"><div className="col-sm-12">
                               <br/>
                                 <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                ENVOYER
                            </Button>
                              </div></div></div></div>

                             </div>

                          </Row>
                          <br/>
<br/>
                          <Row className="mb-3"  >



                          </Row>

                    </Form>







                  </div>
                </div>

            </section>
      </TabPanel>

      )
  }

    const renderagenda = () => {


   return (

    <section className='profiltherapeut'>

<TabPanel value={value} index={0} className='tabpnel'>

{
  statusabonnement === 3 ?

    <section className="section2 " >
                <Container className='section30039'>

                <div className='row '>
                  <div className='col-md-12 '>
                      <div className='alert alert-success' style={{    fontSize: '16px',textAlign: 'center'}}>Fin de formule</div>
                  </div>
                </div>
              </Container>
  </section>

  :

   <section className="section2 " >
                <Container className='section30039'>

                <div className='row '>
                  <div className='col-md-12 '>
                        <h3 className='text-center'> <span style={{textTransform:'uppercase'}}>Ajouter le prochain rendez-vous du patient</span></h3>
                        <br/>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-12 bgplaning'>


                       <div className='calendar'>

                       {
                         isrdvsave === false ?
                          <Agenda onSelect={handleGoTo} formule={formule} therapeutId={user?.user_id} />
                         :



                     <a href="javascript:void(0)" onClick={()=>history.push('/praticien/dashboard/')} className="btnlink marauto">Mes rendez-vous <i className="fa fa-long-arrow-right"></i></a>



                       }



                       </div>

                    </div>
                  </div>



                </Container>
          </section>


}




</TabPanel>
</section>
      )
  }


 const renderactiongroup = () => {

    return (
        <section>
            <Row>
                         <Col md='12'>
                               <Box sx={{ width: '100%' }}>
                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs   value={value} onChange={handleChange} aria-label="basic tabs example">
                                       <Tab label="Note inquiétante" {...a11yProps(0)} />
                                       <Tab label="Signaler un problème technique" {...a11yProps(1)} />

                                    </Tabs>
                                  </Box>
                             </Box>
                         </Col>
            </Row>

            {rendertabnote()}
            {rendertabproblem()}


        </section>

      )

  }
 const renderactionformule = () => {

    return (
        <section className='tabsreserv'>
            <Row>
                         <Col md='12'>
                               <Box sx={{ width: '100%' }}>
                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs   value={value} onChange={handleChange} aria-label="basic tabs example">

                                       <Tab label="Agenda" {...a11yProps(0)} />
                                       <Tab label="Note inquiétante" {...a11yProps(1)} />
                                       <Tab label="Signaler un problème technique" {...a11yProps(2)} />

                                    </Tabs>
                                  </Box>
                             </Box>
                         </Col>
            </Row>

            {rendertabnote()}
            {rendertabproblem()}
            {renderagenda()}


        </section>






      )

  }


const renderformuleaction = () => {

  return (

 <div>

 {
    therapreponse === false &&

  <div className='abonnement' style={{marginBottom:'50px'}}>

     <div className="row">
      <div className='col-md-2'></div>
          <div className='col-md-4' style={{marginRight:'10px'}}><a onClick={(e)=>setreponserefere(e,1)} style={{padding: '10px 22px',marginBottom:'20px'}} href="javascript:void(0)" className="btnlink marauto">Le patient m’a choisi comme praticien référent</a></div>
          <div className='col-md-4' style={{marginLeft:'10px'}}> <a onClick={(e)=>setreponserefere(e,2)}  style={{padding: '10px 19px',marginBottom:'20px'}} href="javascript:void(0)" className="btnlink marauto btnlinkjaun">Le patient souhaite rencontrer un autre praticien</a></div></div>
       <div className='col-md-2'></div>
  </div>
 }



 </div>



)

}

const renderformulegroupe = () => {

  return (


              <div className='row' style={{marginBottom:'40px'}}>


                  <div className='col-md-2'></div>


                    <div className='therapeutescontent col-md-4' style={{marginRight: '10px'}}>

                    <Row className=''>


                     <Col md={12} >



                  <div >

                    <div className='flexrowtarifs row'>


                      <div className=' col-md-12'>
                        <div className="text-center flowpriceheader"><img src={reservation?.tarif?.image}/><h2>{reservation?.tarif?.titre}</h2></div>
                      </div>

                      <div className='item'>



                  <div className='pricebox' style={{boxShadow: 'none'}}>
                  <h3>{reservation?.formule?.titre}</h3>
                  <h4>{parseFloat(reservation?.formule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>




                  </div>
               </div>

              </div>
            </div>
          </div>





                        </Col>


                    </Row>
                </div>



      <div className='therapeutescontent col-md-4' style={{marginLeft: '10px',display:'none'}}>

                    <Row className=''>


                     <Col md={12} >





                  <div >

                    <div className='flexrowtarifs row'>


                      <div className=' col-md-12'>
                         <div className='itemimg ' style={{backgroundImage:reservation?.patient?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+reservation?.patient?.picture})` : "url('/images/default-profile.png')",width: '75px',margin:'0 auto',height: '75px'}}> </div>
                             <h3 className='title text-center'>{reservation?.patient?.name}</h3>

                             <br/>

                      </div>

                      <div className='item'>



                  <div className='pricebox' style={{boxShadow: 'none'}}>

                     <div>
                         <h4>{nbreservation}<span><sup> /{reservation?.formule?.seance} séances </sup></span></h4>
                         <h3>{'effectuées'}</h3>
                         <div className="pricebox-border2"> </div>
                     </div>






               </div>

              </div>
            </div>
          </div>





                        </Col>


                    </Row>
                </div>







                  <div className='col-md-2'></div>






              </div>



  )

}

const renderformule = () => {

  return (


              <div className='row' style={{marginBottom:'40px'}}>


                  <div className='col-md-2'></div>


                    <div className='therapeutescontent col-md-4' style={{marginRight: '10px'}}>

                    <Row className=''>


                     <Col md={12} >



                  <div >

                    <div className='flexrowtarifs row'>


                      <div className=' col-md-12'>
                        <div className="text-center flowpriceheader"><img src={reservation?.tarif?.image}/><h2>{reservation?.tarif?.titre}</h2></div>
                      </div>

                      <div className='item'>



                  <div className='pricebox' style={{boxShadow: 'none'}}>
                  <h3>{reservation?.formule?.titre}</h3>
                  <h4>{parseFloat(reservation?.formule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>




                  </div>
               </div>

              </div>
            </div>
          </div>





                        </Col>


                    </Row>
                </div>



      <div className='therapeutescontent col-md-4' style={{marginLeft: '10px'}}>

                    <Row className=''>


                     <Col md={12} >





                  <div >

                    <div className='flexrowtarifs row'>


                      <div className=' col-md-12'>
                         <div className='itemimg ' style={{backgroundImage:reservation?.patient?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+reservation?.patient?.picture})` : "url('/images/default-profile.png')",width: '75px',margin:'0 auto',height: '75px'}}> </div>
                             <h3 className='title text-center'>{reservation?.patient?.name}</h3>

                             <br/>

                      </div>

                      <div className='item'>



                  <div className='pricebox' style={{boxShadow: 'none'}}>

                     <div>
                         <h4>{nbreservation}<span><sup> /{reservation?.formule?.seance} {reservation?.formule?.seance > 1 ? 'séances' :'séance'} </sup></span></h4>
                         <h3>{'effectuées'}</h3>
                         <div className="pricebox-border2"> </div>
                     </div>






               </div>

              </div>
            </div>
          </div>





                        </Col>


                    </Row>
                </div>







                  <div className='col-md-2'></div>






              </div>



  )

}

 const renderApptherapeut = () => {

    return (

     <Container className='listtherapeutes'>

                  <div className='row listtherapeutesppad'>

                                    <div className='col-md-12'>

                                         <div className="text-center flowpriceheader"><h2>Votre séance de thérapie est terminée </h2></div>
                                         <br/>

                                         {

                                           reservation?.tarif?.id==5 ?
                                            <div>
                                               {renderformulegroupe()}
                                                {renderactiongroup()}

                                            </div>

                                             :
                                             <div>

                                              { renderformule()}

                                              {
                                                statusabonnement==1 &&
                                                 renderformuleaction()
                                              }
                                              { renderactionformule()}

                                             </div>



                                         }










                                    </div>
                  </div>
      </Container>
      )
  }


 const renderApp = () => {

    return (

     <Container className='listtherapeutes'>

                  <div className='row'>

                                    <div className='col-md-12'>

                                            <div className="text-center flowpriceheader"><h2>Votre séance de thérapie est terminée </h2></div>
                                            <br/>

                                        <div className='row'>
                                          <div className='col-md-4'></div>
                                           <div className='col-md-4 text-center'>

                                            <div className='itemimg ' style={{backgroundImage:therapeute?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+therapeute?.picture})` : "url('/images/default-profile.png')",width: '90px',margin:'0 auto', height: '90px'}}> </div>
                                            <br/>
                                            <h3 className='title'>{therapeute?.name}</h3>

                              <div className="ps-product--detail ">
                                  <div className="ps-product__meta" style={{justifyContent: 'center'}} >
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                            <option value="1">1</option>
                                            <option value="1">2</option>
                                            <option value="1">3</option>
                                            <option value="1">4</option>
                                            <option value="2">5</option>
                                        </select>
                                    <div className="br-widget br-readonly">
                                    </div>
                                    </div>
                                    <span>{avg?.avg?.length}  avis</span>
                                    </div>

                                </div>
                                </div>


                                           </div>
                                           <div className='col-md-4'></div>


                                        </div>




                                     </div>

                  </div>



             <section className="section2 " >
                <Container>

                <div className='row '>
                  <div className='col-md-12 '>


                      <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                                            <div className="ps-block--average-rating" style={{display:'none'}}>
                                                <div className="ps-block__header">
                                                    <h3>{parseFloat(avgnote).toFixed(2)}</h3>
                                                    <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                                        <option value="1">1</option>
                                                        <option value="1">2</option>
                                                        <option value="1">3</option>
                                                        <option value="1">4</option>
                                                        <option value="2">5</option>
                                                    </select><div className="br-widget br-readonly"><a href="#" data-rating-value="1" data-rating-text="1" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="2" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="3" className="br-selected br-current"></a><a href="#" data-rating-value="1" data-rating-text="4" className="br-selected br-current"></a><a href="#" data-rating-value="2" data-rating-text="5"></a><div className="br-current-rating">1</div></div></div><span>{avg?.avg?.length} Avis</span>
                                                </div>
                                                <div className="ps-block__star"><span>5 Étoile</span>
                                                    <div className="ps-progress" data-value={notepour5}><span style={{width:(notepour5/avg?.avg?.length)*100+'%'}}></span></div><span>{avg?.avg?.length ? ((notepour5/avg?.avg?.length)*100).toFixed(2):0}%</span>
                                                </div>
                                                <div className="ps-block__star"><span>4 Étoile</span>
                                                    <div className="ps-progress" data-value={notepour4}><span style={{width:(notepour4/avg?.avg?.length)*100+'%'}}></span></div><span>{avg?.avg?.length ? ((notepour4/avg?.avg?.length)*100).toFixed(2):0}%</span>
                                                </div>
                                                <div className="ps-block__star"><span>3 Étoile</span>
                                                    <div className="ps-progress" data-value={notepour3}><span style={{width:(notepour3/avg?.avg?.length)*100+'%'}}></span></div><span>{avg?.avg?.length ? ((notepour3/avg?.avg?.length)*100).toFixed(2):0}%</span>
                                                </div>
                                                <div className="ps-block__star"><span>2 Étoile</span>
                                                    <div className="ps-progress" data-value={notepour2}><span style={{width:(notepour2/avg?.avg?.length)*100+'%'}}></span></div><span>{avg?.avg?.length ? ((notepour2/avg?.avg?.length)*100).toFixed(2):0}%</span>
                                                </div>
                                                <div className="ps-block__star"><span>1 Étoile</span>
                                                    <div className="ps-progress" data-value={notepour}><span style={{width:(notepour/avg?.avg?.length)*100+'%'}}></span></div><span>{avg?.avg?.length ? ((notepour/avg?.avg?.length)*100).toFixed(2):0}%</span>
                                                </div>
                                            </div>
                                           </div>
                                   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                            <form onSubmit={handleSubmit} className="ps-form--review" action="index.html" method="get">
                                                <h4>SOUMETTRE VOTRE AVIS</h4>
                                                <p>Votre adresse email ne sera pas publiée.</p>
                                                <div className="form-group form-group__rating" style={{display:'none'}}>
                                                    <label><strong>Votre évaluation</strong> </label>
                                                    <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="false" style={{display: 'none'}}>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    <div className="br-widget" >
                                                    <a onClick={()=>setNote(1)} data-rating-value="1" data-rating-text="1" className={(note>0 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(2)} data-rating-value="2" data-rating-text="2" className={(note>1 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(3)} data-rating-value="3" data-rating-text="3" className={(note>2 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(4)} data-rating-value="4" data-rating-text="4" className={(note>3 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(5)} data-rating-value="5" data-rating-text="5" className={(note>4 ? 'br-selected br-current' : '')}>
                                                    </a><div className="br-current-rating">
                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="comment" className="form-control" rows="6" placeholder="Donnez votre avis ici"></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group">
                                                            <input defaultValue={user?.api_token ? user.name:""} name="nom" className="form-control" type="text" placeholder="Nom complet" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group">
                                                            <input className="form-control" defaultValue={user?.api_token ? user.email:""} name="email" type="email" placeholder="Votre email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group submit">
                                                    <button className="ps-btn">Envoyer</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 "></div>


                     </div>




                  </div>
                </div>
               </Container>
            </section>



    </Container>





    );
  };


  const handleClose = () => setShow(false);

   const handleShow = (item) => {


      setShow(true);

    }




    return (
        <div className="home finconsultation" >

          <section className={"section2" } >

           {

              slug=='patient' ?  renderApp() : renderApptherapeut()



           }


          </section>



      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
      </Modal>
        </div>
    );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;