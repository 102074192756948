import React,{useState,useEffect} from "react";
import { logo,google_play,app_store,logof,linkedin,face,insta } from "../../assets/images";
import './styles.scss';
import { Container, Row, Col,CloseButton,Modal  } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux"
import { addNewsletter } from "../../actions/mixedmode";
import { getSettings} from "../../actions/mixedmode";
import { REMOVE_PRODUCT_BASKET, GET_TOTAL_CART } from "../../constants/actions";

const Component = ({ path }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const { user } = useSelector(state => state.user)
    const [showmenu,setShowMenu]=useState(false);

    const { data,total } = useSelector(state => state.basket)

    const [value,setValue]=useState('');
    const { settings } = useSelector(state => state.mixedmode)

    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    const [tokenmob, settokenmob] = useState(localStorage.getItem('token'));

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');



   const [sect1, setSect1] = useState(false)
   const [sect2, setSect2] = useState(false)
   const [sect3, setSect3] = useState(false)



const goToCart = () =>{
         history.push(`/panier`);
     }

    const goToPage = (src) =>{
         history.push(src);
    }
    const goToLoginOrAcount = () =>{
         history.push(`/login`);
    }
    const handlerMenu = () => {

          setShowMenu(!showmenu)

    }

useEffect(() => {
    const handler = e => {
      e.preventDefault();

      setSupportsPWA(true);
      setPromptInstall(e);

    };

      window.addEventListener("beforeinstallprompt", handler, false);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {

    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };


      const handleSubmitnewsletter = () => {
        const formDataObj ={email:value};
        if(formDataObj.email=="" ){
           alert("Merci de indiquez votre adresse email.");
        }else{
             dispatch(addNewsletter(formDataObj));
        }

    }




    // TODO - remove dev link
    return (
        <div className='footercontent' style={{position:'relative'}}>
         <div className="bt_bb_section_top_section_coverage_image"><img src="/images/bgfooter.png" alt="bt_bb_section_top_section_coverage_image"/></div>
        <footer className="footer-element">

            <Container className="footer-container">
               <div className="ps-footer__widgets">
                <aside className="widget widget_footer widget_contact-us tcenter">

                <a className="ps-logo" href="#" style={{margin:'0px'}}><img style={{maxWidth: '180px'}} src="/images/logo-feel-blanc.png" alt=""/></a>
                <br/><br/>

                    <div className="widget_content">
                        <p style={{color:'#FFF'}}  dangerouslySetInnerHTML={{ __html: settings.site_description }} ></p>
                         <br/>
                         {
                          (token || tokenmob ) ?
                          <div></div>
                          :
                          <div class="form-group--nest app_store" style={{alignItems: 'center',justifyContent: 'space-between'}}><a style={{    margin: '0px'}} href="https://play.google.com/store/apps/details?id=com.feelbetter" target="_blank"><img src="/images/google_play.png"/></a><a style={{    margin: '0px'}} href="https://apps.apple.com/us/app/feel-better/id1668844281" target="_blank"> <img src="/images/app_store.png"/></a></div>


                         }



                    </div>



                </aside>
                <aside className="widget widget_footer padtopelm990">
                    <h4 className="widget-title" onClick={()=>setSect1(!sect1)}>Compagnie</h4>
                    <ul className={sect1==true ? "ps-list--link displ_9987" : "ps-list--link" }>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/apropos')} >Qui sommes-nous ?</a></li>

                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/rgbd')}>RGPD</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/cgu')} >CGVU</a></li>



                    </ul>
                </aside>
                <aside className="widget widget_footer padtopelm990">
                    <h4 className="widget-title" onClick={()=>setSect2(!sect2)}>Aide </h4>
                    <ul className={sect2==true ? "ps-list--link displ_9987" : "ps-list--link"}>

                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/aide')}>FAQ</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/numeros-d-urgence')}>Numéros d'urgence </a></li>



                    </ul>
                </aside>
                <aside className="widget widget_footer padtopelm990">
                    <h4 className="widget-title" onClick={()=>setSect3(!sect3)}>Contactez-nous</h4>



                    <ul className={sect3==true ? "pul33 displ_9987" : "pul33"} >

                            <li style={{display:'none'}}>
                                <div className="footerContants footer-phone">
                                    <i className="fa fa-phone"></i>
                                    <a href={"tel:"+settings?.site_phone}>{settings?.site_phone}</a>
                                </div>
                            </li>
                            <li>
                                <div className="footerContants">
                                    <i className="fa fa-envelope-o"></i>
                                    <a href='javascript:void(0)' onClick={()=>history.push('/contact')}>{settings?.site_email}</a>
                                </div>

                            </li>
                            <li>
                                <div className="footerContants">
                                     <i className="fa fa-map"></i>
                                     <p><a style={{paddingLeft: '0px'}} href="https://www.google.com/maps/place/229+Rue+Saint-Honor%C3%A9,+75001+Paris,+France/@48.8660154,2.3294181,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66e2e1a161985:0x262161d5a23b4d6c!8m2!3d48.8660154!4d2.3294181" target='_blank'>{settings?.site_address}</a></p>
                                </div>


                            </li>
                        </ul>
                        <div className="socialWrapper">
                            <ul className="tt-socail">
                                    <li>
                                        <a href="https://www.tiktok.com/@psy.en.ligne" target="_blank" className="social-twitter">
                                             <img src='/images/tik-tok.png' />
                                        </a>
                                    </li>
                                      <li>
                                        <a href="https://www.instagram.com/feelbetter_company/" target="_blank" className="social-instagram">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/psyenligne.satinkasanson" target="_blank" className="social-facebook">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>



                            </ul>
                      </div>




                </aside>
            </div>

            <p className='copyright'>Feel Better™ © 2023</p>


            </Container>
        </footer>







<div id="loader-wrapper" style={{display:'none'}}>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
</div>










        </div>
    );
}

export default Component;