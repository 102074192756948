import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { SET_FORMULE } from "../../constants/actions";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getTarifs , getPage} from "../../actions/mixedmode";
import { setFormule} from "../../actions/user";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import { apiClient } from "../../actions/api"

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);

     const [show,setShow]=useState(false);
     const [formules,setFormules]=useState([]);
     const [readplus,setReadPlus]=useState(0);
     const [curenttarif,setCurenttarif]=useState({});
     const idtherapeute = queryParams.get('therapeute');
   
     const { data,pageinfo,tarifs } = useSelector(state => state.mixedmode);
     const { user,formule } = useSelector(state => state.user);
 
 

    useEffect(()=>{
     
      dispatch(getTarifs(idtherapeute?idtherapeute : ''))
      dispatch(getPage(55))
 
    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (item) => {


           setFormules([])
           setCurenttarif(item)
           setShow(true);

           apiClient().get(`/formules/`+item.id)
           .then((res) => {
          
             setFormules(res.data)
              
           })

    }
 
const goTo = (item) => {
  

  if(formule?.therapeut){
      let params={'tarif':curenttarif, 'formule':item,'therapeut':formule.therapeut}
      dispatch({ type: SET_FORMULE, payload: params })
      history.push(`/profil-therapeute/${formule.therapeut.name}/${formule.therapeut.user_id}`);
  }
  else{

      dispatch({ type: SET_FORMULE, payload: {'tarif':curenttarif, 'formule':item} })
      history.push('/list-psychologue')

  }

    
    
}

    return (
        <div className="home tarifs" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Nos tarifs</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3">  {idtherapeute ? 'Choisissez votre formule' : pageinfo?.titre} <br/><span> </span> </h2>
                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu}}></div>
                  
      
                </Container>
            </section>

      

             <section className="section2 section " >
                <Container>
                    
                  

                   <Row className="ps-block--site-features ps-block--site-features-2">

                    {tarifs.map((item,i) => (


                     <Col md={4} style={{marginBottom:'20px'}}>

                        <div className="ps-block__item pocket-shadow">
                          <div className="ps-block__left text-center"><img src={item.image}/></div>
                          <div className="ps-block__right">
                          <h4 className="text-center">{item.titre}</h4>
                          <p className="text-center">{ item.description}</p>
                          </div>
                            <div className="text-center" >
                            <br/>

                                   <a href='javascript:void(0)' onClick={()=>handleShow(item)}  className='btnlink marauto' >Détails <i className='fa fa-long-arrow-right'></i></a>

                             </div>
                          </div>
                         
                      </Col>



                    ))}

                

           

                 

 

                   </Row>

                   


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 
 



      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>

          <div className='flexrowtarifs row'>
          <div className=' col-md-12'>
            <div className="text-center flowpriceheader"><img src={curenttarif?.image}/><h2>{curenttarif?.titre}</h2></div>
          </div>



 {formules?.map((item,i) => (
           
              <div className='item col-md-4'>
               <div className='pricebox'>
                  <h3>{item?.titre}</h3>
                  <h4>{parseFloat(item?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                   {
                     curenttarif?.titre =='Adolescent' &&  <li><i className='fa fa-check-circle icochecked'></i>11 à 18 ans</li>
                   }
                   {
                     curenttarif?.titre =='Adolescent' &&  <li><i className='fa fa-check-circle icochecked'></i>autorisation parentale <span style={{color:'#842029'}}>*</span></li>
                   }

                       <li><i className='fa fa-check-circle icochecked'></i> {item?.temps} min</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {item?.seance} {item?.seance> 1 ? 'séances' : 'séance' }</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {item?.pack}</li>
                       {
                         curenttarif?.titre =='Groupe de parole' &&

                        <li><i className='fa fa-check-circle icochecked'></i> 6 participants</li>
                       }

                  </ul>

                  <label>
                     Description
                  </label>
                  <p>{ item.id==readplus ?  item.contenu : item.shotconte}  <br/> { (item.id!==readplus && item.show==1) && <a href='javascript:void(0)' onClick={()=>setReadPlus(item.id)}>Lire plus</a> } </p>

                   
                 
                   <div className="text-center" >
                         

                                   <a href='javascript:void(0)' onClick={()=>goTo(item)} className='btnlink marauto' >Prendre rendez-vous </a>

                    </div>

                  </div>
               </div>
             </div>


))}
 
            
             {
                     curenttarif?.titre =='Adolescent' &&  

                     <div style={{marginTop: '45px'}} className='alert alert-danger '>
                      * Pour démarrer votre suivi psychologique chez Feel Better™, il est obligatoire de fournir une autorisation parentale.
                     </div>



              }
            

             <div style={{marginTop: '45px'}} className='alert '><img class="hotline" src="/images/callservices.png"/>  <span>Des questions ? Consultez notre <span onClick={()=>history.push('/aide')} style={{color:'#17a2b8',textDecoration: 'underline'}}>FàQ</span> ou contactez l’équipe <span style={{color:'#17a2b8',textDecoration: 'underline'}} onClick={()=>history.push('/contact')} >Feel Better™</span></span></div>

             
         </div>
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;