import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { ProductItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import Stories from 'react-insta-stories';
import { getStories} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";


const Page = () => {
     const dispatch = useDispatch();
     const history = useHistory()
     const [show,setShow]=useState(false);
     const [stories,setStories]=useState([]);
     const {storieslist } = useSelector(state => state.mixedmode);


    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

   /*
   
        const stories = [
    {
        url: 'https://i.pinimg.com/originals/b6/34/31/b634311e07b5c9941a3bacbfc47d5390.jpg',
        header: {
            heading: 'Mohit Karekar',
            subheading: 'Posted 30m ago',
            profileImage: 'https://picsum.photos/100/100',
        },
        
    },
     {
         url: 'https://i.pinimg.com/736x/b2/7e/c7/b27ec7cb9921270f1823809a3c7fee59.jpg',
           header: {
            heading: 'Mohit Karekar',
            subheading: 'Posted 30m ago',
            profileImage: 'https://picsum.photos/100/100',
        },
        
    },
     {
         url: 'https://archzine.fr/wp-content/uploads/2018/08/mode%CC%80le-robe-en-wax-mode-ethnique-chic-femme-robe-longueur-genou-porte%CC%81e-avec-chemise-blanche-escarpins-beige-mode-africaine-tenue-femme-e%CC%81le%CC%81gante.jpg',
           header: {
            heading: 'Mohit Karekar',
            subheading: 'Posted 30m ago',
            profileImage: 'https://picsum.photos/100/100',
        },
    },
    {
         url: 'https://assets.mixkit.co/videos/preview/mixkit-man-under-multicolored-lights-1237-large.mp4',
         duration: 5000, // ignored
         type: 'video',
           header: {
            heading: 'Mohit Karekar',
            subheading: 'Posted 30m ago',
            profileImage: 'https://picsum.photos/100/100',
        },
        
    },

];

*/
    useEffect(()=>{
    
       dispatch(getStories(2))
    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
     
       const handleShow = (item) => {
      
      setStories(item?.stories)
      setShow(true);

    }

 

    return (
        <div className="home" >
    
 

              <section className="section4 " >
                <Container>
                    <h3 className="text-center mb-5"> Stories des boutiques <span></span> </h3>
                   <br/>

                   <div className='row storiessection'>
                      <div className='col-md-4'></div>

                          <div className='col-md-4'>


               

                      <div className='storiesflex' style={{display:'block'}}>


                               {storieslist?.map((item) => (

                                       <div className='item stories' style={{float: 'left'}}>
                                         <div onClick={()=>handleShow(item)}  className="w-32 h-32 rounded-full conic-gradient relative" style={{backgroundImage:item.picture== '' ? `url(${defaultimg})` : `url("${BASE_URL_PROD}/gestion/public/uploads/${item.picture}")`}}></div>
                                         <p className='center'>{item.nomboutique}</p>
                                        
                                      </div> 
                                ))}

                  
                    </div>

                   </div>

                       <div className='col-md-4'></div>


                   </div>

                


                </Container>

              </section>


           


            

      <Modal show={show} onHide={handleClose} className='modelhermix909'>
        <Modal.Header   >
          <CloseButton variant="white" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className='contentstoris'>
            <Stories stories={stories}  loop={true}/>
         </div>
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;