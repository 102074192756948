import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
 
import { getBest , getPage, getSlides, getTarifs} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import {IS_SUBMITED,SET_FORMULE,SET_FORMULE_UPDATE} from "../../constants/actions"

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);

    const typepush = queryParams.get('typepush');
    const token = queryParams.get('token');


     const [show,setShow]=useState(false);
 
     const { data,pageinfo,slides , tarifs} = useSelector(state => state.mixedmode);
    var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: true
          }
        }
      ]
   };


  var settingsSlide = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   
 

    useEffect(()=>{
       dispatch(getTarifs(''))
       dispatch(getSlides())
       dispatch(getPage(6))
    
     
       if(token){

          localStorage.setItem('token', token);
          localStorage.setItem('typepush', typepush);
       }


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
     const handleShow = (item) => {
      
     

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        
        
    }


  const goToPage = (src) =>{
         dispatch({ type: SET_FORMULE, payload: {} })
          dispatch({ type: SET_FORMULE_UPDATE, payload: {} })
         history.push(src);

    }



    return (
        <div className="home" >
            <section className="" >
                 
              <div className="ps-banner--market-4"   >
              <div className='bgheader'>
              </div>
                <div className='container'>

                   <div className='row'>

                         <div className='col-md-6'>

                           <div className='col-title-home'>

                             
                              <h1 dangerouslySetInnerHTML={{ __html:pageinfo?.titre}} ></h1>
                            
                              <p dangerouslySetInnerHTML={{ __html:pageinfo?.contenu }} ></p>

                           </div>

                          
                             
                         </div>

                        <div className='col-md-6 col-img-667'>
                              <img src="/images/5329293.png" />
                         </div>


                       </div>

                </div>
                      
              </div>
               
               
            </section>
            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-5" dangerouslySetInnerHTML={{ __html:pageinfo?.titre1 }} ></h2>
                   

                  <div className=''>

                     <Row className="g-5 "  >

                        <Col md={1}></Col>

                          <Col md={10}>
                          
                                <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu1 }} ></div>
                                <br/>
                               

                          </Col>

                        <Col md={1}></Col>
     
                      
                    </Row>

       
                  </div>

      
                </Container>
            </section>

      

             <section className="section3 section " >
                <Container>
                    <h2 className="text-center mb-3"> {pageinfo?.titre2}</h2>
                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu2 }} ></div>
                   <br/>  <br/>

                   <Row className="ps-block--site-features ps-block--site-features-2">

                    {tarifs.map((item) => (


                     <Col md={4}>

                        <div className="ps-block__item">
                          <div className="ps-block__left text-center"><img src={item.image}/></div>
                          <div className="ps-block__right">
                          <h4 className="text-center">{item.titre}</h4>
                          <p className="text-center">{item.description}</p>
                          </div>
                          </div>
                         
                      </Col>


                    ))}

                    </Row>

                
 


                   <Row>
                       <Col md={12}>
                          <div className="text-center" >

                                   <a href='javascript:void(0)' onClick={()=>goToPage('/tarifs')} className='btn_primary' >Découvrir nos formules</a>

                          </div>
                       </Col>

                   </Row>

                

                </Container>

              </section>
            



  <section className="section4 section " >
                <Container>
                    <h2 className="text-center mb-3">{pageinfo?.titre3}</h2>
                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu3 }}></div>
                 

               
                </Container>


  </section>


    <section className="section3 section " >
                <Container>
                    <h2 className="text-center mb-3">{pageinfo?.titre4}</h2>
                     

                               <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu4 }}></div>

                          <br/>
                                 <div className="text-center" >

                                   <a href='javascript:void(0)' onClick={()=>goToPage('/tarifs')}  className='btn_primary' >Prendre rendez-vous</a>

                                 </div>


                </Container>


  </section>


    <section className="section4 section " >
                <Container>
                    
                    <h2 className="text-center mb-3"> {pageinfo?.titre5}</h2>
                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu5}}></div>
                </Container>


  </section>

    <section className="section5 section " >
                <Container>
                    <h2 className="text-center mb-3"> {pageinfo?.titre6} </h2>
                  <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu6 }}></div>
                   <br/>  <br/>

                    <Row className="g-5" >

                    
                       <Col md={12} >
                       <Slider {...settings}>

                       {
                        slides?.map((item,key)=>(
                           
                             <AvisItem item={item} />

                        ))
                          
                           
                           
                       }

                        </Slider>
                     
                                 
                       </Col>
                      
                   </Row>


                </Container>
    </section>


 

      <Modal show={show} onHide={handleClose} className='modelhermix909'>
        <Modal.Header   >
          <CloseButton variant="white" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className='contentstoris'>
             
         </div>
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;