import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useLocalParticipant,
  useDailyEvent,
} from '@daily-co/daily-react';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';


const getVideoDisplaySize = (videoElement) => {
  const videoWidth    = videoElement.videoWidth;
  const videoHeight   = videoElement.videoHeight;
  const displayWidth  = videoElement.offsetWidth;
  const displayHeight = videoElement.offsetHeight;

  const videoRatio   = videoWidth   / videoHeight
  const displayRatio = displayWidth / displayHeight

  if (videoRatio < displayRatio) {
    return {
      width: displayHeight * videoRatio,
      height: displayHeight,
      borderX: (displayWidth - (displayHeight * videoRatio)) / 2,
      borderY: 0
    }
  } else {
    return {
      width: displayWidth,
      height: displayWidth / videoRatio,
      borderX: 0,
      borderY: (displayHeight - (displayWidth / videoRatio)) / 2
    }
  }
}

export default function Call() {
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [videoElement,      setVideoElement]      = useState(false);
  const [videoDisplay,      setVideoDisplay]      = useState(null);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  const localParticipant = useLocalParticipant();
  const isAlone = useMemo(
    () => remoteParticipantIds?.length < 1 || screens?.length < 1,
    [remoteParticipantIds, screens],
  );

  const onVideoElement = (videoElement) => {
    videoElement.current.addEventListener('loadedmetadata', () => {
      setVideoDisplay(getVideoDisplaySize(videoElement.current))
    })

    setVideoElement(videoElement.current)
  }




  useEffect(() => {
    const handleResize = () => {
      if (videoElement) {
        setVideoDisplay(getVideoDisplaySize(videoElement))
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
   }, [videoElement])



  const renderCallScreen = () => (
    <div className={`${screens.length > 0 ? 'is-screenshare' : 'call'}`}>
      {remoteParticipantIds?.length > 0 || screens?.length > 0 ? (
        <>
          {remoteParticipantIds.map((id) => (
            <Tile key={id} id={id} onVideoElement={onVideoElement} mainVideoDisplaySize={videoDisplay} />
          ))}
          {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))}
        </>
      ) : (
        <div className="info-box">
          <h1>En attente de connexion</h1>
          <span className="room-url" style={{display:'none'}}>{window.location.href}</span>
        </div>
      )}
      {localParticipant && <Tile id={localParticipant.session_id} isLocal isAlone={isAlone} mainVideoDisplaySize={videoDisplay} />}
    </div>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}
