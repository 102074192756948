import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { PsyItem } from "../../components";
import { Link, useHistory } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router";
import { SET_FORMULE } from "../../constants/actions";

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);
    const [json, setJson] = useState([{day:'1',name:'Lundi',number:'7 fév'},{day:'2',name:'Mardi',number:'8 fév'},{day:'3',name:'Mercredi',number:'9 fév'},{day:'4',name:'Jeudi',number:'10 fév'},{day:'5',name:'Vendredi',number:'11 fév'},{day:'6',name:'Samedi',number:'12 fév'},{day:'7',name:'Dimanche',number:'13 fév'},{day:'1',name:'Lundi',number:'14 fév'},{day:'2',name:'Mardi',number:'15 fév'},{day:'3',name:'Mercredi',number:'16 fév'},{day:'4',name:'Jeudi',number:'17 fév'},{day:'5',name:'Vendredi',number:'18 fév'},{day:'6',name:'Samedi',number:'19 fév'},{day:'7',name:'Dimanche',number:'20 fév'}]);
    const [avgnote,setavgnote]=useState(5);
    const [value, setValue] = useState(0);

     const [show,setShow]=useState(false);
     const { data,pageinfo } = useSelector(state => state.mixedmode);

    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)



    const handleClose = () => setShow(false);

    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");

    const [langset, setLangset] = useState(false);
    const [tab, setTab] = useState(1);
    const [tabins, setTabins] = useState(1);
    const [active,setActive]=useState(2);
    const [active2,setActive2]=useState(1);
    const [active3,setActive3]=useState(1);
    const [iflivreur,setIflivreur]=useState(false);

     useEffect(() => {


      if(!user?.user_id){
           history.push('/');
      }



    }, [user])




    return (
        <div className="home paiementtherapeut" >


          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Paiement</li><li>C'est terminé</li></ul></div></div></div>
          </section>



 <section className="section2  " style={{paddingTop:'10px'}} >
    <div class="holder">
    <ul class="SteppedProgress">
    <li class="boxed  complete   "><span>Choix du praticien</span></li>
    <li class="boxed  complete  "><span>Infos patient</span></li>
    <li class="boxed   complete"><span>Paiement</span></li>
    <li class="boxed complete "><span>C'est terminé</span></li>
    </ul>
    </div>

 </section>


  <section className="section2 thisprofil listtherapeutes " >
                <Container>

                  <div className='row'>

                     <Col md={12} >


                    <div className='center'>

                      <img src='/images/validation-150x150.png' />
                       <br/>
                    </div>
                      <br/>
                        <br/>
                          <br/>

                     <h3 className ='center'>

                        Votre rendez-vous est confirmé ! Un email de confirmation vous a été envoyé. <br/>
                        <strong>À bientôt sur Feel Better™ </strong>
                     </h3>
                    <br/>

                     <a href="javascript:void(0)" onClick={()=>history.push('/client/dashboard')} class="btnlink marauto">Mon compte <i class="fa fa-long-arrow-right"></i></a>

                     </Col>

                  </div>
             </Container>
</section>




      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
      </Modal>
        </div>
    );
}




export default Page;