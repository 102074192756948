/*
  We'll add a 30-min expiry (exp) so rooms won't linger too long on your account.
  See other available options at https://docs.daily.co/reference#create-room
 */
async function removeRoom(url) {
  const options = {
  };

  const isLocal = 'local';
  const endpoint = isLocal
    ? 'https://api.daily.co/v1/rooms/'+url
    : `${window.location.origin}/api/rooms`+url;

  /*
    No need to send the headers with the request when using the proxy option:
    netlify.toml takes care of that for us.
  */
  const headers = isLocal && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer f6e45fa1ddfe170bee670a03407db0a342aeda1bca536d516da85b98abb15e3f`,
    },
  };

  const response = await fetch(endpoint, {
    method: 'DELETE',
    body: JSON.stringify(options),
    ...headers,
  });

  return response.json();
}

export default { removeRoom };
