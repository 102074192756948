import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import { BASE_URL_PROD } from "../../../components/config/keys";
import moment from 'moment'
import { apiClient } from "../../../actions/api"

const Page = () => {
     const dispatch = useDispatch();
     const history = useHistory()
     const queryParams = new URLSearchParams(window.location.search);
     const [show,setShow]=useState(false);
     const [article,setarticl]=useState({});
     const { data,pageinfo } = useSelector(state => state.mixedmode);
     const { user,test } = useSelector(state => state.user);
 
 

    useEffect(()=>{
     
       


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (item) => {
      
       
      setShow(true);

    }
 
 const goTo = () => {
      
       
    
    }
    return (
        <div className="home tarifs tests" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Tests</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3" style={{textTransform:'uppercase'}}>  {test?.test?.titre}<br/><span>  </span> </h2>
                     <br/>
                    <h3   className="mb-3" style={{textTransform:'uppercase',display:'none'}}> majorité de réponse {test?.majorite?.id} :<br/><span>  </span> </h3>
      
                </Container>
            </section>

      

             <section className="section2 section " style={{paddingTop:0}} >
                <Container>


                 <Row className="ps-block--site-features ps-block--site-features-2">

                   
                      <Col md={12} >

                          <div dangerouslySetInnerHTML={{ __html:test?.majorite?.description}}></div>

                          
                      </Col>

 

              
                   </Row>

<br/>
<br/>
<br/>
 


    <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2' style={{    borderRadius: '34px',border: '0px'}}>
                        
                    
                        <a href='javascript:void(0)' style={{marginTop: '0px',textTransform: 'inherit',fontSize: '21px'
}} onClick={()=>history.push('/list-psychologue')} >Contactez un praticien Feel Better™</a>

                      </section>
                      </Col>
    </Row>
               {
                        test?.article?.id && 
                           
    <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2' style={{    borderRadius: '34px',border: '0px'}}>
                        
                    
                        <a href='javascript:void(0)' style={{marginTop: '0px',textTransform: 'inherit',fontSize: '21px'
}} onClick={()=>history.push('/article/'+test?.article?.id)}  >Lisez l'article</a>

                      </section>
                      </Col>
    </Row>
                      }


    <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2' style={{    borderRadius: '34px',border: '0px'}}>
                        
                    
                        <a href='javascript:void(0)' style={{marginTop: '0px',textTransform: 'inherit',fontSize: '21px'
}} onClick={()=>history.push('/tests')} >Faites un autre test !</a>

                      </section>
                      </Col>
    </Row>

 



                  

                   <Row className="ps-block--site-features ps-block--site-features-2">

                   
       

              
                   </Row>

       


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 
 



 
        </div>
    );
}

export default Page;