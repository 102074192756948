import React,{useEffect,useState,useRef} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { PsyItem } from "../../components";
import { Link, useHistory } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { SET_FORMULE } from "../../constants/actions";
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import { loginUser } from "../../actions/user";
import {IS_SUBMITED} from "../../constants/actions"
import {AUTHENTICATE_USER} from "../../constants/actions"
import { registerUser } from "../../actions/user";


const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);
    const [json, setJson] = useState([{day:'1',name:'Lundi',number:'7 fév'},{day:'2',name:'Mardi',number:'8 fév'},{day:'3',name:'Mercredi',number:'9 fév'},{day:'4',name:'Jeudi',number:'10 fév'},{day:'5',name:'Vendredi',number:'11 fév'},{day:'6',name:'Samedi',number:'12 fév'},{day:'7',name:'Dimanche',number:'13 fév'},{day:'1',name:'Lundi',number:'14 fév'},{day:'2',name:'Mardi',number:'15 fév'},{day:'3',name:'Mercredi',number:'16 fév'},{day:'4',name:'Jeudi',number:'17 fév'},{day:'5',name:'Vendredi',number:'18 fév'},{day:'6',name:'Samedi',number:'19 fév'},{day:'7',name:'Dimanche',number:'20 fév'}]);
    const [avgnote,setavgnote]=useState(0);
    const [value, setValue] = useState(0);
    const [adolecent, setAdolecent] = useState(false);

    const [isPage, setIsPage] = useState(1);
    const [isError, setError] = useState(false);
    const [ifloading, setIfloading] = useState(0)



     const [show,setShow]=useState(false);
     const [ErrorAdolescent,setErrorAdolescent]=useState(false);
     const { data,pageinfo } = useSelector(state => state.mixedmode);
     const { user,formule } = useSelector(state => state.user);
     const { issubmited } = useSelector(state => state.global)

    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)

    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [filename1, setFilename1] = useState('');
    const [filename2, setFilename2] = useState('');

   const handleClickfile = (t) => {

      switch(t){
             case 1:
               fileInput.current.click()
             break;
              case 2:
               fileInput2.current.click()
             break;
            
      }
     
  }

    const handleRemovefile = (t) => {

      switch(t){
             case 1:
                setFile1('')
             break;
              case 2:
                 setFile2('')
             break;
    


      }
      return false;
     
  }

function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'jpeg':
    case 'bmp':
    case 'png':
    case 'pdf':
    case 'doc':
    case 'docx':
      //etc
      return true;
  }
  return false;
}
 

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}
   

    const handleFileChange = (event,t) => {
       
      const formData =  new FormData();
      formData.append('file',event.target.files[0]);



      if(t==1  ){
      setFilename1(event.target.files[0].name)
      }
      else{
         setFilename2(event.target.files[0].name)
      }

       if(t==1 || t==2 ){

            if(!isImage(event.target.files[0].name)){
               alert('Veuillez sélectionner un fichier valide (png,jpg,jpeg,gif,pdf,doc..)')
               return false;
            }

            formData.append('typefile','image');

       }
 

      setIfloading(1)
    
      apiClient().post(`/uploadfiles`,formData)
              .then((res) => {

         if(res.data.status=='successs'){
          setIfloading(0)

        switch(t){
             case 1:
                setFile1(res.data.message)
             break;
              case 2:
                setFile2(res.data.message)
             break;
            
           


      }

                }
                else{
                     setIfloading(0)
                alert('Erreur !')
                }
                 
        })
     
  }

   
    const handleClose = () => setShow(false);
 
   
    const currentlangStorage = localStorage.getItem("currentlang");
 
    const [langset, setLangset] = useState(false);
    const [tab, setTab] = useState(1);
    const [tabins, setTabins] = useState(1);
    const [active,setActive]=useState(2);
    const [active2,setActive2]=useState(1);
    const [active3,setActive3]=useState(1);
    const [iflivreur,setIflivreur]=useState(false);
    const [therapeute,setTherapeut]=useState([{}]);


 
 

    useEffect(()=>{

      setTherapeut(formule?.therapeut)
       let sum=0;
           for (var i = 0; i < formule?.therapeut?.avg.length; i++) {
                sum=sum+parseInt(formule?.therapeut?.avg[i].note)
           }
          if(sum>0){
            setavgnote(sum/formule?.therapeut?.avg.length)
    }
      

    },[])

   useEffect(()=>{


          if(!formule?.formule || !formule?.therapeut ){
            history.push('/')
            return false;
          }
        

          if(issubmited){
            dispatch({ type: IS_SUBMITED, payload: false })

              if (user?.user_id) {
                if (user?.active===0) {
                      setIsPage(2)
                }
                else{

                   history.push('/paiement/') 
                  
                }

              }
           
        }
        else{
             if(user?.user_id){
                 if (user?.active===0) {
                      setIsPage(2)
                }
                else{
                      history.push('/paiement/')
                }
             }
        }
      

    },[user,issubmited])

     const handleClick = (slug) => {
        history.push(slug);
    }

    
 
   const handleShow = (item) => {
      
       
      setShow(true);

    }




 
   const handleGoTo = (slug) => {
         history.push(slug)
    }

 

  const handleTabs=(a)=>{
    
             setActive(a)
 
    }

    const handleTabs2=(a)=>{
       
             setActive2(a)
             if(a==2){
               history.push("/devenir-vendeur/")
             }
 
    }
      const handleTabs3=(a)=>{
        
             setActive3(a)
 
    }

  
 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        history.push('/paiement/')
        //dispatch(registerUser(formDataObj,'user',currentlang));

 
    }


      const handlerconnexion = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
         dispatch(loginUser(formDataObj,'user'));
        
       
    }

    const handlerregister = (e) => {

        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let type='A';
     

        if(formDataObj.adolesant!=1){
            setAdolecent(true)

            formDataObj.adolescent=1
             if(!file1){
                alert('Veuillez télécharger une autorisation scannée et signée par l’un des responsables légaux')
                  return false;

            }
            else{
                 formDataObj.file1=file1
            }


             if(!file2){
                  alert('Veuillez télécharger la copie de la pièce d’identité du responsable légal ayant signé l’autorisation')
                  return false;

            }
            else{
              formDataObj.file2=file2
            }
           
            
                
        }



        dispatch(registerUser(formDataObj,type));
       
    }



 const resendcodeverification =  (e) => {
   
 
            e.preventDefault();
 
            setError(false)
             let param={
                 user_id:user.user_id
             }
             
            apiClient().post(`/resentcode`,param )
           .then((res) => {
          
              if(res.data.success==true){
                  
                  alert('Un nouveau code vous a été envoyé !')
                
              }
              else{
              
                setError(true)
            

              }
              
           })

 }

    const handlerVerification =  (e) => {
           e.preventDefault();
 
            setError(false)
            const formData = new FormData(e.target)
            const formDataObj = Object.fromEntries(formData.entries())
             formDataObj.user_id=user.user_id
            apiClient().post(`/verfycode`,formDataObj )
           .then((res) => {
          
              if(res.data.success==true){
                 dispatch({ type: AUTHENTICATE_USER, payload: res.data.user })
                 history.push('/paiement/')
                
              }
              else{
              
                setError(true)
            

              }
              
           })
       
    }


 const handleChangecheckbox = event => {
    if (event.target.checked) {
       setAdolecent(false)
    } else {
     setAdolecent(true)
    }
     
  };

 

     useEffect(() => {


   
        if(langset == false){


     
            setLangset(true)
        }
 
 

    }, [])



const returnErrorAdolescent = () => {
     return (
         
         <div className='alert alert-danger'><span><i className='fa fa-remove'></i></span>Votre compte est en cours de vérification.</div>

    )
}

const returnCodeVerification = () => {

     
     return (
            
              <div className="mb-5 divform pddformlng">

                        <div className='section8890' style={{background:'#FFF'}}>

                           <Form className="mb-5" onSubmit={handlerVerification}  >

                             <h3><strong>Un email de confirmation vous a été envoyé.</strong></h3>
                             <p>Pour finir votre inscription, veuillez saisir le code à 4 chiffres que nous avons envoyé par email.  </p>

                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="code"
                                    type="text" placeholder="Votre code de vérification" required />
                            </Form.Group>

                            <Form.Group className="d-flex">
                               
                                <a className="forgetlink" style={{width:'100%',    textAlign: 'right'}} href='jacascript:void(0)'  onClick={(e)=>resendcodeverification(e)} >Renvoyer le code de confirmation  </a>
                            </Form.Group> 
                            <br/>
                            <button
                                className="btnlink btnlinkwdborder  marauto " type="submit">
                                Valider
                           </button>
                           {
                            isError &&
                            <div className="alert alert-danger" style={{marginTop:'40px'}}>

                               Votre code de vérification est incorrect !
                            </div>
                           }



                           </Form>

                        </div>

             </div>

        )

}

const returnLogin = () => {

     
     return (



                      <div className="mb-5 divform pddformlng">

                        <div className='section8890'>
                         <a className={active == 1 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(1)}  >Inscription</a>
                         <a className={active == 2 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(2)} >Connexion</a>
                      </div>

                         {
                         active ==1 ?

                         <div>

                    
                         


                          {
                             active2 == 1 ? 

                    <Form className="mb-5" onSubmit={handlerregister}  >
                           
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="first_name"
                                    type="text" placeholder="Votre Prénom.." required  />
                            </Form.Group>
                             <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="last_name"
                                    type="text" placeholder="Votre Nom.." required />
                            </Form.Group>
                          

                              <Form.Group className="mb-3" >
                                
                              <PhoneInput
                                          country={'fr'}
                                          
                                          name={"phone"}
                                          enableLongNumbers={true}
                                           inputProps={{
                                              name: 'phone',
                                              required: true,
                                              placeholder:'Votre numéro de téléphone..'
                                              
                                            }}
                                        />
                                    

                            </Form.Group>

                             <Form.Group className="mb-3" >
                              
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email" required />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe.." required />
                            </Form.Group>

                            {
                                adolecent &&

                                <div>

                            <p className='alert alert-warning'>Pour démarrer votre suivi psychologique chez Feel Better™, il est obligatoire de fournir une autorisation parentale.<br/> Veuillez télécharger l’autorisation scannée et signée par l’un des responsables légaux et la copie de la pièce d’identité du responsable légal ayant signé l’autorisation.</p>
                                    
                           <Form.Group className="mb-3" >

                            <div onClick={() => handleClickfile(1)} className='btn ps-btn-dark btndwnload'>
                                 
                               {
                                    !file1 ?
                                      <label>Télécharger l'autorisation parentale <i className='fa fa-download'></i></label>
                    
                                    :
                                    <label>{filename1}  <i className='fa fa-check'></i></label>

                                }

                                <form style={{padding:'0px'}}>
                                   <input
                                   style={{display:'none'}}
                                    type="file"
                                    accept='image/*'

                                    onChange={(e) => handleFileChange(e,1)}
                                    ref={fileInput} 
                                />
                                </form>
                                <span style={{display:'none'}}><input type='text' name='file1' value={file1} /></span>
                               
                           </div>
                                
                                 
                            </Form.Group>

                           <Form.Group className="mb-3" >

                                <div onClick={() => handleClickfile(2)} className='btn ps-btn-dark btndwnload'>
                                {
                                    !file2 ?
                                       <label>Télécharger la copie de la pièce d’identité  <i className='fa fa-download'></i></label>
                                    :
                                    <label>{filename2}  <i className='fa fa-check'></i></label>

                                }

                                
                
                                <form style={{padding:'0px'}}>
                                   <input
                                   style={{display:'none'}}
                                    type="file"
                                    accept='image/*'

                                    onChange={(e) => handleFileChange(e,2)}
                                    ref={fileInput2} 
                                />
                                </form>
                                <span style={{display:'none'}}><input type='text' name='file2' value={file2} /></span>
                                </div>
                              
                                
                                 
                            </Form.Group>




                         </div>


                            }




                        <Form.Group className="mb-3" >
                                
                            <div className='js889'>
                             <input type='checkbox' name='adolesant' value='1' onChange={handleChangecheckbox} />
                             <span style={{paddingLeft: '8px'}}>Je certifie avoir plus de 18 ans </span>

                            </div>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                
                            <div className='js889'>
                             <input type='checkbox' required />
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance des <strong>CGV</strong> et <strong>CGU</strong> *</span>

                            </div>
                            </Form.Group>
                             <Form.Group className="mb-3" >
                                
                            <div className='js889'>
                             <input type='checkbox'  required/>
                             <span style={{paddingLeft: '8px'}}>J’ai pris connaissance de la  <strong>politique de confidentialité</strong> *</span>

                            </div>
                            </Form.Group>
                                <Form.Group className="mb-3" style={{    marginTop: '31px'}} >
                                
                              <button
                                className="btnlink btnlinkwdborder  marauto" type="submit">
                                S'inscrire
                            </button>
                            </Form.Group>
                           

                        </Form>

                             : active2 == 3 ? 

                <Form className="mb-5" onSubmit={handlerconnexion}  >
                           
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Votre Nom et Prénom*.." />
                            </Form.Group>

                               <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Ville ou commune*.." />
                            </Form.Group>

                               <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Numéro de téléphone*.." />
                            </Form.Group>
                            



                  
                             <Form.Group className="mb-3" >
                              
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email*" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe*.." />
                            </Form.Group>


                             
                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                S'inscrire
                            </Button>

                        </Form>

                             :

                             <div></div>

                          }


                         </div>

                       

                        :

                        <div>


                       
 

                       <Form className="mb-5" onSubmit={handlerconnexion}  >
                           
                            <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="mail@website.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe*</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Min. 8 character" />
                            </Form.Group>
                            <Form.Group className="d-flex">
                                <Form.Check type="checkbox" label="Se souvenir de moi" />
                                <Link className="forgetlink" to="/password">Mot de passe oublié ?</Link>
                            </Form.Group> 
                            <br/>
                            <button
                                className="btnlink btnlinkwdborder  marauto " type="submit">
                                Connexion
                           </button>


                        </Form>

                        </div>


                      }


                      </div>



    )
 

 
       
} 
   

 

    return (
        <div className="home paiementtherapeut" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Infos patient</li></ul></div></div></div>
          </section>



 <section className="section2  " style={{paddingTop:'10px'}} >
    <div class="holder">
    <ul class="SteppedProgress">
    <li class="boxed  complete   "><span>Choix du praticien</span></li>
    <li class="boxed  complete  "><span>Infos patient</span></li>
    <li class="boxed   "><span>Paiement</span></li>
    <li class="boxed  "><span>C'est terminé</span></li>
    </ul>
    </div>

 </section>

          <section className="section2 thisprofil listtherapeutes " >
                <Container>

                  <div className='row'>

                     <Col md={6} >

                     <div className='therapeutescontent'>






 <div className="login" style={{display:'block',paddingTop:'0px'}} >
          
       
 

                <Row>
                    <Col lg={{ span: 12 }} className="text-center mx-auto " >
                       

                    
                          {isPage==1 ?

                             returnLogin()

                             : isPage==2 ?

                             returnCodeVerification():
                             null




                          }

                          {
                            ErrorAdolescent &&
                              returnErrorAdolescent()
                          }


                    </Col>
                </Row>
        
        </div>



                     </div>


                      

                     </Col>

                     <Col md={1} >

                     </Col>

                     <Col md={5} >


                  <div className='therapeutescontent'>

                      <Row className=''>
                        <Col md={12} >
                             <div className='flexitems row'>
                                 <div className='itemimg col-md-3' style={{backgroundImage:therapeute?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+therapeute?.picture})` : "url('/images/default-profile.png')"}}>

                                 </div>
                                  <div className='itemheader col-md-8' >
                                    <h3>{therapeute?.first_name} {therapeute?.last_name} </h3>
                                

                                 <div className="ps-product--detail ">
                                  <div className="ps-product__meta">
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                            <option value="1">1</option>
                                            <option value="1">2</option>
                                            <option value="1">3</option>
                                            <option value="1">4</option>
                                            <option value="2">5</option>
                                        </select>
                                    <div className="br-widget br-readonly">
                                    <a href="#" data-rating-value="1" data-rating-text="1" className={(parseFloat(avgnote) > 0.99 ? 'br-selected br-current' : '')}></a>
                                    <a href="#" data-rating-value="1" data-rating-text="2" className={(parseFloat(avgnote) > 1.99 ? 'br-selected br-current' : '')}>
                                    </a>
                                    <a href="#" data-rating-value="1" data-rating-text="3" className={(parseFloat(avgnote) > 2.99 ? 'br-selected br-current' : '')}></a>
                                    <a href="#" data-rating-value="1" data-rating-text="4" className={(parseFloat(avgnote) > 3.99 ? 'br-selected br-current' : '')}>
                                    </a>
                                    <a href="#" data-rating-value="2" data-rating-text="5" className={(parseFloat(avgnote) > 4.99 ? 'br-selected br-current' : '')}></a>
                                    <div className="br-current-rating">1</div>
                                    </div>
                                    </div>
                                    <span>{formule?.therapeut?.avg?.length} avis</span>
                                    </div> 

                                </div>
                                </div>
 
                                  <div className=''>
                                       
                                         <div className='item' style={{paddingBottom:'10px'}}>Date: <strong> {moment(new Date(formule?.date)).format("DD/MM/YYYY")}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Heure: <strong>{formule?.heure}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre besoin: <strong>{formule?.tarif?.titre}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre formule: <strong> {formule?.formule?.titre}</strong></div>

                                  </div>


                                   
                                 </div>
                             </div>

                             
                        </Col>
                        <Col md={3} >

                           <div className='actionrdv'>

                               <div className="text-center" >
                         
                                
                          
                                </div>

                           </div>
                            
                        </Col>
                       </Row>

 

                    </div>


                     </Col>

                  </div>

 
                     

                  
                </Container>
          </section>

 

 
 



      




      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>



 
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>

        <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>

</div>
    );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;