import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { Menu } from "../../../components";
import moment from 'moment'
import { apiClient } from "../../../actions/api"

import 'moment/locale/fr';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { getPatientLocalReservations, getNextConsultation } from "../../../actions/api_interface"
import ShowTimezone from '../../../components/show_timezone/'
import NextConsultation from '../../../components/next_consultation'



const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const [datedif, setDatedif] = useState('')
    const [idreservation, setIdReservation] = useState(1)
    const [is24, setIs24] = useState(false)

    const [eventconsulation, setEventconsulation] = useState('')
    const [consulations, setConsulations] = useState('')
    const [rendezvous, setrendezvous] = useState('')


    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);


    const [checkconsultation, setCheckconsultation] = useState({})




 useEffect(() => {


    getAllReservation()
    checkifconsultation()


  let eleme1=document.querySelector(".fc-dayGridMonth-button");
       let eleme2=document.querySelector(".fc-timeGridWeek-button");
       eleme1.innerText='Mois'
       eleme2.innerText='Semaine'

}, []);



  function getAllReservation () {
    getPatientLocalReservations(user, setConsulations)
  }




  const toggle = ({ event, el }) => {
     setEventconsulation('')

     setIdReservation(event._def.publicId)
     setrendezvous(event._def.extendedProps)

     let daterdv=event._def.extendedProps?.date_rdv+'T'+event._def.extendedProps?.heure_rdv

     let mmdate=moment().diff(daterdv, 'hours');

     if(parseInt(Math.abs(mmdate)) < 24){

      setIs24(true)
     }

     setDatedif(moment().diff(daterdv, 'hours'))

     setShowAlert(true)
  };


  const handleConfirm = (id) => {

      if(eventconsulation==1){

             setEventconsulation(3)



      }
      else{

             anullerdv(id)

      }
  };
const anullerdv = (id) => {

          let params={
              token:user.api_token,
              api_token:user.api_token,
              id:idreservation,
              action:'anuller'
          }


          apiClient().post(`/set/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){
                       getAllReservation()
                       setAlertMessage('Votre rendez-vous est annulé !')
                       handleCloseAlert();
                       setTimeout(function(){ setAlertMessage('')}, 5000);
                       checkifconsultation()

              }


           })



}
const deplacerdv = async (e) => {



        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())


      let daterdv=formDataObj.date+'T'+formDataObj.heure
      let datediff=moment().diff(daterdv, 'hours')
      if(datediff>=0){

          alert('Cette date est dépassée !')
          return false;

      }





          let params={

              token:user.api_token,
              api_token:user.api_token,
              id:idreservation,
              action:'deplacer',
              date:formDataObj.date,
              heure:formDataObj.heure
          }




          apiClient().post(`/set/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                    getAllReservation()
                    setAlertMessage('Votre demande de modification de rendez-vous est enregistrée, elle est en attente de confirmation.')
                    handleCloseAlert();
                    setTimeout(function(){ setAlertMessage('')}, 7000);
                    checkifconsultation()

              }


           })



}





    function checkifconsultation() {
        let params={

              token:user.api_token,
              api_token:user.api_token,
          }


          apiClient().post(`/check/consultation/therapeut`,params )
           .then((res) => {
              if(res.data.success=='ok'){
                if(res.data.reservation?.heure_rdv){
                  setCheckconsultation(res.data.reservation)

                }

              }


           })
    }


  const nextConsultation = getNextConsultation(consulations)

    return (
        <div className="mescommandes register vosventes bg778635 " >
            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='mesrendezvous' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright"  >
                            <h3 className="mb-4"> Mes rendez-vous</h3>
                            {
                              alertMessage &&

                              <div className='alert alert-success'>
                                {alertMessage}
                              </div>
                            }
                            <NextConsultation consultation={nextConsultation} target={"/conference/call/" + nextConsultation?.therapeute?.user_id} />




                  <div className='agenda'>


      <ShowTimezone />
      <FullCalendar

        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        weekends={true}
        initialView="dayGridMonth"
        headerToolbar={{
        start: 'title,prev,next',
        center: 'customButtons',
        end: 'dayGridMonth,timeGridWeek'
        }}
        locale={'fr'}
        timeZone= {'local'}
        events={consulations}
        eventClick={toggle}



      />



                             </div>





                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} size="lg"  centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>



        <Row className="mb-3 abonnement text-center "  >



          <div className='itemimg col-md-3' style={{backgroundImage:rendezvous?.therapeute?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+rendezvous?.therapeute?.picture})` : "url('/images/default-profile.png')"}}></div>
          <h3 style={{marginBottom: '3px',marginTop: '18px'}}>{rendezvous?.therapeute?.name}</h3>
          <div style={{marginBottom: '30px'}}>
            <a href='javascript:void(0)' onClick={()=>history.push('/messages?p='+rendezvous?.therapeute?.user_id)} style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Contacter mon praticien</a>
          </div>


                   <div className='row'>


                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Votre besoin: <br/><strong>{rendezvous?.tarif?.titre}</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Votre formule:<br/> <strong> {rendezvous?.formule?.titre}</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Date:<br/> <strong>{moment.utc(rendezvous?.time).local().format("DD/MM/YYYY")}</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Heure:<br/> <strong>{moment.utc(rendezvous?.time).local().format("HH:mm")}</strong></div>

                  </div>




          <div>


           {
            (rendezvous?.status==1 && datedif <=0) ?

                is24 === true ?
                  <div class="flexitems"><a style={{    opacity: '0.4'}} class="btnlink marauto">Déplacer ma consultation  </a><a style={{opacity: '0.4'}} class="btnlink marauto btnlinkjaun">Annuler ma consultation   </a></div>
                :
                 <div class="flexitems"><a href="javascript:void(0)" onClick={()=>setEventconsulation(1)} class="btnlink marauto">Déplacer ma consultation  </a><a href="javascript:void(0)" onClick={()=>setEventconsulation(2)} class="btnlink marauto btnlinkjaun">Annuler ma consultation   </a></div>

             :
             (rendezvous?.status==1 && datedif > 0) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>La date de votre rendez-vous est dépassée !</div></div>
             :
             (rendezvous?.status==3) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>Votre rendez-vous est annulé !</div></div>
             :
             (rendezvous?.status==4) ?
             <div class="flexitems"><div className='alert alert-warning' style={{    width: '100%'}}>Votre rendez-vous a été déplacé, il est  en attente de confirmation. </div></div>
             :
             (rendezvous?.status==5) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>Le rendez-vous est non honoré. </div></div>
             :
             <div class="flexitems"><div className='alert alert-success' style={{    width: '100%'}}>Votre séance en ligne a bien été effectuée. </div></div>

           }


          </div>


          </Row>








        </Modal.Body>
        <Modal.Footer>
        {
          (eventconsulation ==1 || eventconsulation==2) &&

          <div className='alert alert-warning footermodflex'>Rappel : tout rendez-vous reporté ou annulé moins de 24 à l’avance sera prélevé.  <Button variant="secondary" onClick={()=>handleConfirm(rendezvous?.publicId)} style={{background: '#000'}}>
            Je confirme
          </Button></div>


        }
          {
          (eventconsulation ==3) &&

          <div className='alert alert-warning' style={{display:'block',width: '100%'}}>

          <Form onSubmit={deplacerdv} className="forMMp" style={{justifyContent: 'center'}}>

          {
            /*
                <input type="date" name='date' className='form-control' style={{marginRight:'10px'}}  required/>
                <input type="time" name='heure' className='form-control' style={{marginRight:'10px'}} required/>

                <Button variant="secondary" type="submit" style={{background: '#000'}}>
                  Déplacer
                </Button>
            */
          }

            <a className='btnlink' onClick={()=>history.push('/agenda-psychologue/'+idreservation+'/'+rendezvous?.therapeute?.user_id)} style={{background: '#000',    float: 'right',
    color: '#FFF',
    cursor: 'pointer'}}>
                  Choisir une nouvelle date
            </a>









          </Form>
             </div>






        }


        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;