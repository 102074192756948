import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import { apiClient } from "../../actions/api"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const Page = () => {
     const dispatch = useDispatch();
     const history = useHistory()
     const queryParams = new URLSearchParams(window.location.search);
      const [payss, setPayss] = useState('');
      const [inputPays, setInputPays] =useState('');
     const [show,setShow]=useState(false);
     const [pays,setpays]=useState([]);
     const [numeros,setnumeros]=useState([]);
     const [pselect,setpselect]=useState('France');
   
     const { data,pageinfo } = useSelector(state => state.mixedmode);

     const theme = createTheme(
      {
        palette: {
          primary: { main: '#1976d2' },
        },
      },
      frFR,
    );
 
 

    useEffect(()=>{
     
        getcountries('France',true)


    },[])



  function getcountries(pays,w){
     
          apiClient().get(`/numerourgences/`+pays,{} )
           .then((res) => {
          
              if(res.data.success){
                  if(w){
                     setpays(res.data.pays)
                  }
                    
                    setnumeros(res.data.numeros)
              }
              else{
              
               
            

              }
              
           })

  }

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (id) => {
      
         
         history.push('/article/'+id)
        

    }
 
 const goTo = () => {
      
       
     history.push('/list-psychologue')

  }
 const selectNumeros = (n) => {
      
    setpselect(n)
    getcountries(n,false)

  }

    return (
        <div className="home tarifs numeros" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Tests</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3" style={{textTransform: 'uppercase'}}> numéros d'urgence internationaux<br/><span> </span> </h2>
                     
      
                </Container>
            </section>

      

             <section className="section2 section " >
                <Container>


                 <Row>

                     <div className='col-md-6'>

                    <div className='serach'>

                    

                    <div class="orderBy">
                       <div class="select shop__header--select">


                    <ThemeProvider theme={theme}>

                      <Autocomplete
                      
                      onChange={(event, newValue) => {
                        selectNumeros(newValue.pays)
                      }}
                      inputValue={inputPays}
                      onInputChange={(event, newInputValue) => {
                        setInputPays(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={pays}
                      getOptionLabel={(option) => option.pays}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label={'Sélectionnez votre pays'} />}
                    />


                    </ThemeProvider>

             


 
                     </div>
                    </div>


                    </div>
                  </div>

                

                  </Row>


                <Row>

                  <div className='col-md-12'>
 

                    <br/>
                    <br/>

                    <h3>Numéros d'urgence :  {pselect}</h3>
                    <br/>


                      <Row className="ps-block--site-features ps-block--site-features-2">

                      {
                        numeros?.map((item,key)=>(

                       <Col md={6} key={key}>

                        <div className="ps-block__item pocket-shadow noshadowbox" style={{marginBottom:20}} >
                          
                          <div className="ps-block__right">

                          <h4 className="text-center" >{item?.titre}</h4>
                           <a href={`tel:${item?.numero}`}   className='btnlink marauto' style={{fontSize:'20px', fontWeight:'bold'}}> {item?.numero}<i className='fa fa-phone'></i></a>
                          
                          </div>
                            <div className="text-center" >
                            <br/>
                            {
                              item?.description &&
                               <div className='para3' dangerouslySetInnerHTML={{ __html:item?.description}}></div>
                            }
                             

                                  

                             </div>
                          </div>
                         
                      </Col>


                        ))
                      }

              
                   </Row>




                  </div>


                  <div className='col-md-6'>





                  </div>

                </Row>
                    
                  

                 

       


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 
 



 
        </div>
    );
}

export default Page;