import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import Webcam from "react-webcam";
const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)

    useEffect(() => {
       // dispatch(getCommandeById(user.id));
    }, []);
 
    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/vendeur/modifier-produit/${item}`);
     }

     const videoConstraints = {
  width: '100%',
  height:window.screen.height,
  facingMode: "user"
};
    
    return (
 
           
        <div style={{overflow:'hidden', height:'100%', width:'100%'}}>
      <Webcam
        audio={false}
        height={window.screen.height}
     
        screenshotFormat="image/jpeg"
        width={'100%'}
        videoConstraints={videoConstraints}
      >
     
    </Webcam>

    <div>
          <button ></button>
    </div>
        </div>
    
              
    );
}

export default Page;