import React, { useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { addContact, getSettings} from "../../actions/mixedmode";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";

const Page = () => {


    const dispatch = useDispatch();
    const history = useHistory()
    const { settings } = useSelector(state => state.mixedmode)
    const [tel1,setTel1]=useState('');
    const [tel2,setTel2]=useState('');


   const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        
        if(formDataObj.name=="" || formDataObj.email=="" || formDataObj.sujet==""  || formDataObj.message=="" ){
           alert("Merci de remplir tous les champs.");
        }else{
            dispatch(addContact(formDataObj));
            document.getElementById('tt1').value=''
            document.getElementById('tt2').value=''
            document.getElementById('tt3').value=''
            document.getElementById('tt4').value=''
            document.getElementById('tt4').text=''
        }
    
    }

    useEffect(() => {
     dispatch(getSettings())
     if(settings){
        let tel1=settings?.site_phone?.split('<br/>')?.[0];
        let tel2=settings?.site_phone?.split('<br/>')?.[1];
        setTel2(tel2)
        setTel1(tel1)
     }
   }, [])


    return (
    <div>
        <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Nous contacter </li></ul></div></div></div>
        
         <section className="section2" style={{backgroundColor:'#FFF',paddingTop: '25px'}}>

        <div className="" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                       <h2 className="text-center mb-5">Nous contacter </h2>
                        
                        <span className="section-separator"></span>
<br/><br/>

       

 
                    </Col>
                </Row>




            </Container>
        </div>



    <div className="ps-contact-form" style={{paddingTop:'50px',paddingBottom:'50px'}}>
            <div className="container">
                <form className="ps-form--contact-us" onSubmit={handleSubmit}  >
                  
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="text" name="name" placeholder="Nom complet *" required id='tt1' />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="email" name="email" placeholder="Email *" required id='tt2'/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="text" name="tel" placeholder="Numéro de téléphone *" required id='tt3'/>
                            </div>
                        </div>

                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                            <select className="form-control" type="text" name="sujet" required >
                               <option value=''>Sujet *</option>
                               <option value='Formules et abonnements'>Formules et abonnements</option>
                               <option value='Profil patient'>Profil patient </option>
                               <option value='Problème technique'>Problème technique </option>
                               <option value='Autre'>Autre </option>
                            </select>
                                
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required id='tt4' ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group submit">
                        <button className="ps-btn"  type="submit" >Envoyer</button>
                    </div>
                </form>
            </div>
        </div>


   <br/><br/>

   <section className='section section2' style={{ backgroundColor: '#f6f6f6'}} style={{display:'none'}}>

                 <Container>
              

               <div className="ps-section__content" >
               <div className='text-center'>
<img class="hotline" src="/images/callservices.png" style={{    width: '90px'}}/>
</div>


                                      <h2 className="text-center mb-5">Service client </h2>
                     
                        <span className="section-separator"></span>
<br/><br/>
<div style={{marginBottom:'20px',    overflow: 'hidden',clear:'both'}}></div>


                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                               <div style={{fontSize:'30px'}}><i style={{color: '#46c3cc'}} className="fa fa-volume-control-phone" aria-hidden="true"></i></div>
                                <h4>Téléphone</h4>
                                <p><a style={{color:'#666'}} href={"tel:"+tel1}> 008366378837 </a></p>
                              
                            </div>
                        </div>

                    

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                             <div style={{fontSize:'30px'}}><i style={{color: '#46c3cc'}}  class="fa fa-map-marker" aria-hidden="true"></i></div>
                                <h4>Adresse</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                             <div style={{fontSize:'30px'}}><i style={{color: '#46c3cc'}}  class="fa fa-envelope-o" aria-hidden="true"></i></div>
                                <h4>Email</h4>
                                <p><a style={{color:'#666'}} > example@gmail.com</a></p>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>




            </Container>
</section>
         


 
        </section>
    </div>
    );
}

export default Page;