import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiLogInCircle } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { LOGOUT_USER } from "../../../constants/actions";
import { changepicture } from "../../../actions/mixedmode";
import { apiClient } from "../../../actions/api"

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)
    const [note,setNote] = useState(0); 
    const [avis,setAvis] = useState(0);

    const fileInput = useRef(null)
   
    useEffect(() => {
          
        if (!user.user_id || user.google_id!='L' ) {
            history.replace("/")
        }



          let paramsd={
            
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'livreurnote',
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
    

             
               setNote(res.data?.avg?.note ? res.data?.avg?.note : 0)
               setAvis(res.data?.count?.note ? res.data?.count?.note : 0)
             
           
           })



    }, [user.user_id])


    const handleClick = (src) => {
         history.push(src);
    }
        const handleLogout = () =>{
       
        dispatch({type:LOGOUT_USER});
    }

      const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      console.log("formData",formData)
      dispatch(changepicture(formData));
     
  }




    const history = useHistory();
    function gotoCmd(item) {
       history.push(item);
     }
    
    return (
        <div className=" dashboard livreur" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto "  lg="3">


            </Col>
              
           <Col  className="text-center mx-auto "  >
            
           <div className='flxdash1'>
               
            <div>

 {(user.picture=="" || !user.picture) && <Card.Img variant="top" style={{cursor:'pointer',width:100,height:100}} onClick={() => handleClickfile()} src={defaultimg} />}
              {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://mixedmode.fr/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
       <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"

                onChange={(e) => handleFileChange(e)}
                ref={fileInput} 
            />
            </form>

            </div>
               
                 <p><a style={{    color: '#002228',textDecoration: 'underline',marginTop:'10px', display:'block'}} href='javascript:void(0)' onClick={()=>gotoCmd('/livreur/profile/')}>{user.first_name} {user.last_name}</a></p>
                 
                  <div className='logout'>
                     <button className="logout_btn color2-bg" onClick={handleLogout}>Déconnexion <BiLogInCircle color={"#FFF"} size={20} /></button>
                </div>



                 <h6>Tableau de bord</h6>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/livreur/commandes/')} className='coldash'><img src='/images/icolivjours.png' /> <label className='lab778 lab778red'>Les livraisons du jour</label></a></div>
                      <div> <a   className='coldash'> <label>Avis et notes client</label>

                        <div className="ps-product__rating" style={{    width: '100%'}}>
                                        <div className="br-wrapper br-theme-fontawesome-stars" style={{    width: '100%'}}>

                                        <div className="br-widget br-readonly" style={{    width: '100%'}}>

                                            <a data-rating-value="1" data-rating-text="1" className={(note>0 ? 'br-selected br-current' : '')}>
                                                    </a><a  data-rating-value="2" data-rating-text="2" className={(note>1 ? 'br-selected br-current' : '')}>
                                                    </a><a  data-rating-value="3" data-rating-text="3" className={(note>2 ? 'br-selected br-current' : '')}>
                                                    </a><a  data-rating-value="4" data-rating-text="4" className={(note>3 ? 'br-selected br-current' : '')}>
                                                    </a><a  data-rating-value="5" data-rating-text="5" className={(note>4 ? 'br-selected br-current' : '')}>
                                                    </a>


                                        </div></div>
                                    </div>

          <div className="br-current-rating" style={{display:'block', width:'100%'}}>({avis ? avis : 0}) avis</div>
           <span>{note}/5</span>

            </a></div>

                </div>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/livreur/commandes/')} className='coldash'><img src='/images/icofairepoint.png' /> <label className='lab778 lab778blue'>Faire le point</label></a></div>
                      <div> <a href={"tel:0748424839"}  className='coldash'><img src='/images/icoservices.png' /> <label className='lab778 lab778black'>Appeler un responsable </label></a></div>

                </div>

      

             

           </div>

                    <form className="mb-5" style={{display:'none'}}>
                           
                        
                          <Row className="mb-3"  >


                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-eye"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">14</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis récupérés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg1 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-check"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">10</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis livrés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                               <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg2 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-clock-o"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">7</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis non livrés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg4 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-barcode"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">105</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total produits</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                            <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg5 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-anchor"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">76</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total commandes</h6>
                                                </div>
                                                
                                    </div>
                              </div>



                             <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg6 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-credit-card-alt"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">1054 ACF</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total montant</h6>
                                                </div>
                                                
                                    </div>
                              </div>
                           
                          
                           

                          </Row>

                    </form>
                        

                    </Col>
                      <Col  className="text-center mx-auto my-5"  lg="3"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;