import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'
import { Messages } from "../../../components";
import 'moment/locale/fr';



const Page = () => {
    const { user } = useSelector(state => state.user)

    return (
        <div className="mescommandes register vosventes bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <MenuVendeur slug='messages' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright"  >


                        <div className='contentsection'>

                                   <Messages />
                          </div>





                    </Col>
                </Row>



            </Container>
        </div>
    );
}

export default Page;