import React from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { forgetPassword } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux"


const Page = () => {

     const history = useHistory()

     const dispatch = useDispatch();
   

 const gotTocreate = (e) => {
         history.replace("/register")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(forgetPassword(formDataObj));
    }


    return (
        <div className="forget" >
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                         
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 >Mot de passe oublié ?</h3>
                            <Form.Group >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="email" placeholder="mail@website.com" name='email' />
                            </Form.Group>
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                               Envoyer
                            </Button>
                        </Form>
                     
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;