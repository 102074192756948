import { combineReducers } from 'redux'
import user from './user'
import restaurent from './restaurent'
import global from './global'
import basket from './basket'
import mixedmode from './mixedmode'

const rootReducer = combineReducers({
    user,
    restaurent,
    global,
    basket,
    mixedmode
})

export default rootReducer;