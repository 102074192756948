import EventEmitter from 'events';
import createAudioFallbackVideoStateMachine from './audioFallbackVideoStateMachine';
import AudioFallbackVideoStates from './audioFallbackVideoStates';
import { LOW, MEDIUM, CRITICAL } from '../peer_connection/congestionLevel/congestionLevels';

const { ACTIVE_VIDEO, ACTIVE_VIDEO_WITH_WARNING, SUSPENDED_VIDEO } = AudioFallbackVideoStates;

const createAudioFallbackCoordinator = ({
  getAllPeerConnections,
  AnalyticsHelper,
}) => {
  const ee = new EventEmitter();
  const analytics = new AnalyticsHelper();

  const audioFallbackStateMachine = createAudioFallbackVideoStateMachine();
  audioFallbackStateMachine.on('stateChange', (...args) => ee.emit('stateChange', ...args));

  const calculateState = async () => {
    const pcs = await getAllPeerConnections();
    const publisherVideoState = pcs.reduce((acc, pc) =>
      Math.max(acc, pc.getAudioFallbackState()?.videoState), ACTIVE_VIDEO);
    audioFallbackStateMachine.setState(publisherVideoState);
  };

  const onPeerConnectionStateChange = ({
    state: videoState, peerConnection, publishVideo, stream, peerId, connectionId, sessionId,
  }) => {
    if (publishVideo) {
      const subscriberId = peerConnection.getSourceStreamId() === 'MANTIS' ? undefined : peerId;
      switch (videoState) {
        case ACTIVE_VIDEO:
          stream.setChannelActiveState('video', true, 'auto', LOW, subscriberId);
          break;
        case ACTIVE_VIDEO_WITH_WARNING:
          stream.setChannelActiveState('video', true, 'auto', MEDIUM, subscriberId);
          break;
        case SUSPENDED_VIDEO:
          stream.setChannelActiveState('video', false, 'auto', CRITICAL, subscriberId);
          break;
        default:
          break;
      }
      analytics.logEvent({
        action: 'congestionLevelChanged',
        payload: {
          congestionLevel: videoState,
        },
        streamId: stream.id,
        sessionId,
        connectionId,
      });
    }
    calculateState();
  };

  return Object.assign(
    ee,
    {
      onPeerConnectionStateChange,
      onPeerConnectionDisconnected: () => { calculateState(); },
      reset: () => audioFallbackStateMachine.reset(),
      getState: () => audioFallbackStateMachine.getState(),
    });
};

export default createAudioFallbackCoordinator;
