import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addreclamation,getReclamationsById,deletereclamation,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { reclamations } = useSelector(state => state.mixedmode)

    useEffect(() => {
         dispatch(getReclamationsById(user.user_id,user.api_token,2));
    }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/commande/${item.id}`);
     }
        const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.type=2;
        dispatch(addreclamation(formDataObj));
        alert('Votre demande est envoyée !')
    }
    const deletere = (id) => {
        const params={token:user.api_token,api_token:user.api_token,id:id,type:2};
        dispatch(deletereclamation(params));
    }
    return (
        <div className="reclamations register bg778635 " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <MenuVendeur slug='reclamations' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >

                  <Form className="mb-5" onSubmit={handleSubmit}  >
                           
                            <h3 className="mb-4" >Déposer une réclamation</h3>
                             <br/> <br/>


                          <Row className="mb-3"  >

                             <div className="col-md-12">
                             <p className="para alert alert-success  center">Veuillez utiliser le formulaire ci-dessous afin de décrire votre problème.</p>
                              <br/>

                              <div className="profile-edit-container fl-wrap block_box"><div className="custom-form"><div className="row"><div className="col-sm-12"><label></label>

<select className="form-control" type="text" name="objet" required=""><option value="">Sujet *</option><option value="Formules et abonnements">Formules et abonnements</option><option value="Profil patient">Profil patient </option><option value="Problème technique">Problème technique </option><option value="Note inquiétante">Note inquiétante </option> <option value="Autre">Autre </option></select>

                              </div></div><br/><div className="row"><div className="col-sm-12"><label>Description </label><textarea name="description" rows="3" className="form-control" required="required"></textarea></div></div><div className="row"><div className="col-sm-12">
                               <br/>
                                 <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                ENVOYER
                            </Button>
                              </div></div></div></div>

                             </div>   

                          </Row>
                          <br/>
<br/>
                          <Row className="mb-3"  >


 <h3 className="mb-4" >Déposer une réclamation</h3>
                             <br/> <br/>
                           
                          <Table responsive>
                          <thead>
                            <tr>
                                <th>Référence</th>
                                <th key="0">Date</th>
                                <th key="1">Objet</th>
                                <th key="1">Description</th>
                                <th key="2">Statut</th>
                             
                             
                            </tr>
                          </thead>
                        <tbody>
                    {reclamations.length && reclamations?.map((item) => (
                          
                            <tr>
                              <td>{item.ref}</td>
                              <td>
                                {item.datereclamation}
                              </td>
                              <td>{item.objet}</td>
                              <td> {item.description}</td>
                              <td>
                                 {item.status==0 && <span className='badge alert-warning'>En attente</span>}
                                 {item.status==1 && <span className='badge alert-success'>Traité</span>}
                              </td>

                          
                            </tr>
                
                      ))}
                          
                          

                          </tbody>
                        </Table>
                           

                          </Row>

                    </Form>
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;