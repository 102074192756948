import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { MenuVendeur } from "../../../components";
import moment from 'moment'

import 'moment/locale/fr';
import 'moment-timezone';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { apiClient } from "../../../actions/api"

import { getTherapeutLocalReservations, getNextConsultation } from "../../../actions/api_interface"

import ShowTimezone from '../../../components/show_timezone/'
import NextConsultation from '../../../components/next_consultation'


const Page = () => {
  const { user } = useSelector(state => state.user)
  const [datedif,           setDatedif]           = useState('')
  const [idreservation,     setIdReservation]     = useState(1)
  const [erroractive,       seterroractive]       = useState(true)
  const [eventconsulation,  setEventconsulation]  = useState('')
  const [consulations,      setConsulations]      = useState([])
  const [rendezvous,        setrendezvous]        = useState('')
  const [checkconsultation, setCheckconsultation] = useState({})

  const history = useHistory();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const handleCloseAlert = () => setShowAlert(false);

  useEffect(() => {
    getAllReservation()
    getActive()
    checkifconsultation()
    let eleme1=document.querySelector(".fc-dayGridMonth-button");
    let eleme2=document.querySelector(".fc-timeGridWeek-button");
    eleme1.innerText='Mois'
    eleme2.innerText='Semaine'
  }, []);


  function getAllReservation () {
    getTherapeutLocalReservations(user, setConsulations)
  }


  function checkifconsultation() {
    let params={
      token:user.api_token,
      api_token:user.api_token,
    }

    apiClient().post(`/check/consultation/therapeut`, params)
    .then((res) => {
      if(res.data.success=='ok'){
        if(res.data.reservation?.heure_rdv){
          setCheckconsultation(res.data.reservation)
        }
      }
    })
  }




   function getActive() {
        let params={

              token:user.api_token,
              api_token:user.api_token,

          }


          apiClient().post(`/get/therapeut`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                 if(res.data.user?.active==0){
                     seterroractive(false)
                }




              }


           })
     }




  const toggle = ({ event, el }) => {

    if(event._def.extendedProps?.type=='c'){

      setEventconsulation('')
      setIdReservation(event._def.publicId)
      setrendezvous(event._def.extendedProps)
      let daterdv=event._def.extendedProps?.date_rdv+'T'+event._def.extendedProps?.heure_rdv+ 'Z'
      setDatedif(moment().diff(daterdv, 'hours'))

      setShowAlert(true)

    }

  };



const deplacerdv = async (e) => {



      e.preventDefault();
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())

      let daterdv=formDataObj.date+'T'+formDataObj.heure+ 'Z'
      let datediff=moment().diff(daterdv, 'hours')

      if (datediff >= 0) {
        alert('Cette date est dépassée !')
        return false;
      }


          let params={

              token:user.api_token,
              api_token:user.api_token,
              id:idreservation,
              action:'deplacerbytherapeut',
              date:formDataObj.date,
              heure:formDataObj.heure
          }




          apiClient().post(`/set/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                    getAllReservation()
                    setAlertMessage('Votre demande de modification de rendez-vous est enregistrée.')
                    handleCloseAlert();
                    setTimeout(function(){ setAlertMessage('')}, 7000);

              }


           })



}


const anullerdv = (id) => {

          let params={
              token:user.api_token,
              api_token:user.api_token,
              id:idreservation,
              action:'anullerbytherapeut'
          }


          apiClient().post(`/set/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){
                       getAllReservation()
                       setAlertMessage('Le rendez-vous est annulé !')
                       handleCloseAlert();
                       setTimeout(function(){ setAlertMessage('')}, 5000);

              }


           })



}

const honorerdv = (id) => {

          let params={
              token:user.api_token,
              api_token:user.api_token,
              id:idreservation,
              action:'honore'
          }


          apiClient().post(`/set/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){
                       getAllReservation()
                       setAlertMessage('Le rendez-vous est non honoré !')
                       handleCloseAlert();
                       setTimeout(function(){ setAlertMessage('')}, 5000);

              }


           })



}




  const handleConfirm = (id) => {
    if (eventconsulation == 3){
     honorerdv(id)
    }
    else {
      anullerdv(id)
    }
  };


  const nextConsultation = getNextConsultation(consulations)

    return (
        <div className="mescommandes register vosventes bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <MenuVendeur slug='mesrendezvous' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright"  >


                            <h3 className="mb-4" > Mes rendez-vous</h3>

                            <NextConsultation consultation={nextConsultation} target="/conference/patients/9930003003" />






                            {
                              !erroractive &&

                                 <div className='alert alert-danger'>

                               <div style={{    display: 'flex',alignItems: 'center'}}>

                                 <strong> Votre profil est en cours de vérification. <a href="javascript:void(0)" style={{textDecoration:'underline'}} onClick={()=>history.push('/praticien/profile')}>Complétez votre profil</a></strong>



                               </div>
                                </div>
                            }


                           {
                              alertMessage &&

                                <div className='alert alert-success'>
                                      {alertMessage}
                                </div>
                            }




   <div className='agenda'>



        <ShowTimezone />
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          weekends={true}
          initialView="dayGridMonth"
          headerToolbar={{
            start: 'title,prev,next',
            center: 'customButtons',
            end: 'dayGridMonth,timeGridWeek'
          }}
          locale={'fr'}
          timeZone= {'local'}
          events={consulations}
          eventClick={toggle}
        />



                             </div>





                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} size="lg"  centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>



        <Row className="mb-3 abonnement text-center "  >


        <div className='itemimg col-md-3' style={{backgroundImage:rendezvous?.patient?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+rendezvous?.patient?.picture})` : "url('/images/default-profile.png')"}}></div>
          <h3 style={{marginBottom: '3px',marginTop: '18px'}}>{rendezvous?.patient?.name}</h3>
          <div style={{marginBottom: '30px'}}>
          <a href='javascript:void(0)' onClick={()=>history.push('/praticien/messages?p='+rendezvous?.patient?.user_id)}  style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Contacter {rendezvous?.patient?.first_name}</a>
          </div>


                  <div className='row'>


                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Dossier du patient: <br/><strong onClick={()=>history.push('/praticien/dossier-patient/'+rendezvous?.patient?.user_id)} style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Voir</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Séances:<br/> <strong>{parseInt(rendezvous?.formule?.seance) - (parseInt(rendezvous?.formule?.seance)-parseInt(rendezvous?.nbreservation))}/{rendezvous?.formule?.seance} séances effectuées</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Date:<br/> <strong>{moment.utc(rendezvous?.time).local().format("DD/MM/YYYY")}</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Heure:<br/> <strong>{moment.utc(rendezvous?.time).local().format("HH:mm")}</strong></div>

                  </div>








          <div>


           {
            (rendezvous?.status==1 && datedif <=0) ?
             <div class="flexitems"><a href="javascript:void(0)" style={{display:'none'}} onClick={()=>setEventconsulation(1)} class="btnlink marauto">Modifier  </a><a href="javascript:void(0)" style={{display:'none'}} onClick={()=>setEventconsulation(2)} class="btnlink marauto btnlinkjaun">Annuler    </a><a href="javascript:void(0)"  onClick={()=>setEventconsulation(3)}  class="btnlink marauto btnlinkred">Non honoré   </a></div>
             :
             (rendezvous?.status==1 && datedif > 0) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>La date de votre rendez-vous est dépassée !</div></div>
             :
             (rendezvous?.status==3) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>Le rendez-vous est annulé !</div></div>
             :
             (rendezvous?.status==4) ?
             <div class="flexitems"><div className='alert alert-warning' style={{    width: '100%'}}>Le rendez-vous a été déplacé par le patient, il est  en attente de votre confirmation. </div></div>
             :
             (rendezvous?.status==5) ?
             <div class="flexitems"><div className='alert alert-danger' style={{    width: '100%'}}>Le rendez-vous est non honoré. </div></div>
             :
             <div class="flexitems"><div className='alert alert-success' style={{    width: '100%'}}>Votre séance en ligne a bien été effectuée. </div></div>

           }


          </div>




          </Row>








        </Modal.Body>
        <Modal.Footer>


       {
          (eventconsulation==2) &&

          <div className='alert alert-warning footermodflex'>Voulez-vous vraiment annuler le rendez-vous ?   <Button variant="secondary" onClick={()=>handleConfirm(rendezvous?.publicId)} style={{background: '#000'}}>
            Je confirme
          </Button></div>


        }

             {
          (eventconsulation==3) &&

          <div className='alert alert-warning footermodflex'>Voulez-vous vraiment marquer le rendez-vous comme non honoré ?   <Button variant="secondary" onClick={()=>handleConfirm(rendezvous?.publicId)} style={{background: '#000'}}>
            Je confirme
          </Button></div>


        }


     {
          (eventconsulation ==1) &&

          <div className='alert alert-warning' style={{display:'block',width: '100%'}}>
          Veuillez choisir une nouvelle date :
          <Form onSubmit={deplacerdv} className="forMMp">


          <input type="date" name='date' className='form-control' style={{marginRight:'10px'}}  required/>
          <input type="time" name='heure' className='form-control' style={{marginRight:'10px'}} required/>

          <Button variant="secondary" type="submit" style={{background: '#000'}}>
            Déplacer
          </Button>



          </Form>
             </div>






        }


        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;