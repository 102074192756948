import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser } from "../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../assets/images";
import { changepicture } from "../../actions/mixedmode";
import PhoneInput from 'react-phone-input-2'

import { Menu } from "../../components";
const Page = () => {

     const queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get('redirect');

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const fileInput = useRef(null)
  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [percent, setPercent] = useState(70);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(updateInfoUser(formDataObj,user.user_id,user.api_token,'client'));
        if (redirect==1 || redirect==='1'){
              setTimeout(function(){ history.push('/paiement') }, 2000)
        }
      
      
    }
    
    useEffect(() => {
        let ppr=70;
          if(user?.adresse){
             ppr=ppr+5;
          }
         if(user?.picture){
             ppr=ppr+10;
         }

         if(user?.codepostal){
             ppr=ppr+5;
         }

        if(user?.city){
             ppr=ppr+5;
         }
        if(user?.country){
             ppr=ppr+5;
         }
         setPercent(ppr)
        
    }, [user]);

      const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
   
      dispatch(changepicture(formData));
     
  }

    return (
        <div className="register profile bg778635" >

            <Container>
                <Row>
  <Col  className="text-center mx-auto my-5"  lg="3">

         <Menu slug='profile' />

               
                </Col>
                    <Col  className="text-center mx-auto my-5 menudashright "  >
                                  <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Mes coordonnées</h3>

                            {
                              (redirect==1 || redirect==='1') &&
                              <div className='alert alert-danger'>
                                 Veuillez compléter votre profil
                              </div>

                            }


                            <div className='text-center'>


                        {(user.picture=="" || !user.picture) && <Card.Img variant="top" style={{cursor:'pointer',    width: '142px'}} onClick={() => handleClickfile()} src={defaultimg} />}
                          {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://feel-better-company.com/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
                                       <form style={{padding:'0px'}}>
                                       <input
                                       style={{display:'none'}}
                                        type="file"
                                        accept='image/*'

                                        onChange={(e) => handleFileChange(e)}
                                        ref={fileInput} 
                                    />
                                    </form>


                                <div class="col-md-4 mx-auto"><div class="flx889"><div class="clrbl9">Profil complété</div><div class="clrbl9">{percent}%</div></div><div class="progress"><div class="progress-bar" style={{width: percent+'%'}}><span class="sr-only"></span></div></div></div>
                                 <br/>


                            </div>



                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Prénom *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.first_name}
                                        type="text" placeholder="Robert" name="first_name" required />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Nom *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.last_name}
                                        type="text" placeholder="" name="last_name" required />
                                </Form.Group>
                            </Row>

                        <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Adresse *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.adresse}
                                        type="text" placeholder="" name="adresse" required />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Code postal *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.codepostal}
                                        type="text" placeholder="" name="codepostal" required />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Ville *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.city}
                                        type="text" placeholder="" name="city" required />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Pays *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.country}
                                        type="text" placeholder="" name="country" required />
                                </Form.Group>
                            </Row>


                   

                             <Row className="mb-3">

                                <Form.Group as={Col} >
                                <Form.Label>Téléphone *</Form.Label>
                                <Form.Control 
                                    defaultValue={user.phone}
                                    type="tel" placeholder="+225*****" name="phone" />
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Email *</Form.Label>
                                <Form.Control 
                                    defaultValue={user.email}
                                    type="email" placeholder="mail@website.com" name="email" />
                                </Form.Group>

                                </Row>
                            
                          
                             <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="password" />
                            </Form.Group>
                         
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Modifier
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;