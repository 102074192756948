
import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { useParams } from "react-router"

import { apiClient } from "../../actions/api"
import { getTherapeutLocalAgenda } from "../../actions/api_interface"

import './style.scss'



const Component = ({ onSelect, formule, therapeutId }) => {
  const [json, setJson] = useState([])
  const { id } = useParams()

  therapeutId = therapeutId || id


  let isgroupe = formule?.tarif?.titre === 'Groupe de parole' ? 1 : 0;

  useEffect(() => {
    apiClient().post(`/getDates/` + therapeutId + '/' + isgroupe)
    .then((res) => {
      let localAgenda = getTherapeutLocalAgenda(res.data)
      setJson(localAgenda)
    })
  }, [])


  // NOTA / TODO - react-slick doesn't works properly with responsive (not auto-start at 0)
  var settings
  if (window.innerWidth > 600) {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7
    }
  } else {
    settings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 500,
      infinite: true,
      dots: false
    }
  }



  return (
    <div className='calendar'>
      <Slider {...settings}>

      {json?.map((item, index) => {
        let newindex=index+1;
        return (
          <div key={newindex} className='colcalendar'>
            <h5>{item.day.format('dddd')}</h5>
            <span className='dd'>{item.day.format('D MMM')}</span>

            <span className='dd'>{item.number}</span>
            <div className='actions'>
              {item?.states?.map((hourState,index) => (
                <div key={index} style={{position:'relative'}} onClick={()=>onSelect(item.day, hourState)} className={hourState.state === 1 ? 'active' : '' }>
                {hourState.hour}
                {
                  (formule?.tarif?.titre === 'Groupe de parole' && parseInt(hourState.nbreservation) > 0 && hourState.hour.dispo===true ) &&
                  <span className='hourpos'>+ {5 - parseInt(hourState.nbreservation)}</span>
                }
                </div>
              ))
             }
            </div>
          </div>
        );
      })}


      </Slider>
    </div>
  )
}

export default Component