import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addreclamation,getReclamationsById,deletereclamation,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import { apiClient } from "../../../actions/api"


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { reclamations } = useSelector(state => state.mixedmode)
    const [parraianges,setParainages] =useState([])

    useEffect(() => {
         getparainage()


    }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/commande/${item.id}`);
     }
      const handleSubmit = (e,id) => {
        e.preventDefault();
        const params={token:user.api_token,api_token:user.api_token,id:id};
        apiClient().post(`/send/parainage`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                alert('Un mail vous a été envoyé !')
            
              }
            
              
           })

        
    }
 



     const getparainage = () => {
        const params={token:user.api_token,api_token:user.api_token};

         apiClient().post(`/get/parainage`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setParainages(res.data.listparainage)
                
              }
            
              
           })

         
    }



    return (
        <div className="reclamations register bg778635 " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <Menu slug='parrainage' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

                  <Form className="mb-5" onSubmit={handleSubmit}  >
                           
                            <h3 className="mb-4" >Parrainage</h3>
                             <br/> <br/>


                          <Row className="mb-3"  >

                             <div className="col-md-12">
                             <p className="para alert alert-success  center">{parraianges.length} offre{parraianges.length > 1 && 's'} parrainage disponible{parraianges.length > 1 && 's'}</p>
                             </div>
                    

                          </Row>
                          <br/>
<br/>
                          <Row className="mb-3"  >


 
                           
                          <Table responsive>
         
                        <tbody>
                    {parraianges?.map((item) => (
                          
                            <tr>
                              <td style={{textAlign:'left'}}>{item.gift_name}</td>
                    
                    

                              <td>


                              <div className="list-contr" style={{width:'170px'}}>

                               <a  href="#" onClick={(e)=>handleSubmit(e,item.gift_code)} className="color-bg tolt center view"   data-microtip-position="left" data-tooltip="envoi par e-mail">Envoi par email <i className="icon-send"></i></a>
                               


                               </div>
                              </td>
                            </tr>
                
                      ))}
                          
                          

                          </tbody>
                        </Table>
                           

                          </Row>

                    </Form>
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;