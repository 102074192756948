import React,{useEffect,useState,useCallback,useRef} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import { useHistory } from "react-router";
import { setFinishedCall} from "../../actions/mixedmode";
import { apiClient } from "../../actions/api"
import moment from 'moment'
import 'moment/locale/fr';
import Countdown from 'react-countdown';
import { soundnotification } from "../../assets/images";
import DailyIframe from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react';
import Call from '../../components/dayli/Call/Call';
import Tray from '../../components/dayli/Tray/Tray';
import HairCheck from '../../components/dayli/HairCheck/HairCheck';
import { pageUrlFromRoomUrl } from '../../components/dayli/utils';
import ErrorBoundary from "../../helpers/ErrorBoundary";
import { usePusher } from "../../PusherContext";

const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

const Page = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { user } = useSelector(state => state.user);
  const [checkconsultation, setCheckconsultation] = useState({})
  const [therapeute, setTherapeut] = useState([])
  const audioPlayer = useRef(null);
  const [timecall, setTimeCall] = useState(100000)
  const [startcall, setstartcall] = useState(false)
  const pusher = usePusher();


  const [appState, setAppState] = useState(STATE_IDLE);
  const [roomUrl, setRoomUrl] = useState(null);
  const [callObject, setCallObject] = useState(null);
  const [apiError, setApiError] = useState(false);

  const showCall = !apiError && [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(appState);

  useEffect(() => {
    if (!user.user_id || user.google_id !== 'A' ) {
      history.replace("/")
    }

    checkifconsultation()
  }, [user]);



  function checkifconsultationstart (id) {
    let params = {
      token:user.api_token,
      api_token:user.api_token,
      id:id
    }


    apiClient().post(`/isconsultations/start`,params )
    .then((res) => {

      if (res.data.success === 'ok') {
        startHairCheck(res.data?.room);
      }
    })
  }

  function checkifconsultation() {
    let params={

      token:user.api_token,
      api_token:user.api_token,
    }


    apiClient().post(`/getconsultation/therapeut`,params )
    .then((res) => {
      if (res.data.success === 'ok') {
        if (res.data.reservation?.heure_rdv) {

          setCheckconsultation(res.data.reservation)

          setTherapeut(res.data.patients?.therapeute)

          let checkconsultationid = res.data.reservation.id
          let date = moment.utc(res.data.reservation.date_rdv + "T" + res.data.reservation.heure_rdv + "Z")
          let diff = date.diff(moment.utc(), 'seconds')

          if (diff <= 0) {
            setTimeCall(0)
          }
          else{
            setTimeCall(diff * 1000)
          }

      checkifconsultationstart(res.data.reservation.id)




      let idchanel=''

      idchanel=res.data.patients?.therapeute?.user_id+'__call__'+user?.user_id


      const channel = pusher.subscribe(idchanel);
      channel.bind('chat', function (data) {

                  startHairCheck(data?.room);


                });


        let idchanel2=''

        idchanel2=res.data.patients?.therapeute?.user_id+'__callfinished__'+user?.user_id



        const channel2 = pusher.subscribe(idchanel2);
        channel2.bind('callfinished', function (data) {

          let params2={
            token:user.api_token,
            api_token:user.api_token,
            id:checkconsultationid
          }

          dispatch(setFinishedCall(params2))

          history.push('/fin-consultation/patient/'+checkconsultationid)

        });
    }
    else{
                  //history.push('/')
                }

              }


            })
  }


  const showHairCheck = !apiError && appState === STATE_HAIRCHECK;



   const startHairCheck = useCallback(async (url) => {
    const newCallObject = DailyIframe.createCallObject();
    setRoomUrl(url);
    setCallObject(newCallObject);
    setAppState(STATE_HAIRCHECK);
    await newCallObject.preAuth({ url }); // add a meeting token here if your room is private
    await newCallObject.startCamera();
   }, []);


   const joinCall = useCallback(() => {
    callObject.join({ url: roomUrl });
  }, [callObject, roomUrl]);


   const startLeavingCall = useCallback(() => {
    if (!callObject) return;

    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setRoomUrl(null);
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      setAppState(STATE_LEAVING);
      callObject.leave();
    }
  }, [callObject, appState]);




  useEffect(() => {
    const pageUrl = pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, null, pageUrl);
  }, [roomUrl]);


   useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
        setAppState(STATE_JOINED);
        break;
        case 'left-meeting':
        callObject.destroy().then(() => {
          setRoomUrl(null);
          setCallObject(null);
          setAppState(STATE_IDLE);
          let params2={
            token:user.api_token,
            api_token:user.api_token,
            id:checkconsultation?.id
          }
          dispatch(setFinishedCall(params2))

          history.push('/fin-consultation/patient/'+checkconsultation?.id)
        });
        break;
        case 'error':
        setAppState(STATE_ERROR);
        break;
        default:
        break;
      }
    }

    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);






   const renderApp = () => {
    if (apiError) {
      return (
        <div className="api-error">
          <h1>Error</h1>
          <p>
            Room could not be created.
          </p>
        </div>
      );
    }

    if (showHairCheck) {
      history.push('/room-call/' + checkconsultation?.id + "/r")
      return (<div></div>)
      return (
        <DailyProvider callObject={callObject}>
        <HairCheck joinCall={joinCall} cancelCall={startLeavingCall} />
        </DailyProvider>
        );
    }

    if (showCall) {
      return (
        <DailyProvider callObject={callObject}>
          <Call />
          <Tray leaveCall={startLeavingCall} />
        </DailyProvider>
        );
    }


    return (
      <ErrorBoundary>
      <Container className='listtherapeutes'>

      <div className='row'>

      <Col md={6} >
      <div className='therapeutescontent'>

      <div className="home-screen">

      <div className='alert alert-warning' style={{ width: '100%' }}>

      <div style={{  }}>
      <div style={{marginBottom:'10px'}}></div>

      <strong>Votre prochaine consultation commence
      {
        startcall
        ? <span> Maintenant</span>
        : <span> dans
          {
            timecall > 1000 * 60 * 60 * 24
            ? <span> plus d'un jour</span>
            : <Countdown date={Date.now() + timecall} onComplete={()=>setstartcall(true)} renderer={renderer} />
          }
          </span>

      }



      </strong>
      <br/> <br/>
      <div style={{marginBottom:'20px'}}></div>
        Veuillez attendre que votre praticien ait démarré la consultation en ligne
      </div>
      <div style={{marginBottom:'10px'}}></div>


      </div>


      <p className="small">Sélectionnez "Autoriser" pour utiliser votre caméra et votre micro pour cet appel si vous y êtes invité.</p>
      </div>
      </div>



      </Col>



      <Col md={1} >

      </Col>




      <Col md={5} >

    {/*  lister les patients */}

    <div className='therapeutescontent'>

    <Row className=''>
    <Col md={12} >

    <div className=' row '>
    <div className="text-center flowpriceheader"><h2>Votre praticien </h2></div>
    </div>
    <div className=' row ps-block--site-features ps-block--site-features-2' style={{marginBottom:'30px'}}>


    <div className='col-md-6'>

    <div className='itemimg ' style={{backgroundImage:therapeute?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+therapeute?.picture})` : "url('/images/default-profile.png')",width: '90px',margin:'0 auto',
    height: '90px'}}> </div>
    <h3 className='title'>{therapeute?.name}</h3>



    </div>




    </div>



    <div className='therapeutescontent'>

    <div className='flexrowtarifs row'>


    <div className=' col-md-12'>
    <div className="text-center flowpriceheader"><img src={checkconsultation?.tarif?.image}/><h2>{checkconsultation?.tarif?.titre}</h2></div>
    </div>

    <div className='item'>



    <div className='pricebox' style={{boxShadow: 'none'}}>
    <h3>{checkconsultation?.formule?.titre}</h3>
    <h4>{parseFloat(checkconsultation?.formule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
    <div className="pricebox-border2"> </div>
    <div className='ttcontent'>
    <ul className='col-9028-K'>

    <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.temps} min</li>
    <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.seance} {checkconsultation?.formule?.seance > 1 ? 'séances' :'séance' }</li>
    <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.pack}</li>

    </ul>



    </div>
    </div>

    </div>
    </div>
    </div>





    </Col>
    <Col md={3} >

    <div className='actionrdv'>

    <div className="text-center" >



    </div>

    </div>

    </Col>

    </Row>



    </div>


    {/*  End */}

    </Col>



    </div>
    <audio ref={audioPlayer} src={soundnotification} />
    </Container>
    </ErrorBoundary>




    );
};

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <span> "Maintenant"</span>
    )
  } else {
    return (
      <span> {hours}h:{minutes}m:{seconds}s</span>
    )
  }
};



return (
  <div className="home dayli" >

  <section className={showCall ? "section2 colmin ":"section2" } >
  {renderApp()}
  </section>

  </div>
  );
}

export default Page;