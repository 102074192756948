//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";
export const IS_SUBMITED  ="IS_SUBMITED";

//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "LOGOUT_USER"
 


//RESTAURENT
export const SET_RESTAURENTS = "SET_RESTAURENTS"
export const SET_CURRENT_RESTAURENT = "SET_CURRENT_RESTAURENT"
export const SET_LIST_ADDRESS = "SET_LIST_ADDRESS"
export const SET_LIST_COMMANDES = "SET_LIST_COMMANDES"
export const SET_LIST_OFFRES = "SET_LIST_OFFRES"
export const SET_LIST_PRODUCT = "SET_LIST_PRODUCT"
export const SET_CURRENT_COMMANDE = "SET_CURRENT_COMMANDE"
export const SET_HORAIRE_RESTAURENT = "SET_HORAIRE_RESTAURENT"
export const SET_LIST_COMMENTS = "SET_LIST_COMMENTS"
export const SET_LIST_WALETTE = "SET_LIST_WALETTE"
export const SET_LIST_STORIES = "SET_LIST_STORIES"
//BASKET
export const ADD_PRODUCT_BASKET = "ADD_PRODUCT_BASKET"
export const ADD_EXPIDITION = "ADD_EXPIDITION"
export const ADD_MODEEXPIDITION = "ADD_MODEEXPIDITION"
export const REMOVE_PRODUCT_BASKET = "REMOVE_PRODUCT_BASKET"
export const UPDATE_QTY = "UPDATE_QTY"
export const GET_DATA_CART = "GET_DATA_CART"
export const GET_TOTAL_CART = "GET_TOTAL_CART"
export const OBJECT_REDUCTION = "OBJECT_REDUCTION"
export const VIDER_PANIER = "VIDER_PANIER"

//mixedmode
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_FINISHEDCALL = "SET_FINISHEDCALL"
export const SET_TIMEZONE = "SET_TIMEZONE"
export const SET_TARIF = "SET_TARIF"
export const SET_THERAPEUTES = "SET_THERAPEUTES"
export const SET_FORMULE = "SET_FORMULE"
export const SET_FORMULE_UPDATE = "SET_FORMULE_UPDATE"
export const SET_SLIDE = "SET_SLIDE"
export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const SET_CURRENT_BOUTIQUE = "SET_CURRENT_BOUTIQUE"
export const SET_BEST = "SET_BEST"
export const SET_COLOR = "SET_COLOR"
export const SET_BOUTIQUES = "SET_BOUTIQUES"
export const SET_INFO_BOUTIQUES = "SET_INFO_BOUTIQUES"
export const SET_TAIL = "SET_TAIL"
export const SET_LIST_AMAZON = "SET_LIST_AMAZON"
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT"
export const SET_CURRENTPAGE = "SET_CURRENTPAGE"
export const SET_COUNT = "SET_COUNT"
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST_ALL"
export const SET_CATEGORY_LIST_ALL = "SET_CATEGORY_LIST"
export const SET_SOUS_CATEGORY_LIST = "SET_SOUS_CATEGORY_LIST"
export const SET_LIST_RECLAMATIONS = "SET_LIST_RECLAMATIONS"
export const PRODUCT_LIKED = "PRODUCT_LIKED"
export const PRODUCT_FAVORIS = "PRODUCT_FAVORIS"
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
export const SET_CURRENT_SETTINGS = "SET_CURRENT_SETTINGS"
export const SET_FAQ = "SET_FAQ"
export const SET_COMMENTS = "SET_COMMENTS"
export const SET_AVG = "SET_AVG"
export const SET_AGENDA = "SET_AGENDA"
export const SET_TEST = "SET_TEST"
