import React, { useState, useCallback, useEffect } from "react"
import { useSelector }   from "react-redux"
import { DailyProvider } from '@daily-co/daily-react'
import DailyIframe       from '@daily-co/daily-js'


import api       from '../../components/dayli/api'
import HairCheck from '../../components/dayli/HairCheck/HairCheck'
import Call      from '../../components/dayli/Call/Call'
import Tray      from '../../components/dayli/Tray/Tray'



// TODO - NOTA : remove reservations fetch & display
const Page = () => {
  const [roomUrl,    setRoomUrl]    = useState(null)
  const [callObject, setCallObject] = useState(null)
  const [inRoom,     setInRoom]     = useState(false)



  const startHairCheck = useCallback(async (url) => {
    const newCallObject = DailyIframe.createCallObject()
    setRoomUrl(url)
    setCallObject(newCallObject)
    await newCallObject.preAuth({ url }) // add a meeting token here if your room is private
    await newCallObject.startCamera()

    setRoomUrl(url)
  }, [])


  const createCall = useCallback(() => {
    return api
    .createRoom()
    .then((room) => {
      startHairCheck(room.url)
    })
    .catch((error) => {
      console.error('Error creating room', error)
      setRoomUrl(null)
    })
  }, [])


  const startLeavingCall = () => {
    console.log("leaving")
  }

  const joinCall = useCallback(() => {
    callObject.join({ url: roomUrl })
    setInRoom(true)
  }, [callObject, roomUrl]);


  const handleInputChange = (event) => {
    setRoomUrl(event.target.value)
  }

  const handleJoinRoomClick = (event) => {
    const newCallObject = DailyIframe.createCallObject();
    setCallObject(newCallObject);

    newCallObject.startCamera()
  }





  const renderHairCheck = () => {
    return (
      <DailyProvider callObject={callObject}>
        <HairCheck joinCall={joinCall} cancelCall={startLeavingCall} />
        room url : <input type="text" value={roomUrl} />
      </DailyProvider>
    )
  }


  const renderCall = () => {
    return (
      <div className="home dayli">
        <section className="section2 colmin">
          <DailyProvider callObject={callObject}>
            <Call />
            <Tray leaveCall={startLeavingCall} />
          </DailyProvider>
        </section>
      </div>
    )
  }


  const renderHome = () => {
    return (
      <div>
        <button onClick={() => createCall()}>Créer</button>
        <br />
          <input type="text" onChange={handleInputChange} value={roomUrl} />
          <button onClick={handleJoinRoomClick}>Rejoindre</button>
      </div>
    )
  }


  if (inRoom) {
    return renderCall()
  }
  if (roomUrl && callObject) {
    return renderHairCheck()
  }

  return <div>
    {renderHome()}
  </div>
}


export default Page
