import React from "react"
import './styles.scss'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from "react-redux"
import { Footer, Header } from ".."
import { Helmet, HelmetProvider } from "react-helmet-async"


const toCanonicalPath = (path) => {
    const regex = /^\/[^/]+\/[^/]+/;
    const matchedPath = path.match(regex)

    return "https://feel-better-company.com/" + (matchedPath ? matchedPath[0] : "")
}


const Component = ({ component: Component, protect, parts,back,path, ...rest }) => {
    const { user } = useSelector(state => state.user)

    const canonicalPath = toCanonicalPath(path)

    const RenderComponent = (props) => {
        return (
            <HelmetProvider>
                <Helmet>
                    <link rel="canonical" href={canonicalPath} />
                </Helmet>
                <div>
                    {parts && <Header path={path} back={back == 1 ? 1 : 0} />}
                    <Component {...props} />
                    {parts && <Footer path={path} />}
                </div>
            </HelmetProvider>
        )
    }

    return (
        <Route
            {...rest}
            render={props => (
                protect ?
                    user?.id ?
                        <RenderComponent {...props} />
                        :
                        <Redirect to="/login" />
                    :
                    <RenderComponent {...props} />
            )} />
    );
}

export default Component;