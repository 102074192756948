import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getlistproducts} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { products } = useSelector(state => state.restaurent)
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState('')
    const [date, setDate] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [search, setSearch] = useState('')

    useEffect(() => {
          dispatch(getlistproducts(user.user_id,user.api_token,'product',{promo:1}));
    }, []);


    useEffect(() => {
       if(counter){
          setFormFiltre()
       }
    
}, [counter]);

  
    function gotoCmd(item) {
       history.push(`/vendeur/modifier-produit/${item}`);
     }


        const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)

             break;

             case 'rubrique':

                 setRubrique(slug)
                 setCounter(1)
             break;
            case 'order':

                 setOrder(slug)
                 setCounter(1)
             break;

          }

        
            
 
    }


      const  setFormFiltre = async () => {
      let params ={filtre:'1',promo:1}


      if(rubrique){
          params.rubrique=rubrique
      }

      if(order){
        
        params.order=order

      }
      if(date){
         
         params.date=date
      }
  

     dispatch(getlistproducts(user.user_id,user.api_token,'product',params));
     setCounter('')

    }

      const searchproduct = (e) => {
          e.preventDefault();
 
          if(search){
             let params ={search:search,promo:1}
               dispatch(getlistproducts(user.user_id,user.api_token,'product',params));

          }
          else{
           
            
               dispatch(getlistproducts(user.user_id,user.api_token,'product',{promo:1}));

         
          }

          initfiltre()

    }

const initfiltre = () => {
     setDate('')
     setRubrique('')
     setOrder('')

  }



    
    return (
        <div className="mescommandes register listproducts " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

               <MenuVendeur slug='addproduct' />
               
            </Col>
              
                    <Col  className="text-center mx-auto my-5"  >

     
                            <h3 className="mb-4" ><img src="/images/orderico.png" style={{width: '38px'}} /> Gérer mes articles en promo</h3>
                             <br/> 


             <div className='orderactionmob orderactionmob2'>

                <div className='orderactionitem'>
                 
                   <a href='javascript:void(0)' onClick={(event)=>HandleFiltre('','rubrique',event)}  className={rubrique=='' && 'actv' }>TOUT</a>
                </div>
                
                <div className='orderactionitem'>
                 {
                  user?.boutique?.categorie=='fashion' ?
                    <a href='javascript:void(0)' onClick={(event)=>HandleFiltre(1,'rubrique',event)}  className={rubrique==1 && 'actv' }>FASHION</a>

                  :
                   <a  href='javascript:void(0)' onClick={(event)=>HandleFiltre(2,'rubrique',event)}  className={rubrique==2 && 'actv' }>AFRO CREATION</a>

                 }
                   
                  
                </div>
            
                <div className='orderactionitem'>
                   <a href='javascript:void(0)' onClick={(event)=>HandleFiltre(3,'rubrique',event)}  className={rubrique==3 && 'actv' }>VENTOUT</a>
               
                </div>

            </div>
            

            <div className='filtreorder'>
 
                   <div className='item listing ' style={{width: '50%'}}>
                    <div className="form-group--nest" style={{    width: '100%'}}><input className="form-control" type="text" placeholder="Rechercher un article.." onChange={e => setSearch(e.target.value)}/><div className='ico' onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>
                  </div>
                 <div className='item'>
                     <select className='orderbyorder'  onChange={e => HandleFiltre(e.target.value,'order',e)} ><option value=''>Trier par</option><option value='created_at'>Date</option><option value='sale_price'>Prix de vente</option></select>
                </div>


            </div>


                    <form className="mb-5">
                      
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                            <Table responsive >
                          <thead>
                            <tr>
                                <th></th>
                                <th>Référence</th>
                                <th>Nom de l'article</th>
                                <th >Date création </th>
                                <th  >Prix </th>
                                <th  >Statut</th>
                                <th  >Actions</th>

                             
                            </tr>
                          </thead>
                        <tbody>

                      
                         {products?.data?.map((item) => (

                              <tr>
                               <td><img style={{width: '59px'}} src={item.image_url} /></td>
                              <td>{item.sku}</td>
                              <td>{item.product_name}</td>
                              <td>
                                 {moment(new Date(item.date_creation)).format("DD/MM/YYYY")}
                              </td>
                              <td><span class="promo">{parseFloat(item.sale_price).toFixed(2)} CFA</span> {parseFloat(item.off_price).toFixed(2)} CFA</td>
                              <td>
                              {
                                 item.quantity > 0 ? 
                                 <span className='badge alert-success'>Disponible</span>
                                 :
                                 <span className='badge alert-danger'>Rupture de stock </span>
                              }
                                 
                              </td>
                              <td>
                              <div className="list-contr">

                               <a  href='javascript:void(0)' onClick={()=>gotoCmd(item.product_id)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Modifier <i class="icon-edit"></i></a>
                               
                               </div>
                              </td>
                            </tr>


                          ))}
                            
            
                
                               
                          
                          

                          </tbody>
                        </Table>

 
                          </div>
                           
                          


                        <div className='tavblemob'>

                         {products?.data?.map((item) => (
                                
                                 <div className='items'>
                              <a  href='javascript:void(0)' onClick={()=>gotoCmd(item.product_id)}  >

                                <div className='rowitmtop'>
                                   <span> {moment(new Date(item.date_creation)).format("DD MMM YYYY")}</span>
                                   <span>Réf : {item.sku}</span>
                                </div>

                                <div className='process-item'>
                                    <img style={{width: '59px'}} src={item.image_url} />
                                    <strong>{item.product_name}</strong>
                                    <div className='rowsitm'>
                                      <div> <span>Quantité: {item.quantity}</span></div>
                                       <div><b><span class="promo">{parseFloat(item.sale_price).toFixed(2)}</span> {parseFloat(item.off_price).toFixed(2)} CFA</b></div>
                                       <div>

                                          {
                                             item.quantity > 0 ? 
                                             <span className='badge alert-success'>Disponible</span>
                                             :
                                             <span className='badge alert-danger'>Rupture de stock </span>
                                          }


                                       </div>
                                    </div>

                                 </div>
                                </a>
                              </div>


                          ))}
                             

                


                             
  
                        </div> 

                        <br/> <br/>
 
                           

                          </Row>

                    </form>
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;