import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal,CloseButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser } from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiTrash } from 'react-icons/bi';
import Switch from '@material-ui/core/Switch';
import { MenuVendeur } from "../../../components";
import { getCategoryList, getColors, getTails,getsousCategoryList } from "../../../actions/mixedmode";
import { apiClient } from "../../../actions/api"
import { getProductById } from "../../../actions/mixedmode";
import { useParams } from "react-router"; 


const Page = () => {
    const { id } = useParams()

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
     const label = { inputProps: { 'aria-label': '' } };

    const [show,setShow]=useState(false);
    const [showColor,setShowColor]=useState(false);
    const [showTaille,setShowTaille]=useState(false);
    const [disponible,setDisponible]=useState(true);
    const [promo,setPromo]=useState(false);


    const [valColor,setValColor]=useState([]);
    const [valColorid,setValColorid]=useState([]);
    const [valTaille,setValTaille]=useState([]);

    const [rubrique,setRubrique]=useState('');
    const [categid,setCategid]=useState('');
    const [souscategid,setSousCategid]=useState('');

    const [photo1, setPhoto1] = useState('')
    const [photo2, setPhoto2] = useState('')
    const [photo3, setPhoto3] = useState('')

    const [video, setVideo] = useState('')
    const [videoname, setVideoname] = useState('')
    const [ifloading, setIfloading] = useState(0)

     const [titre, setTitre] = useState('')
     const [quantite, setQuantite] = useState('')
     const [description, setDescription] = useState('')
     const [prix, setPrix] = useState('')

    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const fileInput3 = useRef(null)
    const fileInput4 = useRef(null)


    const pointure=[31,32,33,34,35, 36, 37,38];
    const tailadulte=['XS','S','M','L','XL','2XL','3XL','4XL','5xl','6XL'];
    const tailenfant=['1 MOIS','2 MOIS','3 MOIS','1 AN','2 ANS','3 ANS','6 ANS','10 ANS'];

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     const handleCloseColor = () => setShowColor(false);
     const handleShowColor = () => setShowColor(true);
     const handleCloseTaille = () => setShowTaille(false);
     const handleShowTaille = () => setShowTaille(true);

     const {categorieslist,souscategorieslist, colors, tails ,product} = useSelector(state => state.mixedmode)

  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());


  const handleRemovefile = (t) => {

      switch(t){
             case 1:
                setPhoto1('')
             break;
              case 2:
                 setPhoto2('')
             break;
              case 3:
                setPhoto3('')
             break;
              case 4:
                setVideo('')
             break;



      }
      return false;
     
  }

      const handleClickfile = (t) => {

      switch(t){
             case 1:
               fileInput.current.click()
             break;
              case 2:
               fileInput2.current.click()
             break;
              case 3:
               fileInput3.current.click()
             break;
              case 4:
               fileInput4.current.click()
             break;



      }
     
  }



function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
      // etc
      return true;
  }
  return false;
}

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}




    const handleFileChange = (event,t) => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
 

       if(t==1 || t==2 || t==3){

            if(!isImage(event.target.files[0].name)){
               alert('Veuillez sélectionner une image valide (png,jpg,jpeg,gif..)')
               return false;
            }

            formData.append('typefile','image');

       }
       else{
            if(!isVideo(event.target.files[0].name)){
               alert('Veuillez sélectionner une vidéo valide (mp4,mov,avi,m4v..)')
               return false;
            }
            formData.append('typefile','video');
       }

      setIfloading(1)
    
      apiClient().post(`/uploadphotos`,formData)
              .then((res) => {

         if(res.data.status=='successs'){
          setIfloading(0)

        switch(t){
             case 1:
                setPhoto1(res.data.message)
             break;
              case 2:
                 setPhoto2(res.data.message)
             break;
              case 3:
               setPhoto3(res.data.message)
                
             break;
                case 4:
               setVideo(res.data.message)
               setVideoname(event.target.files[0].name)
                
             break;


      }

                }
                else{
                alert('Erreur !')
                }
                 
        })
     
  }




    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
 

         let colorslist=[]
            

                         // check if product sum section
 
                         for(let i=0; i < colors.length; i++){
                          
                                if(valColorid.includes(colors[i].color_code)){
                                   colorslist.push(colors[i].color_id)
                                }
                         }




      if(valTaille.length<=0){
          alert('Veuillez sélectionnez la Taille !')
          return false
      }

       if(colorslist.length<=0){

          alert('Veuillez sélectionnez la couleur ! ')
          return false

      }

        if(photo1=='' && photo2=='' && photo3=='' ){
          alert('Veuillez ajouter au moins une photo !')
          return false
       }

        


        formDataObj.user_id=user.user_id;
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.colors=colorslist;
        formDataObj.tails=valTaille;
        formDataObj.disponible=disponible ? 1 : 0;
        formDataObj.promo=promo ? 1 : 0;
        formDataObj.type='updateproduct';
        formDataObj.product_id=product?.product_id;


        //console.log(formDataObj)
        //return false;

  
         apiClient().post(`/addnewproduct`,formDataObj)
              .then((res) => {
 
                if(res.data.success=='ok'){

                      history.push('/vendeur/liste-produits/')
                }
                else{
                alert('Erreur !')
                }
                 
        })
    
            
      
    }
     
    useEffect(() => {
         let rubrique=user?.boutique?.categorie=='fashion' ? 1: 2;
         setRubrique(rubrique)
         dispatch(getCategoryList(rubrique))
         dispatch(getsousCategoryList(rubrique))
         dispatch(getColors())
         dispatch(getTails())
         dispatch(getProductById(id,user?.user_id));
  

        
    }, []);

     useEffect(() => {
       if(product?.product_id== id){



        setTitre(product?.product_name)
        setDescription(product?.description)
        setPrix(product?.sale_price)
        setQuantite(product?.quantity)

 
        // set categorie
             for(let i=0; i < product?.categories.length; i++){
                 if(product?.categories[i].parent_id==null){
                   
                   setCategid(product?.categories[i].category_id)
                 }

                  if(product?.categories[i].parent_id!=null){
                    setSousCategid(product?.categories[i].category_id)
                 }
             }

         // set colors
         let newcolors=[];
             for(let i=0; i < product?.colors.length; i++){
                  newcolors.push(product?.colors[i].color_code)  
             }

          // set tails
         let newtails=[];
             for(let i=0; i < product?.tails.length; i++){
                  newtails.push(product?.tails[i].tail_id)  
             }


          setValColorid(newcolors)
          setValTaille(newtails)


        //set video 
        setVideo(product?.video)
        setVideoname(product?.video?.split('/')[product?.video?.split('/').length-1])

         // set photos
         setPhoto1(product?.image_url)

          for(let i=0; i < product?.photosss.length; i++){
                
                if(i==0){
                   setPhoto2(product?.photosss[i])
                }
                 if(i==1){
                   setPhoto3(product?.photosss[i])
                }

                     
          }


        // set dispo

        setDisponible(product?.status)
        setPromo(product?.is_off)


        // set en promo



         

                
       }
    
}, [product]);
    

    const setCategorie =  (e) => {
     
     setCategid(e)
     setSousCategid('')


    }

    const setSousCategorie =  (e) => {
     
     setSousCategid(e)

    }


    const HandleFiltre =  (slug,type,e) => {
      
 
      
          e.preventDefault();
          switch(type){
             case 'categorie':
                setCategid(slug)
                

             break;

             case 'souscategorie':

                 setSousCategid(slug)
                 
             break;

             case 'color':
             
                 let newcolors=[...valColorid];
                 var index = newcolors.indexOf(slug)
             
                 if(index > -1){
 
                    newcolors.splice(index,1);
                      setValColorid(newcolors)

                     
                 }
                 else{
                   newcolors.push(slug) 
                   setValColorid(newcolors)
                    
                 }
                 
                   
             break;

             case 'tail':
              
                 let newtail=[...valTaille];
                 var index = newtail.indexOf(slug)
                 if(index > -1){

                    newtail.splice(index,1);
                    setValTaille(newtail)
                  
                 }
                 else{
                     newtail.push(slug) 
                     setValTaille(newtail)
                     
                 }
                 
             break;
 


          }

        
            
 
    }


const handleChangedispo = (event) => {
    setDisponible(event.target.checked);
  };

const handleChangepromo = (event) => {
    setPromo(event.target.checked);
  };


    return (
        <div className="register addproduct" >

            <Container>
                <Row>
  <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

         <MenuVendeur slug='addproduct' />

               
                </Col>
                    <Col  className="text-center mx-auto my-5"  >
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Modifier votre produit</h3>

                            <div style={{display:'none'}}>
                                <input type='text'  name='rubrique' value={rubrique}/>
                                

                            </div>
                            <Row className="">
                                <Form.Group  className='col-md-12' >
                                    <Form.Label>Titre de l'article*</Form.Label>
                                    <Form.Control type="text" placeholder="Ex: JAY MARRON" name="titre" required  defaultValue={titre} />
                                </Form.Group>
                            
                          </Row>
                            <Row className="">
                    
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Catégorie*</Form.Label>
                                    <select name='categorie' className='form-control p99'  required onChange={e => setCategorie(e.target.value)} value={categid}  >
                                       <option value=''>Catégorie</option>
                                         {categorieslist?.map((item) => (
                                              <option value={item.category_id}>{item.category_name}</option>
                                          ))}
                                      
                                    </select>
                                </Form.Group>

                                <Form.Group className='col-md-6' >
                                    <Form.Label>Sous catégorie*</Form.Label>
                                    <select  name='souscategorie' className='form-control p99'  required  onChange={e => setSousCategorie(e.target.value)} value={souscategid}>
                                       <option value=''>Sous Catégorie</option>
                                         {souscategorieslist?.map((item) => (
                                              item.parent_id==categid &&
                                              <option value={item.category_id}>{item.category_name}</option>
                                          ))}
                                      
                                    </select>
                                </Form.Group>


                          </Row>

                          <Row className="">
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Prix(CFA)*</Form.Label>
                                    <Form.Control type="number" placeholder="0.00" name="prix" required defaultValue={prix} />
                                    <span></span>
                                </Form.Group>
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Quantité disponible*</Form.Label>
                                    <Form.Control type="number" placeholder="Ex: 100" name="qte" required defaultValue={quantite} />
                                </Form.Group>

                          </Row>

                             <Row className="">
                                <Form.Group  className='col-md-12' >
                                    <Form.Label>Description</Form.Label>
                                    <textarea className='form-control' rows='2' name='description' defaultValue={description}></textarea>
                                </Form.Group>
                                
                          </Row>
 

                        <Row className="">
                                <Form.Group  className='col-md-6' >
                                  <div className='marg6'>
                                    
                  
                                       <div className='inp inp1' onClick={()=>handleShowColor()}>
                                         Choisir une couleur
                                       
                                       
                                       </div> 

                                         {valColorid?.map((item) => (
                                                 <label  className={"_35LeVin"}  ><span className="_1xuQr" style={{backgroundColor: item}}></span></label>
                                          ))}

                                     </div>

        
                                </Form.Group>
                                <Form.Group className='col-md-6' >
                                      <div className='marg6'>
                                    
                                       <div className='inp inp1' onClick={()=>handleShowTaille()}>
                                       Choisir une taille
                                       </div>

                                        {valTaille?.map((item) => (
                                                 <label    ><span className="" style={{paddingRight:'5px'}} >{item} </span></label>
                                          ))}


                                     </div>
                                </Form.Group>
                          </Row>

                        <Row className="">
                                <Form.Group  className='col-md-6' >
                                   
                                
                                     <Form.Label>Photo de l'article</Form.Label>
           <p className='psr'>Vous pouvez ajouter jusqu'à 3 photos maximum</p>
           <div className='photosblock'>
                
               <div className='item'  style={{position: 'relative'}}  >
                   {
                        photo1 &&
                        <a onClick={() => handleRemovefile(1)} style={{    position: 'absolute',top: '-20px',left: '-9px'}} href='javascript:void(0)'>
                          <BiTrash   size={20} />
                        </a>
                      }
                <div onClick={() => handleClickfile(1)}>
                      <img  src={photo1 ? photo1 : '/images/Groupe7130.png' } />
                    <form style={{padding:'0px'}}>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,1)}
                        ref={fileInput} 
                    />
                    </form>
                    <span style={{display:'none'}}><input type='text' name='photo1' value={photo1} /></span>
                  </div>
               </div>

               <div className='item' style={{position: 'relative'}}   >
                {
                        photo2 &&
                        <a onClick={() => handleRemovefile(2)} style={{    position: 'absolute',top: '-20px',left: '-9px'}} href='javascript:void(0)'>
                          <BiTrash   size={20} />
                        </a>
                      }
                 <div onClick={() => handleClickfile(2)} >
                      <img src={photo2 ? photo2 : '/images/Groupe7130.png' } />
                    <form style={{padding:'0px'}}>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,2)}
                        ref={fileInput2} 
                    />
                    </form>
                    <span style={{display:'none'}}><input type='text' name='photo2' value={photo2} /></span>
                   </div>
               </div>
                <div className='item' style={{position: 'relative'}}   >
                 {
                        photo3 &&
                        <a onClick={() => handleRemovefile(3)} style={{    position: 'absolute',top: '-20px',left: '-9px'}} href='javascript:void(0)'>
                          <BiTrash   size={20} />
                        </a>
                      }
                 <div onClick={() => handleClickfile(3)}>
                      <img src={photo3 ? photo3 : '/images/Groupe7130.png' } />
                    <form style={{padding:'0px'}}>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,3)}
                        ref={fileInput3} 
                    />
                    </form>
                    <span style={{display:'none'}}><input type='text' name='photo3' value={photo3} /></span>
                    </div>
               </div>
             

           </div>
           <br/>
                                    
                                </Form.Group>
                                <Form.Group className='col-md-6' >
                                     <a href='javascript:void(0)'onClick={() => handleClickfile(4)}  className='btnupload' > {video == '' ? 'Ajouter une vidéo' : 'Modifier la vidéo'} <i className='fa fa-download'></i>
                                           
                                          <form style={{padding:'0px'}}>
                                             <input
                                             style={{display:'none'}}
                                              type="file"

                                              onChange={(e) => handleFileChange(e,4)}
                                              ref={fileInput4} 
                                          />
                                          </form>
                                     </a>
                                     {
                                      video &&
                                        <div>{videoname}</div>
                                     }
                                     
                                     <p className='psr'>Votre vidéo ne doit pas dépasser 30s maximum</p>
                                      <span style={{display:'none'}}><input type='text' name='video' value={video} /></span>
                                    
                                                     {
                        video &&
                        <a onClick={() => handleRemovefile(4)} style={{    position: 'absolute',top: '-20px',left: '-9px'}} href='javascript:void(0)'>
                          <BiTrash   size={20} />
                        </a>
                      }

                                </Form.Group>
                                   <br/>


                          </Row>

                           <Row className="">
                                <Form.Group  className='col-md-6' style={{display: 'flex',alignItems: 'center'}} >
                                    
                                    <div className='item labelswt' style={{display:'flex'}}>
                                       <br/>
                                                    <label><strong> En promo ? </strong> <Switch {...label} checked={promo} size="small" color="primary" name="" onChange={handleChangepromo}  /> </label>
                                    </div>
                                    
                                </Form.Group>
                                {
                                    promo== true && 

                                    <Form.Group  className='col-md-6' >
                                    
                                    
                                    <Form.Label>  Promo(%) </Form.Label>
                                    <Form.Control type="number" placeholder="10%" name="percentof" defaultValue={product?.percentof} />
                                    <span></span>
                            
                                    
                                    </Form.Group>
                                }

                                 
                               
                          </Row>





                           <Row className="">
                                <Form.Group  className='col-md-6' >
                                    
                                    <div className='item labelswt' style={{display:'flex'}}>
                                       <br/>
                                                    <label><strong> Disponible ? </strong> <Switch {...label} checked={disponible} size="small" color="primary" name='disponible'  onChange={handleChangedispo}  /> </label>
                                    </div>
                                    
                                </Form.Group>
                               
                           </Row>


                       <br/>
              
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Modifier
                            </Button>
                        </Form>
                    </Col>
                </Row>

                 <Modal show={showColor} fullscreen={true} onHide={() => setShowColor(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Couleur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Choisissez les couleurs disponibles</p>

        <div className='listing '>

      <ul className='Xgz5B'>
   
       {colors?.map((item) => (
           
              
       <li  onClick={(event)=>HandleFiltre(item.color_code,'color',event)} title={item.color_name}  >
          <label  className={valColorid.includes(item.color_code) ? "_35LeV coloractive":"_35LeV"}  ><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

    </ul>


        </div>

        </Modal.Body>
             <Modal.Footer className='ventout'>

          <div className='row' style={{width:'100%'}}>

              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='flx124'>
                  
                    <a className="ps-btn ps-btn--black f1" href="javascript:void(0)"  onClick={()=>handleCloseColor()}  >Valider</a>
                   <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleCloseColor()} >Fermer</a>

                </div>
              </div>
              <div className='col-md-4'></div>

            </div>
        
        
        </Modal.Footer>


      </Modal>


      <Modal show={showTaille} fullscreen={true} onHide={() => setShowTaille(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Taille</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Choisissez les tailles disponibles</p>

        <div className='listing '>


        {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <div className='modblockgr'>
           {item?.tail_code?.map((code) => (
                    
                   <div onClick={(event)=>HandleFiltre(code,'tail',event)}   className={valTaille.includes(code)   ? "item tailactivesent":"item" }  > {code} </div>

            ))}
          
      
        </div>
        </div>

        ))}

   
        </div>

        </Modal.Body>
             <Modal.Footer className='ventout'>

          <div className='row' style={{width:'100%'}}>

              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='flx124'>
                  
                     <a className="ps-btn ps-btn--black f1" href="javascript:void(0)" onClick={()=>handleCloseTaille()}  >Valider</a>
                   <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleCloseTaille()}>Fermer</a>

                </div>
              </div>
              <div className='col-md-4'></div>

            </div>
        
        
        </Modal.Footer>


      </Modal>


      <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>
            </Container>
        </div>
    );
}

export default Page;