import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)

    const [counter, setCounter] = useState('')
    const history = useHistory();
 
    useEffect(() => {
        dispatch(getWalette(user.user_id,user.api_token,'vendeur',{}));
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);
 
    
      function gotoCmd(id,item) {
       dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/vendeur/commande/detail/${id}`);
     }

   const setTypepaiement =  (e,t) => {

        e.preventDefault();

        setType(t)

  }
   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)


             break;

             case 'rubrique':

                 setRubrique(slug)
                
             break;
            

          }

        
            
 
    }


    const  setFormFiltre = async () => {
      let params ={filtre:'1'}

      if(date){
         
         params.date=date
      }
  
  
     dispatch(getWalette(user.user_id,user.api_token,'vendeur',params));
     setCounter('')

    }



  const handleSubmit = (e) => {


        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        if(formDataObj.montant > parseFloat(walette?.totalorder).toFixed(2) ){

          alert('Veuillez entrer un montant valide !')
          return false;
        }

         dispatch(getWalette(user.user_id,user.api_token,'vendeur',formDataObj));
         alert('Votre demande a bien été enregistrée . Cependant, nous accordons 72 HEURES aux acheteurs avant de faire un retour . Si l\'acheteur ne fait aucun retour de sa commande en 72 heures, vous recevrez vos fonds . Nous vous remercions pour votre compréhension ! ')
 
    }








    return (
        <div className="mescommandes register vosventes " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

               <MenuVendeur slug='vos-ventes' />
               
            </Col>
              
                    <Col  className="text-center mx-auto my-5"  >

     
                            <h3 className="mb-4" ><img src="/images/venico4.png" style={{width: '38px'}} /> Le point de vos ventes</h3>
                             <br/> 
 

           <div className='orderactionmob orderactionmob2'>

                <div className='orderactionitem'>
                 
                   <a href='javascript:void(0)' onClick={(event)=>HandleFiltre(1,'rubrique',event)}  className={rubrique==1 && 'actv' }>Portefeuille</a>
                </div>
                
                <div className='orderactionitem'>
                  
                   <a href='javascript:void(0)' onClick={(event)=>HandleFiltre(2,'rubrique',event)}  className={rubrique==2 && 'actv' }>Reversement de fonds</a>
                   
                   <a  href='javascript:void(0)' onClick={(event)=>HandleFiltre(3,'rubrique',event)}  className={rubrique==3 && 'actv' }>Historique de paiements</a>
               
                </div>
            
          

            </div>

           
           {

            rubrique==1 ?

            <div  className='center'>
            <br/>
            <br/>
            <br/>
            <br/>

              <h2 style={{color:'#ab1f8b',    fontSize: '30px'}}> Votre solde actuel </h2>
              <br/>
              <h2 style={{color:'#000',fontSize: '30px'}}> {walette?.totalorder != null ? parseFloat(walette?.totalorder).toFixed(2) : '0.00'}   CFA </h2>
              
              

            </div>

            :

             rubrique==2 ?

                 <div>

                   {
                      walette?.versement==1 ?

                      <div className='alert alert-warning'> Une demande de reversement de fonds  est en cours de traitement.. </div>

                      :

                   <form className='formsoldpauy'   onSubmit={handleSubmit} >





                       <Form.Group className="mb-3" >

                          <div className='e9039'>

                               <label>
                                 <h1><strong> {walette?.totalorder != null ? parseFloat(walette?.totalorder).toFixed(2) : '0.00'}   CFA</strong></h1>
                               </label>
                                
                                <Form.Control
                                    name="montant"
                                    type="number" placeholder="Entrez le montant" required />

                                <div style={{display:'none'}}>

                                 <Form.Control
                                    name="typeversement"
                                    type="number" value={type} />

                                </div>



                          </div>


                          <div className='typepaiement'>

                             <a href="javascript:void(0)" className={type==1 && 'actiftype'} onClick={(e)=>setTypepaiement(e,1)}>< img src='/images/orangemoneu.png' /></a>
                             <a href="javascript:void(0)" className={type==2 && 'actiftype'} onClick={(e)=>setTypepaiement(e,2)}>< img  style={{width:'150px'}} src='/images/wavemoney.png' /></a>
                          </div>
                          <div  className='typepaiement'>

                             <a href="javascript:void(0)" className={type==3 && 'actiftype'} onClick={(e)=>setTypepaiement(e,3)}>< img src='/images/vermentmoney.png' /></a>
                              <a href="javascript:void(0)" className={type==4 && 'actiftype'} onClick={(e)=>setTypepaiement(e,4)}>< img src='/images/cashmoney.png' /></a>
                          </div>

                       </Form.Group>

                       <div className='center'><p style={{fontSize:'18px', color:'#000'}}>Sélectionnez  le canal de paiement </p></div>
                      

                      <div className='center'>
                      {
                        parseFloat(walette?.totalorder) > 0 && 

                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" style={{background:'#000'}} type="submit">
                               VALIDER
                            </Button>
                      }

                      


                      </div>


                   </form>




                   }

                         
                      



                 </div>

             :

            <div>



            <div className='filtreorder'>
                <div className='item'>

                    <div className='colitem'>
                      <div >
                        Date
                      </div>

              
                      <div  className='iiin'>
                         
                         <input type="date" className='form-control' onChange={(event)=>HandleFiltre(event.target.value,'date',event)} />
                        
                     </div>


                    </div>


                </div>
            
                </div>



                 <form className="mb-5">
                      
                          <Row className="mb-3"  >
                          <div className='tavbleweb'>
                           
                          <Table responsive >
                          <thead>
                            <tr>
                                <th>Montant demandé</th>
                                <th>Montangt reçu </th>
                                <th>Mode de paiement</th>
                                <th>Date</th>
                                <th>Statut</th>
                                <th>Actions</th>
                            </tr>
                          </thead>
                        <tbody>
                            
                             {walette?.histopriques?.map((item) => (
                            <tr>
                              <td>{item?.total} CFA</td>
                              <td>{item?.versement == 1 ? '0.00' : item?.total } CFA</td>
                              <td>{item?.type==1 ? 'Orange Money' : item?.type==2 ? 'Wave' : item?.type==3 ? 'Virement bancaire' :  'Payement en cash'  }</td>
                              <td>
                                 {moment(new Date(item.created_at)).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                      {item.statut==0 && <span className='badge alert-warning'>En cours</span>}
                                      {item.statut==2 && <span className='badge alert-danger'>Refusé</span>}
                                      {item.statut==1 && <span className='badge alert-success'>Validé</span>}
                              </td>
                               
                              <td>
                              <div className="list-contr">
                              {
                                item.statut==1 &&

                                  <a  href={item.file} download className="" data-microtip-position="left" data-tooltip=""> <img src="/images/invoiceico.png" /></a>
                              }

                               
                               
                               </div>
                              </td>
                            </tr>
                              ))}
     
                          
                          

                          </tbody>
                        </Table>
                        </div>


                      <div className='tavblemob'>
                           {walette?.histopriques?.map((item) => (

                          <div className='items'>
                              <a  href={item.statut==1 ? item.file : '#'} download >

                                <div className='rowitmtop'>
                                   <span>  {moment(new Date(item.created_at)).format("DD MMM YYYY")}</span>
                                   <span>Montant demandé  : {item?.total} CFA</span>
                                </div>

                                <div className='process-item'>

                              <strong> {item?.type==1 ? 'Orange Money' : item?.type==2 ? 'Wave' : item?.type==3 ? 'Virement bancaire' :  'Payement en cash'  }</strong>
                                    <div className='rowsitm'>
                                      <div> <span>Montant reçu: {item?.versement == 1 ? '0.00' : item?.total } CFA</span></div>
                                        
                                      <div>

                                      {item.statut==0 && <span className='badge alert-warning'>En cours</span>}
                                      {item.statut==2 && <span className='badge alert-danger'>Refusé</span>}
                                      {item.statut==1 && <span className='badge alert-success'>Validé</span>}

                                       </div>
                                    </div>

                                       <div className='center'>

                                   <img src="/images/invoiceico.png" />

                                 </div>


                                 </div>


                              
                                </a>
                              </div>



                          ))}
                       
 
 
  
                        </div> 





                
 

                          </Row>

                    </form>











            </div>


           }

           






                   
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;