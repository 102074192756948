import { SET_RESTAURENTS,SET_LIST_WALETTE,SET_LIST_STORIES,SET_LIST_COMMENTS,SET_CURRENT_RESTAURENT,SET_LIST_ADDRESS,SET_LIST_COMMANDES,SET_LIST_PRODUCT,SET_CURRENT_COMMANDE,SET_HORAIRE_RESTAURENT } from "../constants/actions";

const INITIAL_STATE = {
    data: [],
    addresseslist: [],
    commandeslist: [],
    horaires: [],
    comments:[],
    commandeinfo: {},
    currentRestau:{
        establishment:{},
        categories:[]
    },
    products:[],
    walette:{},
    stories:[],
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_RESTAURENTS :
            return {...state,data:action.payload}
        case SET_CURRENT_RESTAURENT :
            return {...state,currentRestau:action.payload}
      case SET_LIST_ADDRESS :
          return {...state,addresseslist:action.payload}
    case SET_LIST_COMMANDES :
          return {...state,commandeslist:action.payload}
   case SET_CURRENT_COMMANDE :
          return {...state,commandeinfo:action.payload}
   case SET_HORAIRE_RESTAURENT :
            return {...state,horaires:action.payload}
   case SET_LIST_COMMENTS :
            return {...state,comments:action.payload}         
    case SET_LIST_PRODUCT :
            return {...state,products:action.payload} 
    case SET_LIST_WALETTE :
            return {...state,walette:action.payload}   
    case SET_LIST_STORIES :
            return {...state,stories:action.payload}             
    default:

            return state;
    }
}
export default reducer;