import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {apiClient} from "../../actions/api";
import moment from "moment";
import {getRealTherapieTime} from "../../actions/api_interface";
import ErrorBoundary from "../../helpers/ErrorBoundary";
import {Col, Container, Row} from "react-bootstrap";
import Countdown from "react-countdown";
import {soundnotification} from "../../assets/images";


const STATE_IDLE      = 'STATE_IDLE';
const STATE_CREATING  = 'STATE_CREATING';
const STATE_JOINING   = 'STATE_JOINING';
const STATE_JOINED    = 'STATE_JOINED';
const STATE_LEAVING   = 'STATE_LEAVING';
const STATE_ERROR     = 'STATE_ERROR';
const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

const Page = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [tokendayli, settokendayli] = useState('')
    const {user, formule} = useSelector(state => state.user);
    const [checkconsultation, setCheckconsultation] = useState({})
    const [seancetime, setseancetime] = useState(0)
    const [goToCall, setGoToCall] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [patients, setPatients] = useState([])
    const audioPlayer = useRef(null);
    const [sessionId, setSessionId] = useState();
    const [tokenId, setTokenId] = useState();
    const publisherRole = 'publisher';
    const subscriberRole = 'subscriber';
    const [timecall, setTimeCall] = useState(100000)
    const [callDuration, setCallDuration] = useState(10000000)

    const [startcall, setstartcall] = useState(false)





    const [appState, setAppState] = useState(STATE_IDLE);
    const [roomUrl, setRoomUrl] = useState(null);
    const [callObject, setCallObject] = useState(null);
    const [apiError, setApiError] = useState(false);

    const showCall = !apiError && [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(appState);

    useEffect(() => {
        if (!user.user_id || user.google_id !== 'V' ) {
            history.replace("/")
        }

        checkifconsultation()
    }, [user]);




    useEffect(() => {
        if (!checkconsultation?.id) {
            return
        }

        let params = {
            token: user.api_token,
            api_token: user.api_token,
            id: checkconsultation?.id
        }

        //vérifie si une room a déjà été crée pour cette consultation et le user connecté, si oui, renvoie vers room call
        apiClient().post(`/roomtoken`, params)
            .then((res) => {
                if (res.data.success === 'ok' && res.data?.room) {
                    setSessionId(res.data?.token);
                    history.push('/room-call/' + checkconsultation?.id + "/r")
                }
            })
    }, [checkconsultation])

    useEffect(() => {
        if (goToCall) {
            history.push('/room-call/' + checkconsultation?.id + "/r")
        }
    }, [goToCall])


    // function playAudio() {
    //     audioPlayer.current.play();
    // }



    function checkifconsultation() {
        let params = {
            token: user.api_token,
            api_token: user.api_token,
        }


        apiClient().post(`/getconsultation/therapeut`, params)
            .then((res) => {
                console.log(res)
                if (res.data.success === 'ok') {
                    if (res.data.reservation?.heure_rdv) {
                        setCheckconsultation(res.data.reservation)
                        setPatients(res.data.patients)
                        let date = moment.utc(res.data.reservation.date_rdv + "T" + res.data.reservation.heure_rdv + "Z")
                        let diff = date.diff(moment.utc(), 'seconds')


                        setStartDate(date)
                        console.log("date, diff : ", date, diff)
                        if(diff <= 0){
                            setTimeCall(0)
                        }
                        else {
                            setTimeCall(diff * 1000)
                        }

                        setseancetime(getRealTherapieTime(res.data))
                    }
                    else{
                        console.log("inside els push home")
                        history.push('/')
                    }
                }
            })
    }

    //fonction qui créé et et sauvegarde en base la room
    const startDemo = () => {
        let params={
            // TODO - next params looks useless
            token:user.api_token,
            api_token:user.api_token,
            patients:patients,
            action:'deplacerbytherapeut',
        }

        apiClient().post(`/save/room`, params)
            .then((res) => {
                if (res.data.success === 'ok') {
                }
                setGoToCall(true)
            })
            .catch(e => {
                // TODO / NOTA - in current dev mode, request crash due to pusher, but room is created
                setGoToCall(true)
            })
        // startHairCheck('https://feelbettersession.daily.co/992002928');
    };

    // récupère la roomurl pour Daily depuis la bdd et la met dans la variable createCall, pas besoin de ça avec vonage
    // const createCall = useCallback(() => {
    //     setAppState(STATE_CREATING);
    //     return api
    //         .createRoom()
    //         .then((room) => room.url)
    //         .catch((error) => {
    //             console.error('Error creating room', error);
    //             setRoomUrl(null);
    //             setAppState(STATE_IDLE);
    //             setApiError(true);
    //         });
    // }, []);



    // lance hair check pour choisir source audio video Daily
    // const startHairCheck = useCallback(async (url) => {
    //     const newCallObject = DailyIframe.createCallObject();
    //     setRoomUrl(url);
    //     settokendayli(url)
    //     setCallObject(newCallObject);
    //     setAppState(STATE_HAIRCHECK);
    //     await newCallObject.preAuth({ url }); // add a meeting token here if your room is private
    //     await newCallObject.startCamera();
    //
    // }, []);

    //rejoin l'appel Daily
    // const joinCall = useCallback(() => {
    //     callObject.join({ url: roomUrl });
    // }, [callObject, roomUrl]);


    //quitter l'appel Daily
    // const startLeavingCall = useCallback(() => {
    //     if (!callObject) return;
    //
    //     if (appState === STATE_ERROR) {
    //         callObject.destroy().then(() => {
    //             setRoomUrl(null);
    //             setCallObject(null);
    //             setAppState(STATE_IDLE);
    //         });
    //     } else {
    //         setAppState(STATE_LEAVING);
    //         callObject.leave();
    //     }
    // }, [callObject, appState]);


    //pour Daily
    // useEffect(() => {
    //     const url = roomUrlFromPageUrl();
    //     if (url) {
    //         startHairCheck(url);
    //     }
    // }, [startHairCheck]);


    // useEffect(() => {
    //     const pageUrl = pageUrlFromRoomUrl(roomUrl);
    //     if (pageUrl === window.location.href) return;
    //     window.history.replaceState(null, null, pageUrl);
    // }, [roomUrl]);

    // si arrivé en fin de session, ferme le call
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (!goToCall) {
    //             return
    //         }
    //
    //         let rest = moment.utc(startDate).add(seancetime, "minutes").diff(moment.utc(), 'minutes')
    //         if (rest < 0) {
    //             startLeavingCall()
    //         }
    //     }, 5000);
    //
    //     return () => clearInterval(intervalId);
    // }, [goToCall, startDate, seancetime]);


    // useEffect(() => {
    //     if (!callObject || !checkconsultation) {
    //         return
    //     }
    //
    //     const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];
    //
    //     function handleNewMeetingState() {
    //         switch (callObject.meetingState()) {
    //             case 'joined-meeting':
    //
    //                 setAppState(STATE_JOINED);
    //                 playAudio()
    //
    //
    //
    //                 break;
    //             case 'left-meeting':
    //                 callObject.destroy().then(() => {
    //                     setRoomUrl(null);
    //                     setCallObject(null);
    //                     setAppState(STATE_IDLE);
    //
    //                     //remove url room and add info
    //                     const thistokne = tokendayli.split('/').pop();
    //
    //                     remove.removeRoom(thistokne).then((room) => console.log(room));
    //
    //                     let paramsDispatch={
    //                         token:user.api_token,
    //                         api_token:user.api_token,
    //                         id:checkconsultation?.id
    //                     }
    //                     dispatch(setFinishedCall(paramsDispatch))
    //
    //                     let params2 = {
    //                         token:user.api_token,
    //                         api_token:user.api_token,
    //                         patients:patients,
    //                         action:'valide'
    //                     }
    //
    //                     apiClient().post(`/set/reservation`, params2).then((res) => { history.push('/fin-consultation/therapeut/'+checkconsultation?.id)  })
    //                 });
    //                 break;
    //             case 'error':
    //                 setAppState(STATE_ERROR);
    //                 console.log('error hir')
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    //
    //     handleNewMeetingState();
    //
    //     events.forEach((event) => callObject.on(event, handleNewMeetingState));
    //
    //     return () => {
    //         events.forEach((event) => callObject.off(event, handleNewMeetingState));
    //     };
    // }, [callObject, checkconsultation]);



    // const renderApiError = () => {
    //     return (
    //         <div className="api-error">
    //             <h1>Error</h1>
    //             <p>
    //                 Room could not be created.
    //             </p>
    //         </div>
    //     );
    // }


    // const renderHairCheck = () => {
    //     return (
    //         <DailyProvider callObject={callObject}>
    //             <HairCheck joinCall={joinCall} cancelCall={startLeavingCall} />
    //         </DailyProvider>
    //     );
    // }


    // const renderCall = () => {
    //     return (
    //         <DailyProvider callObject={callObject}>
    //             <Call />
    //             <Tray leaveCall={startLeavingCall} />
    //         </DailyProvider>
    //     );
    // }


    const renderApp = () => {

        return (
            <ErrorBoundary>
                <Container className='listtherapeutes'>
                    <div className='row'>
                        <Col md={6} >
                            <div className='therapeutescontent'>

                                <div className="home-screen">
                                    <h1>React Daily Hooks custom video app</h1>
                                    <div className='alert alert-warning' style={{width: '100%'}}>
                                        <div style={{  }}>
                                            <div style={{marginBottom:'10px'}}></div>
                                            <strong>Votre prochaine consultation commence
                                                {
                                                    startcall ? <span> Maintenant</span>
                                                        : <span> dans
                                                            {
                                                                timecall > 1000 * 60 * 60 * 24
                                                                    ? <span> plus d'un jour</span>
                                                                     : <Countdown date={Date.now() + timecall} onComplete={ ()=>setstartcall(true)} renderer={renderer} />
                                                            }
                                      </span>

                                                }
                                            </strong>
                                            <br/> <br/>
                                            <div style={{marginBottom:'20px'}}></div>

                                            <div className='js889' >
                                                <input type='checkbox' name='adolesant' value='1'  />
                                                <span style={{paddingLeft: '8px'}}>J’ai pris connaissance du dossier patient</span>
                                            </div>

                                            <div style={{marginBottom:'20px'}}></div>
                                            {
                                                startcall ?
                                                    <a href="javascript:void(0)" onClick={startDemo} className="btnlink marauto">Cliquez pour démarrer la consultation <i className="fa  fa-video-camera"></i></a>
                                                    :
                                                    <a href="javascript:void(0)" style={{    background: '#ccc',
                                                        cursor: 'none'}}  className="btnlink marauto">Cliquez pour démarrer la consultation <i className="fa  fa-video-camera"></i></a>
                                            }
                                        </div>
                                        <div style={{marginBottom:'10px'}}></div>


                                    </div>


                                    <p className="small">Sélectionnez "Autoriser" pour utiliser votre caméra et votre micro pour cet appel si vous y êtes invité.</p>
                                </div>
                            </div>



                        </Col>



                        <Col md={1} >

                        </Col>




                        <Col md={5} >

                            {/*  lister les patients */}

                            <div className='therapeutescontent'>

                                <Row className=''>
                                    <Col md={12} >

                                        <div className=' row '>
                                            <div className="text-center flowpriceheader"><h2>{ patients.length > 1 ? " Les patients" :"Patient"}</h2></div>
                                        </div>
                                        <div className=' row ps-block--site-features ps-block--site-features-2' style={{marginBottom:'30px'}}>

                                            {
                                                patients?.map((item,index)=>(

                                                    <div className='col-md-6' key={item.id}>

                                                        <div className='itemimg ' style={{backgroundImage:item?.patient?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+item?.patient?.picture})` : "url('/images/default-profile.png')",width: '90px',margin:'0 auto',
                                                            height: '90px'}}> </div>
                                                        <h3 className='title'>{item?.patient?.name}</h3>

                                                    </div>


                                                ))
                                            }

                                        </div>



                                        <div className='therapeutescontent'>

                                            <div className='flexrowtarifs row'>


                                                <div className=' col-md-12'>
                                                    <div className="text-center flowpriceheader"><img src={checkconsultation?.tarif?.image}/><h2>{checkconsultation?.tarif?.titre}</h2></div>
                                                </div>

                                                <div className='item'>



                                                    <div className='pricebox' style={{boxShadow: 'none'}}>
                                                        <h3>{checkconsultation?.formule?.titre}</h3>
                                                        <h4>{parseFloat(checkconsultation?.formule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                                                        <div className="pricebox-border2"> </div>
                                                        <div className='ttcontent'>
                                                            <ul className='col-9028-K'>

                                                                <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.temps} min</li>
                                                                <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.seance} {checkconsultation?.formule?.seance > 1 ? 'séances' :'séance'}</li>
                                                                <li><i className='fa fa-check-circle icochecked'></i> {checkconsultation?.formule?.pack}</li>

                                                            </ul>



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>





                                    </Col>
                                    <Col md={3} >

                                        <div className='actionrdv'>

                                            <div className="text-center" >



                                            </div>

                                        </div>

                                    </Col>

                                </Row>



                            </div>


                            {/*  End */}

                        </Col>



                    </div>
                    <audio ref={audioPlayer} src={soundnotification} />
                </Container>

            </ErrorBoundary>
        );
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return ( <span>"Maintenant"</span>)
        } else {
            return (<span> {hours}h:{minutes}m:{seconds}s</span>)
        }
    };



    return (
        <div className="home dayli" >
            <section className={showCall ? "section2 colmin ":"section2" } >
                {renderApp()}
            </section>
        </div>
    );
}

export default Page