import React, { useState } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";

const Page = () => {


    return (
    <div>
            <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Comment ça marche
 </li></ul></div></div></div>
        <div className="commentcamarche" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                       <h1 className="text-center mb-5"> Comment ça marche </h1>
                        <span className="section-separator"></span>
<br/><br/>

<h3 className="text-center mb-5">
Nous connectons des millions d'acheteurs et de vendeurs à travers le monde, responsabilisant les gens et créant des opportunités économiques pour tous.</h3>
                       

 
                    </Col>
                </Row>
            </Container>
        </div>
<div className=''>
 <section className='section387'>
              <Container>
                
       

                 <div className="ps-block--site-features ps-block--site-features-2">
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><i style={{color:'#000'}} className="icon-rocket"></i></div>
                        <div className="ps-block__right">
                            <h4 className="text-center">Petit frais de service</h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><i style={{color:'#000'}} className="icon-sync"></i></div>
                        <div className="ps-block__right text-center">
                            <h4 className="text-center">Un panier unique pour tous vos achats</h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><i style={{color:'#000'}} className="icon-credit-card"></i></div>
                        <div className="ps-block__right">
                            <h4 className="text-center">économisez sur l'envoi </h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    
                </div>
              </Container>
            </section>

            <div className="ps-vendor-banner bg--cover ps-vendor-banner89"  style={{background: "url('/images/vendor.jpg')",    maxHeight: '340px'}}>
            <div className="container">
                <h2>Des millions d'acheteurs ont hâte de voir ce que nous avons en magasin</h2><a href='#' className="ps-btn ps-btn--lg" href="#">Trouver un produit</a>
            </div>
        </div>
        </div>
    </div>
    );
}

export default Page;