import React from "react";
import './styles.scss';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";


const Component = ({ favori,item,onClick, }) => {
    const history = useHistory();
    const {user} = useSelector(state => state.user)
 
    const handleClick = () => {

          //history.push(`/boutique/produit/${item.product_slug}/${item.product_id}`);
    }

    return (
      
                <div className="clientSayWrapper" onClick={handleClick}>
                            <div className='clientSay'>

                              <i className="fa fa-quote-left"></i>
                              <div className="simple-article normall">
                                  <p>{item?.text}</p>
                              </div>

                            </div>
                            <div className="sayPersone">
                                <img src={"https://feel-better-company.com/gestion/public/slides/"+item?.img} className="attachment-66x66 size-66x66 wp-post-image" style={{    width: '66px',
    height: '66px'}} alt=""/>
                              <div class="personeInfo">
                                 <p style={{color: '#35cdd7'}}>{item?.title}</p>
                        
                               </div>
                            </div>
                 </div>
   
    );
}

export default Component;