import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import { apiClient } from "../../actions/api"

const Page = () => {
     const dispatch = useDispatch();
     const history = useHistory()
     const queryParams = new URLSearchParams(window.location.search);

     const [show,setShow]=useState(false);
     const [articles,setarticles]=useState([]);
   
     const { data,pageinfo } = useSelector(state => state.mixedmode);
 
 

    useEffect(()=>{
     
          apiClient().get(`/blogs`,{} )
           .then((res) => {
          
              if(res.data){
                
                    setarticles(res.data)
              }
              else{
              
               
            

              }
              
           })


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (id) => {
      
         
         history.push('/article/'+id)
        

    }
 
 const goTo = () => {
      
       
     history.push('/list-psychologue')

    }
    return (
        <div className="home tarifs tests articles" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Tests</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3"> Nos articles <br/><span> </span> </h2>
                     
      
                </Container>
            </section>

      

             <section className="section2 section " >
                <Container>
                    
                  

                   <Row className="ps-block--site-features ps-block--site-features-2">

                      {
                        articles?.map((item,key)=>(

                       <Col md={4} key={key}>

                        <div className="ps-block__item pocket-shadow noshadowbox" >
                          <div className="ps-block__left text-center" style={{minHeight: '215px'}}><img src={'https://feel-better-company.com/gestion/public/articles/'+item?.img}/></div>
                          <div className="ps-block__right">
                          <h4 className="text-center" style={{    minHeight: '65px'}}>{item?.titre}</h4>
                          
                          </div>
                            <div className="text-center" >
                            <br/>

                                   <a href='javascript:void(0)' onClick={()=>handleShow(item.id)}  className='btnlink marauto' >Lire la suite <i className='fa fa-long-arrow-right'></i></a>

                             </div>
                          </div>
                         
                      </Col>


                        ))
                      }

              
                   </Row>

       


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 
 



 
        </div>
    );
}

export default Page;