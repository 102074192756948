import { useMediaTrack } from '@daily-co/daily-react';
import { memo, useEffect, useRef, useState } from 'react';


const margin = 6
const getLocalTileOffset = (videoElement, mainVideoDisplay) => {
  const { offsetWidth, offsetHeight } = videoElement.current

  if (offsetHeight < mainVideoDisplay.borderY - margin * 2 || offsetWidth < mainVideoDisplay.borderX - margin * 2) {
    return {right: margin, bottom: margin}
  }

  return { right: margin + mainVideoDisplay.borderX, bottom: margin + mainVideoDisplay.borderY }

}

const TileVideo = memo(({ id, isScreenShare, onVideoElement, isLocal, mainVideoDisplay }) => {
  const videoTrack = useMediaTrack(id, isScreenShare ? 'screenVideo' : 'video')
  const videoElement = useRef(null)

  // NOTA - react ne semble pas gérer correctement sur certain device les updates de la référence sur videoElement
  //      - les deux variables suivantes ne sont pas utilisées, mais le refresh est nescessaire
  const [isVideoElementReady, setIsVideoElementReady] = useState(false)
  const [forcedRefresh, setForcedRefresh] = useState(false)

  useEffect(() => {
    const video = videoElement.current;
    if (!video || !videoTrack?.persistentTrack) return;
    /*  The track is ready to be played. We can show video of the participant in the UI. */
    video.srcObject = new MediaStream([videoTrack?.persistentTrack]);
  }, [videoTrack?.persistentTrack]);

  useEffect(() => {
    onVideoElement(videoElement)
  }, [videoElement])




  const checkVideoGetSize = () => {
    if (videoElement?.current?.videoWidth) {
      setIsVideoElementReady(true)
    }
  }


  // TODO - NOTA, setInterval semble avoir des soucis sur certain device ? Ajout de forcedRefresh
  useEffect(() => {
    const intervalId = setInterval(checkVideoGetSize, 1000)
    return () => clearInterval(intervalId)
  }, []);
  useEffect(() => {
    let id = setTimeout(() => {
      setForcedRefresh(true)
    }, 5000)
    return () => clearTimeout(id)
  }, [videoElement]);


  const resolveSize = (isLocal, mainVideoDisplay, videoElement) => {
    if (!isLocal) {
      return {}
    }

    if (!videoElement?.current?.videoWidth) {
      return { width: 0, height: 0 }
    }

    let ratio = videoElement.current.videoWidth / videoElement.current.videoHeight

    let style = { width: videoElement.current.offsetHeight * ratio }

    if (mainVideoDisplay ) {
      style = Object.assign(style, getLocalTileOffset(videoElement, mainVideoDisplay))
    }

    return style
  }



  if (!videoElement) return null;

  return <video autoPlay muted playsInline ref={videoElement} style={resolveSize(isLocal, mainVideoDisplay, videoElement)} />;
});

export default TileVideo;
