import './Username.css';
import { useParticipantProperty } from '@daily-co/daily-react';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"

export default function Username({ id, isLocal }) {
  const username = useParticipantProperty(id, 'user_name');
    const { user } = useSelector(state => state.user);

  return (
    <div className="username" style={{display:'none'}}>
     {username || id} {isLocal && '(Vous)'}
    </div>
  );
}
