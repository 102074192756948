
async function createRoom() {
  const exp = Math.round(Date.now() / 1000) + 60 * 135;
  const options = {
    properties: {
      exp,
      sfu_switchover: 0.5
    },
  };

  const isLocal = 'local';
  const endpoint = isLocal
    ? 'https://api.daily.co/v1/rooms/'
    : `${window.location.origin}/api/rooms`;

  const headers = isLocal && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer f6e45fa1ddfe170bee670a03407db0a342aeda1bca536d516da85b98abb15e3f`, // TODO - normal ?
    },
  };

  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(options),
    ...headers,
  });

  return response.json();
}

export default { createRoom };
