import React from "react";
import './styles.scss';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { defaultimg } from "../../assets/images";
import { BASE_URL_PROD } from "../../components/config/keys";


const Component = ({ favori,item,onClick, }) => {
    const history = useHistory();
    const {user} = useSelector(state => state.user)
 
    const handleClick = () => {
           let categ= item.categorie== 'fashion' ? 1: 2
           history.push(`/boutiques/${item.nomboutique}/${item.user_id}/${categ}`);

            
    }

    return (
        <div className="ps-product" onClick={handleClick}>
                            <div className="ps-product__thumbnail"><a href="javascript:void(0)">
                              <img src={item.picture ? BASE_URL_PROD+"/gestion/public/uploads/"+item.picture : "/images/126122.png"  } alt=""/>
                              </a>
                             {
                                favori== 1 &&
                                <div className='favoris'><i className='fa fa-heart' ></i></div>
                             }
                              
                            
                            </div>
                            <div className="ps-product__container">
                                <div className="ps-product__content"><a className="ps-product__title" href="javascript:void(0)">{item.nomboutique}</a>
                                   
                                </div>
                               


                            </div>
                               <div>
                                 <a href="javascript:void(0)" className='add-to-cart'>Voir boutique</a>

                                </div>
                        </div>
    );
}

export default Component;