import React, { useState, useEffect } from "react";
import {  GET_DATA_CART,GET_TOTAL_CART,OBJECT_REDUCTION,VIDER_PANIER ,ADD_EXPIDITION ,ADD_MODEEXPIDITION} from "../../constants/actions";

import './styles.scss';
import { useSelector,useDispatch } from "react-redux"
import { Container, Row, Col, Form, Table, Button, Stack,Modal,CloseButton  } from "react-bootstrap"
import { BasketItem } from "../../components";
import { getInfoUserById,getInfoAdresseById} from "../../actions/user";
import { getRestaurentHoraires} from "../../actions/restaurent";
import { BiPlus } from 'react-icons/bi';
import { apiClient } from "../../actions/api"
import { useHistory } from "react-router-dom";
import { getSettings} from "../../actions/mixedmode";
import moment from 'moment'
 
const Page = () => {
    
    const { data,total,reduction ,expidition} = useSelector(state => state.basket)
    //const { settings} = useSelector(state => state.mixedmode)
    const[state, setState]=useState('');
    const [soustotal, setSoustotal] = useState(0);
    const [bonreduction, setBonreduction] = useState("");
    const [heurelivraison, setHeurelivraison] = useState("");
    const [comment, setComment] = useState("");
    const [asap, setAsap] = useState(false);
    const [settings, setsettings] = useState({});
    const [fraislivraison, setFraislivraison] = useState(0);
    const [showcities, setShowcities] = useState(false);
     const [citie, setCitie] = useState('Chronopost');
    const [citieblock, setCitieblock] = useState('Chronopost');

    const [colosimo, setcolosimo] = useState(0);
    const [relais, setrelais] = useState(0);
    
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const [selectadresse, setSelectadresse] = useState(0);
  
    const [valuereduction, setValuereduction] = useState(0);
    const [totalwithoutreduction, setTotalwithoutreduction] = useState(0);
    const [idresto, setIdresto] = useState(0);
    const [heures, setHeures] = useState([]);
 
    const { addresseslist } = useSelector(state => state.restaurent)
    const { horaires } = useSelector(state => state.restaurent)
    const [name, setName] = useState(addresseslist?.name);
    const [surname, setSurname] = useState(addresseslist?.surname);
    const [city, setCity] = useState(addresseslist?.city);
    const [pays, setPays] = useState(addresseslist?.state);
    const [codep, setCodep] = useState(addresseslist?.postal_code);
    const [phone, setPhone] = useState(addresseslist?.phone_number);
    const [street, setStreet] = useState(addresseslist?.street);
    const [isfreelivraison, setIsfreelivraison] = useState(true);
    const [counter, setCounter] = useState('')
    const [iscalculat, setIscalculat] = useState(false);
     const [modeexpidition, setModeexpidition] = useState('');
    const [typeCommande, setTypeCommande] = useState(1);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');


     const handleClose = () => setShowcities(false);
     const handleCloseAlert = () => setShowAlert(false);


     const handleShow = () => setShowcities(true);

    useEffect(() => {

      
        
           
            //setcolosimo(settings.site_m2)
            //setrelais(settings.site_m1)

              setValuereduction(reduction.discount);
              setBonreduction(reduction.voucher);
              setTotalwithoutreduction(total)

            
            let type_commande=1;
            if(data?.length){
                type_commande=data[0]?.product?.rubrique;
                setTypeCommande(type_commande)

            }

            if(type_commande==4){

            }
            else{

                 apiClient().get(`/settings`)
                .then((res) => {
                   
                       setsettings(res.data.page)
                       setCounter(1)
                       
                    
                })

           

            
            }

       
          // dispatch(getSettings())
      
    }, []);


useEffect(() => {
   if(counter){
        setCitie('Chronopost')
        setCitieblock('Chronopost')
        refreshexpidition('Chronopost')
        setCounter('')
   }
    
}, [counter]);


    const changeInchild = function() {  
        dispatch({ type: GET_TOTAL_CART }) 
        setCounter(1)
         
              
    }

       const setModeexpiditionfun = function(t) {  
        if(t==1){
               setModeexpidition('Aérienne')
        }
        else{
               setModeexpidition('Maritime')
        }
         
        let fraislivraison=getExpedition(t)
        dispatch({ type: ADD_EXPIDITION,payload:fraislivraison })
        dispatch({ type: ADD_MODEEXPIDITION,payload:t==1 ? "Aérienne" : "Maritime"})
        setFraislivraison(fraislivraison)
      
         
              
    }

    const isfraislivraison = function() {  
 
         // calcule expedition for Fashion & afrocreation
            if( parseFloat(total) >=60){
               setIsfreelivraison(true)

            }
            else{
               setIsfreelivraison(false)
            }
          
         // end       
    }


 
      const viderpanier = () => {
      dispatch({ type: VIDER_PANIER })
      dispatch({ type: GET_TOTAL_CART })  
      };


      const removecodepromo = (e) => {

       setValuereduction(0);
       const datareductionstore = {
                    voucher: '',
                    discount:0
        };   
       dispatch({ type: OBJECT_REDUCTION,payload:datareductionstore })

     }
 
      const appliquerReduction = () => {

        if(bonreduction==''){
           return false;
        }

        if(!user.user_id){

        
             setAlertMessage("Veuillez vous connecter pour utiliser le code promotionnel !")
                   setShowAlert(true)
           return false;

        }

        if(total < 30){


         
           setAlertMessage("Bon d’achat disponible pour les commandes minimum de 30€")
           setShowAlert(true)
           return false;


        }

          const paramsd = {
            gift_code: bonreduction,
            total: total,
            id:user.user_id,
           
           };   
            apiClient().post(`/codeReduction`,paramsd)
            .then((res) => {
               
               if (res.data.success == 'ko') {
                   
                   setAlertMessage("Le code promotionnel spécifié est inconnu ou n'est plus disponible !")
                   setShowAlert(true)
               }else if(res.data.success == 'ok'){

                let percent=res.data.reduction.gift_amount;
               // percent=total - (total * parseInt(percent) / 100) 
               

                setValuereduction(percent);
                let totalinitial=total;
                 
                setTotalwithoutreduction(totalinitial);
                const datareductionstore = {
                    voucher: res.data.reduction.gift_id,
                    discount:percent
                   };   
                dispatch({ type: OBJECT_REDUCTION,payload:datareductionstore })
                
                 setAlertMessage("votre code promo est bien été appliqué !")
                 setShowAlert(true)
               }
            })
           }
          const   updateInputValue=(evt)=>{
              setBonreduction(evt.target.value)
          }
         const   updateAsap=(evt)=>{
            setAsap(evt.target.checked)
         }
         const   updateHeure=(evt)=>{
            setHeurelivraison(evt.target.value)
         }
         const   updateindexaddresse=(evt)=>{
            setSelectadresse(evt.target.value)
         }
         const   updatecomment=(evt)=>{
            setComment(evt.target.value)
         }

        const handleFinalizeCommande = (e) => {
 e.preventDefault();

 
             // check if expedition is calculed

             let continues=true

             if(typeCommande==4){

            

             }
             else{

                  if( citie=="" ){
                     
                          setAlertMessage("Veuillez choisir un mode de livraison !")
                          setShowAlert(true)
                          continues=false;
                         return false;

                     }

             }
                     
                    

                  //


        

            if(user.user_id){

                  history.push("/finalisez-commande");
             }
             else{
                history.push(`/login/?back=finalisez-commande`);
             } 

         



             
           
        }

             const savecommande=(payement_inten_id)=>{
            let new_street=addresseslist?.street;
            if(street!==undefined){
                new_street=street;
            }
            let new_name=addresseslist?.name;
            if(name!==undefined){
                new_name=name;
            }
            let new_codep=addresseslist?.postal_code;
            if(codep!==undefined){
                new_codep=codep;
            }
            let new_city=addresseslist?.city;
            if(codep!==undefined){
                new_city=city;
            }
            let new_pays=addresseslist?.state;
            if(pays!==undefined){
                new_pays=pays;
            }
            let new_phone=addresseslist?.phone_number;
            if(phone!==undefined){
                new_phone=phone;
            }
            let new_surname=addresseslist?.surname;
            if(surname!==undefined){
                new_surname=surname;
            }
            if(new_street==undefined || new_name==undefined || new_codep==undefined ||
                 new_city==undefined || new_pays==undefined || new_phone==undefined || new_surname==undefined){
                setAlertMessage("Merci de remplir tous les champs")
                setShowAlert(true)

               
            }
            const addressobjet={
            "street":new_street,
            "name":new_name,
            "postal_code":new_codep,
            "city":new_city,
            "state":new_pays,
            "phone_number":new_phone,
            "surname":new_surname
            };
       
            const params = {
                user_id: user.user_id,
                payement_inten_id:payement_inten_id,
                details: comment,
                products: data,
                fraislivraison:fraislivraison,
                total_price:parseFloat(parseFloat(total+fraislivraison)).toFixed(2),
                client_name:user.name,
                client_phone:user.phone,
                client_email:user.email,
                api_token:user.api_token,
                address:addressobjet,
                token:user.api_token
              };
             
              apiClient().post(`/submitorder`,params)
              .then((res) => {
                dispatch({ type: VIDER_PANIER })
                dispatch({ type: GET_TOTAL_CART })  
                history.push("/commandes");
              })
          
        }



       const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let citie=formDataObj.citi;
        setCitie(citie)
        setCitieblock(citie)
        localStorage.setItem('citie', citie);

        refreshexpidition(citie)
     
     
    
    }


       const refreshexpidition = (citie) => {



      
       // calcule expedition for Fashion & afrocreation

       if(citie){
            // check if abidjan

           
                if( parseFloat(total) >= 60){
                               setIsfreelivraison(true)
                               dispatch({ type: ADD_EXPIDITION,payload:0 })
                               setFraislivraison(0)
                               return false;

                }
            
 


        setIsfreelivraison(false)

    
        setCitie(citie)
        setCitieblock(citie)
        localStorage.setItem('citie', citie);
        let fraislivraison=0;
        let qte=0;

        // calculer frais expedition

        if(citie=='Colissimo à Domicile'){
           fraislivraison=settings.site_m2
        }
        else{
          
          fraislivraison=settings.site_m1
        }
  

         
       

         dispatch({ type: ADD_EXPIDITION,payload:fraislivraison })
         setFraislivraison(fraislivraison)
         dispatch({ type: ADD_EXPIDITION,payload:fraislivraison })
         setShowcities(false)


       }
        
     
        

        
       // end  
     
    
    }


 const getExpedition =  (type) => {
      let somme=0;
       for(let i=0; i < data?.length; i++){
           
           if(type==1){
              somme=somme+data[i].product?.arienne
           }
           else{
             somme=somme+data[i].product?.maritime
           }

 

      }

      return parseFloat(somme).toFixed(2);

}



    return (
        <div className="panier" style={{paddingBottom:'0px'}} >
         <div className="ps-breadcrumb">
             <Container>
                <div className="ps-container">
                    <ul className="breadcrumb">
                        <li><a href="#" onClick={()=>history.push('/')}>Accueil</a></li>
                       
                        <li>Panier </li>
                    </ul>
               </div>
                </Container>
             </div>

            <section className="section2" style={{backgroundColor:'#FFF',paddingTop: '25px'}}>


            {
                data?.length >0 ? 

                <Container>
            <br/>
                   <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Mon panier </h2>
                           </div>
                        </div>
            </div>

                <Row className="g-5" >
                    <Col md={8} className='table-responsive'>
                    <table className="table ps-table--shopping-cart ps-table--responsive">
                    <thead>
                                <tr>
                                    <th>Produit</th>
                                    <th>Prix</th>
                                    <th>Quantité</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>

                           
                   
                         
                            {data.map((item) => (
                            <BasketItem  item={item} changeInchild={changeInchild}  />
                            ))}
                         

                        
                  </table>

                  <br/>
                  <div className="form-group--nest"><input className="form-control" type="text" placeholder="Saisissez un code promo" onChange={evt => updateInputValue(evt)} /><button className="ps-btn" onClick={appliquerReduction} >Appliquer</button></div>
                  </Col>

                 
                    <Col md={4} className=" mx-auto my-5" >
                        

                            <div className="ps-block--shopping-total">

                             <div className="ps-block__header">
                                    <p>Sous-total <span> { parseFloat(total).toFixed(2)} €</span></p>
                             </div>

                                {valuereduction > 0 &&
                                    <div className="ps-block__header">
                                    <p style={{    marginBottom: 0}}>Bon de réduction <span> -{valuereduction}  €</span></p>
                                    <p><a style={{textDecoration: 'underline'}} href='javascript:void(0)' onClick={(e)=>removecodepromo(e)} >Supprimer</a></p>
                                    </div>
                                 }

                                
                             
                                <div className="ps-block__content">

                          

                                <div>
                                 
                              
                                  <div className="ps-block__header" style={{paddingBottom:'11px'}}>
                                    <p >Mode de livraison   <span>

                                      {
                                        citie ? <a   href='javascript:void(0)' style={{textDecoration:'underline', color:'#000'}}>{citie}</a> : <a  href='javascript:void(0)' style={{textDecoration:'underline', color:'#000'}} >Choisir</a>
                                      }


                                    </span> </p>

                                    <div style={{display:'flex',justifyContent: 'space-between',
    alignItems: 'center'}}>


                                      

                                    </div>
                   
                                   
                                    </div>
                           

                                  </div>

                             

                                     <div>
                                 {
                                   isfreelivraison ?
                                    <div className="ps-block__header" >
                                    <p >Expédition  <span> { parseFloat(fraislivraison).toFixed(2)} €</span>    {/*citie=='' ? <span onClick={handleShow} style={{    textDecoration: 'underline',cursor: 'pointer'}}> Calculer</span> :  <span> { parseFloat(fraislivraison).toFixed(2)} €</span>   */}
                              

                                    </p>
                   
                                   
                                </div>

                                    :
                                <div className="ps-block__header" >
                                    <p >Expédition  <span> { parseFloat(fraislivraison).toFixed(2)} €</span>    {/*citie=='' ? <span onClick={handleShow} style={{    textDecoration: 'underline',cursor: 'pointer'}}> Calculer</span> :  <span> { parseFloat(fraislivraison).toFixed(2)} €</span>   */}
                                    :  

                                   {
                                    /*
                                        citie!='' &&
                                       <div onClick={handleShow} style={{    textDecoration: 'underline',cursor: 'pointer'}}> {citie} </div>
                                    */
                                    }

                                    </p>
                   
                                   
                                </div>
                                    
                                 }

                                </div>


                                

                                
 

                                {valuereduction > 0  ?

                                  <h3>Total <span className={"prix-barre"} style={{paddingLeft:'5px'}}>{ (parseFloat(totalwithoutreduction) + parseFloat(fraislivraison)).toFixed(2) } € </span> <span> { ((parseFloat(total)-parseInt(valuereduction)) + parseFloat(fraislivraison)).toFixed(2)} €</span></h3>
                                :
                                 <h3>Total<span>{ (parseFloat(total) + parseFloat(fraislivraison)).toFixed(2)} €</span></h3>

                                }

                                </div>

                               


                            </div>

                            
                                    <div className="form-group fn7789">
                                     
                                        <a className="ps-btn ps-btn--black" href="javascript:void(0)" onClick={(e)=>handleFinalizeCommande(e)}>Finalisez votre commande</a>
                                    </div>

                           
                           

      

                    </Col>



<Col md='12'>

      <section className='section2' style={{background:'#FFF',marginTop: '82px'}} >
          <div className="ps-site-features">
            <div className="container">
                <div className="ps-block--site-features ps-block--site-features-2">
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-rocket" style={{color:'#46c3cc'}}></i></div>
                        <div className="ps-block__right">
                            <h4>Livraison offerte </h4>
                            <p>à partir de 60€ d’achat</p>
                        </div>
                    </div>
    
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-credit-card" style={{color:'#46c3cc'}}></i></div>
                        <div className="ps-block__right">
                            <h4>Paiement sécurisé</h4>
                            <p>Paiement 100% sécurisé</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-bubbles" style={{color:'#46c3cc'}}></i></div>
                        <div className="ps-block__right">
                            <h4>Assistance  24/7</h4>
                            <p>Service client dédié </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         </section>
</Col>

 

                </Row>
                
                <Row className="g-5 " className={`task ${data?.length != 0 ? "hide-div" : ""}`} >
                <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                <h3 className={"vide-phrase"} style={{display:'none'}}>Votre panier vide</h3>
                </Col>
                </Row>


            <Row className="g-5 " >
                <Col md={8} className=" mx-auto my-5" >

                  
 
                </Col>
                

                </Row>



                {
                  /*
                   
                                  <Row className="g-5 mt-4" className={`task ${data?.length == 0 ? "hide-div" : ""}`}>

                    <Col lg={{ span: 5 }} className=" mx-auto my-5" >


                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label  onClick={ajouterAddresse}>
                                Choisir une Adresse de livraison : <BiPlus color={"#FF914D"} size={20} />
                            </Form.Label>
                            <Form.Select aria-label="Default select example" onChange={evt => updateindexaddresse(evt)}>
                              <option value="">Choisir une Adresse de livraison</option>
                                {addresseslist.addresses?.map((item,index) => (
                                <option value={index}>{item.address}</option>
                                ))}
                             
                            </Form.Select>

                            <Form.Label className=" my-3">
                                Heure souhaitée:
                            </Form.Label>
                            <Form.Select aria-label="Heure souhaitée" onChange={evt => updateHeure(evt)}>
                            {heures?.map((item) => (
                                <option value={item.value}>{item.label}</option>
                                ))}
                            </Form.Select>


                            <Row className="mb-3 my-3">
                                <Form.Group >
                                    <Form.Check 
                                        value={1}
                                        type="checkbox" label="Me livrer le plus vite possible" onChange={evt => updateAsap(evt)} name="profile_id" defaultChecked={asap} />
                                </Form.Group>

                                <Row  >
                                       <span>Même si vous cochez l'option "Me livrer le plus vite possible" choisissez bien une heure de livraison.</span>
                                </Row>
                            </Row>

                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label> Notes de la commande :</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={evt => updatecomment(evt)} />
                            </Form.Group>

                            
                            <Elements stripe={stripe}>
                              <CheckoutForm adresseliv={selectadresse} savecommande={savecommande} heurelivraison={heurelivraison} total={total < 50 ? parseFloat(parseFloat(total)+parseFloat(3.20)-valuereduction).toFixed(2): parseFloat(parseFloat(total)+parseFloat(4.80)-valuereduction).toFixed(2)} />
                            </Elements>
                        </Form.Group>


                    </Col>
                </Row>


                  */
                }
               
               
  <Modal show={showcities} onHide={handleClose} centered>
        <Modal.Header closeButton>
           
        </Modal.Header>

       
        <Modal.Body>

        <p>  Sélectionnez votre mode de livraison </p>

        <div style={{paddingLeft:'30px', paddingRight:'30px'}}>

        <form onSubmit={handleSubmit} >
           
           <select className='form-control' name='citi' value={citieblock}  onChange={evt => setCitieblock(evt.target.value)} >
              
                   <option  value='Point Relais'>Point Relais</option>
                   <option  value='Colissimo à Domicile'>Colissimo à Domicile</option>
           
          

           </select>


           <Button type='submit' style={{color:'#FFF',width: '100%',marginTop: '20px',marginBottom: '20px'}} className='ps-btn ps-btn--black'  >
           Mettre à jour
          </Button>



        </form>

        </div>


        </Modal.Body>
    
       
 </Modal>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>



            </Container>





        :


       <div>

         <Container>

          <br/>
                   <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Mon panier </h2>
                           </div>
                        </div>

                        <div className='center'>

                            <h1 className='alert alert-warning' style={{color:'#000'}}>Votre panier est vide !</h1>
                        </div>
                   </div>


        </Container>

       </div>


            }

            </section>
            
        </div>

    );
}

export default Page;
 