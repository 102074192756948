import React,{ useEffect,useState,useRef }  from "react";
import './styles.scss';

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux"

import { defaultimg } from "../../assets/images";
import { Nav,Card} from "react-bootstrap";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiUser,BiLogInCircle,BiHeart,BiMoney,BiCalendarCheck ,BiFolderPlus,BiCog,BiMessageRoundedDots,BiAward,BiFolderOpen} from 'react-icons/bi';
import { LOGOUT_USER ,SET_FORMULE} from "../../constants/actions";
import { changepicture } from "../../actions/mixedmode";

const Component = ({ slug }) => {
    const history = useHistory();
    const {user} = useSelector(state => state.user)
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const fileInput = useRef(null)

     useEffect(() => {
          
        if (!user.user_id || user.google_id!='A') {
           history.replace("/")
        }

        
                if (user?.active===0) {
                      history.replace('/client/validation/') 
                }
               

           

    }, [user.user_id])

    const handleClick = (src) => {
         history.push(src);
    }
       const handleLogout = () =>{
        dispatch({ type: SET_FORMULE, payload: {} })
        dispatch({type:LOGOUT_USER});

    }

  const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      console.log("formData",formData)
      dispatch(changepicture(formData));
     
  }



 



    return (

      <div className='menudash'>

      <div onClick={()=>setValue(!value)} className="mob-nav-content-btn color2-bg init-dsmen fl-wrap "><i className="fa fa-bars" style={{marginLeft: '10px',    marginRight: '10px'}}></i>Menu</div>

             <Card  className={value==1 ? 'menudashitems' :'menudashitems mndbnone'} >
              
        
              {(user.picture=="" || !user.picture) && <div variant="top" style={{cursor:'pointer',width:100,height:100,borderRadius:50,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20,backgroundImage:`url(${defaultimg})`}} onClick={() => handleClickfile()}  ></div>}
              {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://feel-better-company.com/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
               <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"
                accept='image/*'
                onChange={(e) => handleFileChange(e)}
                ref={fileInput} 
            />
            </form>


                  <Card.Body>
                    <Card.Title className='text-center'>{user.first_name} {user.last_name} </Card.Title>
                    <Card.Text>
                  
                    </Card.Text>
                  <Nav defaultActiveKey="/home" className="flex-column nav-style">

                  <Nav.Link   onClick={()=>handleClick('/client/mesrendezvous')} className={slug=='mesrendezvous' && "active"} ><BiCalendarCheck  size={20} /> Mes rendez-vous</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/abonnement')} className={slug=='abonnement' && "active"} ><BiAward  size={20} /> Abonnement</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/profile')}  className={slug=='profile' && "active"}><BiUserCircle   size={20} /> Mon profil</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/dossier-patient')} className={slug=='dossierpatient' && "active"} ><BiFolderPlus  size={20} /> Dossier patient</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/documents')} className={slug=='documents' && "active"} ><BiFolderOpen  size={20} /> Mes documents</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/messages')} className={slug=='messages' && "active"} ><BiMessageRoundedDots  size={20} /> Messages</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/parametres')} className={slug=='parametres' && "active"} ><BiCog  size={20} /> Paramètres</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/commandes')} className={slug=='commandes' & "active"} ><BiShoppingBag  size={20} /> Mes commandes</Nav.Link>                 
                  <Nav.Link   onClick={()=>handleClick('/adresses')} className={slug=='adresses' && "active"} ><BiMap  size={20} /> Adresse de livraison</Nav.Link>
                  <Nav.Link    onClick={()=>handleClick('/mes-articles-favoris')} className={slug=='favoris' && "active"} ><BiHeart   size={20} /> Mes articles favoris</Nav.Link>
                  <Nav.Link    onClick={()=>handleClick('/parrainage')} className={slug=='parrainage' && "active"} ><BiHistory   size={20} /> Parrainage</Nav.Link>
                  <Nav.Link  onClick={()=>handleClick('/reclamations')} className={slug=='reclamations' && "active"}  ><BiUser   size={20} /> Laisser une réclamation</Nav.Link>
                </Nav>

                <div className='logout'>
                     <button className="logout_btn color2-bg" onClick={handleLogout}>Déconnexion <BiLogInCircle color={"#FFF"} size={20} /></button>
                </div>

                
                  </Card.Body>
                </Card>

            </div>
    );
}

export default Component;