import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { getAgenda} from "../../../actions/mixedmode";
import "react-datepicker/dist/react-datepicker.css";
import { MenuVendeur } from "../../../components";
import Slider from "react-slick";
import { apiClient } from "../../../actions/api"
import { getTherapeutLocalAgenda } from "../../../actions/api_interface"
import 'moment/locale/fr';
import 'moment-timezone';

import ShowTimezone from '../../../components/show_timezone/'



const Page = () => {
  const dispatch   = useDispatch();
  const { user}    = useSelector(state => state.user)
  const { agenda } = useSelector(state => state.mixedmode)
  const [localAgenda, setLocalAgenda] = useState(null)

  const [result, setResult] = useState(false)
  const [ifloading, setifloading] = useState(false)

  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => setShowAlert(false);



  useEffect(() => {
    if (!agenda?.data) {
      return
    }

    let localAgenda = getTherapeutLocalAgenda(agenda)
    setLocalAgenda(localAgenda)
  }, [agenda])

  useEffect(() => {

    if(result===false){
       let params={

          token:user.api_token,
          api_token:user.api_token,

      }
    dispatch(getAgenda(params));
    setResult(true)
    }


  }, [result,user]);





  const toggleState = (day, hourState) => {
    if (hourState.state === 2){
      return false;
    }

    const dayCopy = day.clone().hour(hourState.hour.slice(0, 2)).minute(hourState.hour.slice(3, 5)).second(0)
    const utcTime = dayCopy.utc();


    setifloading(true)

    let params = {
      token:user.api_token,
      api_token:user.api_token,
      month:utcTime.format("MM"),
      day:utcTime.format("DD"),
      heure: utcTime.format("HH") + ":00",
      year: utcTime.format("YYYY"),
      type:'item',
      action: hourState.state === 1 ? 'remove' : 'add'
    }

    apiClient().post(`/save/agenda`, params)
    .then((res) => {
      setifloading(false)
      if (res.data.success === 'ok'){

        let data = {
          token:user.api_token,
          api_token:user.api_token,
        }

        dispatch(getAgenda(data));
      }
    })
  }



  const setFullLocalDayAvailable = (item) => { // TODO - turn on
    let lishours = []
    return

    for (var i = 0; i < item.states.length ; i++) {
      if(item?.states[i].state == 0){
        lishours.push(item.states[i].hour)
      }
    }

    setifloading(true)

    let params={
      token:user.api_token,
      api_token:user.api_token,
      heures:lishours,
      day:item.day,
      month:item.month,
      year: item.year,
      type:'liste',
    }



    apiClient().post(`/save/agenda`, params)
    .then((res) => {

      setifloading(false)
      if(res.data.success === 'ok'){

        let data = {
          token:user.api_token,
          api_token:user.api_token,
        }

        dispatch(getAgenda(data));
      }
    })
  }


  // TODO - dry with agenda
  // NOTA / TODO - react-slick doesn't works properly with responsive (not auto-start at 0)
  var settings
  if (window.innerWidth > 600) {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7
    }
  } else {
    settings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 500,
      infinite: true,
      dots: false
    }
  }

  return (
        <div className="mescommandes register vosventes bg778635 " >
            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <MenuVendeur slug='agenda' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright "  lg="9" >


                            <h3 className="mb-4" > Agenda</h3>



   <div className='profiltherapeut '>

      <div className='row '>
                  <div className='col-md-12 bgplaning'>


                       <div className='calendar'>

                       <div className='alert alert-primary'>Veuillez utiliser cette interface pour personnaliser la disponibilité de vos rendez-vous</div>
                       <div className='alert alert-success'>
                        <div className='flxc0'>
                          <div className='flxc1'><span style={{background:'#00818a'}}></span> Disponible</div>
                          <div className='flxc1'><span style={{background:'red'}}></span> Non Disponible</div>
                          <div className='flxc1'><span style={{background:'#CCC'}}></span> Réservé</div>
                        </div>
                       </div>




                       <ShowTimezone />

                        <Slider {...settings}>

                                   {localAgenda?.map((item,index) => {
                                       let newindex=index+1;
                                       return (

                                            <div key={newindex} className='colcalendar'>
                                               <h5 style={{cursor: 'pointer'}} onClick={()=>setFullLocalDayAvailable(item)} >{item.day.format('dddd')}</h5>
                                               <span className='dd'>{item.day.format('D MMM')}</span>
                                               <div className='actions'>
                                                {item.states.map((hourState,index) => (
                                                  <div onClick={()=>toggleState(item.day, hourState)} className={hourState.state===1 ? 'active' : hourState.state===0 ? 'bloque':'' }>{hourState.hour}</div>

                                                ))
                                                }





                                               </div>
                                           </div>



                                        );

                                     })}


                        </Slider>

                       </div>

                    </div>
                  </div>




   </div>





                </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} size="lg"  centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>







        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
</Modal>
   <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>
            </Container>
        </div>
    );
}

export default Page;