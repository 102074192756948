import { AUTHENTICATE_USER,SET_USER,LOGOUT_USER,SET_FORMULE,SET_TEST,SET_FORMULE_UPDATE } from "../constants/actions";

const INITIAL_STATE = {
    user: {},
    formule:{},
    formuleupdate:{},
    test:{}
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTHENTICATE_USER :
            return {...state,user:action.payload}
        case SET_USER :
            return {...state,user:{...state.user,...action.payload}}
        case SET_FORMULE :
            return {...state,formule:action.payload} 
       case SET_FORMULE_UPDATE :
            return {...state,formuleupdate:action.payload} 
        case SET_TEST :
            return {...state,test:action.payload}       
        case LOGOUT_USER :
           return {...state,user:INITIAL_STATE.user}
        default:
            return state;
    }
}
export default reducer;