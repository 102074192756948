import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiBell,BiLock } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion' 
import Switch from '@material-ui/core/Switch';
import { apiClient } from "../../../actions/api"
import { LOGOUT_USER } from "../../../constants/actions";
import {AUTHENTICATE_USER} from "../../../constants/actions"

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);
    const label = { inputProps: { 'aria-label': '' } };
      const [issucces, setissucces] = useState(false);
     const [isloading, setisloading] = useState(false);
      const [parametres, setparametres] = useState({});
   const [securite, setSecurite] = useState(false);
   const [isError, setError] = useState(false);
   const [notification, setNotification] = useState(false);
  
    useEffect(() => {
          getParametres()
    }, []);

 useEffect(() => {
 
    
}, []);
 
    

    function getParametres() {
        let params={
           
              token:user.api_token,
              api_token:user.api_token,

          }


          apiClient().post(`/get/parametres`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                 if(res.data.parametres?.notification){

                  setNotification(true)
                }

                 if(res.data.parametres?.securite){

                  setSecurite(true)
                }
              
                
              }
            
              
           })
     }



  const handleChange = (event) => {
 
       setNotification(event.target.checked);
 
       
  }
  const handleChangeSecurite = (event) => {
  
       setSecurite(event.target.checked);
   
       
  }


  const handleSave = () => {
       
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
              notification:notification,
              securite:securite,
          }
 

   
       apiClient().post(`/save/parametres`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                if(res.data.parametres?.notification){

                  setNotification(true)
                }

                 if(res.data.parametres?.securite){

                  setSecurite(true)
                }
                 setissucces(true)
              
                
              }
            
              
           })
     
  }


  const handleRemove= () => {
       
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
           
          }
 

   
       apiClient().post(`/remove/profil`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                 dispatch({type:LOGOUT_USER});
              
                
              }
            
              
           })
     
  }


  const resendcodeverification =  (e) => {
   
 
            e.preventDefault();
 
            setError(false)
             let param={
                 user_id:user.user_id
             }
             
            apiClient().post(`/resentcode`,param )
           .then((res) => {
          
              if(res.data.success==true){
                  
                  alert('Un nouveau code vous a été envoyé !')
                
              }
              else{
              
                setError(true)
            

              }
              
           })

 }

 const handlerVerification =  (e) => {
           e.preventDefault();
 
            setError(false)
            const formData = new FormData(e.target)
            const formDataObj = Object.fromEntries(formData.entries())
             formDataObj.user_id=user.user_id
            apiClient().post(`/verfycode`,formDataObj )
           .then((res) => {
          
              if(res.data.success==true){
                 dispatch({ type: AUTHENTICATE_USER, payload: res.data.user })
                 history.push('/dossier-patient')
                
              }
              else{
              
                setError(true)
            

              }
              
           })
       
    }


const returnCodeVerification = () => {

     
     return (
            
              <div className="mb-5 divform pddformlng">

                        <div className='section8890' style={{background:'#FFF'}}>

                           <Form className="mb-5" onSubmit={handlerVerification}  style={{display: 'block'}} >

                             <h3><strong>Un email de confirmation vous a été envoyé.</strong></h3>
                             <p>Pour finir votre inscription, veuillez saisir le code à 4 chiffres que nous avons envoyé par email.  </p>

                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="code"
                                    type="text" placeholder="Votre code de vérification" required />
                            </Form.Group>

                            <Form.Group className="d-flex">
                               
                                <a className="forgetlink" style={{width:'100%',    textAlign: 'right'}} href='jacascript:void(0)'  onClick={(e)=>resendcodeverification(e)} >Renvoyer le code de confirmation  </a>
                            </Form.Group> 
                            <br/>
                            <button
                                className="btnlink btnlinkwdborder  marauto " type="submit">
                                Valider
                           </button>
                           {
                            isError &&
                            <div className="alert alert-danger" style={{marginTop:'40px'}}>

                               Votre code de vérification est incorrect !
                            </div>
                           }



                           </Form>

                        </div>

             </div>

        )

}

    return (
        <div className="mescommandes register parametres bg778635 " >

            <Container>
                <Row>
         
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

     
                            <h3 className="mb-4" > Validation</h3>


                               {
    issucces &&

  <div className='alert alert-success'>
   Vos paramètres de sécurité ont été enregistrés avec succès.
 </div>
}



                    
                          {returnCodeVerification()}

                              




                              
                                


 
                  

                         

                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;