import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion' 
import { apiClient } from "../../../actions/api"


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);
  
    const [questions, setQuestions] = useState([]);
    const [responses, setResponse] = useState([]);
    const [ifdossier, setifdossier] = useState(0);
    const [load, setLoad] = useState(false);
    


   
    const [questionD1, setQuestionD1] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [issucces, setissucces] = useState(false);
   


 
useEffect(() => {
         
getDossier()

    }, []);


     function getDossier() {
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
          }




          apiClient().post(`/get/dossierpatient`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setQuestions(res.data.dossierpatients)
                setifdossier(res.data.count)
                setLoad(true)
              
                
              }
            
              
           })
     }


    const setDossier=(question,reponse,index)=>{
       
         let newquestions=[]  
 
         for (var i = 0; i<questions.length ; i++) {
                   
                if(index===i){ 
                     let obj=questions[i]
                     obj.id_question=obj.idquestion
                     obj.reponse=reponse
                     newquestions.push(obj)
                }
                else{
                     newquestions.push(questions[i])
                }

        } 
       
         setQuestions(newquestions) 
 
    }

    const setMotif=(reponse,question,index)=>{
       
         let newquestions=[]  
 
         for (var i = 0; i<questions.length ; i++) {
                   
                if(index===i){ 
                     let obj=questions[i]
                     obj.motif=reponse
                     newquestions.push(obj)
                }
                else{
                     newquestions.push(questions[i])
                }

        } 
       
         setQuestions(newquestions) 

 
    }


 
    
function saveDossier() {
        
        let error=false;
        setissucces(false)

        for (var i = 0; i<questions.length ; i++) {
                let obj=questions[i]
                if(obj.reponse===null){
                       error=true;
                }

                if(obj.reponse==='true' && obj.motif===null){
                       error=true;
                }

        } 

        if(error){

             alert('Veuillez compléter votre dossier ! ')

        }
        else{

          let params={
           
              token:user.api_token,
              api_token:user.api_token,
              questions:questions
          }




          apiClient().post(`/save/dossierpatient`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setQuestions(res.data.dossierpatients)
                setissucces(true)

                if(localStorage.getItem('dossierisokreturn')=='true'){
                    localStorage.setItem('dossierisokreturn','false')
                    history.push('/paiement')

                }
              
                
              }
            
              
           })
                
        }
     
}
 


 

 







    return (
        <div className="mescommandes register dossierpatient bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='dossierpatient' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

     
                            <h3 className="mb-4" > Mon dossier patient</h3>


                           


                             <div className='contentsection'>

    <div className='alert alert-warning'><strong>Informations confidentielles à destination de votre praticien soumis au secret professionnel</strong></div>

{
  (ifdossier <= 0 && load ===true ) &&
   <div className='alert alert-danger'><strong>Le remplissage du dossier patient  est obligatoire avant votre premier rendez-vous</strong></div>
}
    


                                

 
 {
    questions?.map((item,key)=>(

<Accordion style={{marginBottom:'20px'}} >
  <Accordion.Item eventKey={key}>
    <Accordion.Header>{item?.question}</Accordion.Header>
    <Accordion.Body>
        
        <div className='flexitems'>
           <a href="javascript:void(0)" onClick={()=>setDossier(item,'true',key)} class="btnlink marauto">Oui {item.reponse==='true' && <i class="fa fa-check"></i>} </a>
           <a href="javascript:void(0)" onClick={()=>setDossier(item,'false',key)} class="btnlink marauto btnlinkjaun" >Non {item.reponse==='false' && <i class="fa fa-check"></i>} </a>

        </div>

        {
            item.reponse=='true' &&

            <textarea class="form-control" defaultValue={item.motif} onChange={evt => setMotif(evt.target.value,item,key)} name="message" rows="3" placeholder="Si oui, précisez.." style={{marginTop:'20px'}}></textarea>
        }
     
    </Accordion.Body>
</Accordion.Item>

</Accordion>

    ))
 }

 
 

<br/>
<button type="button" className="ps-btn ps-btn--fullwidth ps-btn-dark btn btn-primary" onClick={saveDossier}>Enregistrer</button>
 </div>

{
    issucces &&

  <div className='alert alert-success'>
   Votre dossier patient a bien été enregistré.
 </div>
}




                         

                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;