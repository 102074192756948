import React, {useEffect, useRef, useState} from 'react';
import { BsMic,BsSpeaker, BsCameraVideo } from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { IconButton, Menu, MenuItem } from '@mui/material';
import OT from '@opentok/client';
import './DeviceSelection.css';

const DeviceSelection = ({setHandleChangeMicrophoneDevice, setHandleChangeVideoDevice, joinCall}) => {
    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);
    const [availableDevices, setAvailableDevices] = useState([]);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
    const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
    const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState('');
    const [availableAudioOutput, setAvailableAudioOutput] = useState([]);
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        console.log(' :event.currentTarget:', event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchDevices();
        getNavigatorPermissions();
    }, []);

    useEffect(() => {
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.style.transform = 'scaleX(-1)';
        }
    }, []);

    const fetchDevices = async () => {
        try {
            await OT.getUserMedia().then((stream) => {
                populateDeviceSources();
                stream.getTracks().forEach(track => track.stop());
            })
            const audioOutput = await OT.getAudioOutputDevices();
            setAvailableAudioOutput(audioOutput);
            setSelectedAudioOutputDevice(audioOutput[0]);
        } catch (error) {
            console.error('Error getting devices:', error);
        }
    };

    const populateDeviceSources = () => {
        OT.getDevices((err, devices) => {
            if (err) {
                alert('getDevices error ' + err.message);
                return;
            }
            setAvailableDevices(devices);
        })
    };

    const getNavigatorPermissions = () => {
        if(!navigator?.permissions) return;
        navigator.permissions.query({ name: 'camera' || 'microphone' })
            .then((permissionStatus) => {
                permissionStatus.onchange = () => {
                    if(permissionStatus.state === 'granted')
                        fetchDevices();
                };
            })
    }

    // const startCamera = async () => {
    //     try {
    //         const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
    //         setStream(mediaStream);
    //     } catch (error) {
    //         console.error('Error accessing camera:', error);
    //     }
    // };

    useEffect(() => {
        if(availableDevices.length > 0){
            const audioInputDevices = availableDevices.filter(device => device.kind === 'audioInput');
            const videoInputDevices = availableDevices.filter(device => device.kind === 'videoInput');
            setAudioDevices(audioInputDevices);
            setHandleChangeMicrophoneDevice(audioInputDevices[0].deviceId);
            setVideoDevices(videoInputDevices);
            setHandleChangeVideoDevice(videoInputDevices[0].deviceId);
        }
    }, [availableDevices, availableAudioOutput])

    const handleAudioDeviceChange = event => {
        const audioDevice = event.target.value;
        setSelectedAudioDevice(audioDevice)
        setHandleChangeMicrophoneDevice(audioDevice)
    };

    const handleAudioOutputDeviceChange = event => {
        const audioOutputDevice = event.target.value;
        setSelectedAudioOutputDevice(audioOutputDevice);
        OT.setAudioOutputDevice(audioOutputDevice);
    };

    const handleVideoDeviceChange = event => {
        const videoDevice = event.target.value;
        setSelectedVideoDevice(videoDevice);
        setHandleChangeVideoDevice(videoDevice)
    };

    const startCallInParent = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null);
        joinCall();
    }

    return (
      <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            size="small"
            style={{
                width: '50px',
                height: '50px',
                borderRadius: '50px',
                border: '2px solid white',
                backgroundColor: '#D4D4D4',
            }}
          >
              <MdSettings
                style={{
                    color: 'white',
                    fontSize: '45px'
                }}
              />
          </IconButton>
          <Menu
            blurOnSelect
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            styles={{ paper: {marginBottom: '10px'} }}
          >
              <MenuItem>
                  <div>
                      <BsMic style={{ margin: '10px', fontSize: '15px'}} />
                      <select id="audioSource" value={selectedAudioDevice} onChange={handleAudioDeviceChange}>
                          {audioDevices.map(device => (
                            <option key={`audioSource-${device.deviceId}`} value={device.deviceId}>
                                {device.label || 'Microphone'}
                            </option>
                          ))}
                      </select>
                  </div>
              </MenuItem>
              <MenuItem>
                  <div>
                      <BsSpeaker style={{ margin: '10px', fontSize: '15px'}} />
                      <select name="speakersOptions" id="speakersSelect" onChange={handleAudioOutputDeviceChange} value={selectedAudioOutputDevice}>
                          {availableAudioOutput?.map((device) => (
                            <option key={`speaker-${device.deviceId}`} value={device.deviceId}>
                                {device.label || "Par défaut"}
                            </option>
                          ))}
                      </select>
                  </div>
              </MenuItem>
              <MenuItem>
                  <div>
                      <BsCameraVideo style={{ margin: '10px', fontSize: '15px'}} />
                      <select id="videoSource" value={selectedVideoDevice} onChange={handleVideoDeviceChange}>
                          {videoDevices.map(device => (
                            <option key={`videoSource-${device.deviceId}`} value={device.deviceId}>
                                {device.label || 'Camera'}
                            </option>
                          ))}
                      </select>
                  </div>
              </MenuItem>
          </Menu>
          {/*<button onClick={startCallInParent}>
              Rejoindre la consultation
          </button>*/}
      </div>
    );
};

export default DeviceSelection;
