import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import MainNavigator from './navigation';
import "./assets/sass/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PusherProvider } from "./PusherContext";
import Pusher from 'pusher-js';

const pusher = new Pusher('8dfc788b8c1d27a7b8f5', {
                cluster: 'eu',
                forceTLS: true
            });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
       <PusherProvider pusher={pusher}>
        <MainNavigator />
        </PusherProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

