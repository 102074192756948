import React,{useState,useEffect} from "react";
import './styles.scss';
import { Container, Navbar, Nav,CloseButton,Modal  } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { logo,addtocart } from "../../assets/images";
import { LOGOUT_USER } from "../../constants/actions";
import { BiUser,BiLogOut} from 'react-icons/bi';
import { useHistory } from "react-router";
import Accordion from 'react-bootstrap/Accordion'
import { getSettings,getCategoryListAll,getNotification,gettimezone} from "../../actions/mixedmode";
import { REMOVE_PRODUCT_BASKET, GET_TOTAL_CART ,SET_FORMULE, SET_FORMULE_UPDATE} from "../../constants/actions";
import Pusher from 'pusher-js';
import { apiClient } from "../../actions/api"
import moment from 'moment'
import { usePusher } from "../../PusherContext";

import Echo from "laravel-echo"

const Component = ({back,path}) => {
    const dispatch = useDispatch();
    const history = useHistory()
 
    const { user } = useSelector(state => state.user)
    const { data,total } = useSelector(state => state.basket)
    const { settings,allcategories ,notification,timezone} = useSelector(state => state.mixedmode)
   
    const [showmenu,setShowMenu]=useState(false);
    const [expand,setExpand]=useState(0);
    const [send,setSend]=useState(false);
    const [show,setShow]=useState(false);
    const [checkconsultation, setCheckconsultation] = useState({})

    const [scrool,setScrool]=useState(false);
    const pusher = usePusher();


   useEffect(() => {

    if(user?.user_id && user?.google_id=='A'){
     
     /*

      var echo =window.Echo = new Echo({
        broadcaster:'pusher',
        key:'8dfc788b8c1d27a7b8f5'
      });

      window.Echo.channel('dispach')
      .listen('Dispatch', (data) => {
        alert(data.id)
      })

      */


 
        let idchanel=''
 
        idchanel='lanshpopup__'+user?.user_id

    


        const channel = pusher.subscribe(idchanel);
        channel.bind('lanshpopup', function (data) {


         let params44={
           
              token:user.api_token,
              api_token:user.api_token,
          }


          apiClient().post(`/check/consultation/therapeut`,params44 )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
               
                if(res.data.reservation?.heure_rdv){

                  if(!path.includes('conference')){
                          setCheckconsultation(res.data.reservation)
                          setShow(true)
                  }
                        
                         

                }
                
              }
            
              
           })


            });



      
       
      
        

    }
 
       

     // set time zone en local
     if(!timezone || timezone===''){

       var timezone_offset_minutes = new Date().getTimezoneOffset();
       timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
       let params={'time':timezone_offset_minutes}

       dispatch(gettimezone(params))

     }
     
      
    
    },[]);


 



  useEffect(() => {
  if(send===false){
         dispatch(getSettings())
         dispatch(getCategoryListAll('all'))
         setSend(true)
  }
         
         const param = {}
         if(user.user_id){

            param.user_id=user.user_id;
         param.token=user.api_token;
         param.api_token=user.api_token;
          dispatch(getNotification(param))

         }

         if(!scrool){
           window.scrollTo(0,0);
           setScrool(true)
         }
        

       

    }, [send]);

    const handleDelete = (item) => {
        dispatch({ type: REMOVE_PRODUCT_BASKET, payload: item.id })  
        dispatch({ type: GET_TOTAL_CART })  
    }
 
    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
    }

     const goToCart = () =>{
         history.push(`/panier`);
    }


    const gototarif = () =>{
         
         dispatch({ type: SET_FORMULE, payload: {} })
         dispatch({ type: SET_FORMULE_UPDATE, payload: {} })
          history.push('/tarifs');
    }

  const goToPage = (src) =>{
         dispatch({ type: SET_FORMULE, payload: {} })
          dispatch({ type: SET_FORMULE_UPDATE, payload: {} })
         history.push(src);
    }

    const goToLoginOrAcount = () =>{
         if(user?.user_id) {

            if(user?.google_id=='A'){
                 history.push('/client/dashboard');
            }
            else{
                  history.push('/praticien/dashboard');
            }

         }

        
    }

        const handlerMenu = () => {
        
          setShowMenu(!showmenu)

    }
   const goTowhtasapp = () =>{
           let url =`https://api.whatsapp.com/send/?phone=${settings.site_fax}&type=phone_number&text=Bonjour,`
        window.open(url);
    }

     const handleClose = () => setShowMenu(false);
      const handleClosemodal = () => setShow(false);

    return (
        <div>
        <header className={back==1 ? "header header--standard header--market-place-2 is_header_sticky" : "header header--standard header--market-place-2" } data-sticky="true">
        
        <div className="header__content">
            <div className="container">
               <div className='row' style={{alignItems: 'center'}}>

          

                     <div className="col-md-2 ">
                          <a className="ps-logo" href="#" onClick={()=>history.push('/')}><img src="/images/logo-feel-blanc.png" alt=""/></a>
                     </div>

                     <div className="col-md-9 ">
                          <nav className="navigation">
                       
                        </nav>
                     </div>

                      <div className="col-md-1">

                <div className="header__content-right">
                    <div className="header__actions">
                    <a style={{display:'none'}} className="header__extra" href="javascript:void(0)" onClick={goToLoginOrAcount}  ><i className="fa fa-globe "></i><span style={{background: '#f47777'}}><i>{notification}</i></span></a>
                                          <div className="ps-block--user-header">
                            <div className="ps-block__left" onClick={goToLoginOrAcount}><i className="icon-user"></i></div>
                             
                            {
                               !user?.user_id &&


                            <div className="ps-cart__content_user">
                            <div class="ps-user__items"></div>



                            <div className='ps-user__footer'>


                             <div className="text-center"><a onClick={()=>history.push('/login/?espace=patient')} style={{width:'100%'}} href="javascript:void(0)" className="btnlink marauto">Vous êtes patient ? </a></div>
                             <br/>
                            <div className="text-center"><a  onClick={()=>history.push('/login/?espace=pro')} style={{width:'100%',    backgroundColor: '#46c3cc'}}  href="javascript:void(0)" className="btnlink marauto">Vous êtes praticien ? </a></div>
                              

                                
                            </div>

                         
                            </div>


                             }

                            
                        </div>
                        <div className="ps-cart--mini"><a className="header__extra" href="javascript:void(0)"><i className="icon-bag2"></i><span><i>{data?.length}</i></span></a>
                            <div className="ps-cart__content">
                                <div className="ps-cart__items">

                                  {data.map((item) => (
                                    <div className="ps-product--cart-mobile">
                                        <div className="ps-product__thumbnail"><a href="javascript:void(0)"><img src={item.product.img} alt=""/></a></div>
                                        <div className="ps-product__content"><a className="ps-product__remove" href="javascript:void(0)" onClick={()=>handleDelete(item)}><i className="icon-cross"></i></a><a href="product-default.html">{item.product.title}</a>
                                            <p></p>
                                            <small>{item.qty} x {item.product.price} €</small>
                                        </div>
                                    </div>
                                     ))}


                                  
                                </div>
                                <div className="ps-cart__footer">
                                    <h3>Sous-total :<strong>{total} €</strong></h3>
                                    <figure><a onClick={goToCart} className="ps-btn ps-fullwidth ps-btn--black" href="javascript:void(0)">Voir panier</a></figure>
                                </div>
                            </div>
                        </div>
      


                      
                       <div className="navigation__right">
                          <div className="header__actions header__actions_menu">

                                 <a onClick={handlerMenu} className="navigation__item ps-toggle--sidebar" href="javascript:void(0)"><i className="icon-menu"></i></a>
                       
                          </div>
                        </div>

 



                    </div>
                </div>
                    
                     </div>

               </div>
               
          
 
            </div>
        </div>
   
    </header>
    <header className="header header--mobile" data-sticky="true">
        <div className="header__top" style={{display:'none'}}>
            <div className="header__left">
                 
            </div>
            <div className="header__right">
               
            </div>
        </div>
        <div className="navigation--mobile">
           
            <div className="navigation__center">

            {
                 back==1 ? 
                    <a className="ps-goback" href="javascript:void(0)" onClick={() => history.goBack()}><img src="/images/goback.png" alt=""/> Retour</a>
                 :
                  <a className="ps-logo" href="javascript:void(0)" onClick={()=>history.push('/')}><img src="/images/logo-feel-blanc.png" alt=""/></a>


            }

           


            </div>


            <div className="navigation__right">
                <div className="header__actions">
                    

                    
                     <a onClick={()=>history.push('/panier')} style={{ height: 'auto'}} className="header__extra" href="javascript:void(0)"><i className="icon-bag2"></i><span><i>{data?.length}</i></span></a>
                     <a onClick={handlerMenu} className="navigation__item ps-toggle--sidebar" href="javascript:void(0)"><i className="icon-menu"></i></a>
                       
                 
                 
                </div>
            </div>

            
        </div>
        <div className="ps-search--mobile" style={{display:'none'}}>
            <form className="ps-form--search-mobile" action="">
                <div className="form-group--nest">
                    <input className="form-control" type="text" placeholder="Rechercher un produit..."/>
                    <button><i className="icon-magnifier"></i></button>
                </div>
            </form>
        </div>
    </header>
    {
        showmenu==true && 
          <div className='mask'></div>

    }
      
        <div className={showmenu==true ? "ps-panel--sidebar active" : "ps-panel--sidebar" } id="menu-mobile" >
        <div className="ps-panel__header">
           
            <h3>Menu</h3>
             <CloseButton variant="white" onClick={handlerMenu} />

        </div>
        <div className="ps-panel__content">


          <ul className="menu--mobile menu--mobile-border" style={{marginLeft: '20px'}}>
            
             <li className="menu-item"><a onClick={()=>goToPage('/#')} href="javascript:void(0)">Accueil</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/nos-methodes')} href="javascript:void(0)">Méthodes</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/list-psychologue')} href="javascript:void(0)">Praticiens</a></li>
             <li className="menu-item"><a onClick={()=>gototarif()} href="javascript:void(0)">Tarifs</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/produits')} href="javascript:void(0)">Produits</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/articles')} href="javascript:void(0)">Articles</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/tests')} href="javascript:void(0)">Tests</a></li>
         
                    
            </ul>


            <div className="ps-block--user-header" style={{marginTop: '50px',marginBottom: '50px'}}>
                            
                             
                            {
                               !user?.user_id ?


                             <div className='ps-user__footer' style={{    width: '100%',    paddingBottom: '50px'}}>


                             <div className="text-center"><a onClick={()=>history.push('/login/?espace=patient')} style={{width:'100%'}} href="javascript:void(0)" className="btnlink marauto">Vous êtes patient ? </a></div>
                             <br/>
                            <div className="text-center"><a  onClick={()=>history.push('/login/?espace=pro')} style={{width:'100%',    backgroundColor: '#46c3cc'}}  href="javascript:void(0)" className="btnlink marauto">Vous êtes praticien ? </a></div>
                              

                                
                            </div>
                            :
                            <a className="btnlink marauto" onClick={goToLoginOrAcount} style={{color:'#FFF', fontWeight:'bold'}}><i className="fa fa-user" style={{paddingRight:'10px'}}></i> Mon compte</a>


                             }

                            
                        </div>



            </div>


 
        
    </div>

      <Modal show={show} onHide={handleClosemodal} className='modelhermixtarif'   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClosemodal} />
        </Modal.Header>
        <Modal.Body>

   <br/>

     <div className='alert alert-warning'>

                               <div style={{     alignItems: 'center',overflow: 'hidden'}}>

                                 <div style={{paddingBottom:'15px'}}><strong>Votre prochaine  consultation  sera aujourd'hui à {moment(checkconsultation?.heure_rdv, "h:mm").format("HH")}h:{moment(checkconsultation?.heure_rdv, "h:mm").format("mm")}</strong></div>
                                 
                                 <a href="javascript:void(0)" style={{float:'right'}} onClick={()=>history.push('/conference/call/'+checkconsultation?.therapeut_id)} class="btnlink marauto">Rejoindre la consultation  <i class="fa  fa-video-camera"></i></a>

                               </div>


     </div>

     <br/>


        </Modal.Body>
        <Modal.Footer>
        
        </Modal.Footer>
      </Modal>



    </div>
    );
}

export default Component;