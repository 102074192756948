import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { AvisItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getTarifs , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";


const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);
    const [show,setShow]=useState(false);
    const { data,pageinfo } = useSelector(state => state.mixedmode);
 

    useEffect(()=>{
       
       dispatch(getPage(54))
      


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (item) => {
      
       
      setShow(true);

    }
 
 const goTo = () => {
      
       
     history.push('/list-psychologue')

    }
    return (
        <div className="home methodes" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Nos tarifs</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3"> {pageinfo?.titre}<br/></h2>
                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu }} ></div>
                  
                 
                </Container>
            </section>




  <section className="section4 section " >
                <Container>
                    
               

                   <Row className="">
                     <Col md={6}>

                      <img src="/images/psywomen.jpg" />

                     </Col>

                     <Col md={6}>
                      <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu1 }} ></div>
                      


                     </Col>

                   </Row>
                </Container>


  </section>



 
    <section className="section5 section " >
                <Container>
                    <h2 className="text-center mb-3"> {pageinfo?.titre2}</h2>
                    <div style={{    paddingRight: '16px',overflow: 'hidden'}}>
                     <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu2 }} ></div>
                    </div>
                   <br/>  <br/>

                    <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu3 }} ></div>

                    

                          <br/>
                                 <div className="text-center" >

                                   <a href='javascript:void(0)' onClick={()=>history.push('/tarifs')}  className='btn_primary' >Prendre rendez-vous</a>

                                 </div>


                </Container>


  </section>


 
 



      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




          <div className='flexrowtarifs row'>
          <div className=' col-md-12'>
            <div className="text-center flowpriceheader"><img src='/images/relaxation.png'/><h2>Thérapie individuelle</h2></div>
          </div>

             <div className='item col-md-4'>
               <div className='pricebox'>
                  <h3>Formule Découverte</h3>
                  <h4>64,20€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                       <li><i className='fa fa-check-circle icochecked'></i> 50 min</li>
                       <li><i className='fa fa-check-circle icochecked'></i> 5 séances</li>
                       <li><i className='fa fa-check-circle icochecked'></i> Starter pack thérapeutique</li>

                  </ul>

                  <label>
                    Contenu du pack
                  </label>
                  <p>livret thérapie, guide bien-être, marque-pages, outils d’écriture, bracelet en satin, tote bag.</p>
                  <br/>

                   <div className="text-center" >
                         

                                   <a href='javascript:void(0)' onClick={()=>goTo()} className='btnlink marauto' >Prendre rendez-vous </a>

                    </div>

                  </div>
               </div>
             </div>


             <div className='item col-md-4'>
               <div className='pricebox'>
                  <h3> Formule Exploration</h3>
                  <h4>58,40€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                       <li><i className='fa fa-check-circle icochecked'></i> 50 min</li>
                       <li><i className='fa fa-check-circle icochecked'></i> 12 séances</li>
                       <li><i className='fa fa-check-circle icochecked'></i> Pack thérapeutique</li>

                  </ul>

                  <label>
                    Contenu du pack
                  </label>
                  <p>livret thérapie, guide bien-être, marque-pages, outils d’écriture, bracelet en satin, carnet de notes Neutre, porte-clés anti-stress, gourde pastel, tote bag 2 poches</p>
                  <br/>

                   <div className="text-center" >
                       

                                   <a href='javascript:void(0)' onClick={()=>goTo()} className='btnlink marauto' >Prendre rendez-vous </a>

                    </div>

                  </div>

               </div>

                  

             </div>
             
            

             <div style={{marginTop: '45px'}} className='alert alert-success'>Des questions ? Consultez notre <span style={{color:'#17a2b8',textDecoration: 'underline'}}>FàQ</span> ou contactez l’équipe <span style={{color:'#17a2b8',textDecoration: 'underline'}}>Marque</span></div>

             
         </div>
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;