import React, { useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { getPage } from "../../actions/mixedmode";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";

const Page = () => {
    const dispatch = useDispatch();
     const history = useHistory()
    const { pageinfo } = useSelector(state => state.mixedmode)
  useEffect(() => {
     dispatch(getPage(7))
   }, [])

   const goToPage = (src) =>{
         history.push(src);
    }

    return (
    <div className="home tarifs">
            <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="javascript:void(0)" onClick={()=>goToPage('/')}>Accueil</a></li><li>Qui sommes-nous </li></ul></div></div></div>
        <div className="quisommesnous" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                       <h2 class="text-center mb-5"> {pageinfo?.titre} </h2>
                        <span class="section-separator"></span>
<br/><br/>


                                     <div dangerouslySetInnerHTML={{ __html:pageinfo?.contenu }}></div>


 
                    </Col>
                </Row>
            </Container>
        </div>
<div className=''>
 
        </div>
    </div>
    );
}

export default Page;