// NOTA - avant le rework, tous les morceaux DRY finissent ici.
// Le nommage fichier vs fonctionalités n'est pas respecté
// TODO - exploser le fichier en plusieurs


import { apiClient } from "./api"
import moment from 'moment'
import 'moment/locale/fr';
import 'moment-timezone';


function toLocalReservations (reservations) {
  let excludeKeys = ["date", "time"]

  // NOTA - TODO - (rework) - la valeur d'origine est date, mais injecté de facons unsafe par le back
  // La valeur time à été ajouté pour le rework, en gardant date pour le moment
  return reservations.map(reservation => {
    var copy = {}

    for (let key in reservation) {
      if (!excludeKeys[key]) {
        copy[key] = reservation[key]
      }
    }

    copy.date = copy.time

    return copy
  })
}


const getLocalReservations = (user, path, callback) => {
  let params={
    token:user.api_token,
    api_token:user.api_token,
  }


  apiClient().post(path, params)
  .then((res) => {
    if (res.data.success === 'ok') {
      let localReservations = toLocalReservations(res.data.reservations)
      callback(localReservations)
    }
  })
}


export const getTherapeutLocalReservations = (user, callback) => {
  getLocalReservations(user, `/get/reservations/therapeut`, callback)
}


export const getPatientLocalReservations = (user, callback) => {
  getLocalReservations(user, `/get/reservations`, callback)
}




function ensure2digit (number) {
  return number < 10 ? "0" + number : "" + number;
}

function getEmptyDay () {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const minuteOffset = ((moment.tz(timezone).utcOffset() / 60) % 1) * 60

  let day = []
  for (let j = 0; j < 24; j++) {
    day.push({
      hour: ensure2digit(j) + ":" + ensure2digit(minuteOffset),
      state: 0,
      nbreservation: 0
    })
  }

  return day
}


const getEmptyAgenda = () => {
  let localAgenda = []

  const now = moment.tz(new Date(), moment.tz.guess());

  for (let i = 0; i < 31; i++) {
    localAgenda.push({
      states: getEmptyDay(),
      day: now.clone().add(i, 'days')
    })
  }

  return localAgenda
}


const getLocalHourFrom = (localAgenda, strZuluDate) => {
  let local = moment.utc(strZuluDate).local()
  let localDay = localAgenda.find(({ day }) => day.isSame(local, "day"))

  if (localDay) {
    let localHour = localDay.states.find(({ hour }) => hour === local.format("HH:mm"))

    return localHour || null
  }

  return null
}

const findAndSetLocalState = (localAgenda, strZuluDate, value) => {
  let localHour = getLocalHourFrom(localAgenda, strZuluDate)

  if (localHour) {
    localHour.state = value
  }
}


const injectPlaning = (localAgenda, zuluPlaning) => {
  zuluPlaning.forEach(strDate => findAndSetLocalState(localAgenda, strDate, 1))
}


const injectChecking = (localAgenda, zuluChecking) => {
  zuluChecking.forEach(strDate => findAndSetLocalState(localAgenda, strDate, 2))
}

const setNotEnablePreviousHours = (localAgenda) => {
  let currentDay = localAgenda[0]
  let now = moment().format("HH:mm")

  currentDay.states.forEach(hourState => {
    if (moment(hourState.hour, "HH:mm").isBefore(moment(now, "HH:mm"))) {
      hourState.state = 2
    }
  })
}


const getLocalAgenda = (agenda) => {
  let localAgenda = getEmptyAgenda()
  injectPlaning(localAgenda, agenda.easyPlaning)
  injectChecking(localAgenda, agenda.easyChecking)
  setNotEnablePreviousHours(localAgenda)

  return localAgenda
}


export const getTherapeutLocalAgenda = (agenda) => {
  return getLocalAgenda(agenda)
}



export const fetchAbonnement = (user, callback) => {
  let params = {
    token:user.api_token,
    api_token:user.api_token,
  }

  apiClient().post(`/get/abonnement`, params)
  .then((res) => {
    if(res.data.success === 'ok') {
      callback(res.data)
    }
  })
}




export const getNextConsultation = (consultations) => {
  if (!consultations || !consultations.length) {
    return null
  }

  let now = moment.utc().subtract(59, "minutes")
  let withAvailableStatus = consultations.filter(current => current.status < 2)
  let filtered = withAvailableStatus.filter(current => moment(current.time).isAfter(now))
  let ordered  = filtered.sort((a, b) => moment(a.time).diff(moment(b.time)))
  let next = ordered[0]

  return next
}


export const agendaClickToParams = (day, hourState, therapeut) => {
  const dayCopy = day.clone().hour(hourState.hour.slice(0, 2)).minute(hourState.hour.slice(3, 5)).second(0) // TODO - dry
  const utcTime = dayCopy.utc()

  return {
    'fullDate': utcTime.format('YYYY-MM-DDTHH:mm:ss'),
    'date': utcTime.format("YYYY-MM-DD"),
    'heure': utcTime.format("HH") + ":00",
    'therapeut': therapeut
  }

}




// TODO - hotfix
export const getRealTherapieTime = (data) => {
  let title = data.reservation?.tarif?.titre
  let titleToTime = {
    'Thérapie individuelle': 70,
    'Thérapie de couple': 85,
    'Adolescent': 70,
    'Groupe de parole': 135
  }

  if (!title) {
    return 135
  }
  else if (title === 'Parentalité') {
    if (data.reservation?.formule?.titre==='1 parent') {
      return 70
    }
    else{
      return 95
    }
  }
  else {
    return titleToTime[title]
  }
}