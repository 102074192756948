import React from "react";
import './styles.scss';

import { useHistory } from "react-router-dom";

import moment from "moment"
import "moment-timezone"




const toTodayText = (localConsultation) => {
  return "aujourd'hui à " + localConsultation.format("HH[h]:mm")
}

const toAnotherDayText = (localConsultation) => {
  return "le " + localConsultation.format("dddd D MMMM") + " à " + localConsultation.format("HH[h]:mm")
}

const Component = ({ consultation, target }) => {
  const history = useHistory();

  if (!consultation) {
    return null
  }


  let localConsultation = moment.utc(consultation.time).local()
  let isToday = localConsultation.isSame(moment(), "day")
  let text = isToday ? toTodayText(localConsultation) : toAnotherDayText(localConsultation)

  return (
    <div className='alert alert-warning'>
       <div style={{ display: 'flex',alignItems: 'center'}}>

         <strong>Votre prochaine consultation sera {text}</strong>

         <a href="javascript:void(0)" onClick={()=>history.push(target)} class="btnlink marauto">Rejoindre la consultation  <i class="fa  fa-video-camera"></i></a>

       </div>


    </div>
  )
}


export default Component
