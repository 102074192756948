import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import { MenuVendeur } from "../../../components";
import moment from 'moment'

const Page = () => {
     const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState('')
    const [date, setDate] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
 
    useEffect(() => {
        dispatch(getCommandeById(user.user_id,user.api_token,'ramasseursuivilivraison',{}));
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);
 
    
      function gotoCmd(id,item) {
       dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/ramasseurs/commande/detail/${id}`);
     }


   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)

             break;

             case 'rubrique':

                 setRubrique(slug)
                 setCounter(1)
             break;
            case 'order':

                 setOrder(slug)
                 setCounter(1)
             break;

          }

        
            
 
    }


    const  setFormFiltre = async () => {
      let params ={filtre:'1'}


      if(rubrique){
          params.rubrique=rubrique
      }

      if(order){
        
        params.order=order

      }
      if(date){
         
         params.date=date
      }
  
  
     dispatch(getCommandeById(user.user_id,user.api_token,'ramasseursuivilivraison',params));
     setCounter('')

    }





    
    return (
        <div className="mescommandes register " >
            <Container>
                <Row>
      
              
                    <Col  className="text-center mx-auto my-5"  >

     
                            <h3 className="mb-4" ><img src="/images/camion.png" style={{width: '30px'}} /> Service de ramassage</h3>
                             <br/> 

          

    <div className='filtreorder'>
                <div className='item'>

                    <div className='colitem'>
                      <div >
                        Date
                      </div>

              
                      <div  className='iiin'>
                         
                         <input type="date" className='form-control' onChange={(event)=>HandleFiltre(event.target.value,'date',event)} />
                        
                     </div>


                    </div>


                </div>
                <div className='item'>
                     <select className='orderbyorder'  onChange={e => HandleFiltre(e.target.value,'order',e)}  ><option value=''>Trier par</option><option value='created_at'>Date</option><option value='total_price'>Valeurs des colis</option></select>
                </div>


            </div>


                    <form className="mb-5 detailcmd ">
                      
                          <Row className="mb-3"  >
 


                        <div className=' detcommande'>

                          {commandeslist?.map((item) => ( 


                            <div className='items'>
                              <a  onClick={() => {gotoCmd(item.createdat,item)}} href="javascript:void(0)">

                                <div className='rowitmtop'>
                                {
                                  item.createdat &&
                                   <span> {moment(new Date(item.createdat)).format("DD MMM YYYY")}</span>
                                }
                                   
                                     
                                </div>

                                <div className='process-item'>

                                   
                                     
                                  <div className='rowsitm'>
                                      <div> <span>- Colis récupérées: </span></div>
                                       <div><b>{parseInt(item.nbcolirec)}</b></div>
                                       
                                    </div>
                                     <div className='rowsitm'>
                                      <div> <span>- Valeurs des colis: </span></div>
                                       <div><b>{parseFloat(item.total).toFixed(2)} CFA</b></div>
                                       
                                    </div>
                                    <div className='rowsitm'>
                                      <div> <span>- Total articles: </span></div>
                                       <div><b>{parseInt(item?.totalarticles)}</b></div>
                                       
                                    </div>

                                 </div>
                                </a>
                              </div>

                               
               
 

                            ))}



                             

 
  
                        </div> 

                        <br/> <br/>
 
                           

                          </Row>

                    </form>
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;