import isBrowserSupported from './browserSupportsSinglePeerConnection';

// SPC is only available if defined by sessionInfo or forced by flag
// and browser supports it.
module.exports = (sessionInfo, overrideSinglePeerConnection) => {
  const { p2pEnabled, singlePeerConnection } = sessionInfo || {};
  if (p2pEnabled) {
    // SPC is not available for P2P sessions.
    return false;
  }
  // We use singlePeerConnection flag from GSI by default.
  let isSinglePeerConnectionEnabled = !!singlePeerConnection;

  // We override sessionInfo with force flag.
  if (typeof overrideSinglePeerConnection === 'boolean') {
    isSinglePeerConnectionEnabled = overrideSinglePeerConnection;
  }

  return isSinglePeerConnectionEnabled && isBrowserSupported();
};
