import './Tile.css';
import { useState, useEffect, useRef } from 'react';
import { useMediaTrack } from '@daily-co/daily-react';
import Username from '../Username/Username';
import TileVideo from '../TileVideo/TileVideo';
import ErrorBoundary from "../../../helpers/ErrorBoundary";

import {
  MicrophoneOff,
  MicrophoneOn
} from '../Tray/Icons';


export default function Tile({ id, isScreenShare, isLocal, isAlone, onVideoElement, mainVideoDisplaySize }) {
  onVideoElement = onVideoElement || (() => {})
  const [isLaunched, setIsLaunched] = useState(false)
  const [isPaused, setIsPaused] = useState(null);
  const audioTrack = useMediaTrack(id, isScreenShare ? 'screenAudio' : 'audio');
  const audioElement = useRef(null);

  const toggleAudio = () => {
    let paused = audioElement.current.paused
    let methodName = paused ? "play": "pause"

    audioElement.current[methodName]()
    setIsPaused(!paused)
  }


  useEffect(() => {
    if (isLaunched) {
      return
    }

    const fetchData = async () => {
      if (audioTrack?.state === 'playable') {
        if (audioElement?.current) {
          audioElement.current.srcObject = audioTrack && new MediaStream([audioTrack.persistentTrack])
          setIsLaunched(true)

          setTimeout(() => {
            toggleAudio()
          }, 100)
        }
      }
    }

    fetchData()
  }, [audioTrack]);

  let containerCssClasses = isScreenShare ? 'tile-screenshare' : 'tile-video';

  if (isLocal) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  const resolveTogglerPosition = (displaySize) => {
    if (!displaySize) {
      return {}
    }

    return { left: displaySize.borderX, top: displaySize.borderY }
  }



  return (
  <ErrorBoundary>
    <div className={containerCssClasses}>
      <TileVideo id={id} isScreenShare={isScreenShare} onVideoElement={onVideoElement} isLocal={isLocal} mainVideoDisplay={mainVideoDisplaySize} />
      {
        !isLocal && audioTrack &&
        <audio style={{position: "absolute", top: 0, left: 0, width: "250px"}} playsInline ref={audioElement} />
      }
      {
        !isLocal && audioTrack &&
        <button style={resolveTogglerPosition(mainVideoDisplaySize)} class={isPaused ? "audio-toggler pause" : "audio-toggler play"} onClick={() => toggleAudio()}>{ isPaused ? <MicrophoneOff /> : <MicrophoneOn /> }</button>
      }
      <Username id={id} isLocal={isLocal} />
    </div>
  </ErrorBoundary>
  );
}
