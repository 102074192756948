import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import { updateInfoUser,getlistOffres} from "../../../actions/user";
 import { useParams } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import { apiClient } from "../../../actions/api"
import moment from 'moment'

const Page = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
     const { offres } = useSelector(state => state.mixedmode)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState('')
    const [date, setDate] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
 
    useEffect(() => {
        let params ={product_id:id}
        dispatch(getlistOffres(user.user_id,user.api_token,'product',params));
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);


  
    function gotoCmd(id,item) {
    
      if(item.status == 0){
        return false;
      }
       dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/detail-offre/${id}`);
     }






   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)

             break;

             case 'rubrique':

                 setRubrique(slug)
                 setCounter(1)
             break;
            case 'order':

                 setOrder(slug)
                 setCounter(1)
             break;

          }

        
            
 
    }



    const  setFormFiltre = async () => {
      let params ={filtre:'1',rubrique:3}


     

      if(order){
        
        params.order=order

      }
       dispatch(getlistOffres(user.user_id,user.api_token,'product',params));
       
  
  
    
     setCounter('')

    }






    
    return (
        <div className="mescommandes register listproducts mesannonces" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <Menu slug='mesannonces' />
               
            </Col>
              
            <Col  className="text-center mx-auto my-5"  >

            <h3 className="mb-4" > Mes offres reçues ventout - {offres?.product_name} </h3>
            <br/> 

            <div className='row'>
                 <div>

                 </div>
            </div>

            <div className='orderactionmob'>

              
            </div>

            <div className='filtreorder'>
                <div className='item'>

         


                </div>
                 <div className='item'>
                     <select className='orderbyorder'  onChange={e => HandleFiltre(e.target.value,'order',e)}  ><option value=''>Trier par</option><option value='created_at'>Date</option><option value='sale_price'>Prix</option></select>
                </div>


            </div>

                    <form className="mb-5 mb5mob" >
                           
                            
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                                <Table responsive  >
                          <thead>
                            <tr>
                                <th>Référence</th>
                                <th>Prix demandé  </th>
                                <th>Prix proposé  </th>
                                <th >Date création </th>
                                <th  >Statut</th>
                                <th  >Actions</th>

                            </tr>
                          </thead>
                        <tbody>

                     {offres?.orders?.map((item) => (

                              <tr>
                              <td>{item.track_code}</td>
                              <td> {parseFloat(offres?.sale_price).toFixed(2)} CFA</td>
                              <td>  {parseFloat(item.total_price).toFixed(2)} CFA</td>
                              <td>
                                 {moment(new Date(item.created_at)).format("DD/MM/YYYY")}
                              </td>
                              
                              <td>
                                 {item.order_status==0 && <span className='badge alert-warning'>en attente de validation</span>}
                                 {item.order_status==1 &&  <span className='badge alert-success'>Traité</span>}
                                 {item.order_status==6 &&  <span className='badge alert-danger'>Incomplet</span>}
                                 {item.order_status==5 &&  <span className='badge alert-success'>Validée</span>}
                                 {item.order_status==4 &&  <span className='badge alert-success'>En cours de livraison</span>}
                                 {item.order_status==3 &&  <span className='badge alert-success'>Livrée</span>}
                                 {item.order_status==2 &&  <span className='badge alert-danger'>Annulé</span>}
                                 {item.order_status==7 &&  <span className='badge alert-danger'>Refusée</span>}
                                 
                              </td>
                              <td>
                              <div className="list-contr">
                                 {
                                  item.status != 0 &&
                                   <a  href='javascript:void(0)' onClick={()=>gotoCmd(item.order_id,item)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Voir <i class="icon-eye"></i></a>
                                 }
                               
                               
                               </div>
                              </td>
                            </tr>


                          ))}
                          
                          

                          </tbody>
                        </Table>
                          </div>
                           
                      

                        <div className='tavblemob'>

                          {offres?.orders?.map((item) => (
                                
                                 <div className='items'>
                              <a  href='javascript:void(0)' onClick={()=>gotoCmd(item.order_id,item)}  >

                                <div className='rowitmtop'>
                                   <span> {moment(new Date(item.created_at)).format("DD MMM YYYY")}</span>
                                   <span>Réf : {item.track_code}</span>
                                </div>

                                <div className='process-item'>
  
                                    <strong>Prix demandé : {parseFloat(offres?.sale_price).toFixed(2)} CFA</strong>
                                      <strong>Prix proposé: {parseFloat(item.total_price).toFixed(2)} CFA</strong>
                                    <div className='rowsitm'>
                                    
                                    
                                       <div>

                           {item.order_status==0 && <span className='badge alert-warning'>en attente de validation</span>}
                                 {item.order_status==1 &&  <span className='badge alert-success'>Traité</span>}
                                 {item.order_status==6 &&  <span className='badge alert-danger'>Incomplet</span>}
                                 {item.order_status==5 &&  <span className='badge alert-success'>Validée</span>}
                                 {item.order_status==4 &&  <span className='badge alert-success'>En cours de livraison</span>}
                                 {item.order_status==3 &&  <span className='badge alert-success'>Livrée</span>}
                                 {item.order_status==2 &&  <span className='badge alert-danger'>Annulé</span>}
                                 {item.order_status==7 &&  <span className='badge alert-danger'>Refusée</span>}


                                       </div>
                                    </div>

                                 </div>
                                </a>
                              </div>


                          ))}
                           

                            
                             
                        </div> 
 
                           

                          </Row>

                    </form>
                        
                         






   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;