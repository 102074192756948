import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion' 
import { apiClient } from "../../../actions/api"

const Page = () => {
    const dispatch = useDispatch();
    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const fileInput3 = useRef(null)
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [counter, setCounter] = useState('')
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [documents, setDocuments] = useState([]);
    const handleCloseAlert = () => setShowAlert(false);
     const [issucces, setissucces] = useState(false);
     const [isloading, setisloading] = useState(false);
 
  

    useEffect(() => {
         getDocuments()
    }, []);

     useEffect(() => {
     
        
    }, []);
 

   const handleClickfile = (t) => {
    if(t==1){

      fileInput.current.click()
    }
    else if(t==2){
        fileInput2.current.click()
    }
    else{

      fileInput3.current.click()

    }
  }


   const handleFileChange = (event,type) => {
       
      const formData =  new FormData();
      formData.append('file',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      formData.append('type',type);
 
      setisloading(true)
   
       apiClient().post(`/save/documents`,formData )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                 setDocuments(res.data.documents)
                 setissucces(true)
                 setisloading(false)
              
                
              }
            
              
           })
     
  }
  
       function getDocuments() {
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
              type:'patient',

          }


          apiClient().post(`/get/documents`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setDocuments(res.data.documents)
              
                
              }
            
              
           })
     }


      function gotoCmd(id,item) {
       
     }
 



    return (
        <div className="mescommandes register dossierpatient bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='documents' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

     
                            <h3 className="mb-4" > Mes documents</h3>

   {
    issucces &&

  <div className='alert alert-success'>
   Votre document  a bien été enregistré.
 </div>
}

                             <div className='contentsection'>

                             

                                
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Documents patient</strong></Accordion.Header>
    <Accordion.Body>
  


 <br/>
    <div className='alert alert-primary'>Déposer une note ou un écrit à votre praticien référent.</div>
   
        <div className='flexitems'>
           <a href="javascript:void(0)"  onClick={() => handleClickfile(1)}   class="btnlink marauto">Déposer <i class="fa fa-plus"></i> 
            <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"
                accept='image/*'

                onChange={(e) => handleFileChange(e,1)}
                ref={fileInput} 
            />
            </form>
           </a>
          
        </div>
        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Praticien</th>
                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>

                         {
                        documents?.map((item,key)=>(

                          item.type===1 &&

                            <tr>
                              <td>{item.therapeut}</td>
                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>
                         
                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>
                               

                               
                               </div>
                              </td>

                          </tr>


                        ))
                      }

                    
          
                        </tbody>



                      </Table>

              </div>

          </Row>

   
     
    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Documents praticien</strong></Accordion.Header>
    <Accordion.Body>
   <br/>
    <div className='alert alert-primary'>Récupérer les notes et exercices de votre praticien référent.</div>
   
  
        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Praticien</th>
                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>

                      {
                        documents?.map((item,key)=>(

                          item.type===3 &&

                            <tr>
                              <td>{item.therapeut}</td>
                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>
                         
                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>
                               

                               
                               </div>
                              </td>

                          </tr>


                        ))
                      }
          
                        </tbody>



                      </Table>

              </div>

          </Row>

   
     
    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Comptes rendus et attestations</strong></Accordion.Header>
    <Accordion.Body>
   <br/>
    <div className='alert alert-primary'>Déposer et récupérer des comptes rendus et attestations.</div>
       <div className='flexitems'>
              <a href="javascript:void(0)"  onClick={() => handleClickfile(2)}   class="btnlink marauto">Déposer <i class="fa fa-plus"></i> 
            <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"
                accept='image/*'

                onChange={(e) => handleFileChange(e,2)}
                ref={fileInput2} 
            />
            </form>
           </a>
          
        </div>
  
        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Praticien</th>
                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>

                             {
                        documents?.map((item,key)=>(

                          item.type===2 &&

                            <tr>
                              <td>{item.therapeut}</td>
                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>
                         
                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>
                               

                               
                               </div>
                              </td>

                          </tr>


                        ))
                      }
          
                        </tbody>



                      </Table>

              </div>

          </Row>

   
     
    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
                             </div>



                         

                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

 <div className='mask' style={{display:isloading== true ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif" style={{width:'200px'}}  />
  </div>

            </Container>
        </div>
    );
}

export default Page;