import React,{useEffect,useState} from "react";
import './styles.scss';

import { useHistory } from "react-router";
import { useSelector,useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { SET_FORMULE,SET_FORMULE_UPDATE } from "../../constants/actions";

const Component = ({ item,isupdate}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user,formule, formuleupdate} = useSelector(state => state.user);
    const [avgnote,setavgnote]=useState(0);
    const [sumnote,setsumnote]=useState(0);

    const handleClick = () => {

          history.push(`/boutique/produit/${item.product_slug}/${item.product_id}`);
    }
   const goTo = () => {


          if(isupdate==='true'){


              if(formuleupdate?.formule){
                 let params={'tarif':formuleupdate?.tarif, 'formule':formuleupdate?.formule,'therapeut':item}
                 dispatch({ type: SET_FORMULE_UPDATE, payload: params })
                 history.push(`/profil-therapeute/${item.name}/${item.user_id}/?p=`+isupdate);

                  }
                else{


                     history.push('/');

                }




          }

          else{


                if(formule?.formule){
                 let params={'tarif':formule?.tarif, 'formule':formule?.formule,'therapeut':item}
                 dispatch({ type: SET_FORMULE, payload: params })
                 history.push(`/profil-therapeute/${item.name}/${item.user_id}`);

                  }
                else{

                           let params={'therapeut':item}
                           dispatch({ type: SET_FORMULE, payload: params })
                           history.push('/tarifs/?therapeute='+item?.user_id);

                }


          }






    }

      useEffect(()=>{

          let sum=0;
           for (var i = 0; i < item?.avg.length; i++) {
                sum=sum+parseInt(item?.avg[i].note)
           }
          if(sum>0){
            setavgnote(sum/item?.avg.length)
          }


    },[])




    return (

            <div className='therapeutescontent'>

                      <Row className=''>
                        <Col md={9} >
                             <div className='flexitems row'>
                                 <div className='itemimg col-md-3' style={{backgroundImage:item.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+item?.picture})` : "url('/images/default-profile.png')"}}>

                                 </div>
                                  <div className='itemheader col-md-9' >
                                    <h3>{item.first_name} {item.last_name}</h3>




                                 <div className="ps-product--detail ">
                                  <div className="ps-product__meta">
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                            <option value="1">1</option>
                                            <option value="1">2</option>
                                            <option value="1">3</option>
                                            <option value="1">4</option>
                                            <option value="2">5</option>
                                        </select>
                                    <div className="br-widget br-readonly">
                                    </div>
                                    </div>
                                    <span>{item?.avg.length} avis</span>
                                    </div>






                                </div>
                                </div>




                                 <p >{item?.description}</p>


                                  <div className='listtags'>

                                    {
                                      item?.methodes.map((met,i) =>(
                                         <div className='item'>{met?.tarif?.titre}</div>
                                      )
                                    )}



                                     </div>



                                 </div>
                             </div>


                        </Col>
                        <Col md={3} >

                           <div className='actionrdv'>

                               <div className="text-center" >

                                   <i class="fa fa-calendar" aria-hidden="true"></i>
                                   <a href='javascript:void(0)' onClick={()=>goTo()} className='btnlink marauto' >Prendre rendez-vous </a>

                                </div>

                           </div>

                        </Col>
                       </Row>

                    </div>

    );
}

export default Component;