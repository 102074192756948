import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import { BASE_URL_PROD } from "../../../components/config/keys";
import moment from 'moment'
import { apiClient } from "../../../actions/api"

const Page = () => {
     const dispatch = useDispatch();
     const history = useHistory()
     const queryParams = new URLSearchParams(window.location.search);
 const { id } = useParams()
     const [show,setShow]=useState(false);
     const [article,setarticl]=useState({});
   
     const { data,pageinfo } = useSelector(state => state.mixedmode);
 
 

    useEffect(()=>{
     
         apiClient().get(`/blog/`+id,{} )
           .then((res) => {
          
              if(res.data){
                
                    setarticl(res.data)
              }
              else{
              
               
            

              }
              
           })


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
 
   const handleShow = (item) => {
      
       
      setShow(true);

    }
 
 const goTo = () => {
      
       
     history.push('/list-psychologue')

    }
    return (
        <div className="home tarifs tests" >
      

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Tests</li></ul></div></div></div>
         </section>


            <section className="section2 " >
                <Container>
                    <h2 className="text-center mb-3"> {article?.titre} <br/></h2>
                     
      
                </Container>
            </section>

      

             <section className="section2 section " >
                <Container>



   



                 <Row className="ps-block--site-features ps-block--site-features-2">

                   
                      <Col md={12} >

                            <div className="ps-block__left text-center"><img src={'https://feel-better-company.com/gestion/public/articles/'+article?.img}/></div>
                             <br/><br/>
                      </Col>

 

              
                   </Row>





                    
                  

                   <Row className="ps-block--site-features ps-block--site-features-2">

                   
                      <Col md={12}>

                          <div dangerouslySetInnerHTML={{ __html:article?.description}}></div>
                         
                      </Col>

                   </Row>



{
  /*

    <div class="ps-block--site-features ps-block--site-features-2">
                      <div class='col-md-6'>

                      <section class='buleright'>
                        
                       <div> <h2>À RETENIR</h2></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>

                      </section>
                      </div>
     </div>

     <div class="ps-block--site-features ps-block--site-features-2">
                      <div class='col-md-6'>

                      <section class='buleft'>
                        
                       <div> <h2>Les conseils de Satinka Sansòn, fondatrice de Feel Better™</h2></div>
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                      
                       <strong>Mes tips de psy</strong>
                        <br/>
                       <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                         <li>Lorem ipsum dolor sit amet</li>
                          <li>Lorem ipsum dolor sit amet</li>
                           <li>Lorem ipsum dolor sit amet</li>

                       </ul>

                      </section>
                      </div>
     </div>



  */




}
   

        
                     <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2'>
                        
                    <a href='javascript:void(0)' style={{    marginTop: '0px'}}  onClick={()=>history.push('/list-psychologue')} >Contactez un praticien Feel Better™</a>
   
                      </section>
                      </Col>
                   </Row>

       

{
  article?.test && 
  <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2'>
                        
                    
                        <a href='javascript:void(0)' style={{    marginTop: '0px'}} onClick={()=>history.push('/tests?id='+article?.test)} >Faites le test !</a>

                      </section>
                      </Col>
                   </Row>
}

                   

                  <Row className="ps-block--site-features ps-block--site-features-2">
                      <Col md={6}>

                      <section className='bul2'>
                        
                    
                        <a href='javascript:void(0)' style={{marginTop: '0px'}} onClick={()=>history.push('/articles')} >Articles</a>

                      </section>
                      </Col>
                   </Row>




       


                </Container>

              </section>
            


 
 

<div className='forseparatefooter'></div>


 
 



 
        </div>
    );
}

export default Page;