/* eslint-disable no-underscore-dangle */
import EventEmitter from 'events';
import createLogger from '../../helpers/log';
import AudioFallbackVideoStates from '../publisher/audioFallbackVideoStates';
import { CongestionLevels } from './congestionLevel/congestionLevels';

const logging = createLogger('AudioFallbackState');

const createAudioFallbackStateMachine = () => {
  const ee = new EventEmitter();
  let congestionLevel = CongestionLevels.LOW;
  let videoState = AudioFallbackVideoStates.ACTIVE_VIDEO;

  const _setVideoState = (state) => {
    videoState = state;
    ee.emit('stateChange', { state });
  };

  const _transitionToLow = () => {
    congestionLevel = CongestionLevels.LOW;
    _setVideoState(AudioFallbackVideoStates.ACTIVE_VIDEO);
  };

  const _transitionToMedium = () => {
    if (congestionLevel === CongestionLevels.LOW) {
      _setVideoState(AudioFallbackVideoStates.ACTIVE_VIDEO_WITH_WARNING);
    }
    congestionLevel = CongestionLevels.MEDIUM;
  };

  const _transitionToCritical = () => {
    congestionLevel = CongestionLevels.CRITICAL;
    _setVideoState(AudioFallbackVideoStates.SUSPENDED_VIDEO);
  };

  const _transitions = {
    [CongestionLevels.LOW]: _transitionToLow,
    [CongestionLevels.MEDIUM]: _transitionToMedium,
    [CongestionLevels.CRITICAL]: _transitionToCritical,
  };

  return Object.assign(ee, {
    setCongestionLevel(level) {
      if (congestionLevel === level) {
        return;
      }
      const transition = _transitions[level];
      if (transition) {
        transition();
      } else {
        logging.warn(`Invalid congestion level: ${level}`);
      }
    },
    getState() {
      return { videoState, congestionLevel };
    },
    setInternalState(state) {
      // Sets internal state without transitions or events
      videoState = state.videoState;
      congestionLevel = state.congestionLevel;
    },
    reset() {
      congestionLevel = CongestionLevels.LOW;
      videoState = AudioFallbackVideoStates.ACTIVE_VIDEO;
    },
  });
};

export default createAudioFallbackStateMachine;
