import React,{useEffect,useState} from "react";
import './styles.scss';
import { useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { useHistory } from "react-router";
import Slider from "react-slick";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { apiClient } from "../../actions/api"
import { getTherapeutLocalAgenda, agendaClickToParams } from "../../actions/api_interface"
import { useParams } from "react-router";
import moment from 'moment'

import Agenda       from '../../components/agenda/'



const Page = () => {
    const history = useHistory()
    const {slug,id } = useParams()
    const [json, setJson] = useState([]);
    const [avgnote,setavgnote]=useState(0);
    const [value, setValue] = useState(0);
    const [show,setShow]=useState(false);
    const [therapeut,setTherapeut]=useState({});

     const { data,pageinfo,timezone } = useSelector(state => state.mixedmode);
     const { user,formule } = useSelector(state => state.user);



    useEffect(() => {
      getdatatherapeut()
    },[])

   function getdatatherapeut(){

      apiClient().get(`/getparticien/`+id)
           .then((res) => {

             setTherapeut(res.data)

          let sum=0;
           for (var i = 0; i < res.data?.avg?.length; i++) {
                sum=sum+parseInt(res.data?.avg[i].note)
           }
          if(sum>0){
            setavgnote(sum/res.data?.avg.length)
          }

      })

        let isgroupe=formule?.tarif?.titre == 'Groupe de parole' ? 1 : 0;

        let newtimezone=localStorage.getItem('tiemzone') ? localStorage.getItem('tiemzone'): 'non';

        let params22={timezone:newtimezone}

        apiClient().post(`/getDates/`+id+'/'+isgroupe,params22)
        .then((res) => {
          // let localAgenda = getTherapeutLocalAgenda(res.data)
          // setJson(localAgenda)
          setJson(res.data.data)
        })
    }


  const handleClose = () => setShow(false);

   const onSelect = (day, hourState) => {
     if (hourState.state === 1) {
      let params = agendaClickToParams(day, hourState, null)
      params.token     = user.api_token
      params.api_token = user.api_token
      params.id        = slug
      params.action    = 'deplacer'

      apiClient().post(`/set/reservation`,params )
      .then((res) => {
        if (res.data.success=='ok') {
          history.push('/client/dashboard')
        }
      })
    }
  }

    const goTo = () => {


     history.push('/profil-therapeute/Jaean-Piere')

    }

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    initialSlide:0,
     responsive: [

        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };


    return (
        <div className="home profiltherapeut" >
          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>praticien</li><li>{therapeut?.first_name} {therapeut?.last_name}</li></ul></div></div></div>
          </section>


          <section className="section2 thisprofil listtherapeutes " >
                <Container>

                    <div className='therapeutescontent'>

                      <Row className=''>
                        <Col md={9} >
                             <div className='flexitems row'>
                                 <div className='itemimg col-md-3' style={{backgroundImage:therapeut.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+therapeut?.picture})` : "url('/images/default-profile.png')"}}>

                                 </div>
                                  <div className='itemheader col-md-9' >
                                    <h3>{therapeut?.first_name} {therapeut?.last_name}</h3>




                                 <div className="ps-product--detail ">
                                  <div className="ps-product__meta">
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                            <option value="1">1</option>
                                            <option value="1">2</option>
                                            <option value="1">3</option>
                                            <option value="1">4</option>
                                            <option value="2">5</option>
                                        </select>
                                    <div className="br-widget br-readonly">
                                    </div>
                                    </div>
                                    <span>{therapeut?.avg?.length}  avis</span>
                                    </div>






                                </div>
                                </div>




                                  <div className='listtags'>
                                   {
                                      therapeut?.methodes?.map((met,i) =>(
                                         <div className='item'>{met?.tarif?.titre}</div>
                                      )
                                    )}



                                     </div>



                                 </div>
                             </div>


                        </Col>
                        <Col md={3} >

                           <div className='actionrdv'>

                               <div className="text-center" >

                                    {
                                    /*
                                        <a href='javascript:void(0)' onClick={()=>goTo()} className='btnlink marauto' >Prendre rendez-vous </a>
                                    */
                                    }



                                </div>

                           </div>

                        </Col>
                       </Row>


                       <Row>
                         <Col md='12'>
                               <Box sx={{ width: '100%' }}>
                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs   value={value} onChange={handleChange} aria-label="basic tabs example">

                                       <Tab    label="Agenda" {...a11yProps(0)} />

                                    </Tabs>
                                  </Box>
                             </Box>
                         </Col>
                       </Row>

                    </div>



                </Container>
          </section>





<TabPanel value={value} index={0} className='tabpnel'>

           <section className="section2 " >
                <Container className='section30039'>

                <div className='row '>
                  <div className='col-md-12 '>
                        <h3 className='text-center'>DEMANDEZ UN RENDEZ-VOUS À <span style={{textTransform:'uppercase'}}>{therapeut.first_name}</span></h3>
                        <br/>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-md-12 bgplaning'>
                    <Agenda onSelect={onSelect} formule={formule} />
                    </div>
                  </div>



                </Container>
          </section>


</TabPanel>








      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
      </Modal>
        </div>
    );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;