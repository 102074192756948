import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'
import { apiClient } from "../../../actions/api"
 


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [name, setName] = useState('')
    const [type, setType] = useState(1)
    const [patients,setpatients]=useState([]);

    const [eventconsulation, setEventconsulation] = useState('')

   

    const [counter, setCounter] = useState('')
    const history = useHistory();

      const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);
 
    useEffect(() => {


        getPatients()
    }, []);



     function getPatients() {
        let params={
           
              token:user.api_token,
              api_token:user.api_token,
          }


          apiClient().post(`/get/patients`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setpatients(res.data.patients)
              
                
              }
            
              
           })
     }

 
    
      function gotoCmd(id,item) {
       //dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/praticien/dossier-patient/${id}`);
     }
 

 



 
  const toggle = ({ event, el }) => {
     //console.log(event)

     setShowAlert(true)
  };


   const serachpatient =  (e) => {
      
       e.preventDefault();

       if(name){
           let params={
           
              token:user.api_token,
              api_token:user.api_token,
              name:name,
          }


          apiClient().post(`/get/patients`,params )
           .then((res) => {
          
              if(res.data.success=='ok'){
                
                setpatients(res.data.patients)
              
                
              }
            
              
           })

       }
  

    }

 const datediff =  (date) => {
      
      
    let daterdv=date

    if(moment().diff(daterdv, 'hours') < 24){
       if(moment().diff(daterdv, 'hours')==0){
         return '-'
       }
       else{
         return  moment().diff(daterdv, 'hours') + ' heure(s) de thérapie'

       }
       
    }
    else if(moment().diff(daterdv, 'days') < 30){
        return  moment().diff(daterdv, 'days') + ' jour(s) de thérapie'
    }
    else{
        return  moment().diff(daterdv, 'month') + ' mois de thérapie'
    }
    

          
 
  }


    return (
        <div className="mescommandes register vosventes bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <MenuVendeur slug='patients' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5 menudashright"  >

     
                          <h3 className="mb-4" > Mes patients</h3>


        <div className='filtreorder'>
                <div className='item'>

                    <div className='colitem'>
                      <div >
                        
                      </div>

                     <div  className='iiin'>
                      
                     </div>

                    
                   


                    </div>


                </div>
                 <div className='item' style={{width: '30%'}}>
                       <div className='item listing ' style={{width: '100%'}}>
                    <div className="form-group--nest" style={{    width: '100%'}}><input className="form-control" type="text"  onChange={(event)=>setName(event.target.value)}  placeholder="Rechercher un patient.." /><div className='ico' onClick={(e)=>serachpatient(e)} ><i className="fa fa-search"></i></div></div>
                  </div>
                </div>


            </div>

                           

                      <div className='agenda'>


                        <form className="mb-5 mb5mob" >
                           
                            
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                          <Table responsive  >
                          <thead>
                            <tr>
                                <th key="0">Référence</th>
                                <th key="2">Patient</th>
                                <th key="3" >Méthode</th>
                                <th key="4">Formule</th>
                                <th key="5" >Durée</th>
                                <th key="6" >Actions</th>

                            </tr>
                          </thead>
                        <tbody>

                        {
                            patients.map((item,key)=>(


                            <tr>
                              <td>{item?.id}{moment(new Date(item?.created_at)).format("DDM")}</td>
                              
                              <td>
                                {item?.patient?.name}
                              </td>
                              <td>{item?.tarif?.titre}</td>
                              <td>

                               {item?.formule?.titre}

                              </td>
                               <td>
                                {datediff(item?.created_at)}
                                 
                              </td>
                              <td>
                              <div className="list-contr">

                                  <a onClick={() => {gotoCmd(item?.patient?.user_id,'')}} href="javascript:void(0)" className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Voir dossier<i class="icon-eye" style={{paddingLeft:'5px'}}></i></a>
                               

                               
                               </div>
                              </td>
                            </tr>


                              ))
                        }
 

                    
                        
                          
                          
                          

                          </tbody>
                        </Table>
                          </div>
                           
                      

                        <div className='tavblemob'>

 
                           

                            
                             
                        </div> 
 
                           

                          </Row>

                    </form>


                          </div>



                         

                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} size="lg"  centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>



        <Row className="mb-3 abonnement text-center "  >


          <div className='itemimg col-md-3' style={{backgroundImage:"url('/images/product15.png')"}}></div>
          <h3 style={{marginBottom: '3px',marginTop: '18px'}}>Ahmed AITSAKEL</h3>
          <div style={{marginBottom: '30px'}}>
          <a href='' style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Contacter A.aitsakel</a>
          </div>
         

                  <div className='row'>
                                        
                         
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Dossier du patient: <br/><strong style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Voir</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Séances:<br/> <strong> 4/5 séances effectuées</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Date:<br/> <strong>01/10/2022</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Heure:<br/> <strong>09:00</strong></div>

                  </div>

                


          <div>


 
             <div class="flexitems"><a href="javascript:void(0)" class="btnlink marauto">Modifier  </a><a href="javascript:void(0)"  class="btnlink marauto btnlinkjaun">Annuler   </a> <a href="javascript:void(0)"  class="btnlink marauto btnlinkred">Non honoré   </a> </div>




          </div>
              

          </Row>







        
        </Modal.Body>
        <Modal.Footer>
        {
          (eventconsulation ==1 || eventconsulation==2) &&

          <div className='alert alert-warning footermodflex'>Rappel : tout rendez-vous reporté ou annulé moins de 24 à l’avance sera prélevé.  <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Je confirme
          </Button></div>


        }
         
          
        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;