import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { CustomRoute } from "../components";
import {
  Home,
  Tarifs,
  Therapeutes,
  Profiltherapeut,
  Agendatherapeut,
  Paiementtherapeut,
  Infopatient,
  Paiementvalidation,
  Listing,
  Basket,
  DetailProduct,
  Commande,
  CommandeConfirmation,
  Methodes,
  Tests,
  TestResultat,
  Parametres,
  Messages,
  Abonnement,
  Documents,
  Dayli,
  Daylipatient,
  Articles,
  ArticlesDetail,
  Numeros,
  Finconsultation,
  Parainage,

  DashboardVendeur,
  MesrendezvousPro,
  Patients,
  Agenda,
  DossierpatientPro,
  Messagespro,
  BoutiquemenuVendeur,
  ProfileVendeur,
  AdressesVendeur,
  CommandesVendeur,
  LivraisonVendeur,
  DetailLivraisonVendeur,
  CommandeDetailVendeur,
  HistoriqueVendeur,
  ReclamationsVendeur,
  BoutiquesfavorisVendeur,
  AddproductVendeur,
  ListProductVendeur,
  ListproductpromoVendeur,
  updateproductVendeur,
  Liststories,
  Addstorie,
  Listcomments,
  Webcam,
  vosventes,


  Commandes,

  Fashion,
  Stories,

  Afrocreation,
  ListingAfrocreation,
  Ventout,
  ListingVentout,
  Surcommande,
  ListingSurcommande,
  Enpromo,
  DevenirVendeur,
  DevenirVendeurRegister,

  Login,
  Register,
  Comptecree,
  ForgetPassword,
  Platformes,

  Commentcamarche,
  Mesrendezvous,
  Validation,


  Profile,
  Dashboardclient,

  Reclamations,

  CommandeDetail,
  Favoris,
  Boutiquesfavoris,
  Dossierpatient,
  OffresClient,
  DetailOffre,


  DashboardLivreur,
  CommandesLivreur,
  CommandeDetailLivreur,
  ProfileLivreur,

  DashboardRamasseur,
  CommandesRamasseur,
  CommandeDetailRamasseur,
  ProfileRamasseur,

  DashboardAdmin,
  CommandesAdmin,
  CommandeDetailAdmin,
  ProfileAdmin,

  Historique,
  Apropos,
  Contact,
  Aide,
  Cgv,
  Cgu,
  Rgbd,
  Retour,
  Livraison,
  Adresses,

  Daylidev,
  RoomCall, Vonage, RoomCallVonage
} from "../pages";

const MainNavigator =()=> {
  return (
    <Router >
    <Switch>
    <CustomRoute parts exact path="/" component={Home} />
    <CustomRoute parts back={1} exact path="/tarifs" component={Tarifs} />
    <CustomRoute parts back={1}  path="/nos-methodes/" component={Methodes} />
    <CustomRoute parts back={1}  path="/tests/" component={Tests} />
    <CustomRoute parts back={1}  path="/votre-resultat/" component={TestResultat} />
    <CustomRoute parts back={1}  path="/articles/" component={Articles} />
    <CustomRoute parts back={1}  path="/article/:id" component={ArticlesDetail} />
    <CustomRoute parts back={1} exact path="/list-psychologue" component={Therapeutes} />
    <CustomRoute parts back={1} exact path="/profil-therapeute/:slug/:id" component={Profiltherapeut} />
    <CustomRoute parts back={1} exact path="/agenda-psychologue/:slug/:id" component={Agendatherapeut} />
    <CustomRoute parts back={1} exact path="/infos-patient/" component={Infopatient} />
    <CustomRoute parts  exact path="/paiement" component={Paiementtherapeut} />
    <CustomRoute parts back={1} exact path="/paiement-valide" component={Paiementvalidation} />
    <CustomRoute parts back={1} path="/produits/" component={Listing} />
    <CustomRoute parts back={1} path="/boutique/produit/:slug2/:id" component={DetailProduct} />
    <CustomRoute parts back={1} path="/panier" component={Basket} />
    <CustomRoute parts back={1}  path="/finalisez-commande" component={Commande} />
    <CustomRoute parts back={1}   path="/commande/confirmation/:id" component={CommandeConfirmation} />
      {/*au clic sur rejoindre la séance, je suis redirigée sur ce lien, c'est la salle d'attente*/}
    {/*<CustomRoute parts back={1} path="/conference/patients/:id" component={Dayli} />*/}
    <CustomRoute parts back={1} path="/conference/patients/:id" component={Vonage} />
    <CustomRoute parts back={1} path="/conference/call/:id" component={Daylipatient} />
    <CustomRoute parts back={1} path="/numeros-d-urgence" component={Numeros} />
    <CustomRoute parts  path="/fin-consultation/:slug/:id" component={Finconsultation} />

    <CustomRoute parts  back={1}  path="/commandes" component={Commandes} />
    <CustomRoute parts  back={1} path="/adresses" component={Adresses} />

    <CustomRoute parts  back={1} path="/profile" component={Profile} />
    <CustomRoute parts  back={1} path="/parametres" component={Parametres} />
    <CustomRoute parts  back={1} path="/messages" component={Messages} />
    <CustomRoute parts  back={1} path="/abonnement" component={Abonnement} />
    <CustomRoute parts  back={1} path="/documents" component={Documents} />

    <CustomRoute parts  path="/client/dashboard" component={Mesrendezvous} />
    <CustomRoute parts  path="/client/validation" component={Validation} />
    <CustomRoute parts  back={1} path="/client/mesrendezvous" component={Mesrendezvous} />
    <CustomRoute parts  back={1} path="/dossier-patient" component={Dossierpatient} />
    <CustomRoute parts  back={1} path="/mes-offres-recus/:id" component={OffresClient} />
    <CustomRoute parts   back={1} path="/detail-offre/:id" component={DetailOffre} />
    <CustomRoute parts  back={1} path="/commande/detail/:id" component={CommandeDetail} />
    <CustomRoute parts  back={1} path="/historique" component={Historique} />
    <CustomRoute parts back={1}  path="/reclamations/" component={Reclamations} />
    <CustomRoute parts back={1}  path="/mes-articles-favoris/" component={Favoris} />
    <CustomRoute parts back={1}  path="/mes-boutiques-preferees/" component={Boutiquesfavoris} />
    <CustomRoute parts back={1}  path="/parrainage/" component={Parainage} />


    <CustomRoute parts  path="/praticien/dashboard/" component={MesrendezvousPro} />
    <CustomRoute parts back={1} path="/praticien/profile" component={ProfileVendeur} />
    <CustomRoute parts back={1} path="/praticien/patients" component={Patients} />
    <CustomRoute parts back={1} path="/praticien/dossier-patient/:id" component={DossierpatientPro} />
    <CustomRoute parts back={1} path="/praticien/agenda" component={Agenda} />
    <CustomRoute parts back={1} path="/praticien/messages" component={Messagespro} />

    <CustomRoute parts  back={1} path="/praticien/historique" component={HistoriqueVendeur} />
    <CustomRoute parts  back={1} path="/praticien/ajouter-produit/" component={AddproductVendeur} />
    <CustomRoute parts  back={1} path="/praticien/liste-produits/" component={ListProductVendeur} />
    <CustomRoute parts  back={1} path="/praticien/promo/liste-produits/" component={ListproductpromoVendeur} />
    <CustomRoute parts  back={1} path="/praticien/modifier-produit/:id" component={updateproductVendeur} />
    <CustomRoute parts  back={1} path="/praticien/stories" component={Liststories} />
    <CustomRoute parts  back={1} path="/praticien/ajouter-storie" component={Addstorie} />
    <CustomRoute parts  back={1} path="/praticien/modifier-storie/:id" component={Addstorie} />
    <CustomRoute parts  back={1} path="/praticien/commentaires" component={Listcomments} />
    <CustomRoute parts  back={1} path="/praticien/reclamations" component={ReclamationsVendeur} />
    <CustomRoute   back={1} path="/praticien/screen-video" component={Webcam} />
    <CustomRoute parts  back={1} path="/praticien/vos-ventes" component={vosventes} />







    <CustomRoute parts  back={1} exact path="/stories" component={Stories} />
    <CustomRoute parts back={1} exact path="/fashion-style" component={Fashion} />

    <CustomRoute parts back={1} exact path="/afro-creation" component={Afrocreation} />
    <CustomRoute parts back={1} path="/afro-creation/produits/:slug" component={ListingAfrocreation} />
    <CustomRoute parts back={1} exact path="/ventout/produits/:slug" component={ListingVentout} />
    <CustomRoute parts back={1} exact path="/ventout" component={ListingVentout} />
    <CustomRoute parts back={1} path="/ventout/produit/:slug" component={Ventout} />
    <CustomRoute parts back={1} exact path="/sur-commande" component={Surcommande} />
    <CustomRoute parts  back={1} path="/sur-commande/produits/:slug" component={ListingSurcommande} />
    <CustomRoute parts  back={1} path="/en-promo/produits/:slug" component={Enpromo} />

    <CustomRoute parts back={1} path="/boutiques/:slug/:id/:categorie" component={Platformes} />

    <CustomRoute parts back={1} path="/devenir-vendeur" component={DevenirVendeur} />
    <CustomRoute parts back={1} path="/vendeur/inscription/:slug" component={DevenirVendeurRegister} />
    <CustomRoute parts back={1} path="/login" component={Login}  />
    <CustomRoute parts back={1} path="/login/to/:id" component={Login}  />
    <CustomRoute parts back={1} path="/register" component={Register}  />
    <CustomRoute parts back={1} path="/compte-cree" component={Comptecree}  />
    <CustomRoute parts back={1} path="/password" component={ForgetPassword}  />







    <CustomRoute parts  path="/livreur/dashboard/" component={DashboardLivreur} />
    <CustomRoute parts back={1} path="/livreur/commandes/" component={CommandesLivreur} />
    <CustomRoute parts back={1}  path="/livreur/commande/detail/:id" component={CommandeDetailLivreur} />
    <CustomRoute parts back={1} path="/livreur/profile/" component={ProfileLivreur} />

    <CustomRoute parts  path="/ramasseurs/dashboard/" component={DashboardRamasseur} />
    <CustomRoute parts back={1} path="/ramasseurs/commandes/" component={CommandesRamasseur} />
    <CustomRoute parts back={1}  path="/ramasseurs/commande/detail/:id" component={CommandeDetailRamasseur} />
    <CustomRoute parts back={1} path="/ramasseurs/profile/" component={ProfileRamasseur} />

    <CustomRoute parts  path="/admin/dashboard/" component={DashboardAdmin} />
    <CustomRoute parts back={1} path="/admin/commandes/" component={CommandesAdmin} />
    <CustomRoute parts back={1}  path="/admin/commande/detail/:id" component={CommandeDetailAdmin} />
    <CustomRoute parts back={1} path="/admin/profile/" component={ProfileAdmin} />


    <CustomRoute parts  back={1} path="/apropos" component={Apropos} />
    <CustomRoute parts back={1}  path="/commentcamarche" component={Commentcamarche} />
    <CustomRoute parts  back={1} path="/aide" component={Aide} />
    <CustomRoute parts  back={1} path="/contact" component={Contact} />
    <CustomRoute parts  back={1} path="/cgv" component={Cgv} />
    <CustomRoute parts  back={1} path="/cgu" component={Cgu} />
    <CustomRoute parts  back={1} path="/rgbd" component={Rgbd} />
    <CustomRoute parts back={1} path="/retour" component={Retour} />
    <CustomRoute parts back={1} path="/livraison" component={Livraison} />

    <CustomRoute part back={1} path="/daylidev" component={Daylidev} />
    {/*<CustomRoute part back={1} path="/room-call/:id/:refresh" component={RoomCall} />*/}
    {/*<CustomRoute part back={1} path="/room-call/:id/" component={RoomCall} />*/}
    <CustomRoute part back={1} path="/room-call/:id/:refresh" component={RoomCallVonage} />
    <CustomRoute part back={1} path="/room-call/:id/" component={RoomCallVonage} />



    </Switch>
    </Router>
    );
}

export default MainNavigator;