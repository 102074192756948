import React,{useEffect,useState,useRef} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { VentoutItem } from "../../../components";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Accordion from 'react-bootstrap/Accordion'
import { getBest,getCategoryList, getColors, getTails,getsousCategoryList,getListProduct } from "../../../actions/mixedmode";
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import ReactPaginate from 'react-paginate';


function valuetext(value) {
  return `${value}°C`;
}
const Page = () => {
    const dispatch = useDispatch();
    const { slug } = useParams()
    const history = useHistory();
      const { user } = useSelector(state => state.user)
 
    const [show,setShow]=useState(false);
    const [showColor,setShowColor]=useState(false);
    const [showTaille,setShowTaille]=useState(false);

    const [valColor,setValColor]=useState(false);
    const [valColorid,setValColorid]=useState('');
    const [valTaille,setValTaille]=useState(false);
    const [Price,setPrice]=useState(0);
    const [fraiservice,setFraisService]=useState(0);
    const [yourprice,setYourprice]=useState(0);


    const pointure=[31,32,33,34,35, 36, 37,38];
    const tailadulte=['XS','S','M','L','XL','2XL','3XL','4XL','5xl','6XL'];
    const tailenfant=['1 MOIS','2 MOIS','3 MOIS','1 AN','2 ANS','3 ANS','6 ANS','10 ANS'];
   
    const {categorieslist,souscategorieslist, colors, tails,dataamazon,pageCount } = useSelector(state => state.mixedmode)
    const [showcategorie,setShowcategorie]=useState(false);
    const [categid,setCategid]=useState('');
    const [souscategid,setSousCategid]=useState('');
    const [colorid,setColorid]=useState([]);
    const [tailid,setTailid]=useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [rubrique, setRubrique] = useState(3)
    const [listprodutc,setListProduct]=useState([]);
    const [filtres,setFiltres]=useState([]);
    const [search, setSearch] = useState('')
    const [order, setOrder] = useState('')
    const [counter, setCounter] = useState('')
    const [ifprice, setIfprice] = useState('')
    const [minprice, setMinprice] = useState(7000)
    const [maxprice, setMaxprice] = useState(100000)

    const [photo1, setPhoto1] = useState('')
    const [photo2, setPhoto2] = useState('')
    const [photo3, setPhoto3] = useState('')
    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const fileInput3 = useRef(null)
    const fileInput4 = useRef(null)

    const [video, setVideo] = useState('')
    const [videoname, setVideoname] = useState('')
    const [ifloading, setIfloading] = useState(0)




     const [showmodal, setShowmodal] = useState(false);
      const handleCloseP = () => setShowmodal(false);
     const handleShowP = () => setShowmodal(true);

      const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);


     const setPricevalue = (val) => {
          
          if(val==''){
             val=0
          }
          setPrice(val)
        

          let percent=val - (val * 10 / 100) 
          let service=val - percent;

          setFraisService(percent)
          setYourprice(service)

     }

  const handleClickfile = (t) => {

      switch(t){
             case 1:
               fileInput.current.click()
             break;
              case 2:
               fileInput2.current.click()
             break;
              case 3:
               fileInput3.current.click()
             break;
               case 4:
               fileInput4.current.click()
             break;


      }
     
  }


     const [value, setValue] = useState([7, 180]);
     const handleChange = (event, newValue) => {
       setValue(newValue);
       setMinprice(parseFloat(newValue[0]) * 1000)
       setMaxprice(parseFloat(newValue[1]) * 1000)
       setIfprice(1)
       setCounter(1)

     };

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     const handleCloseColor = () => setShowColor(false);
     const handleShowColor = () => setShowColor(true);
     const handleCloseTaille = () => setShowTaille(false);
     const handleShowTaille = () => setShowTaille(true);
   

    useEffect(()=>{

      let params={slug:rubrique}
        dispatch(getCategoryList(rubrique))
        dispatch(getListProduct(currentPage,params))
        dispatch(getsousCategoryList(rubrique))
        dispatch(getColors())
        dispatch(getTails())

          if(Number.isInteger(parseInt(slug))){
            
            setCategid(slug)
            setCounter(1)
        }

        window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };
    },[])

    const isSticky = (e) => {
    const header = document.querySelector(".filtrerightmobile");
    const scrollTop = window.scrollY;
    
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    };

     const handleClick = (slug) => {
        history.push(`/boutiques/${slug}#`);
    }

     const setValColorF = (code,id) => {
         setValColor(code)
         setValColorid(id)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
 
        
    }
     const handlerMenu = () => {
       
          setShowcategorie(!showcategorie)

    }

     const handleGoTo = (slug) => {
        history.push(slug);
    }

 const setOrderfiltre = (val) => {
        setOrder(val)
        setCounter(1)

    }

   const initfiltre = () => {
     setCategid('')
     setSousCategid('')
     setColorid([])
     setTailid([])

  }

  const searchproduct = (e) => {
          e.preventDefault();
 
          if(search){
             let params ={slug:rubrique,search:search,order:order}
             dispatch(getListProduct(currentPage,params))

          }
          else{
           
             let params ={slug:rubrique,order:order}
             dispatch(getListProduct(currentPage,params))

         
          }

          initfiltre()

    }


   useEffect(() => {
   if(counter){
      setFormFiltre()
   }
   console.log('Do something after counter has changed', counter);
}, [counter]);


 
    const HandleFiltre =  (slug,type,e) => {
      
 
      
e.preventDefault();
          switch(type){
             case 'categorie':
                setCategid(slug)
                dispatch(getsousCategoryList(rubrique,slug))
                setCounter(1)

             break;

             case 'souscategorie':

                 setSousCategid(slug)
                 setCounter(1)
             break;

             case 'color':
             
                 let newcolors=[...colorid];
                 var index = newcolors.indexOf(slug)
             
                 if(index > -1){
 
                    newcolors.splice(index,1);
                      setColorid(newcolors)
                      console.log(newcolors)
                      setCounter(1)
                 }
                 else{
                     newcolors.push(slug) 
                   setColorid(newcolors)
                   setCounter(1)
                 }
                 
                   
             break;

             case 'tail':
              
                 let newtail=[...tailid];
                 var index = newtail.indexOf(slug)
                 if(index > -1){

                    newtail.splice(index,1);
                    setTailid(newtail)
                    setCounter(1)
                 }
                 else{
                     newtail.push(slug) 
                     setTailid(newtail)
                     setCounter(1)
                 }
                 
             break;

             case 'price':
                
             break;


          }

        
            
 
    }

    const  setFormFiltre = async () => {
      let params ={filtre:'1',slug:rubrique,order:order}


      if(ifprice==1){
          params.price=value
      }

      if(categid){
        
        params.categorie=categid

      }
      if(souscategid){
         
         params.souscateg=souscategid
      }
      if(colorid){
           params.colors=colorid

      }
      if(tailid){
           params.tails=tailid

      }
  
     dispatch(getListProduct(currentPage,params))
     setCounter('')

    }
    
    const handleSubmitAnnonce = (e) => {
        
        e.preventDefault();
        const formData = new FormData(e.target)
        let formDataObj = Object.fromEntries(formData.entries())

        formDataObj.user_id=user.user_id;
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.type='ventout';

         if(video==''){
         
            setAlertMessage("Veuillez ajouter une vidéo de 30s maximum ! ")
              setShowAlert(true)
          return false
       }



        if(photo1=='' && photo2=='' && photo3=='' ){
          
           setAlertMessage("Veuillez ajouter au moins une photo ! ")
              setShowAlert(true)
          return false
       }


     

         apiClient().post(`/addnewproduct`,formDataObj)
              .then((res) => {

                if(res.data.success=='ok'){

                    handleClose()
                    handleShowP()

 

                }
                else{
                alert('Erreur !')
                }
                 
        })



    }


    const handleFileChange = (event,t) => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);

      if(t==1 || t==2 || t==3){

            if(!isImage(event.target.files[0].name)){
              
                setAlertMessage("Veuillez sélectionner une image valide (png,jpg,jpeg,gif..)")
                setShowAlert(true)
               return false;
            }

            formData.append('typefile','image');

       }
       else{
            if(!isVideo(event.target.files[0].name)){
               
                   setAlertMessage("Veuillez sélectionner une vidéo valide (mp4,mov,avi,m4v..)")
                   setShowAlert(true)
                   return false;
            }
            formData.append('typefile','video');
       }

        setIfloading(1)



       switch(t){
             case 1:
               
             break;
              case 2:
                
             break;
              case 3:
                
             break;


      }
    
      apiClient().post(`/uploadphotos`,formData)
              .then((res) => {

                if(res.data.status=='successs'){
                  
                     setIfloading(0)
        switch(t){
             case 1:
                setPhoto1(res.data.message)
             break;
              case 2:
                 setPhoto2(res.data.message)
             break;
              case 3:
               setPhoto3(res.data.message)
                
             break;
              break;
                case 4:
               setVideo(res.data.message)
               setVideoname(event.target.files[0].name)
                
             break;


      }

                }
                else{
                     setIfloading(0)
                alert('Erreur !')
                }
                 
        })
     
  }




function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'jpeg':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
      // etc
      return true;
  }
  return false;
}

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}


      const   handlePageClick= (data) => {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        setCurrentPage(page)
         setCounter(1)
         window.scrollTo({top: 0});


           
    }









    return (
        <div className="categories listing ventout" >

          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Ventout</li></ul></div></div></div>
         </section>

            <section className='bgcouverture' style={{background:'#FFF'}}>
             <div className='headerpageimgweb' style={{backgroundImage:"url('/images/ventoutpalet.jpg')", backgroundSize: 'contain'}}></div>
         </section>

         <section className='section2'>
            <div className="container">

              <div className=" bigcategories">
                       
                    <div className='item '>
                        <a href='javascript:void(0)' className='fashion ' onClick={()=>handleGoTo('/fashion-style/produits/Homme#')}><img src='/images/fashionrond.jpg' /></a>
                        <label>Fashion</label>
                       </div>

                       <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/afro-creation/produits/Homme#')}   className='afrocreation ' ><img src='/images/afrocreationrond.jpg' /></a>
                        <label>Afro Creation</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' className='ventout' ><img src='/images/ventoutrond.jpg' /></a>
                         <label>Ventout</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/sur-commande/produits/1#')}  className='surcommande' ><img src='/images/surcommanderond.jpg' /></a>
                         <label>Sur commande</label>
                       </div> 
                         
            </div>




    <div className='headerpageimg' style={{backgroundImage:"url('/images/ventoutpalet.jpg')"}}>
 
            </div>


            <div className="row ventouttitle"  style={{display:'none'}}>
                        <div className="col-lg-12 cllgsect66">
                           <div className="section-title text-center mb-5" style={{display:'block'}}>
                            <h2 className="border-left-right-btm">Bienvenue sur ventout </h2>
                           </div>
                        </div>
            </div>

            <div className='row' style={{marginBottom:'30px'}}>

              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='flx124'>
                  
                   <a className="ps-btn ps-btn--black f1" style={{width:'100%'}} href="javascript:void(0)" onClick={()=>user.user_id ? handleShow() : history.push(`/login?back=ventout`) } >Publier une annonce</a>
           

                </div>
              </div>
              <div className='col-md-4'></div>

            </div>

     
       <div className='headerpagecateg'>

            

            {categorieslist?.map((item) => (
                          <div className='item' onClick={(event)=>HandleFiltre(item.category_id,'categorie',event)}>
                             <h4  className={item.category_id==categid  ?'active' :''}>{item.category_name}</h4>
                          </div>        
                          ))}
             
            
            </div>


           <div className="row filtrerightmobile">
                        <div className="col-lg-12 ishiden">
                             <div className=''>
                               <div className='orderBy'>
                                 <div className="select-filtre" onClick={handlerMenu}>
                                          <div><i className='fa fa-bars'></i></div>
                                          <span>Filtres</span>
                                  </div>
                               </div>
                               <div className="form-group--nest"><input className="form-control" type="text" placeholder="Rechercher un article.." onChange={e => setSearch(e.target.value)}/><div className='ico' onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>

                            </div>
                        </div>
                        <div className="col-lg-12 ishiden">

                                             <div className={showcategorie==true ? "flitres filtredropdown activefiltre" : "flitres filtredropdown" }>

                    <h3>Filtrer par</h3>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Catégorie</Accordion.Header>
    <Accordion.Body>
    <ul className='catglist'>
       {souscategorieslist?.map((item) => (
           
           <li onClick={(event)=>HandleFiltre(item.category_id,'souscategorie',event)} className={item.category_id==souscategid ?'active' :''}>{item.category_name}</li>      
      ))}
 
    </ul>
     
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Prix</Accordion.Header>
    <Accordion.Body>
      <Box sx={{ width: '100%' }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <div style={{marginTop:'5px'}}>
      <span className="_3AtSb">{parseFloat(minprice).toFixed(2)}CFA
</span>

      <span class="_3AtSr">{parseFloat(maxprice).toFixed(2)}CFA

</span>

      </div>
    </Box>
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="2">
    <Accordion.Header>Couleur</Accordion.Header>
    <Accordion.Body>

      <ul className='Xgz5B'>
   
       {colors?.map((item) => (
           
              
       <li onClick={(event)=>HandleFiltre(item.color_id,'color',event)} title={item.color_name}  >
          <label  className={colorid.includes(item.color_id) ? "_35LeV coloractive":"_35LeV" }><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

    </ul>
     
      
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="3">
    <Accordion.Header>Tailles</Accordion.Header>
    <Accordion.Body style={{    maxHeight: '208px',overflowY: 'auto'}} >

      {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <ul className='_1A0B3'>
           {item?.tail_code?.map((code) => (
                   <li onClick={(event)=>HandleFiltre(code,'tail',event)}  ><input class="_1LXRy"  type="checkbox"  value="2XL"/><label className={tailid.includes(code) ? "wixSdkShowFocusOnSibling _3dpj1 tailactive":"wixSdkShowFocusOnSibling _3dpj1" }   for="3comp-ku68r1742XL"><span className="_1Cv08">{code}</span></label></li>
            ))}
          
      
        </ul>

        </div>
              
      
     

      ))}
      
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

                   </div>

                        </div>
                </div>

              <div className='row'>
                <div className='col-md-3 '>
                   <div className="flitres">

                    <h3>Filtrer par</h3>


<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Catégorie</Accordion.Header>
    <Accordion.Body>
    <ul className='catglist'>
       {souscategorieslist?.map((item) => (
           
           <li onClick={(event)=>HandleFiltre(item.category_id,'souscategorie',event)} className={item.category_id==souscategid ?'active' :''}>{item.category_name}</li>      
      ))}
    
    </ul>
     
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Prix</Accordion.Header>
    <Accordion.Body>
      <Box sx={{ width: '100%' }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <div style={{marginTop:'5px'}}>
      <span className="_3AtSb">{parseFloat(minprice).toFixed(2)}CFA</span>

      <span class="_3AtSr">{parseFloat(maxprice).toFixed(2)}CFA

</span>

      </div>
    </Box>
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="2">
    <Accordion.Header>Couleur</Accordion.Header>
    <Accordion.Body>

      <ul className='Xgz5B'>

       {colors?.map((item) => (
           
              
       <li onClick={(event)=>HandleFiltre(item.color_id,'color',event)} title={item.color_name}  >
          <label  className={colorid.includes(item.color_id) ? "_35LeV coloractive":"_35LeV"}><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

      
 

      </ul>
     
      
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="3">
    <Accordion.Header>Tailles</Accordion.Header>
    <Accordion.Body>

      {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <ul className='_1A0B3'>
           {item?.tail_code?.map((code) => (
                   <li onClick={(event)=>HandleFiltre(code,'tail',event)}  ><input class="_1LXRy"  type="checkbox"  value="2XL"/><label className={tailid.includes(code) ? "wixSdkShowFocusOnSibling _3dpj1 tailactive":"wixSdkShowFocusOnSibling _3dpj1" }   for="3comp-ku68r1742XL"><span className="_1Cv08">{code}</span></label></li>
            ))}
          
      
        </ul>

        </div>
              

      ))}

      
    </Accordion.Body>
  </Accordion.Item>
</Accordion>




                   </div>
                </div>

                <div className='col-md-9 products'>
                  <div className='filtreright'>

                   <div className='headerpagecategweb'>

            

                         {categorieslist?.map((item) => (
                          <div className='item' onClick={(event)=>HandleFiltre(item.category_id,'categorie',event)}>
                             <h4  className={item.category_id==categid  ?'active' :''}>{item.category_name}</h4>
                          </div>        
                          ))}

                         
                        
                        </div>


                       <div className='orderBy'>
                       <div className="select shop__header--select">
                                <select onChange={e => setOrderfiltre(e.target.value)}  className="product__view--select">
                                    <option   value="">Trier par :</option>
                                    <option   value="1">Trier par nouveauté</option>
                                    <option value="2">Trier par prix</option>
                                    
                                </select>
                            </div>
                     </div>
                     <div className="form-group--nest"><input className="form-control" type="text" placeholder="Rechercher un article.."  onChange={e => setSearch(e.target.value)}/><div className='ico' onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>

                  </div>



                  <div className='list_items'>

                   <Row className="g-5" >

                    {dataamazon?.map((item) => (
                           <Col md={3} className='col-6' >
                                <VentoutItem item={item} />
                        </Col>
                      ))}

          

                   </Row>


                     <Row style={{marginTop: '100px'}}>
                    <ReactPaginate
                    pageCount={pageCount}
                    initialPage={currentPage - 1}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    itemClass="page-item"
                    linkClass="page-link"
                    containerClassName="pagination uk-flex-center"
                    activeClassName="active"
                    disabledClassName="disabled"
                    onPageChange={handlePageClick}
                    disableInitialCallback={true}
                />
    </Row>
                   


                  </div>




                </div>

              </div>

            </div>

         </section>
 




      <Modal show={show} onHide={handleClose} className='modalpublish'>
        <Modal.Header   >
           <span className='mop1'>Publier une annonce</span>
          <CloseButton variant="black" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
   
        <Form onSubmit={handleSubmitAnnonce}>
 

           <div className='marg5'>
           <h5 className='mop1'>Description de l'article</h5>
           <p className='mop2'>Rentrez un titre, un prix, et une description </p>
         </div>

          <div className='marg6'>
           <label><strong>Titre de l'article</strong></label>
           <input required type="text" className="form-control" name='titre' placeholder='Ex: Chaussures compensées' />
         </div>

          <div className='marg6'>
           <label><strong>Prix</strong> (F CFA)</label>
           <input required type="number" name='prix'  className="form-control" placeholder='Ex: 15000'  onChange={e => setPricevalue(e.target.value)} />
         </div>

          <div className='marg6'>
          <div className='flxmdl'>
            <div><img src="/images/devis.png"/></div>
            <div>
              <p>Frais de service 10%: <strong>{ parseFloat(yourprice).toFixed(2)}  CFA</strong></p>
              <p>Vous toucherez: <strong>{parseFloat(fraiservice).toFixed(2)}  CFA</strong></p>
            </div>
          </div>
         </div>

          <div className='marg6'>
           <label><strong>Catégorie</strong></label>
            <select required className='form-control' name='categorie'>
            <option></option>


               
                {categorieslist?.map((item) => (

                    <optgroup label={item.category_name} >
                      {souscategorieslist?.map((item2) => (
                         item.category_id == item2.parent_id &&
                           <option value={item2.category_id}>{item2.category_name}</option> 

                    ))}   

                    </optgroup>
            ))}
                  
                    
            

            </select>
         </div>

          <div className='marg6'>
           <label><strong>Couleur</strong></label>
           <div className='inp inp1' onClick={()=>handleShowColor()}>
            Choisir une couleur{valColor && <label class="Uy778" style={{backgroundColor: valColor}}></label>}
            <span style={{display:'none'}}><input type='text' name='color' value={valColor} />
<input type='text' name='color_id' value={valColorid} />
            </span>
           </div>
         </div>


         <div className='marg6'>
           <label><strong>Taille</strong></label>
           <div className='inp inp1' onClick={()=>handleShowTaille()}>
           Choisir une taille{valTaille && ': ' + valTaille }
           <span style={{display:'none'}}><input type='text' name='tail' value={valTaille} /></span>
           </div>
         </div>

           <div className='marg6'>
           <label><strong>Authencité</strong></label>
            <p className='psr'>Nous vérifierons l'authencité de votre article avant livraison.</p>
            <div className=' inp2' >

              <Form.Check
                required
                label="Marque ORIGINALE"
                name="authencite"
                value="Marque ORIGINALE"
                type={'radio'}
                id={'radio1'}
              />

              <Form.Check
                required
                label="Marque non reconnue"
                value="Marque non reconnue"
                name="authencite"
                type={'radio'}
                id={'radio2'}
              />
               
            </div>

         </div>

          <div className='marg6'>
           <label><strong>Etat de l'article</strong></label>
            <p>Votre article est-il neuf ou d'occasion ?</p>
            <div className=' inp2' >

              <Form.Check
                required
                label="Neuf"
                value='Neuf'
                name="etat"
                type={'radio'}
                id={'radio3'}
              />

              <Form.Check
                 required
                label="Occasion"
                value='Occasion'
                name="etat"
                type={'radio'}
                id={'radio4'}
              />
               
            </div>

         </div>

        <div className='marg6'>
           <label><strong>Vos informations</strong></label>
            <p className='psr'>Aucun autre utilisateur ne verra vos informations.<br/>Notre équipe s'en servira pour vous contacter.</p>
      

         </div>

         <div className='marg6'>
           <label><strong>Lieu</strong></label>
           <input required type="text" className="form-control" placeholder='Ex: Abidjan Cocody' name='lieu' />
         </div>

          <div className='marg6'>
           <label><strong>Numéro de téléphone</strong></label>
           <input required type="text" className="form-control" placeholder='N° de téléphone' name='telephone' defaultValue={user?.phone} />
         </div>
          <div className='marg6'>
           <label><strong>Numéro Whatsapp</strong></label>
           <input required type="text" className="form-control" placeholder='N° de téléphone (Whatsapp)' name='whatsap' defaultValue={user?.numwhatsap} />
         </div>

          <div className='marg6'>
           <label><strong>Photo de l'article</strong></label>
           <p className='psr'>Vous pouvez ajouter jusqu'à 3 photos maximum</p>
           <div className='photosblock'>
               <div className='item' onClick={() => handleClickfile(1)} >
                      <img src={photo1 ? photo1 : '/images/Groupe7130.png' } />
                    <form>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,1)}
                        ref={fileInput} 
                    />
                    </form>
                    <span style={{display:'none'}}><input type='text' name='photo1' value={photo1} /></span>
               </div>
               <div className='item' onClick={() => handleClickfile(2)} >
                      <img src={photo2 ? photo2: '/images/Groupe7130.png' } />
                    <form>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,2)}
                        ref={fileInput2} 
                    />
                    </form>
                     <span style={{display:'none'}}><input type='text' name='photo2' value={photo2} /></span>
               </div>
                <div className='item' onClick={() => handleClickfile(3)} >
                      <img src={photo3 ? photo3 : '/images/Groupe7130.png' } />
                    <form>
                       <input
                       style={{display:'none'}}
                        type="file"

                        onChange={(e) => handleFileChange(e,3)}
                        ref={fileInput3} 
                    />
                    </form>
                     <span style={{display:'none'}}><input type='text' name='photo3' value={photo3} /></span>
               </div>
             

           </div>
           <br/>

           <div class='row'>

              <Form.Group className='col-md-12' >
                                     <a href='javascript:void(0)'onClick={() => handleClickfile(4)}  className='ps-btn ps-btn--black btn88900' style={{ background: '#073460'}} > {video == '' ? 'Ajouter une vidéo' : 'Modifier la vidéo'} <i className='fa fa-download'></i>
                                           
                                          <form style={{padding:'0px'}}>
                                             <input
                                             style={{display:'none'}}
                                              type="file"

                                              onChange={(e) => handleFileChange(e,4)}
                                              ref={fileInput4} 
                                          />
                                          </form>
                                     </a>
                                     {
                                      video &&
                                        <div>{videoname}</div>
                                     }
                                     
                                     <p className='psr center'>Votre vidéo ne doit pas dépasser 30s maximum</p>
                                      <span style={{display:'none'}}><input type='text' name='video' value={video} /></span>
                                    
                                </Form.Group>
                                   <br/>

                            </div>


         </div>
 <div className='marg6'>
 <button type='submit'  className="ps-btn ps-btn--black btn88900"    >Publier mon annonce</button>
 </div>


        </Form>
          





        </Modal.Body>
        <Modal.Footer>


        
        
        </Modal.Footer>
      </Modal>




      <Modal show={showColor} fullscreen={true} onHide={() => setShowColor(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Couleur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Choisissez une couleur</p>

        <div className='listing '>

          <ul className='Xgz5B'>
   
       {colors?.map((item) => (
           
              
       <li onClick={()=>setValColorF(item.color_code,item.color_id)} title={item.color_name}  >
          <label  className={valColor==item.color_code ? "_35LeV coloractive":"_35LeV" }><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

    </ul>


        </div>

        </Modal.Body>
             <Modal.Footer className='ventout'>

          <div className='row' style={{width:'100%'}}>

              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='flx124'>
                  
                   <a className="ps-btn ps-btn--black f1" href="javascript:void(0)"  onClick={()=>handleCloseColor()}  >Valider</a>
                   <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleCloseColor()} >Fermer</a>

                </div>
              </div>
              <div className='col-md-4'></div>

            </div>
        
        
        </Modal.Footer>


      </Modal>


      <Modal show={showTaille} fullscreen={true} onHide={() => setShowTaille(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Taille</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Choisissez une taille</p>

        <div className='listing '>

        {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <div className='modblockgr'>
           {item?.tail_code?.map((code) => (
                    
                   <div onClick={()=>setValTaille(code)} className={valTaille== code  ? "item tailactivesent":"item" }  > {code} </div>

            ))}
          
      
        </div>

        </div>
              
      
     

      ))}

 


        </div>

        </Modal.Body>
             <Modal.Footer className='ventout'>

          <div className='row' style={{width:'100%'}}>

              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='flx124'>
                  
                   <a className="ps-btn ps-btn--black f1" href="javascript:void(0)" onClick={()=>handleCloseTaille()}  >Valider</a>
                   <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleCloseTaille()}>Fermer</a>

                </div>
              </div>
              <div className='col-md-4'></div>

            </div>
        
        
        </Modal.Footer>


      </Modal>


      <Modal show={showmodal} onHide={handleCloseP} centered>
        <Modal.Header closeButton>
           
        </Modal.Header>
        <Modal.Body>

        <p style={{textAlign:'center',marginBottom: '20px'}}> <img style={{width: '100px'}} src='/images/valide.png' /></p>
       
        <p> Votre annonce a bien été ajoutée, elle sera traitée dans les meilleurs délais.</p>

         </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseP}>
            Close
          </Button>
          <Button style={{color:'#FFF'}} className='ps-btn ps-btn--black'   onClick={()=>history.push(`/commandes`)}>
           Mon profil
          </Button>
        </Modal.Footer>
     </Modal>


<Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>


 <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>
           
             
        </div>
    );
}

export default Page;