import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { ProductItem } from "../../../components";
import { ProductItemPromo } from "../../../components";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Accordion from 'react-bootstrap/Accordion'
import { getBest,getCategoryList, getColors, getTails,getsousCategoryList,getListProduct } from "../../../actions/mixedmode";
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import ReactPaginate from 'react-paginate';


function valuetext(value) {
  return `${value}°C`;
}
const Page = () => {
    const dispatch = useDispatch();
     const { slug } = useParams()
    const history = useHistory()
    const {categorieslist,souscategorieslist, colors, tails,dataamazon,pageCount } = useSelector(state => state.mixedmode)
    const [showcategorie,setShowcategorie]=useState(false);
    const [categid,setCategid]=useState('');
    const [souscategid,setSousCategid]=useState('');
    const [colorid,setColorid]=useState([]);
    const [tailid,setTailid]=useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [rubrique, setRubrique] = useState(2)
    const [listprodutc,setListProduct]=useState([]);
    const [filtres,setFiltres]=useState([]);
    const [search, setSearch] = useState('')
    const [order, setOrder] = useState('')
    const [counter, setCounter] = useState('')
    const [ifprice, setIfprice] = useState('')
    const [minprice, setMinprice] = useState(7000)
    const [maxprice, setMaxprice] = useState(100000)

     const [value, setValue] = useState([7, 180]);
     const handleChange = (event, newValue) => {
       setValue(newValue);
       setMinprice(parseFloat(newValue[0]) * 1000)
       setMaxprice(parseFloat(newValue[1]) * 1000)
       setIfprice(1)
       setCounter(1)

     };
 


    useEffect(()=>{

        let params={slug:rubrique}
        dispatch(getCategoryList(rubrique))
        dispatch(getListProduct(currentPage,params))
        dispatch(getsousCategoryList(rubrique))
        dispatch(getColors())
        dispatch(getTails())
           if(Number.isInteger(parseInt(slug))){
            
            setCategid(slug)
            setCounter(1)
        }

        window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };
    },[])

    const isSticky = (e) => {
    const header = document.querySelector(".filtrerightmobile");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    };

     const handleClick = (slug) => {
        history.push(`/boutiques/${slug}#`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
 
        
    }
     const handlerMenu = () => {
       
          setShowcategorie(!showcategorie)

    }

    const handleGoTo = (slug) => {
        history.push(slug);

    }

    const setOrderfiltre = (val) => {
        setOrder(val)
        setCounter(1)

    }

   const initfiltre = () => {
     setCategid('')
     setSousCategid('')
     setColorid([])
     setTailid([])

  }

  const searchproduct = (e) => {
          e.preventDefault();
 
          if(search){
             let params ={slug:rubrique,search:search,order:order}
             dispatch(getListProduct(currentPage,params))

          }
          else{
           
             let params ={slug:rubrique,order:order}
             dispatch(getListProduct(currentPage,params))

         
          }

          initfiltre()

    }


   useEffect(() => {
   if(counter){
      setFormFiltre()
   }
   console.log('Do something after counter has changed', counter);
}, [counter]);


 
    const HandleFiltre =  (slug,type,e) => {
      
 
      
e.preventDefault();
          switch(type){
             case 'categorie':
                setCategid(slug)
                dispatch(getsousCategoryList(rubrique,slug))
                setCounter(1)

             break;

             case 'souscategorie':

                 setSousCategid(slug)
                 setCounter(1)
             break;

             case 'color':
             
                 let newcolors=[...colorid];
                 var index = newcolors.indexOf(slug)
             
                 if(index > -1){
 
                    newcolors.splice(index,1);
                      setColorid(newcolors)
                      console.log(newcolors)
                      setCounter(1)
                 }
                 else{
                     newcolors.push(slug) 
                   setColorid(newcolors)
                   setCounter(1)
                 }
                 
                   
             break;

             case 'tail':
              
                 let newtail=[...tailid];
                 var index = newtail.indexOf(slug)
                 if(index > -1){

                    newtail.splice(index,1);
                    setTailid(newtail)
                    setCounter(1)
                 }
                 else{
                     newtail.push(slug) 
                     setTailid(newtail)
                     setCounter(1)
                 }
                 
             break;

             case 'price':
                
             break;


          }

        
            
 
    }

    const  setFormFiltre = async () => {
      let params ={filtre:'1',slug:rubrique,order:order}


      if(ifprice==1){
          params.price=value
      }

      if(categid){
        
        params.categorie=categid

      }
      if(souscategid){
         
         params.souscateg=souscategid
      }
      if(colorid){
           params.colors=colorid

      }
      if(tailid){
           params.tails=tailid

      }
  
     dispatch(getListProduct(currentPage,params))
     setCounter('')

    }

      const   handlePageClick= (data) => {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        setCurrentPage(page)
         setCounter(1)
         window.scrollTo({top: 0});


           
    }


    return (
        <div className="categories listing" >

         <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>Afro Creation</li></ul></div></div></div>
         </section>

         <section className='bgcouverture'>
             <div className='headerpageimgweb' style={{backgroundImage:"url('/images/afrocreactionplaet.jpg')"}}></div>
         </section>

         <section className='section2'>
            <div className="container">

              <div className=" bigcategories">
                       
                        <div className='item '>
                        <a href='javascript:void(0)' className='fashion ' onClick={()=>handleGoTo('/fashion-style/produits/Homme#')} ><img src='/images/fashionrond.jpg' /></a>
                        <label>Fashion</label>
                       </div>

                       <div className='item '>
                        <a href='javascript:void(0)'   className='afrocreation ' ><img src='/images/afrocreationrond.jpg' /></a>
                        <label>Afro Creation</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/ventout#')} className='ventout' ><img src='/images/ventoutrond.jpg' /></a>
                         <label>Ventout</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/sur-commande/produits/1#')}  className='surcommande' ><img src='/images/surcommanderond.jpg' /></a>
                         <label>Sur commande</label>
                       </div>      
            </div>


            <div className='headerpageimg' style={{backgroundImage:"url('/images/afrocreactionplaet.jpg')"}}>
 
            </div>

            <div className='headerpagecateg'>

            

             {categorieslist?.map((item) => (
                          <div className='item' onClick={(event)=>HandleFiltre(item.category_id,'categorie',event)}>
                             <h4  className={item.category_id==categid  ?'active' :''}>{item.category_name}</h4>
                          </div>        
                          ))}

             
            
            </div>



                 <div className="row" style={{display:'none'}}>
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Afro Création </h2>
                           </div>
                        </div>
                </div>

                 <div className="row filtrerightmobile">
                        <div className="col-lg-12 ishiden">
                             <div className=''>
                               <div className='orderBy'>
                                 <div className="select-filtre" onClick={handlerMenu}>
                                          <div><i className='fa fa-bars'></i></div>
                                          <span>Filtres</span>
                                  </div>
                               </div>
                               <div className="form-group--nest"><input className="form-control" type="text" placeholder="Rechercher un article.." onChange={e => setSearch(e.target.value)}/><div className='ico' onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>

                            </div>
                        </div>
                        <div className="col-lg-12 ishiden">

                                             <div className={showcategorie==true ? "flitres filtredropdown activefiltre" : "flitres filtredropdown" }>

                    <h3>Filtrer par</h3>


<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Catégorie</Accordion.Header>
    <Accordion.Body>
    <ul className='catglist'>
      {souscategorieslist?.map((item) => (
           
           <li onClick={(event)=>HandleFiltre(item.category_id,'souscategorie',event)} className={item.category_id==souscategid ?'active' :''}>{item.category_name}</li>      
      ))}
 
    </ul>
     
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Prix</Accordion.Header>
    <Accordion.Body>
      <Box sx={{ width: '100%' }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <div style={{marginTop:'5px'}}>
      <span className="_3AtSb">{parseFloat(minprice).toFixed(2)}CFA
</span>

      <span class="_3AtSr">{parseFloat(maxprice).toFixed(2)}CFA

</span>

      </div>
    </Box>
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="2">
    <Accordion.Header>Couleur</Accordion.Header>
    <Accordion.Body>

      <ul className='Xgz5B'>
   
       {colors?.map((item) => (
           
              
       <li onClick={(event)=>HandleFiltre(item.color_id,'color',event)} title={item.color_name}  >
          <label  className={colorid.includes(item.color_id) ? "_35LeV coloractive":"_35LeV" }><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

    </ul>
     
      
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="3">
    <Accordion.Header>Tailles</Accordion.Header>
    <Accordion.Body style={{    maxHeight: '208px',overflowY: 'auto'}}>

      {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <ul className='_1A0B3'>
           {item?.tail_code?.map((code) => (
                   <li onClick={(event)=>HandleFiltre(code,'tail',event)}  ><input class="_1LXRy"  type="checkbox"  value="2XL"/><label className={tailid.includes(code) ? "wixSdkShowFocusOnSibling _3dpj1 tailactive":"wixSdkShowFocusOnSibling _3dpj1" }   for="3comp-ku68r1742XL"><span className="_1Cv08">{code}</span></label></li>
            ))}
          
      
        </ul>

        </div>
              
      
     

      ))}
      
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

                   </div>

                        </div>
                </div>

              <div className='row'>
                <div className='col-md-3 '>
                   <div className="flitres">

                    <h3>Filtrer par</h3>


<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header>Catégorie</Accordion.Header>
    <Accordion.Body>
    <ul className='catglist'>
      {souscategorieslist?.map((item) => (
           
           <li onClick={(event)=>HandleFiltre(item.category_id,'souscategorie',event)} className={item.category_id==souscategid ?'active' :''}>{item.category_name}</li>      
      ))}
    
    </ul>
     
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Prix</Accordion.Header>
    <Accordion.Body>
      <Box sx={{ width: '100%' }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <div style={{marginTop:'5px'}}>
      <span className="_3AtSb">{parseFloat(minprice).toFixed(2)}CFA</span>

      <span class="_3AtSr">{parseFloat(maxprice).toFixed(2)}CFA

</span>

      </div>
    </Box>
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="2">
    <Accordion.Header>Couleur</Accordion.Header>
    <Accordion.Body>

      <ul className='Xgz5B'>

       {colors?.map((item) => (
           
              
       <li onClick={(event)=>HandleFiltre(item.color_id,'color',event)} title={item.color_name}  >
          <label  className={colorid.includes(item.color_id) ? "_35LeV coloractive":"_35LeV"}><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
       </li>

      ))}

      
 

      </ul>
     
      
    </Accordion.Body>
  </Accordion.Item>
 
  <Accordion.Item eventKey="3">
    <Accordion.Header>Tailles</Accordion.Header>
    <Accordion.Body>

      {tails?.map((item) => (

        <div style={{marginBottom:'16px'}}>
         
         <h4>{item.tail_name}</h4>  

         <ul className='_1A0B3'>
           {item?.tail_code?.map((code) => (
                   <li onClick={(event)=>HandleFiltre(code,'tail',event)}  ><input class="_1LXRy"  type="checkbox"  value="2XL"/><label className={tailid.includes(code) ? "wixSdkShowFocusOnSibling _3dpj1 tailactive":"wixSdkShowFocusOnSibling _3dpj1" }   for="3comp-ku68r1742XL"><span className="_1Cv08">{code}</span></label></li>
            ))}
          
      
        </ul>

        </div>
              
      
     

      ))}

    

    
      
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

                   </div>
                </div>

                <div className='col-md-9 products'>
                  <div className='filtreright'>
                   <div className='headerpagecategweb'>

            

                         {categorieslist?.map((item) => (
                          <div className='item' onClick={(event)=>HandleFiltre(item.category_id,'categorie',event)}>
                             <h4  className={item.category_id==categid  ?'active' :''}>{item.category_name}</h4>
                          </div>        
                          ))}

                         
                        
                        </div>
                     <div className='orderBy'>
                       <div className="select shop__header--select">
                                <select onChange={e => setOrderfiltre(e.target.value)}  className="product__view--select">
                                    <option   value="">Trier par :</option>
                                    <option   value="1">Trier par nouveauté</option>
                                    <option value="2">Trier par popularité</option>
                                    
                                </select>
                            </div>
                     </div>
                     <div className="form-group--nest"><input className="form-control" type="text" placeholder="Rechercher un article.." onChange={e => setSearch(e.target.value)}/><div className='ico'  onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>

                  </div>



                  <div className='list_items'>




                   <Row className="g-5" >

                      

                    {dataamazon?.map((item) => (
                        <Col md={3} className='col-6' >
                                
                        {
                          item.is_off==1 ?
                           <ProductItemPromo item={item} />
                          :
                          <ProductItem item={item} />

                        }


                        </Col>
                      ))}
               

                   </Row>

                     <Row style={{marginTop: '100px'}}>
                    <ReactPaginate
                    pageCount={pageCount}
                    initialPage={currentPage - 1}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    itemClass="page-item"
                    linkClass="page-link"
                    containerClassName="pagination uk-flex-center"
                    activeClassName="active"
                    disabledClassName="disabled"
                    onPageChange={handlePageClick}
                    disableInitialCallback={true}
                />
    </Row>

                   


                  </div>




                </div>

              </div>

            </div>

         </section>


         <section className='section2' style={{background:'#FFF'}} style={{display:'none'}} >
          <div className="ps-site-features">
            <div className="container">
                <div className="ps-block--site-features ps-block--site-features-2">
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-rocket"></i></div>
                        <div className="ps-block__right">
                            <h4>Free Delivery</h4>
                            <p>For all oders over $99</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-sync"></i></div>
                        <div className="ps-block__right">
                            <h4>90 Days Return</h4>
                            <p>If goods have problems</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-credit-card"></i></div>
                        <div className="ps-block__right">
                            <h4>Secure Payment</h4>
                            <p>100% secure payment</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-bubbles"></i></div>
                        <div className="ps-block__right">
                            <h4>24/7 Support</h4>
                            <p>Dedicated support</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         </section>

           
             
        </div>
    );
}

export default Page;
