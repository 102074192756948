
import React, { useRef, useEffect } from "react"
import "./style.scss"

const Component = ({ text, type, onChange }) => {
  var containerRef = useRef()


  const getFitParentSize = () => { // NOTA - percents not works in safari
    if (!containerRef.current) {
      return {}
    }

    return {
      width: containerRef.current.offsetWidth,
      height: containerRef.current.offsetHeight
    }
  }

  return (
    <div className='inpfilter item' ref={containerRef}>
      {text}
      <input style={getFitParentSize()} type={type} onChange={onChange}  />
    </div>
  )
}

export default Component
