import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)

    useEffect(() => {
        //dispatch(getCommandeById(user.id));
    }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/commande/${item.id}`);
     }
    
    return (
        <div className="mescommandes register " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <MenuVendeur slug='historique' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >

                    <form className="mb-5">
                           
                            <h3 className="mb-4" ><BiShoppingBag color={"#FF914D"} size={20} /> Mes commandes livrées</h3>
                             <br/> <br/>
                          <Row className="mb-3"  >
                           
                          <Table responsive>
                          <thead>
                            <tr>
                                <th>Référence</th>
                                <th key="0">Date</th>
                                <th key="1">Prix total</th>
                                <th key="2">Statut</th>
                                <th key="1">Actions</th>

                             
                            </tr>
                          </thead>
                        <tbody>
                            
                            <tr>
                              <td>OHLTJKV</td>
                              <td>
                                08/03/2022
                              </td>
                              <td>120.30€</td>
                              <td>
                                 <span className='badge badge-success'>Livrée</span>
                              </td>
                              <td>


                              <div className="list-contr">

                               <a  href="#" className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Facture <i class="icon-files"></i></a>
                               


                               </div>
                              </td>
                            </tr>
        
                    
                          
                          

                          </tbody>
                        </Table>
                           

                          </Row>

                    </form>
                        
                         






   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;