import React, { useState, useEffect } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux"

  const Page = () => {
  const { id } = useParams()
  const history = useHistory();
  const { user } = useSelector(state => state.user)


      useEffect(() => {
   
       if (!user.user_id ) {
            history.replace("/")
        }

   


  
     


 
    }, [user]);


  const goto = (e) => {

         if (user.google_id == 'A'  ) {
          
            history.push("/client/dashboard")
        }
        else{
             history.push("/praticien/dashboard")

        }
     
  }

    return (
    <div>
        <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Commande</li><li>Confirmation </li></ul></div></div></div>
      <section className="section2" style={{backgroundColor:'#FFF',paddingTop: '25px'}}>
        <div className="quisommesnous" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                        <p className='center'> <img src='/images/validation-150x150.png' /></p>

                        <h1 className="text-center mb-5">Merci pour votre commande sur Feel Better™ ! </h1>
                        <br/>
                         <p className='center'> Votre commande <strong>n° {id} </strong>sera traitée dans les meilleurs délais. </p>
                        <span className="section-separator"></span>
                        <br/><br/>

                        <a href="javascript:void(0)" onClick={(e)=> goto(e)}className="btnlink marauto" style={{color:'#FFF'}}>Retour <i class="fa fa-long-arrow-right"></i></a>

           
                           

 
                    </Col>
                </Row>




            </Container>
       
        </div>
  </section>
 
   
    </div>
    );
}

export default Page;